/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, useTheme, useMediaQuery, Button } from '@mui/material';
import { collection, doc, onSnapshot, orderBy, query, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { AldgDataGrid, InputSelect } from '@aldridge/aldg-data-components';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { firestore } from '../firebase';
import ErrorBoundary from '../_GlobalComponents/ErrorBoundary';

const SegmentsTable = () => {
  const [Segments, setSegments] = useState([]);
  const [StatusOption, setStatusOption] = useState([]);
  const GridApiRef = useGridApiRef();

  useEffect(() => {
    let mounted = true;
    const fRef = query(collection(firestore, 'ENT-Segments'), orderBy('SegmentValue'));
    onSnapshot(
      fRef,
      (querySnapshot) => {
        const all = [];
        querySnapshot.forEach((d) => {
          all.push(d.data());
        });
        if (mounted) {
          setSegments(all);
        }
      },
      (err) => {
        toast.error(err.message);
      }
    );
    return () => {
      mounted = false;
    };
  }, []);

  const history = useHistory();

  const [rows, setRows] = useState([]);
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let mounted = true;
    if (mounted) setRows(Segments);
    return () => (mounted = false);
  }, [Segments]);

  useEffect(() => {
    console.log(StatusOption);
  }, [StatusOption]);

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const handleSelect = (idx) => {
    history.push(`/Segments/${idx}`);
  };

  const columns = [
    {
      field: 'Segment',
      headerName: 'Segment',
      valueGetter: (params) => params.row.SegmentValue,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'CostType',
      headerName: 'Cost Type',
      valueGetter: (params) => params.row.CostType,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'ECMSName',
      headerName: 'ECMS Description',
      valueGetter: (params) => params.row.SegmentDescription,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'PNP',
      headerName: 'Is Productive Cost Code?',
      valueGetter: (params) => (params.row.ProductiveCostCode === 'P' ? 'Yes' : 'No'),
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Status',
      headerName: 'Status',
      valueGetter: (params) => params.row.Status,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];

  const toggleStatus = async () => {
    const selectedItems = Array.from(GridApiRef.current.getSelectedRows(), ([key, value]) => ({ key, value }));
    const segmentIds = selectedItems.map((i) => i.key.toString());
    console.log(selectedItems);
    const updatedRows = rows.map((row) => {
      if (segmentIds.includes(row.jctccsid)) {
        return { ...row, Status: StatusOption };
        // row.Status === 'Yes' ? 'No' : 'Yes'
      }
      return row;
    });
    setRows(updatedRows);
    const batch = writeBatch(firestore);
    selectedItems.forEach((segment) => {
      const docRef = doc(firestore, `ENT-Segments/${segment.key}`);
      const newSegment = { ...segment.value, Status: StatusOption };
      // segment.value.Status === 'Yes' ? 'No' : 'Yes'
      batch.set(docRef, newSegment, { merge: true });
    });
    await batch.commit();
  };

  return (
    <ErrorBoundary componentName='Segments Table'>
      <div style={{ marginTop: '20px', marginLeft: 'auto' }}>
        <Grid container>
          <Grid item xs={6}>
            <InputSelect
              name='Status'
              label='Status'
              onChange={(e) => setStatusOption(e.target.value)}
              value={StatusOption}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' }
              ]}
              style={{ width: 150, marginTop: '10px', marginRight: '0px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Button onClick={toggleStatus} variant='contained' style={{ marginTop: '18px', marginLeft: '20px' }}>
              Set Status
            </Button>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <AldgDataGrid
            getRowId={(row) => row.jctccsid}
            onRowClick={(row) => handleSelect(row.id)}
            rows={rows}
            columns={columns}
            checkboxSelection
            apiRef={GridApiRef}
          />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

SegmentsTable.propTypes = {};
SegmentsTable.defaultProps = {};

export default SegmentsTable;
