import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { nonMuiThemes } from '../../../theme';
import { StatusContext } from '../../../providers/StatusProvider';
import GeneralInformation from './GeneralInformation';
import ContractDetails from './ContractDetails';
import ProjectedAnalysisStatus from './ProjectedAnalysisStatus';
import { SumCostDetails, SumLaborCostToComplete } from '../ProjectStatus/calculationHelpers';
import ProjectedAnalysisPM from './ProjectedAnalysisPM';
import { _CurrentJob } from '../../../_Recoil/atoms';

const CashFlow = (props) => {
  const { monthEndDate, memorialized } = props;
  const { contractValues, forecasting } = useContext(StatusContext);
  const CurrentJob = useRecoilValue(_CurrentJob);

  const { FinancialApprovedContract, OriginalContract, ContractAdjustments } = contractValues || {};

  const laborPT = SumCostDetails(structuredClone(forecasting), 'L', 'CostToDate') + SumLaborCostToComplete(structuredClone(forecasting));
  const [materialPT, subPT, equipPT, otherPT] = ['M', 'S', 'E', 'O'].map(
    (letter) =>
      SumCostDetails(structuredClone(forecasting), letter, 'CostToDate') + SumCostDetails(structuredClone(forecasting), letter, 'CostToComplete')
  );
  const totalPT = laborPT + materialPT + subPT + equipPT + otherPT;

  return (
    <Paper variant='outlined' className='aldg-field'>
      <Grid container className='aldg-gridNoPadding'>
        <Grid item xs={12} className='aldg-headerTitle'>
          {`Division ${CurrentJob.EcmsDivision} Projected Cash Flow Schedule`}
        </Grid>
        <GeneralInformation Transaction={CurrentJob} monthEndDate={monthEndDate} />
        <div style={nonMuiThemes.spacer} />
        <ContractDetails
          Transaction={CurrentJob}
          OriginalContract={OriginalContract}
          ContractAdjustments={ContractAdjustments}
          monthEndDate={monthEndDate}
        />
        <div style={nonMuiThemes.spacer} />
        <ProjectedAnalysisStatus
          CurrentContractValue={FinancialApprovedContract}
          laborPT={laborPT}
          materialPT={materialPT}
          subPT={subPT}
          equipPT={equipPT}
          otherPT={otherPT}
          totalPT={totalPT}
        />
        <div style={nonMuiThemes.spacer} />
        <ProjectedAnalysisPM
          totalPT={totalPT}
          CurrentContractValue={FinancialApprovedContract}
          Transaction={CurrentJob}
          monthEndDate={monthEndDate}
          laborPT={laborPT}
          materialPT={materialPT}
          subPT={subPT}
          equipPT={equipPT}
          otherPT={otherPT}
          memorialized={memorialized}
        />
      </Grid>
    </Paper>
  );
};

CashFlow.propTypes = {
  monthEndDate: PropTypes.string,
  memorialized: PropTypes.bool
};
CashFlow.defaultProps = {
  monthEndDate: '2010-12-31',
  memorialized: false
};

export default CashFlow;
