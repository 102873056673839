import dayjs from 'dayjs';
import { collection, getDocs, query } from 'firebase/firestore';
import { firestore } from '../firebase';
import existsWithLength from './existsWithLength';

const getMostRecentMonth = async (jctdscid, monthEndDate) => {
  let mostRecentMonth = '2010-12-31';
  if (!existsWithLength(jctdscid) || !existsWithLength(monthEndDate)) return undefined;
  const allMonths = await getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd`)));
  const allMonthIDs = allMonths.docs.map((month) => month.id);
  const allMonthsPromises = [];
  allMonths.forEach((month) => {
    allMonthsPromises.push(getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${month.id}/ChangeOrders`))));
  });

  return Promise.all(allMonthsPromises).then((cos) => {
    cos.forEach((co, idx) => {
      const currentMonth = allMonthIDs[idx];
      if (!co.empty && dayjs(currentMonth).isBefore(dayjs(monthEndDate)) && dayjs(currentMonth).isAfter(dayjs(mostRecentMonth))) {
        mostRecentMonth = currentMonth;
      }
    });
    return mostRecentMonth;
  });
};

export default getMostRecentMonth;
