import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { useRecoilValue } from 'recoil';
import { nonMuiThemes } from '../../../theme';
import existsWithLength from '../../../utils/existsWithLength';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { AdminUp, DivisionalUp, EditorUp, SuperAdmin, ViewerUp } from '../UserAssignment/checkSecurity';
import { _CurrentJob, _MonthEndDate, _SecurityLevel } from '../../../_Recoil/atoms';
import { UserContext } from '../../../providers/UserProvider';
import { firebaseConfig } from '../../../firebase';

const StatusDataGrid = (props) => {
  const { rows, columns, title } = props;

  const user = useContext(UserContext);
  const Role = useRecoilValue(_SecurityLevel);
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  const jctdscid = useRecoilValue(_CurrentJob);

  const canUserViewNote = (_, rowWriterNote) => {
    if (SuperAdmin(Role)) return true;
    if (AdminUp(Role)) return !SuperAdmin(rowWriterNote);
    if (DivisionalUp(Role)) return !AdminUp(rowWriterNote);
    if (ViewerUp(Role)) return !DivisionalUp(rowWriterNote);
    return false;
  };
  return (
    <>
      <AldgDataGrid
        pageSizeOptions={[10, 20, 30]}
        columns={columns}
        extraClasses={[{ '& .negative': nonMuiThemes.negative }]}
        FirebaseProps={{
          firebaseConfig,
          collection: `ENT-Jobs/${jctdscid}/AldgDataGridNotes`
        }}
        getRowId={(r) => r.Title || r.id}
        globalCellClassName={(params) => (NaNtoZero(params.value) < -0.01 ? 'negative' : '')}
        loading={!existsWithLength(rows)}
        noToolbar
        noteSettings={{
          allowNotes: true,
          canUserViewNote,
          getUserRole: () => Role,
          canUserAddNote: EditorUp(Role),
          noteCollection: `ENT-Jobs/${jctdscid.id}/AldgDataGridNotes`,
          dataGridName: `${title} Table`,
          meta: {
            MonthEndDate,
            Page: 'Page Status Report'
          }
        }}
        rows={rows}
        user={user}
      />
    </>
  );
};

StatusDataGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
};
StatusDataGrid.defaultProps = {
  rows: [],
  columns: [],
  title: ''
};

export default StatusDataGrid;
