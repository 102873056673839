import { formatAsCurrency, formatAsPercent, formatWithCommas } from '../../UIComponents/format';

const formatter = (type, value, title, decimals = 0) => {
  if (title.includes('Hours')) return formatWithCommas(value, true, decimals);
  if (title === 'Gross Return %') return formatAsPercent(value, true, decimals);
  switch (type) {
    case 'currency':
      return formatAsCurrency(value, true, decimals);
    case 'accounting':
      return formatWithCommas(value, true, decimals);
    default:
      return value;
  }
};

const originalBudget = (getWidth, getFlex, type = 'currency', decimals = 0) => ({
  field: 'OriginalBudget',
  headerAlign: 'center',
  headerName: 'Original Budget',
  align: 'center',
  valueGetter: (params) => params.row.OriginalBudget,
  valueFormatter: (params) => formatter(type, params.value, params.id, decimals),
  width: getWidth(150),
  flex: getFlex(150)
});

const customerReAllocation = (getWidth, getFlex, type = 'currency', decimals = 0) => ({
  field: 'ConstructionReAllocation',
  headerAlign: 'center',
  headerName: 'Construction Re-Allocation',
  align: 'center',
  valueFormatter: (params) => formatter(type, params.value, params.id, decimals),
  valueGetter: (params) => params.row.ConstructionReAllocation,
  width: getWidth(160),
  flex: getFlex(160)
});

const approvedChanges = (getWidth, getFlex, type = 'currency', decimals = 0) => ({
  field: 'ApprovedChanges',
  headerAlign: 'center',
  headerName: 'Approved Changes',
  align: 'center',
  valueFormatter: (params) => formatter(type, params.value, params.id, decimals),
  valueGetter: (params) => params.row.ApprovedChanges,
  width: getWidth(150),
  flex: getFlex(150)
});

const revisedBudget = (getWidth, getFlex, type = 'currency', decimals = 0) => ({
  field: 'RevisedBudget',
  headerAlign: 'center',
  headerName: 'Revised Budget',
  align: 'center',
  valueFormatter: (params) => formatter(type, params.value, params.id, decimals),
  valueGetter: (params) => params.row.RevisedBudget,
  width: getWidth(150),
  flex: getFlex(150)
});

const costToDate = (getWidth, getFlex, type = 'currency', decimals = 0) => ({
  field: 'CostToDate',
  headerAlign: 'center',
  headerName: 'Cost To Date',
  align: 'center',
  valueFormatter: (params) => formatter(type, params.value, params.id, decimals),
  valueGetter: (params) => params.row.CostToDate,
  width: getWidth(150),
  flex: getFlex(150)
});

const costToComplete = (getWidth, getFlex, type = 'currency', decimals = 0) => ({
  field: 'CostToComplete',
  headerAlign: 'center',
  headerName: 'Cost To Complete',
  align: 'center',
  valueFormatter: (params) => formatter(type, params.value, params.id, decimals),
  valueGetter: (params) => params.row.CostToComplete,
  width: getWidth(150),
  flex: getFlex(150)
});

const projectedTotal = (getWidth, getFlex, type = 'currency', decimals = 0) => ({
  field: 'ProjectedTotal',
  headerAlign: 'center',
  headerName: 'Projected Total',
  align: 'center',
  valueFormatter: (params) => formatter(type, params.value, params.id, decimals),
  valueGetter: (params) => params.row.ProjectedTotal,
  width: getWidth(150),
  flex: getFlex(150)
});

const variance = (getWidth, getFlex, type = 'currency', decimals = 0) => ({
  field: 'Variance',
  headerAlign: 'center',
  headerName: 'Variance',
  align: 'center',
  valueFormatter: (params) => formatter(type, params.value, params.id, decimals),
  valueGetter: (params) => params.row.Variance,
  width: getWidth(150),
  flex: getFlex(150)
});

const title = (getWidth, getFlex) => ({
  field: 'id',
  headerAlign: 'center',
  headerName: '',
  align: 'left',
  valueGetter: (params) => params.id,
  width: getWidth(135),
  flex: getFlex(135)
});

export {
  originalBudget,
  customerReAllocation,
  approvedChanges,
  revisedBudget,
  costToDate,
  costToComplete,
  projectedTotal,
  variance,
  title,
  formatter
};
