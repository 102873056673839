import React from 'react';
import { Button, Paper, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/pro-regular-svg-icons';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { _CurrentJob } from '../_Recoil/atoms';

const UnauthorizedJob = () => {
  const Record = useRecoilValue(_CurrentJob);
  const history = useHistory();
  return (
    <Paper>
      <Grid container spacing={1} style={{ padding: '8px' }}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <FontAwesomeIcon size='8x' icon={faWarning} color='red' />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Typography variant='h5' align='center'>
            You are currently unauthorized to modify or view this Job. Please reach out to the PM ({Record?.ProjectManager?.trim()}) for access. If
            you are the PM, please reach out to Job Cost so that the Job can be updated.
          </Typography>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={3} />
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <Button
            type='button'
            variant='contained'
            onClick={() => {
              history.push('/');
            }}
          >
            Back to Job List
          </Button>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </Paper>
  );
};
export default UnauthorizedJob;
