import { collection, getDocs, query } from 'firebase/firestore';
import { isUndefined } from '@aldridge/aldg-helpers';
import NaNtoZero from '../../../utils/convertNaNToZero';
import existsWithLength from '../../../utils/existsWithLength';
import getLastIndex from '../../../utils/getLastIndex';
import { firestore } from '../../../firebase';

const cleanCO = (co) => {
  const cleanedCO = JSON.parse(JSON.stringify(co));
  cleanedCO.SegmentValue = cleanedCO.SegmentValue?.toString();
  cleanedCO.LaborHours = NaNtoZero(co.LaborHours);
  cleanedCO.LaborCost = NaNtoZero(co.LaborCost);
  cleanedCO.BurdenCost = NaNtoZero(co.BurdenCost);
  cleanedCO.LaborTotal = NaNtoZero(co.LaborTotal);
  cleanedCO.Total = NaNtoZero(co.Total);
  return cleanedCO;
};

const mergeCOs = (coArr1, coArr2, COType) => {
  const finalCOArr = coArr1;
  coArr2.forEach((uncleanedCO) => {
    // if the cost code in the second array is also in the first array, merge
    const co = cleanCO(uncleanedCO);
    const dupIdx = finalCOArr.findIndex((fco) => `${fco.PayItem}${fco.CostCode[0]}` === `${co.PayItem}${co.CostCode[0]}`);
    if (dupIdx >= 0) {
      if (existsWithLength(co.FriendlyName) && co.FriendlyName.length > NaNtoZero(finalCOArr[dupIdx]?.FriendlyName?.length))
        finalCOArr[dupIdx].FriendlyName = co.FriendlyName;
      if (COType === 'LaborChangeOrders') {
        const totalHours = existsWithLength(co.LaborHours) ? NaNtoZero(co.LaborHours) + NaNtoZero(finalCOArr[dupIdx].LaborHours) : 0;
        const totalLabor = NaNtoZero(finalCOArr[dupIdx].LaborTotal) + NaNtoZero(co.LaborTotal);
        finalCOArr[dupIdx].LaborHours = NaNtoZero(totalHours.toFixed(2));
        finalCOArr[dupIdx].LaborCost = NaNtoZero(finalCOArr[dupIdx].LaborCost) + NaNtoZero(co.LaborCost);
        finalCOArr[dupIdx].BurdenCost = NaNtoZero(finalCOArr[dupIdx].BurdenCost) + NaNtoZero(co.BurdenCost);
        finalCOArr[dupIdx].LaborTotal = NaNtoZero(totalLabor);
        if (totalHours !== 0) finalCOArr[dupIdx].LaborRate = NaNtoZero(NaNtoZero(totalLabor) / NaNtoZero(totalHours));
      } else {
        if (isUndefined(finalCOArr[dupIdx].Total)) {
          finalCOArr[dupIdx].Total = 0;
        }
        finalCOArr[dupIdx].Total = NaNtoZero(finalCOArr[dupIdx].Total) + NaNtoZero(co.Total);
      }
    } else finalCOArr.push(co); // otherwise add new item to final array
  });
  return finalCOArr;
};
const aggregateMoMChangeOrders = (jctdscid, monthEnd, isRelevant) =>
  new Promise((resolve, reject) => {
    try {
      if (!existsWithLength(jctdscid)) resolve([]);
      const aggregatedCOs = {
        data: {
          ContractValue: 0,
          LaborChangeOrders: [],
          EquipmentChangeOrders: [],
          MaterialChangeOrders: [],
          OtherChangeOrders: [],
          SubcontractorsChangeOrders: []
        }
      };

      const allCOs = [];
      const getValue = (aco) => {
        const lastCo = getLastIndex(aco);
        if (lastCo?.Status === 'Pending Carried') {
          return lastCo?.CarryAmount || lastCo?.ContractValue;
        }
        return lastCo?.ContractValue;
      };
      Promise.all([getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEnd}/ChangeOrders`)))]).then((allData) => {
        allData.forEach((monthData) => {
          if (!monthData.empty) {
            monthData.forEach((d) => {
              const data = d.data();
              if (isRelevant(data?.data?.Status, data?.data?.ChangeOrderType)) {
                if (allCOs.length === 0) {
                  aggregatedCOs.data.LaborChangeOrders = data?.data?.LaborChangeOrders;
                  aggregatedCOs.data.EquipmentChangeOrders = data?.data?.EquipmentChangeOrders;
                  aggregatedCOs.data.MaterialChangeOrders = data?.data?.MaterialChangeOrders;
                  aggregatedCOs.data.OtherChangeOrders = data?.data?.OtherChangeOrders;
                  aggregatedCOs.data.SubcontractorsChangeOrders = data?.data?.SubcontractorsChangeOrders;
                }
                allCOs.push(data?.data);
                const amtCarried = getValue(allCOs) || 0;
                aggregatedCOs.data.ContractValue += NaNtoZero(amtCarried);
                if (allCOs.length > 1) {
                  ['LaborChangeOrders', 'EquipmentChangeOrders', 'MaterialChangeOrders', 'OtherChangeOrders', 'SubcontractorsChangeOrders'].forEach(
                    (COType) => {
                      aggregatedCOs.data[COType] = mergeCOs(aggregatedCOs.data[COType], getLastIndex(allCOs)[COType], COType);
                    }
                  );
                }
              }
            });
          }
        });
        resolve(aggregatedCOs);
      });
    } catch (err) {
      reject(err);
    }
  });
const aggregateChangeOrders = (budgets, isRelevant) =>
  new Promise((resolve, reject) => {
    try {
      const workingBudgets = structuredClone(budgets);
      const aggregatedCOs = {
        data: {
          ContractValue: 0,
          LaborChangeOrders: [],
          EquipmentChangeOrders: [],
          MaterialChangeOrders: [],
          OtherChangeOrders: [],
          SubcontractorsChangeOrders: []
        }
      };
      if (!existsWithLength(workingBudgets)) resolve(aggregatedCOs);

      const allCOs = [];
      const getValue = (aco) => {
        const lastCo = getLastIndex(aco);
        if (lastCo?.Status === 'Pending Carried') {
          return lastCo?.CarryAmount || lastCo?.ContractValue;
        }
        return lastCo?.ContractValue;
      };
      workingBudgets.forEach((monthData) => {
        const data = monthData;
        if (isRelevant(data?.data?.Status, data?.data?.ChangeOrderType)) {
          if (allCOs.length === 0) {
            aggregatedCOs.data.LaborChangeOrders = data?.data?.LaborChangeOrders;
            aggregatedCOs.data.EquipmentChangeOrders = data?.data?.EquipmentChangeOrders;
            aggregatedCOs.data.MaterialChangeOrders = data?.data?.MaterialChangeOrders;
            aggregatedCOs.data.OtherChangeOrders = data?.data?.OtherChangeOrders;
            aggregatedCOs.data.SubcontractorsChangeOrders = data?.data?.SubcontractorsChangeOrders;
          }
          allCOs.push(data?.data);
          const amtCarried = getValue(allCOs) || 0;
          aggregatedCOs.data.ContractValue += NaNtoZero(amtCarried);
          if (allCOs.length > 1) {
            ['LaborChangeOrders', 'EquipmentChangeOrders', 'MaterialChangeOrders', 'OtherChangeOrders', 'SubcontractorsChangeOrders'].forEach(
              (COType) => {
                aggregatedCOs.data[COType] = mergeCOs(aggregatedCOs.data[COType], getLastIndex(allCOs)[COType], COType);
              }
            );
          }
        }
      });
      resolve(aggregatedCOs);
    } catch (err) {
      reject(err);
    }
  });

export default aggregateChangeOrders;
export { aggregateMoMChangeOrders };
