import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormHelperText, Alert, Tooltip, Box, Paper, Button, Typography, Table } from '@mui/material';
import { toast } from 'react-toastify';
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { useRecoilValue } from 'recoil';
import { faLock, faArrowProgress, faFileDownload, faSave, faTimes, faTrash, faUnlock } from '@fortawesome/pro-regular-svg-icons';
import { AldgDialog } from '@aldridge/aldg-ui-components';
import dayjs from 'dayjs';
import TooltipIconButton from '../../UIComponents/TooltipIconButton';
import { _Clickstream, _Defaults, _MonthEndDate, _SecurityLevel } from '../../../_Recoil/atoms';
import existsWithLength from '../../../utils/existsWithLength';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { isApproved } from '../../../utils/isPending';
import { UserContext } from '../../../providers/UserProvider';
import ErrorBoundary from '../../../_GlobalComponents/ErrorBoundary';
import DownloadUploadTemplate from './DownloadUploadChangeOrder';
import AccordionDetailsList from './AccordionDetailsList';
import ChangeOrderSummaryTable from './ChangeOrderSummaryTable';
import InputToggle from '../../UIComponents/InputToggle';
import InputToggleDate from '../../UIComponents/InputToggleDate';
import InputToggleNumber from '../../UIComponents/InputToggleNumber';
import { firestore } from '../../../firebase';
import InputToggleSelect from '../../UIComponents/InputToggleSelect';
import TextDisplay from '../../UIComponents/TextDisplay';
import { formatAsCurrency } from '../../UIComponents/format';
import { AdminUp, DivisionalUp, EditorUp } from '../UserAssignment/checkSecurity';
import StatusTooltip from './StatusTooltip';
import DownloadECMS from './DownloadECMS';

const ChangeOrderForm = (props) => {
  const { jctdscid, Transaction, memorialized, history, changeOrderId, monthEndDate, prevMemorialized, NextFromStatusMonth, isStatusMonth } = props;
  const SecurityLevel = useRecoilValue(_SecurityLevel);
  const user = useContext(UserContext);
  const Defaults = useRecoilValue(_Defaults);
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  const Clickstream = useRecoilValue(_Clickstream);
  const [gridEdits, setGridEdits] = useState({ L: false, M: false, S: false, E: false, O: false });
  const [roundingValues, setRoundingValues] = useState([]);
  const [editing, setEditing] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [record, setRecord] = useState({
    id: '',
    ModifiedBy: '',
    ModifiedDate: '',
    CreatedBy: '',
    CreatedDate: '',
    data: {
      CustomerChangeOrder: '',
      ContractValue: '',
      EstimatedValue: '',
      AldridgeChangeOrder: '',
      BudgetDescription: '',
      DateSubmitted: '',
      DateApproved: '',
      Status: '',
      ECMSChangeOrder: '',
      LaborChangeOrders: [],
      SubcontractorsChangeOrders: [],
      MaterialChangeOrders: [],
      EquipmentChangeOrders: [],
      OtherChangeOrders: [],
      MonthEndDate,
      JobID: jctdscid
    }
  });

  const inAgenda = Clickstream.inAgenda;
  const NextMonthEdit = prevMemorialized && monthEndDate === NextFromStatusMonth;
  const StatusMonthEdit = isStatusMonth || NextMonthEdit; // Can edit if status month or above condition (or admin)
  const isMemorializedLock = () => {
    if (!AdminUp(SecurityLevel) && ['Approved', 'Approved FE'].includes(record?.data?.Status) && (memorialized || !StatusMonthEdit)) return true;
    return record?.MemorializedLock === 'true';
  };
  const canEdit = !isMemorializedLock() && EditorUp(SecurityLevel) && !inAgenda;
  const canEditDG = canEdit && editing;

  const checkForPerms = () => {
    if (DivisionalUp(SecurityLevel)) return true;
    if (user.user === true) return false;
    return false;
  };

  useEffect(() => {
    if (!existsWithLength(record?.data?.Reviews) && record.id !== '') {
      const uRecord = JSON.parse(JSON.stringify(record));
      uRecord.data.Reviews = {};
      getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/UserAssignment`))).then((reviews) => {
        reviews.forEach((review) => {
          const reviewData = review.data();
          if (reviewData.ecmsRole) {
            uRecord.data.Reviews[reviewData.Email] = {
              name: reviewData.nameDisplay.split('[')[0].trim(),
              role: reviewData.role,
              status: 'Not Reviewed'
            };
          }
        });
        setRecord((prev) => ({ ...prev, ...uRecord }));
      });
    }
  }, [record.id]);

  useEffect(() => {
    let mounted = true;
    if (changeOrderId !== 'new' && mounted) {
      getDoc(doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${MonthEndDate}/ChangeOrders`, changeOrderId)).then((d) => {
        if (!d.exists() && mounted) {
          toast.error('This change order does not exist in this job or has been deleted. Select a different change order.');
          history.push(`/Job/${jctdscid}/Budgets`);
        } else if (mounted) setRecord((PREV) => ({ ...PREV, ...d.data() }));
      });
    }
    return () => {
      mounted = false;
    };
  }, [changeOrderId]);

  // useEffect(() => {
  //   const newRecord = JSON.parse(JSON.stringify(record));
  //   if (['Construction Budget', 'Original Budget'].includes(record?.data?.ChangeOrderType)) {
  //     // newRecord.data.ContractValue = '';
  //     if (record?.data?.ChangeOrderType === 'Construction Budget') {
  //       newRecord.data.EstimatedValue = '';
  //     }
  //     setRecord((prev) => ({ ...prev, ...newRecord }));
  //   }
  // }, [record?.data?.ChangeOrderType]);

  const validateSave = () => {
    let valid = true;
    if (record?.data?.ChangeOrderType === '' || !existsWithLength(record?.data?.ChangeOrderType)) {
      toast.warn('Change Order Type is required. Please select a value.');
      valid = false;
    }
    if (record?.data?.CustomerChangeOrder === '') {
      toast.warn('Customer # is required. Please Input a value.');
      valid = false;
    }
    if (record?.data?.AldridgeChangeOrder === '') {
      toast.warn('Aldridge # is required. Please Input a value.');
      valid = false;
    }
    if (record?.data?.BudgetDescription === '') {
      toast.warn('Budget Description is required. Please Input.');
      valid = false;
    }
    if (record?.data?.EstimatedValue === '' && !['Construction Budget', 'Original Budget'].includes(record?.data?.ChangeOrderType)) {
      toast.warn('Estimated Contract Value is required. Please Input a value.');
      valid = false;
    }
    if (record?.data?.ChangeOrderType === 'Construction Budget' && !existsWithLength(record?.data?.Notes)) {
      toast.warn('Notes are required for a Construction Budget Type. Please Input.');
      valid = false;
    }
    if (record?.data?.Status === '') {
      toast.warn('Status is required. Please select a value.');
      valid = false;
    }
    if (record?.data?.Status === 'Pending Negative' && NaNtoZero(record?.data?.ContractValue) > 0) {
      toast.warn('A Pending Negative Change Order cannot have a positive Contract Value.');
      valid = false;
    }
    if (record?.data?.Status === 'Pending Carried' && NaNtoZero(record?.data?.CarryAmount) === 0) {
      toast.warn('A Pending Carried Change Order must have a Amount to Carry in Status.');
      valid = false;
    }

    if (['Pending Carried', 'Pending Not Carried'].includes(record?.data?.Status) && NaNtoZero(record?.data?.ContractValue) <= 0) {
      toast.warn(`A ${record?.data?.Status} Budget must have a Contract Value greater than 0.`);
      valid = false;
    }
    return valid;
  };
  const handleLock = () => {
    // LOOKHERE
    setDoc(
      doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${MonthEndDate}/ChangeOrders`, record.id),
      {
        MemorializedLock: isMemorializedLock() ? 'false' : 'true'
      },
      { merge: true }
    );
    const newRecord = JSON.parse(JSON.stringify(record));
    newRecord.MemorializedLock = isMemorializedLock() ? 'false' : 'true';
    setRecord((prev) => ({ ...prev, ...newRecord }));
    if (newRecord?.data?.ECMSChangeOrder !== '' && newRecord.MemorializedLock === 'false') {
      toast.warn(
        'This budget has been assigned an ECMS Budget #. If this budget has already been uploaded to ECMS, you will need to manually update the ECMS Budget in ECMS.',
        { timeout: 10000 }
      );
    }
  };

  const handleSave = async (isApproveDeny) => {
    if (editing || isApproveDeny) {
      if (gridEdits.L || gridEdits.M || gridEdits.S || gridEdits.E || gridEdits.O) {
        toast.error('Your budget changes have not been saved! Please click the green check mark on the open grid(s) below before trying to save.');
        return;
      }
      if (record.id === '') record.id = doc(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${MonthEndDate}/ChangeOrders`)).id;
      const recordId = record.id;
      if (validateSave()) {
        const dRef = doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${MonthEndDate}/ChangeOrders`, recordId);
        // LOOKHERE
        setDoc(dRef, JSON.parse(JSON.stringify(record)), { merge: true });
        if (existsWithLength(record?.data?.DateApproved)) {
          // handleLock();
        }
        if ((record?.data?.Status === 'Approved' || record?.data?.Status === 'Approved FE') && user.admin) {
          toast.success('Approved Budget has been saved. If you are finished with the budget, you may close the record.');
          setEditing(!editing);
          return;
        }
        history.push(`/Job/${jctdscid}/Budgets`);
        setEditing(!editing);
      }
    }
  };

  const approveDeny = (choice) => {
    const newRecord = JSON.parse(JSON.stringify(record));
    // if (existsWithLength(newRecord?.data?.Reviews)) {
    if (existsWithLength(newRecord?.data?.Reviews?.[user.email])) {
      newRecord.data.Reviews[user.email].status = choice;
      newRecord.data.Reviews[user.email].DateReviewed = new Date().toJSON();
    } else {
      newRecord.data.Reviews[user.email] = {
        name: user.name,
        role: SecurityLevel,
        status: choice,
        DateReviewed: new Date().toJSON()
      };
    }
    // }
    setRecord(newRecord);
    const dRef = doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${MonthEndDate}/ChangeOrders`, newRecord.id);
    // LOOKHERE
    setDoc(dRef, JSON.parse(JSON.stringify(newRecord)), { merge: true });
    // handleSave(true);
  };

  // useEffect(() => {
  //   if (existsWithLength(record?.data?.Reviews?.[user.email] || [])) handleSave(true);
  // }, [record.data.Reviews]);

  const handleDelete = () => {
    if (existsWithLength(record.id)) deleteDoc(doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${MonthEndDate}/ChangeOrders`, record.id));
    history.push(`/Job/${jctdscid}/Budgets`);
  };

  const handleChange = (event, name, display) => {
    try {
      let id;
      let value;
      const changedReport = { ...record };
      if (typeof name === 'object' && name !== null) {
        id = name.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      } else {
        id = name || event.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      }
      if (typeof display !== 'undefined') {
        changedReport.data[`${name}Display`] = display;
      }
      if (!existsWithLength(changedReport.CreatedBy)) {
        changedReport.CreatedBy = user.email;
        changedReport.CreatedDate = new Date().toJSON();
      }
      changedReport.ModifiedBy = user.email;
      changedReport.ModifiedDate = new Date().toJSON();
      if (id === 'Status') {
        const statuslog = changedReport.data?.StatusLog || [];
        changedReport.data.StatusLog = [
          ...statuslog,
          { NewStatus: value, PreviousStatus: changedReport.data.Status, ModifiedDate: new Date().toJSON(), ModifiedBy: user.email, MonthEndDate }
        ];
      }
      changedReport.data[id] = value;
      if (id === 'ChangeOrderType') {
        if (['Construction Budget', 'Original Budget'].includes(value)) {
          // newRecord.data.ContractValue = '';
          if (value === 'Construction Budget') {
            changedReport.data.EstimatedValue = '';
          }
        }
      }
      if (id === 'ContractValue') {
        const GLRow = changedReport?.data?.OtherChangeOrders.findIndex((oco) => `${oco?.PayItem}${oco?.SegmentValue}` === '10050002');
        const ContractGLRate = Transaction.ContractGLRate || Defaults.ContractGLRate;
        if (GLRow < 0) {
          getDocs(query(collection(firestore, `ENT-Segments`), where('SegmentValue', '==', '50002'))).then((segments) => {
            segments.forEach((segment) => {
              const segData = segment.data();
              const shiftedOthers = JSON.parse(JSON.stringify(changedReport?.data?.OtherChangeOrders));
              shiftedOthers.unshift({
                PayItem: '100',
                SegmentValue: 50002,
                Total: value * ContractGLRate,
                CostCode: [segData.jctccsid],
                Description: segData.SegmentDescription,
                CostCodeDisplay: `${segData.SegmentValue} - ${segData.SegmentDescription}`
              });
              changedReport.data.OtherChangeOrders = shiftedOthers;
            });
            setRecord((prev) => ({ ...prev, ...changedReport }));
          });
        } else {
          changedReport.data.OtherChangeOrders[GLRow].Total = value * ContractGLRate;
          setRecord((prev) => ({ ...prev, ...changedReport }));
        }
      } else if (id === 'ChangeOrderType') {
        if (value === 'Authorized Extras') changedReport.data.Status = 'Approved';
        else if (value === 'Claims') changedReport.data.Status = 'Pending Tracking';
        setRecord((prev) => ({ ...prev, ...changedReport }));
      } else {
        setRecord((prev) => ({ ...prev, ...changedReport }));
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 8000 });
    }
  };

  const getSelectOptions = () => {
    switch (record?.data?.ChangeOrderType) {
      case 'Authorized Extras':
        return [{ label: 'Approved', value: 'Approved' }];
      case 'Claims':
        return [{ label: 'Pending Tracking', value: 'Pending Tracking' }];
      case 'Construction Budget':
        return [
          { label: 'Approved', value: 'Approved' },
          { label: 'Not Approved', value: 'Not Approved' }
        ];
      default:
        return [
          { label: '1. Pending Tracking', value: 'Pending Tracking' },
          { label: '2. Pending Not Carried', value: 'Pending Not Carried' },
          { label: '3. Pending Negative', value: 'Pending Negative' },
          { label: '4. Pending Carried', value: 'Pending Carried' },
          { label: '5. Approved', value: 'Approved' },
          { label: '6. Approved FE', value: 'Approved FE', disabled: !user.admin },
          { label: '7. Voided', value: 'Voided' }
        ];
    }
  };

  const updateEdit = (type) => {
    const newEdits = JSON.parse(JSON.stringify(gridEdits));
    newEdits[type] = !newEdits[type];
    setGridEdits(newEdits);
  };
  const GenerateECMSBudgetNumber = () => {
    if (record?.data?.DateApproved !== '') {
      const newECMSNumber = Transaction?.EcmsBudgetNumber || 1;
      const newRecord = { ...record };
      newRecord.data.ECMSChangeOrder = `ALDG${`000${newECMSNumber}`.slice(-4)}`;
      setRecord((prev) => ({ ...prev, ...newRecord }));
      setDoc(doc(firestore, `ENT-Jobs/${jctdscid}`), { EcmsBudgetNumber: newECMSNumber + 1 }, { merge: true });
      // LOOKHERE
      setDoc(doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${MonthEndDate}/ChangeOrders/${record.id}`), newRecord, { merge: true });
    } else {
      toast.error('Please select a date approved before generating an ECMS number.');
    }
  };
  const showDEButton = () =>
    !inAgenda &&
    AdminUp(SecurityLevel) &&
    (record?.data?.ECMSChangeOrder || '') !== '' &&
    (record?.data?.LaborChangeOrders.length > 0 ||
      record?.data?.MaterialChangeOrders.length > 0 ||
      record?.data?.EquipmentChangeOrders.length > 0 ||
      record?.data?.SubcontractorsChangeOrders.length > 0 ||
      record?.data?.OtherChangeOrders.length > 0);

  return (
    <ErrorBoundary componentName='Budget Form'>
      <AldgDialog
        title='Confirm Delete'
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        pieces={[
          {
            type: 'text',
            content: 'Are you sure you want to delete your change order? They will be lost forever if you proceed!',
            key: 'sure'
          },
          {
            type: 'actions',
            key: 'btn',
            buttons: [
              {
                text: 'Cancel',
                onClick: () => {
                  setShowConfirm(false);
                },
                color: 'error'
              },
              {
                text: 'Delete',
                onClick: () => {
                  handleDelete();
                  setShowConfirm(false);
                },
                color: 'success'
              }
            ]
          }
        ]}
      />
      <Paper variant='outlined' className='aldg-field'>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'contents',
              padding: '0px',
              textAlign: 'center'
            }}
          >
            <div
              style={{
                position: 'sticky',
                top: '-1px',
                left: 0,
                right: 0,
                width: '100%',
                padding: '8px',
                display: 'flex',
                justifyContent: 'flex-end',
                zIndex: 100,
                backgroundColor: 'white'
              }}
            >
              {AdminUp(SecurityLevel) && !inAgenda && (
                <TooltipIconButton
                  color='gray'
                  onClick={handleLock}
                  icon={isMemorializedLock() ? faUnlock : faLock}
                  title={isMemorializedLock() ? 'Unlock' : 'Lock'}
                />
              )}
              {!memorialized && (
                // editing ? (
                <TooltipIconButton onClick={() => handleSave(false)} color='success' title='Save' icon={faSave} />
                // ) : (<TooltipIconButton title='Edit' icon={faPen} onClick={() => setEditing(!editing)} /> )
              )}
              {canEdit && <TooltipIconButton onClick={() => setShowConfirm(true)} color='error' title='Delete' icon={faTrash} />}
              {AdminUp(SecurityLevel) && isApproved(record?.data?.Status) && (record?.data?.ECMSChangeOrder || '') === '' && !inAgenda && (
                <TooltipIconButton onClick={GenerateECMSBudgetNumber} color='success' title='Generate ECMS Budget Number' icon={faArrowProgress} />
              )}
              {showDEButton() ? (
                <TooltipIconButton
                  onClick={() => DownloadECMS(Transaction, record)}
                  color='success'
                  title='Download ECMS Budget Upload'
                  icon={faFileDownload}
                />
              ) : null}
              <TooltipIconButton
                color='gray'
                onClick={() => {
                  setEditing(false);
                  history.push(`/Job/${jctdscid}/Budgets`);
                }}
                icon={faTimes}
                title='Cancel'
              />
            </div>
          </Grid>

          <Grid container>
            <Grid item xs={12} style={{ marginTop: '0' }} className='aldg-divider' />
            <Grid item md={6} xs={12}>
              <InputToggle
                edit={!memorialized} // ={editing && canEdit}
                name='CustomerChangeOrder'
                value={record?.data?.CustomerChangeOrder || ''}
                onChange={handleChange}
                label='Customer #'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputToggle
                edit={editing && canEdit}
                name='AldridgeChangeOrder'
                value={record?.data?.AldridgeChangeOrder || ''}
                onChange={handleChange}
                label='Aldridge #'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextDisplay value={record?.data?.ECMSChangeOrder || ''} label='Ecms Budget #' />
              <FormHelperText style={{ paddingLeft: '16px' }}>Auto Generated Once Imported into ECMS</FormHelperText>
            </Grid>
            <Grid item md={6} xs={12}>
              <InputToggleSelect
                edit={editing && canEdit}
                nobg
                name='ChangeOrderType'
                value={record?.data?.ChangeOrderType || ''}
                options={[
                  { value: 'T & M Change Order', label: 'Change Order - T & M' },
                  { value: 'Force Account Change Order', label: 'Change Order - Force Account' },
                  { value: 'Lump Sum Change Order', label: 'Change Order - Lump Sum' },
                  { value: 'Units Change Order', label: 'Change Order - Units' },
                  { value: 'Change Order Escalation', label: 'Change Order - Escalation', disabled: !AdminUp(SecurityLevel) },
                  { value: 'Construction Budget', label: 'Construction Budget' },
                  { value: 'Original Budget', label: 'Original Budget' },
                  { value: 'Pre-Start Budget', label: 'Pre-Start Budget' },
                  { value: 'Authorized Extras', label: 'Authorized Extras' },
                  { value: 'Claims', label: 'Claims' }
                ]}
                onChange={handleChange}
                label='Budget Type'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Box sx={[{ '& .MuiFormControl-root': { width: '100%' } }]}>
                <InputToggleDate
                  edit={editing && canEdit}
                  label='Date Submitted'
                  name='DateSubmitted'
                  onChange={handleChange}
                  value={dayjs(record?.data?.DateSubmitted).format('YYYY-MM-DD') || ''}
                />
              </Box>
              {user.admin ? (
                <Box sx={[{ '& .MuiFormControl-root': { width: '100%' } }]}>
                  <InputToggleDate
                    edit={editing && canEdit}
                    label='Date Approved'
                    name='DateApproved'
                    onChange={handleChange}
                    value={dayjs(record?.data?.DateApproved).format('YYYY-MM-DD') || ''}
                  />
                </Box>
              ) : null}
            </Grid>
            <Grid item md={6} xs={12}>
              <Tooltip title={<StatusTooltip />}>
                <div>
                  <InputToggleSelect
                    edit={editing && canEdit}
                    // label={
                    //   <span>
                    //     Status <FontAwesomeIcon icon={faQuestionCircle} />
                    //   </span>
                    // }
                    label='Status'
                    name='Status'
                    nobg
                    onChange={handleChange}
                    value={record?.data?.Status || ''}
                    options={getSelectOptions()}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <InputToggle
                edit={editing && canEdit}
                name='BudgetDescription'
                value={record?.data?.BudgetDescription || ''}
                onChange={handleChange}
                label='Budget Description'
              />
            </Grid>
            <Grid item xs={12}>
              <InputToggle
                edit={!memorialized} // ={editing && canEdit}
                name='Notes'
                value={record?.data?.Notes || ''}
                onChange={handleChange}
                label='Notes'
              />
            </Grid>
            {record?.data?.ChangeOrderType !== 'Original Budget' && record?.data?.ChangeOrderType !== 'Construction Budget' ? (
              <Grid item xs={12}>
                <InputToggleNumber
                  edit={editing && canEdit}
                  name='EstimatedValue'
                  label='Estimated Contract Value'
                  onChange={handleChange}
                  value={record?.data?.EstimatedValue || ''}
                  displayFunc={formatAsCurrency}
                />
              </Grid>
            ) : null}
            {record?.data?.ChangeOrderType !== 'Construction Budget' ? (
              <Grid item xs={12}>
                <InputToggleNumber
                  edit={editing && canEdit}
                  name='ContractValue'
                  label='Total Contract Value'
                  onChange={handleChange}
                  value={record?.data?.ContractValue || ''}
                  displayFunc={formatAsCurrency}
                />
              </Grid>
            ) : null}
            {record?.data?.Status === 'Pending Carried' && (
              <Grid item xs={12}>
                <InputToggleNumber
                  edit={editing && canEdit}
                  name='CarryAmount'
                  label='Amount to Carry in Status'
                  onChange={handleChange}
                  value={record?.data?.CarryAmount || ''}
                  displayFunc={formatAsCurrency}
                />
              </Grid>
            )}
            {(record?.data?.Reviews?.[user.email]?.status === 'Not Reviewed' || record?.data?.Reviews?.[user.email] === undefined) &&
            record?.data?.Status === 'Approved' &&
            checkForPerms() ? (
              <>
                <Grid item xs={12} className='aldg-divider' />
                <Grid container sx={{ textAlign: 'center' }}>
                  <Grid item xs={12}>
                    <Typography variant='h5'> Review</Typography>
                    <Typography variant='b'> Has this budget been reviewed and approved? </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button onClick={() => approveDeny('Approved')} color='success' variant='contained'>
                      Approve
                    </Button>{' '}
                    <Button onClick={() => approveDeny('Denied')} color='error' variant='contained'>
                      Deny
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : null}
            {record?.data?.Status === 'Approved' && checkForPerms() ? (
              <>
                <Grid item xs={12} className='aldg-divider' />
                <Typography variant='h5'> Review</Typography>
                <Table>
                  <thead>
                    <th>Status</th>
                    <th>Reviewed By</th>
                    <th>Reviewed On</th>
                  </thead>
                  <tbody>
                    {Object.keys(record?.data?.Reviews || {}).map((review) => (
                      <tr>
                        <td>
                          <Typography variant='b'>{record?.data?.Reviews[review].status}</Typography>
                        </td>
                        <td>
                          <Typography variant='b'>{record?.data?.Reviews[review].name}</Typography>
                        </td>
                        <td>
                          <Typography variant='b'>
                            {' '}
                            {existsWithLength(record?.data?.Reviews[review].DateReviewed)
                              ? dayjs(record?.data?.Reviews[review].DateReviewed).format('MM/DD/YYYY')
                              : ''}{' '}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : null}
            <Grid item xs={12} className='aldg-divider' />
            <ChangeOrderSummaryTable record={{ ...record, ChangeOrders: [record] }} />
            <Grid item xs={12} className='aldg-divider' />
            {EditorUp(SecurityLevel) && !inAgenda && (
              <DownloadUploadTemplate
                record={record}
                setRecord={setRecord}
                recordid={jctdscid}
                Transaction={Transaction}
                memorialized={memorialized || isMemorializedLock()}
                editing={editing}
                jctdscid={jctdscid}
                setRoundingValues={setRoundingValues}
              />
            )}
            {roundingValues.length > 0 && (
              <>
                <Grid item xs={12} className='aldg-divider' />
                <Grid item xs={12}>
                  <Alert
                    severity='warning'
                    sx={{
                      '& .MuiAlert-message': {
                        width: '100%'
                      }
                    }}
                  >
                    <strong>Warning!</strong> The following values have been rounded to the nearest 2 decimals. These may impact the total amount of
                    your costs to comply with the ECMS budget uploads.{' '}
                    <Grid
                      container
                      sx={{
                        '& .MuiGrid-item': {
                          fontWeight: 'bold'
                        }
                      }}
                    >
                      <Grid item xs={3}>
                        PICC
                      </Grid>
                      <Grid item xs={3}>
                        Field
                      </Grid>
                      <Grid item xs={3}>
                        Uploaded Value
                      </Grid>
                      <Grid item xs={3}>
                        Rounded Value
                      </Grid>
                    </Grid>
                    {roundingValues.map((rv) => (
                      <Grid container key={`${rv.line}-${rv.header}`}>
                        <Grid item xs={3}>
                          {rv.line}
                        </Grid>
                        <Grid item xs={3}>
                          {rv.header}
                        </Grid>
                        <Grid item xs={3}>
                          ${rv.original}
                        </Grid>
                        <Grid item xs={3}>
                          ${rv.round}
                        </Grid>
                      </Grid>
                    ))}
                  </Alert>
                </Grid>
              </>
            )}
            <Grid item xs={12} className='aldg-divider' />
            <Grid item xs={12}>
              <AccordionDetailsList
                editable={canEditDG}
                editing={gridEdits.L}
                setEditing={() => updateEdit('L')}
                record={record}
                setRecord={setRecord}
                label='Budget Details - Labor'
                costCodeType='LaborChangeOrders'
                changeOrders={record?.data?.LaborChangeOrders}
                jctdscid={jctdscid}
              />
            </Grid>
            <Grid item xs={12}>
              <AccordionDetailsList
                editable={canEditDG}
                editing={gridEdits.M}
                setEditing={() => updateEdit('M')}
                record={record}
                setRecord={setRecord}
                label='Budget Details - Material'
                costCodeType='MaterialChangeOrders'
                changeOrders={record?.data?.MaterialChangeOrders}
                jctdscid={jctdscid}
              />
            </Grid>
            <Grid item xs={12}>
              <AccordionDetailsList
                editable={canEditDG}
                editing={gridEdits.S}
                setEditing={() => updateEdit('S')}
                record={record}
                setRecord={setRecord}
                label='Budget Details - Subcontractors'
                costCodeType='SubcontractorsChangeOrders'
                changeOrders={record?.data?.SubcontractorsChangeOrders}
                jctdscid={jctdscid}
              />
            </Grid>
            <Grid item xs={12}>
              <AccordionDetailsList
                editable={canEditDG}
                editing={gridEdits.E}
                setEditing={() => updateEdit('E')}
                record={record}
                setRecord={setRecord}
                label='Budget Details - Equipment'
                costCodeType='EquipmentChangeOrders'
                changeOrders={record?.data?.EquipmentChangeOrders}
                jctdscid={jctdscid}
              />
            </Grid>
            <Grid item xs={12}>
              <AccordionDetailsList
                editable={canEditDG}
                editing={gridEdits.O}
                setEditing={() => updateEdit('O')}
                record={record}
                setRecord={setRecord}
                label='Budget Details - Other'
                costCodeType='OtherChangeOrders'
                changeOrders={record?.data?.OtherChangeOrders}
                jctdscid={jctdscid}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ErrorBoundary>
  );
};

ChangeOrderForm.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  Transaction: PropTypes.object,
  memorialized: PropTypes.bool,
  history: PropTypes.object.isRequired,
  changeOrderId: PropTypes.string,
  monthEndDate: PropTypes.string,
  prevMemorialized: PropTypes.bool,
  NextFromStatusMonth: PropTypes.string.isRequired,
  isStatusMonth: PropTypes.bool
};
ChangeOrderForm.defaultProps = {
  Transaction: {},
  memorialized: false,
  changeOrderId: 'new',
  monthEndDate: '2010-12-31',
  prevMemorialized: false,
  isStatusMonth: false
};

export default ChangeOrderForm;
