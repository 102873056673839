import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from '@aldridge/aldg-data-components';
import { useMediaQuery } from '@mui/material';
import TextDisplay from './TextDisplay';
import { nonMuiThemes, AldgTheme } from '../../theme';

const InputToggleNumber = (props) => {
  const { edit, name, value, onChange, label, allowWrap, displayFunc, min, max } = props;
  const matches = useMediaQuery(AldgTheme.breakpoints.down('md'));

  return (
    <div style={edit ? nonMuiThemes.InputToggleOuterEdit : nonMuiThemes.InputToggleOuter}>
      {edit ? (
        <div style={allowWrap || matches ? nonMuiThemes.InputToggleTextContainerWrap : nonMuiThemes.InputToggleTextContainer}>
          <div style={allowWrap || matches ? nonMuiThemes.InputToggleTextWrap : nonMuiThemes.InputToggleText}>{label}:</div>
          <div style={nonMuiThemes.InputToggleValue}>
            <InputNumber name={name} value={value} onChange={onChange} min={min} max={max} disableWheelScroll />
          </div>
        </div>
      ) : (
        <TextDisplay editable label={label} value={value} displayFunc={displayFunc} />
      )}
    </div>
  );
};

InputToggleNumber.propTypes = {
  edit: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  allowWrap: PropTypes.bool,
  displayFunc: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number
};
InputToggleNumber.defaultProps = {
  edit: false,
  name: '',
  value: '',
  onChange: () => {},
  label: '',
  allowWrap: false,
  displayFunc: (g) => g?.toString(),
  min: undefined,
  max: undefined
};

export default InputToggleNumber;
