import NaNtoZero from '../../../utils/convertNaNToZero';

export const SumCosts = (costArray) => {
  let totalSum = 0;
  costArray.forEach((co) => {
    totalSum += NaNtoZero(co);
  });
  return totalSum;
};

export const SumTotalCost = (record) =>
  SumCosts([
    ...record.data.LaborChangeOrders.map((l) => NaNtoZero(l.LaborTotal)),
    ...record.data.SubcontractorsChangeOrders.map((e) => NaNtoZero(e.Total)),
    ...record.data.MaterialChangeOrders.map((m) => NaNtoZero(m.Total)),
    ...record.data.EquipmentChangeOrders.map((e) => NaNtoZero(e.Total)),
    ...record.data.OtherChangeOrders.map((o) => NaNtoZero(o.Total))
  ]);
