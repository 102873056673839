import { toast } from 'react-toastify';
import NaNtoZero from '../../../utils/convertNaNToZero';
import existsWithLength from '../../../utils/existsWithLength';

const parseHeader = (header) => {
  if (header === 'Supplementary Description') return 'FriendlyName';
  if (header === 'Labor Burden') return 'BurdenCost';
  return header.replace(/\W/g, '');
};
const headerTyping = (header) => {
  switch (header) {
    case 'LaborRate':
    case 'LaborHours':
    case 'LaborCost':
    case 'BurdenCost':
    case 'LaborTotal':
    case 'Total':
      return 'number';
    default:
      return 'string';
  }
};
const convertToFixed = (val, fixed = 2) => {
  if (typeof val === 'number') return NaNtoZero(val.toFixed(fixed));
  return NaNtoZero(NaNtoZero(val).toFixed(fixed));
};
const checkForRounding = (changeOrder, originalRecord, headers) => {
  const roundedCosts = [];
  headers.forEach((h, idx) => {
    const oVal = typeof originalRecord[idx] === 'object' ? originalRecord[idx].result : originalRecord[idx];
    const pHeader = parseHeader(h);
    if (headerTyping(pHeader) === 'number' && changeOrder[pHeader] !== (oVal || 0)) {
      roundedCosts.push({ line: `${changeOrder.PayItem}${changeOrder.SegmentValue}`, header: pHeader, original: oVal, round: changeOrder[pHeader] });
    }
  });
  return roundedCosts;
};
const getRowID = (r) => (existsWithLength(r.PayItem) && existsWithLength(r.CostCode) ? `${r.PayItem}${r.CostCode[0]}` : r.id);
const parseChangeOrders = (changedRecord, COType, COs, headers, jctccsidRef, setRoundingValues) => {
  if (COType === 'Summary') return ['', ''];
  const parsedCOs = [];
  const incorrectCOs = [];
  let uploadedRecords = 0;
  let failedRecords = '';
  const roundedCosts = [];
  COs.forEach((CO) => {
    const costCode = jctccsidRef.find(({ SegmentValue }) => SegmentValue.toString() === CO?.[1]?.toString());
    if (existsWithLength(costCode)) {
      const changeOrder = {};
      headers.forEach((header, idx) => {
        const parsedHeader = parseHeader(header);
        if (!['', 'CostCode', 'P/NP'].includes(parsedHeader)) {
          switch (typeof CO[idx]) {
            case 'object':
              changeOrder[parsedHeader] = typeof CO[idx].result === 'number' ? NaNtoZero(CO[idx].result) : CO[idx].result;
              break;
            default:
              changeOrder[parsedHeader] = CO[idx];
          }
          if (headerTyping(parsedHeader) === 'number') {
            changeOrder[parsedHeader] = convertToFixed(changeOrder[parsedHeader]);
          } else changeOrder[parsedHeader] = changeOrder[parsedHeader] || '';
        }
      });
      changeOrder.ProductiveCostCode = costCode.ProductiveCostCode;
      changeOrder.CostCode = [costCode.jctccsid];
      changeOrder.CostCodeDisplay = `${costCode.SegmentValue} - ${costCode.SegmentDescription}`;
      changeOrder.SegmentValue = CO[1];
      changeOrder.SegmentDescription = CO[2].value;
      changeOrder.TempID = `${Date.now()}${costCode.jctccsid}${uploadedRecords}`;
      const existiingCOIndex = parsedCOs.findIndex((co) => getRowID(co) === getRowID(changeOrder));
      if (existiingCOIndex > -1) {
        const existingCO = parsedCOs[existiingCOIndex];
        headers.forEach((header, idx) => {
          const parsedHeader = parseHeader(header);
          if (!['', 'CostCode', 'P/NP'].includes(parsedHeader)) {
            let addr;
            switch (typeof CO[idx]) {
              case 'object':
                addr = typeof CO[idx].result === 'number' ? convertToFixed(CO[idx].result) : CO[idx].result;
                break;
              default:
                addr = CO[idx];
            }
            if (headerTyping(parsedHeader) === 'number') {
              addr = NaNtoZero(addr);
              existingCO[parsedHeader] = convertToFixed(existingCO[parsedHeader] + addr);
            } else {
              addr = addr || '';
              if (existingCO[parsedHeader]?.toString()?.trim() !== addr?.toString()?.trim())
                existingCO[parsedHeader] = `${existingCO[parsedHeader]} ${addr}`.trim();
            }
          }
        });
        parsedCOs[existiingCOIndex] = existingCO;
      } else {
        const jctccsidCOType = costCode.CostType;
        if (jctccsidCOType !== COType.substring(0, 1)) {
          incorrectCOs.push(changeOrder);
          failedRecords += `${changeOrder.CostCodeDisplay}, `;
        } else if (
          Math.abs(NaNtoZero(changeOrder.Total)) > 0 ||
          Math.abs(NaNtoZero(changeOrder.LaborTotal)) > 0 ||
          existsWithLength(changeOrder.FriendlyName)
        ) {
          parsedCOs.push(changeOrder);
          uploadedRecords += 1;
        }
      }
      roundedCosts.push(...checkForRounding(changeOrder, CO, headers));
    }
  });
  setRoundingValues((prev) => [...prev, ...roundedCosts]);
  switch (COType) {
    case 'Labor':
      changedRecord.data.LaborChangeOrders = parsedCOs;
      break;
    case 'Material':
      changedRecord.data.MaterialChangeOrders = parsedCOs;
      break;
    case 'Equipment/fuel':
      changedRecord.data.EquipmentChangeOrders = parsedCOs;
      break;
    case 'Subcontractor':
      changedRecord.data.SubcontractorsChangeOrders = parsedCOs;
      break;
    case 'Other costs':
      changedRecord.data.OtherChangeOrders = parsedCOs;
      break;
    default:
      break;
  }
  return [
    `Uploaded ${uploadedRecords} ${COType} records.\n`,
    failedRecords.length > 0
      ? `Cost Codes ${failedRecords} attempted to upload into ${COType}, but as they are not marked as ${COType} Cost Codes, they did not upload successfully. Double check your spreadsheet and try again.`
      : ''
  ];
};

const UploadChangeOrder = (record, setRecord, workbook, setUploadingBudget, Segments, setRoundingValues) => {
  const changedRecord = { ...record };
  try {
    const TemplateWorksheet = workbook._worksheets.find((sheet) => existsWithLength(sheet) && sheet.name === 'Template');
    if (existsWithLength(TemplateWorksheet)) {
      let COType = '';
      let COs = [];
      let startEndTracker = 0;
      let headers = [];
      let successToastMessage = '';
      TemplateWorksheet._rows.every((row) => {
        const values = row.values;
        const sections = ['LABOR', 'MATERIAL', 'EQUIPMENT/FUEL', 'SUBCONTRACTOR', 'OTHER COSTS', 'SUMMARY'];
        if (
          (existsWithLength(values[1]) && (values.length === 2 || sections.includes(values[1])) && typeof values[1] === 'string') ||
          values[1] === 'BUDGET'
        ) {
          COType = values[1].charAt(0) + values[1].slice(1).toLowerCase();
          if (COType === 'Budget') {
            startEndTracker = -1000;
          } else if (COType !== 'Summary') {
            startEndTracker += 1;
          }
        } else if (startEndTracker === -1000) {
          changedRecord.data.ContractValue = values[7];
          startEndTracker = -999;
        } else if (existsWithLength(values[4]) && values[4] === 'TOTAL:') {
          const toastResults = parseChangeOrders(changedRecord, COType, COs, headers, Segments, setRoundingValues);
          successToastMessage += toastResults[0];
          if (toastResults[1].length > 0) toast.error(toastResults[1]);
          startEndTracker = 0;
          COs = [];
        } else if (startEndTracker === 1) {
          headers = values.slice(1);
          startEndTracker += 1;
        } else if (startEndTracker > 1) {
          COs.push(values.slice(1));
          startEndTracker += 1;
        }
        return true;
      });
      toast.success(successToastMessage);
    } else {
      toast.error(
        "No worksheet named 'Template' in uploaded spreadsheet. Please make sure you uploaded the correct workbook and that the worksheet to be uploaded is named 'Template'."
      );
    }
    setRecord(changedRecord);
    setUploadingBudget(false);
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
    toast.error('Spreadsheet failed to upload.');
    setUploadingBudget(false);
  }
};

export default UploadChangeOrder;
