import { isFunction } from '@aldridge/aldg-helpers';
import { signOut } from '../firebase';

const fRefreshApplication = () => {
  signOut().then(() => {
    if (isFunction(navigator.serviceWorker.getRegistration)) {
      navigator.serviceWorker.getRegistration().then((reg) => {
        reg.unregister().then(() => {
          localStorage.removeItem('OfflineInited');
          window.location.reload();
        });
      });
    }
  });
};

export default fRefreshApplication;
