/* eslint-disable no-unused-vars */
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

const DownloadAbstractTemplate = async (Transaction, record, JobNumber, tables) => {
  const TableColumns = ['A', 'B', 'C', 'D'];
  const Abstract = ['Aldridge Contract Abstract', 'Job Number', 'Owner', '']; // 1
  // const ContactInformation = ['Contact Information', 'Contact', `Customer's Phone #`, 'Email', '']; // 5
  // const ContractDescription = ['Contract Description', 'Contract Description', '']; // 10
  // const Risks = ['Top 3 Risks', '1.', '2.', '3.', '']; // 13
  // const Opport = ['Top 3 Opportunities', '1.', '2.', '3.', '']; // 18
  const MajorContractPriorities = [
    'Major Contract Priorities',
    'Are Consequential Damages allowed:',
    'How are we being paid for changes or impacts:',
    'Does the contract have Liquidated Damages?:',
    ''
  ]; // 5
  const rowHeaders = ['Description', 'Notes', 'Contract Section', 'Sub Section']; // 9 [was 28]

  const workbook = new ExcelJS.Workbook();

  const TemplateWorksheet = workbook.addWorksheet('Template', {
    views: [{ showGridLines: false }]
  });

  TemplateWorksheet.getColumn(1).width = 65;
  for (let i = 2; i < 6; i++) {
    TemplateWorksheet.getColumn(i).width = 22;
  }

  for (let i = 1; i < 56; i++) {
    TableColumns.forEach((col) => {
      const boarder = {};
      if (col === 'C' && [2, 3].includes(i)) {
        boarder.left = { style: 'thin' };
        boarder.right = { style: 'thin' };
      }
      if (col === 'A') {
        boarder.right = { style: 'thin' };
      }
      boarder.top = { style: 'thin' };
      boarder.bottom = { style: 'thin' };

      TemplateWorksheet.getCell(`${col}${i}`).border = boarder;
    });
  }

  for (let i = 28; i < 56; i++) {
    const cols = ['B', 'C', 'D'];
    const tableHeaderRows = [9, 18, 28, 35, 40, 52]; // [28, 37, 47, 57, 63, 75];
    const beforeRows = [17, 27, 34, 39, 51]; // [36, 46, 56, 62, 74];
    cols.forEach((col) => {
      if (!tableHeaderRows.includes(i) && !beforeRows.includes(i)) {
        TemplateWorksheet.getCell(`${col}${i}`).border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
          left: { style: 'thin' }
        };
      }
    });
  }

  const MergedHeaders = [1, 5]; // , 10, 13, 18, 23];
  MergedHeaders.forEach((row) => {
    TemplateWorksheet.mergeCells(`A${row}:C${row}`);
    TemplateWorksheet.getCell(`A${row}`).font = {
      name: 'Calibri',
      bold: true,
      underline: true,
      size: 18
    };
  });

  const fillerData = [];
  const addToFiller = (arr) => {
    fillerData.push('');
    for (let i = 1; i < arr.length - 1; i++) {
      fillerData.push('[data]');
    }
    fillerData.push('');
  };

  addToFiller(Abstract);
  // addToFiller(ContactInformation);
  // addToFiller(ContractDescription);
  // addToFiller(Risks);
  // addToFiller(Opport);
  addToFiller(MajorContractPriorities);

  TemplateWorksheet.getColumn(2).values = fillerData;
  TemplateWorksheet.getColumn(1).values = Abstract.concat(MajorContractPriorities); // (ContactInformation, ContractDescription, Risks, Opport);

  TemplateWorksheet.getCell('C2').value = `Date Created:`;
  TemplateWorksheet.getCell('D2').value = dayjs(new Date()).format('YYYY-MM-DD');
  TemplateWorksheet.getCell('C3').value = `Date Modified:`;
  TemplateWorksheet.getCell('D3').value = dayjs(new Date()).format('YYYY-MM-DD');

  const tableHeaderRows = [10, 19, 29, 36, 41, 53]; // [29, 38, 48, 58, 64, 76];
  tableHeaderRows.forEach((row, index) => {
    const headerIndex = row - 1;
    const questIndex = row + 1;
    TemplateWorksheet.getCell(`A${headerIndex}`).value = tables[index].name.title;
    TemplateWorksheet.mergeCells(`A${headerIndex}:C${headerIndex}`);
    TemplateWorksheet.getCell(`A${headerIndex}`).font = {
      name: 'Calibri',
      bold: true,
      underline: true,
      size: 18
    };
    TableColumns.forEach((col) => {
      TemplateWorksheet.getRow(`${col}${headerIndex}`).font = {
        name: 'Calibri',
        bold: true,
        size: 12
      };
    });

    TemplateWorksheet.getRow(row).values = rowHeaders;
    tables[index].description.forEach((desc, i) => {
      TemplateWorksheet.getCell(`A${questIndex + i}`).value = desc.question;
      TemplateWorksheet.getCell(`B${questIndex + i}`).value = '[data]';
      TemplateWorksheet.getCell(`C${questIndex + i}`).value = '[data]';
      TemplateWorksheet.getCell(`D${questIndex + i}`).value = '[data]';
      // TemplateWorksheet.getCell(`E${questIndex + i}`).value = '[data]';
    });
  });

  workbook.xlsx.writeBuffer().then((r) => {
    saveAs(new Blob([r.buffer]), `${JobNumber}-ContractAbstractTEMPLATE.xlsx`);
  });
};

export default DownloadAbstractTemplate;
