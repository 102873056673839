import { NaNtoNum } from '@aldridge/aldg-helpers';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import NaNtoZero from '../../../utils/convertNaNToZero';
import existsWithLength from '../../../utils/existsWithLength';
import {
  generalOverview,
  basicFont,
  headerFont,
  numberFormatAccounting$,
  numberFormatRP1000s0D,
  createTotalRow,
  createTable,
  createSegmentTable
  // createSuppDescriptionTable
} from '../../../_helpers/ExcelJSHelpers';
import { firestore } from '../../../firebase';

const DownloadChangeOrder = async (recordid, record, Segments, pm, jobGL, Defaults) => {
  const changeOrderType = record?.data?.ChangeOrderType || 'Budget';
  const AldridgeNumber = record?.data?.AldridgeChangeOrder || '';
  const BudgetDescription = record?.data?.BudgetDescription || '';
  const contractVal = record?.data?.ContractValue;
  const GLCalc = !['Authorized Extras', 'Claims'].includes(changeOrderType);
  let GLIdx = -1;
  let GLrow = -1;

  const blueMoney = (cell) => {
    cell.font = { name: 'Arial', size: 10, color: { argb: 'FF0000FF' } };
    cell.numFmt = numberFormatAccounting$;
  };

  const totalRowAccounting = (cell) => {
    cell.numFmt = (cell.numFmt || '') === '' ? numberFormatAccounting$ : cell.numFmt;
    cell.font = { name: 'Arial', size: 10, color: { argb: 'FF00006C' } };
  };

  const workbook = new ExcelJS.Workbook();
  const SegCount = Segments.length;
  const valueList = ['Pay Item', 'Cost Code', 'Description', 'Supplementary Description', '', '', '', '', 'Total', '', 'Notes', 'Customer Cost Code'];
  const jobName = await generalOverview(recordid);
  const ContractGLRate = existsWithLength(jobGL) ? jobGL : Defaults.ContractGLRate;

  const allPiccs = await getDocs(
    query(
      collection(firestore, 'ENT-Piccs'),
      where('jobid', '==', recordid),
      //  where('Status', '==', 'Active'),
      where('CostType', 'in', ['L', 'M', 'S', 'E', 'O']),
      orderBy('PICC')
    )
  );
  const allPiccData = allPiccs?.docs?.map((d) => d?.data()).map((d) => ({ ...d, SegmentValue: d?.CostCode }));
  // ?.sort((a, b) => a.PICC - b.PICC);
  const fiveBlankRows = [
    ['', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', '', '', '']
  ];

  const rows = {};

  const createLaborRows = (lcos) => {
    const lrows = [];
    lcos.forEach((lco) => {
      lrows.push([
        lco?.PayItem,
        NaNtoZero(lco?.SegmentValue) === 0 ? lco?.SegmentValue : NaNtoZero(lco?.SegmentValue),
        lco?.Description,
        lco?.FriendlyName,
        NaNtoZero(lco?.LaborRate),
        NaNtoZero(lco?.LaborHours),
        NaNtoZero(lco?.LaborCost),
        NaNtoZero(lco?.BurdenCost),
        NaNtoZero(lco?.LaborTotal),
        lco?.PNP,
        lco?.Notes,
        lco?.CustomerCostCode
      ]);
    });
    return lrows;
  };
  const createNonLaborRows = (cos) => {
    const nlrows = [];
    cos.forEach((co) => {
      nlrows.push([
        co?.PayItem,
        NaNtoZero(co?.SegmentValue) === 0 ? co?.SegmentValue : NaNtoZero(co?.SegmentValue),
        co?.Description,
        co?.FriendlyName,
        '',
        '',
        '',
        '',
        NaNtoZero(co?.Total),
        '',
        co?.Notes,
        co?.CustomerCostCode
      ]);
    });
    return nlrows;
  };

  // create rows that are there on download

  const updateRowObj = (r, COType) => {
    if (existsWithLength(r.data[`${COType}ChangeOrders`]))
      rows[`${COType}ChangeOrders`] = createNonLaborRows(r.data[`${COType}ChangeOrders`]).concat(fiveBlankRows);
    else rows[`${COType}ChangeOrders`] = createNonLaborRows(allPiccData.filter((p) => p.CostType === COType.substring(0, 1))).concat(fiveBlankRows);
  };

  if (existsWithLength(record?.data.LaborChangeOrders))
    rows.LaborChangeOrders = createLaborRows(record?.data.LaborChangeOrders).concat(fiveBlankRows);
  else rows.LaborChangeOrders = createNonLaborRows(allPiccData.filter((p) => p.CostType === 'L')).concat(fiveBlankRows);

  ['Material', 'Subcontractors', 'Equipment'].forEach((cotype) => updateRowObj(record, cotype));

  if (existsWithLength(record?.data.OtherChangeOrders)) {
    if (record?.data.OtherChangeOrders.length === 1) {
      rows.OtherChangeOrders = createNonLaborRows(record?.data.OtherChangeOrders).concat(
        createNonLaborRows(
          allPiccData.filter((p) => p.CostType === 'O' && p.SegmentValue !== record?.data.OtherChangeOrders[0].SegmentValue.toString())
        ).concat(fiveBlankRows)
      );
    } else {
      rows.OtherChangeOrders = createNonLaborRows(record?.data.OtherChangeOrders).concat(fiveBlankRows);
    }
  } else {
    rows.OtherChangeOrders = createNonLaborRows(allPiccData.filter((p) => p.CostType === 'O')).concat(fiveBlankRows);
  }

  // begin cost code worksheet

  const TemplateWorksheet = workbook.addWorksheet('Template', {
    views: [{ showGridLines: false }]
  });
  createSegmentTable(workbook, Segments, SegCount);
  // createSuppDescriptionTable(workbook, allPiccData, allPiccData.length);

  // b,e,l,m,o,s
  const EquipCCs = [Segments.map((s) => s.CostType).indexOf('E') + 2, Segments.map((s) => s.CostType).indexOf('L') + 1];
  const LaborCCs = [Segments.map((s) => s.CostType).indexOf('L') + 2, Segments.map((s) => s.CostType).indexOf('M') + 1];
  const MaterialCCs = [Segments.map((s) => s.CostType).indexOf('M') + 2, Segments.map((s) => s.CostType).indexOf('O') + 1];
  const OtherCCs = [Segments.map((s) => s.CostType).indexOf('O') + 2, Segments.map((s) => s.CostType).indexOf('S') + 1];
  const SubCCs = [Segments.map((s) => s.CostType).indexOf('S') + 2, Segments.length + 1];

  // end cost code worksheet

  // begin template worksheet

  // begin header

  TemplateWorksheet.mergeCells('A1:C1');
  for (let i = 2; i < 7; i++) {
    TemplateWorksheet.mergeCells(`A${i}:B${i}`);
  }
  TemplateWorksheet.getColumn(1).values = ['Original Budget', 'Job Number', 'Job Name', 'PM', 'Aldridge #', 'Budget Description'];

  TemplateWorksheet.getCell('A1').font = {
    name: 'Arial',
    bold: true,
    underline: true,
    size: 18
  };
  TemplateWorksheet.getColumn(3).values = [changeOrderType || 'Budget', recordid, jobName, pm, AldridgeNumber, BudgetDescription];
  TemplateWorksheet.getCell('A2').font = {
    name: 'Arial',
    bold: true,
    underline: true,
    size: 10
  };
  for (let i = 2; i < 7; i++) {
    TemplateWorksheet.getCell(`C${i}`).alignment = { horizontal: 'left' };
  }
  TemplateWorksheet.getCell('G3').value = `Today's Date:`;
  TemplateWorksheet.getCell('H3').value = new Date();

  // end header

  // begin labor table

  TemplateWorksheet.columns = [
    { key: 'PayItem', width: 11, style: basicFont },
    { key: 'CostCode', width: 10, style: basicFont },
    { key: 'Description', width: 32, style: basicFont },
    { key: 'SupplementaryDescription', width: 32, style: basicFont },
    { key: 'LaborRate', width: 10, style: basicFont },
    { key: 'LaborHours', width: 18, style: basicFont },
    { key: 'LaborCost', width: 18, style: basicFont },
    { key: 'LaborBurden', width: 18, style: basicFont },
    { key: 'LaborTotal', width: 18, style: basicFont },
    { key: 'PNP', width: 10, style: basicFont },
    { key: 'Notes', width: 40, style: basicFont },
    { key: 'CostCodeId', width: 15, style: basicFont }
  ];

  const laborTableStart = 10;
  const endLaborRow = createTable(
    TemplateWorksheet,
    laborTableStart,
    'LABOR',
    rows.LaborChangeOrders,
    [
      'Pay Item',
      'Cost Code',
      'Description',
      'Supplementary Description',
      'Labor Rate',
      'Labor Hours',
      'Labor Cost',
      'Labor Burden',
      'Labor Total',
      'P/NP',
      'Notes',
      'Customer Cost Code'
    ],
    ['F', 'G', 'H', 'I']
  );

  TemplateWorksheet.getCell(`F${endLaborRow}`).numFmt = numberFormatRP1000s0D;

  TemplateWorksheet.getCell(`D${endLaborRow + 1}`).value = 'Productive Hours';
  TemplateWorksheet.getCell(`D${endLaborRow + 1}`).alignment = {
    horizontal: 'right'
  };

  // Aggregation for Productive hours
  TemplateWorksheet.getCell(`F${endLaborRow + 1}`).value = {
    formula: `SUMIF(J${laborTableStart}:J${endLaborRow - 1},"P",F${laborTableStart}:F${endLaborRow - 1})`
  };
  TemplateWorksheet.getCell(`G${endLaborRow + 1}`).value = {
    formula: `SUMIF(J${laborTableStart}:J${endLaborRow - 1},"P",G${laborTableStart}:G${endLaborRow - 1})`
  };
  TemplateWorksheet.getCell(`H${endLaborRow + 1}`).value = {
    formula: `SUMIF(J${laborTableStart}:J${endLaborRow - 1},"P",H${laborTableStart}:H${endLaborRow - 1})`
  };
  TemplateWorksheet.getCell(`I${endLaborRow + 1}`).value = {
    formula: `SUMIF(J${laborTableStart}:J${endLaborRow - 1},"P",I${laborTableStart}:I${endLaborRow - 1})`
  };

  TemplateWorksheet.getCell(`F${endLaborRow + 1}`).font = {
    name: 'Arial',
    size: 10,
    color: { argb: 'FF898989' }
  };
  TemplateWorksheet.getCell(`F${endLaborRow + 1}`).numFmt = numberFormatRP1000s0D;
  TemplateWorksheet.getCell(`G${endLaborRow + 1}`).numFmt = '$#,##0.00_);[Red]($#,##0.00)';
  TemplateWorksheet.getCell(`H${endLaborRow + 1}`).numFmt = '$#,##0.00_);[Red]($#,##0.00)';
  TemplateWorksheet.getCell(`I${endLaborRow + 1}`).numFmt = '$#,##0.00_);[Red]($#,##0.00)';

  TemplateWorksheet.getCell(`D${endLaborRow + 2}`).value = 'Non Productive Hours';
  TemplateWorksheet.getCell(`D${endLaborRow + 2}`).alignment = {
    horizontal: 'right'
  };

  // Aggregation for Non-Productive hours
  TemplateWorksheet.getCell(`F${endLaborRow + 2}`).value = {
    formula: `SUMIF(J${laborTableStart}:J${endLaborRow - 1},"NP",F${laborTableStart}:F${endLaborRow - 1})`
  };
  TemplateWorksheet.getCell(`G${endLaborRow + 2}`).value = {
    formula: `SUMIF(J${laborTableStart}:J${endLaborRow - 1},"NP",G${laborTableStart}:G${endLaborRow - 1})`
  };
  TemplateWorksheet.getCell(`H${endLaborRow + 2}`).value = {
    formula: `SUMIF(J${laborTableStart}:J${endLaborRow - 1},"NP",H${laborTableStart}:H${endLaborRow - 1})`
  };
  TemplateWorksheet.getCell(`I${endLaborRow + 2}`).value = {
    formula: `SUMIF(J${laborTableStart}:J${endLaborRow - 1},"NP",I${laborTableStart}:I${endLaborRow - 1})`
  };
  TemplateWorksheet.getCell(`F${endLaborRow + 2}`).font = {
    name: 'Arial',
    size: 10,
    color: { argb: 'FF898989' }
  };
  TemplateWorksheet.getCell(`F${endLaborRow + 2}`).numFmt = numberFormatRP1000s0D;
  TemplateWorksheet.getCell(`G${endLaborRow + 2}`).numFmt = '$#,##0.00_);[Red]($#,##0.00)';
  TemplateWorksheet.getCell(`H${endLaborRow + 2}`).numFmt = '$#,##0.00_);[Red]($#,##0.00)';
  TemplateWorksheet.getCell(`I${endLaborRow + 2}`).numFmt = '$#,##0.00_);[Red]($#,##0.00)';

  // end labor table

  // begin material table

  const materialTableStart = endLaborRow + 4;
  const endMaterialRow = createTable(TemplateWorksheet, materialTableStart, 'MATERIAL', rows.MaterialChangeOrders, valueList, ['I']);

  // end material table

  // begin subcontractor table

  const subcontractorTableStart = endMaterialRow + 2;
  const endSubcontractorRow = createTable(TemplateWorksheet, subcontractorTableStart, 'SUBCONTRACTOR', rows.SubcontractorsChangeOrders, valueList, [
    'I'
  ]);

  // end subcontractor table

  // begin equipment table

  const equipmentTableStart = endSubcontractorRow + 2;
  const endEquipmentRow = createTable(TemplateWorksheet, equipmentTableStart, 'EQUIPMENT/FUEL', rows.EquipmentChangeOrders, valueList, ['I']);

  // end equipment table

  // begin other table

  const otherTableStart = endEquipmentRow + 2;

  if (GLCalc) {
    GLIdx = rows.OtherChangeOrders.findIndex((oco) => `${oco[0]}${oco[1]}` === '10050002');
    if (GLIdx < 0) rows.OtherChangeOrders.unshift([100, 50002, 'GEN LIAB/UMBR INS', '', '', '', '', '', '', '', '', '']);
    GLrow = GLIdx < 0 ? otherTableStart + 1 : otherTableStart + 1 + GLIdx;
  }
  const endOtherRow = createTable(TemplateWorksheet, otherTableStart, 'OTHER COSTS', rows.OtherChangeOrders, valueList, ['I']);

  // end other table

  // begin summary table

  TemplateWorksheet.getCell(`A${endOtherRow + 1}`).value = 'SUMMARY';
  TemplateWorksheet.getCell(`A${endOtherRow + 1}`).font = headerFont;

  TemplateWorksheet.getCell(`D${endOtherRow + 2}`).value = 'Labor';
  TemplateWorksheet.getCell(`H${endOtherRow + 2}`).value = {
    formula: `F${endLaborRow}`
  };
  TemplateWorksheet.getCell(`H${endOtherRow + 2}`).numFmt = numberFormatRP1000s0D;
  TemplateWorksheet.getCell(`I${endOtherRow + 2}`).value = {
    formula: `I${endLaborRow}`
  };

  TemplateWorksheet.getCell(`D${endOtherRow + 3}`).value = 'Material';
  TemplateWorksheet.getCell(`I${endOtherRow + 3}`).value = {
    formula: `I${endMaterialRow}`
  };

  TemplateWorksheet.getCell(`D${endOtherRow + 4}`).value = 'Subcontracts';
  TemplateWorksheet.getCell(`I${endOtherRow + 4}`).value = {
    formula: `I${endSubcontractorRow}`
  };

  TemplateWorksheet.getCell(`D${endOtherRow + 5}`).value = 'Equipment/Fuel';
  TemplateWorksheet.getCell(`I${endOtherRow + 5}`).value = {
    formula: `I${endEquipmentRow}`
  };

  TemplateWorksheet.getCell(`D${endOtherRow + 6}`).value = 'Other costs';
  TemplateWorksheet.getCell(`I${endOtherRow + 6}`).value = {
    formula: `I${endOtherRow}`
  };

  createTotalRow(TemplateWorksheet, ['I'], endOtherRow + 2, endOtherRow + 7);

  // end summary table

  // begin budget table

  const budgetTableStart = endOtherRow + 9;
  TemplateWorksheet.getCell(`A${budgetTableStart}`).value = 'BUDGET';
  TemplateWorksheet.getCell(`A${budgetTableStart}`).font = headerFont;
  TemplateWorksheet.getCell(`I${budgetTableStart}`).value = '';

  TemplateWorksheet.getCell(`D${budgetTableStart + 1}`).value = 'Contract Value';
  TemplateWorksheet.getCell(`G${budgetTableStart + 1}`).value = NaNtoZero(contractVal);
  TemplateWorksheet.getCell(`G${budgetTableStart + 1}`).numFmt = numberFormatAccounting$;
  TemplateWorksheet.getCell(`I${budgetTableStart + 1}`).value = '';

  TemplateWorksheet.getCell(`D${budgetTableStart + 2}`).value = 'Projected Costs';
  TemplateWorksheet.getCell(`G${budgetTableStart + 2}`).value = { formula: `I${endOtherRow + 7}` };
  totalRowAccounting(TemplateWorksheet.getCell(`G${budgetTableStart + 2}`));
  TemplateWorksheet.getCell(`I${budgetTableStart + 2}`).value = '';

  TemplateWorksheet.getCell(`D${budgetTableStart + 3}`).value = 'Projected Profit';
  TemplateWorksheet.getCell(`G${budgetTableStart + 3}`).value = { formula: `G${budgetTableStart + 1} - G${budgetTableStart + 2}` };
  TemplateWorksheet.getCell(`G${budgetTableStart + 3}`).border = { top: { style: 'thick' } };
  totalRowAccounting(TemplateWorksheet.getCell(`G${budgetTableStart + 3}`));

  TemplateWorksheet.getCell(`H${budgetTableStart + 3}`).value = { formula: `IFERROR(G${budgetTableStart + 3} / G${budgetTableStart + 1}, "")` };
  TemplateWorksheet.getCell(`H${budgetTableStart + 3}`).alignment = { horizontal: 'right' };
  TemplateWorksheet.getCell(`H${budgetTableStart + 3}`).numFmt = '0%';
  TemplateWorksheet.getCell(`H${budgetTableStart + 3}`).font = { name: 'Arial', size: 10, color: { argb: 'FF00006C' } };
  TemplateWorksheet.getCell(`I${budgetTableStart + 3}`).value = '';

  // end budget table

  // final formatting

  const isInNonLaborTable = (rowNumber) =>
    (rowNumber > materialTableStart && rowNumber < endMaterialRow) ||
    (rowNumber > equipmentTableStart && rowNumber < endEquipmentRow) ||
    (rowNumber > subcontractorTableStart && rowNumber < endSubcontractorRow) ||
    (rowNumber > otherTableStart && rowNumber < endOtherRow);

  const getCCLookupRange = (rowNumber) => {
    if (rowNumber > materialTableStart && rowNumber < endMaterialRow) return MaterialCCs;
    if (rowNumber > equipmentTableStart && rowNumber < endEquipmentRow) return EquipCCs;
    if (rowNumber > subcontractorTableStart && rowNumber < endSubcontractorRow) return SubCCs;
    if (rowNumber > otherTableStart && rowNumber < endOtherRow) return OtherCCs;
    return LaborCCs;
  };

  TemplateWorksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1 && rowNumber < 8) {
      row.eachCell((cell, colNumber) => {
        if ([3, 8].includes(colNumber)) {
          if (colNumber === 3) cell.border = { bottom: { style: 'thin' } };
          cell.font = basicFont;
          cell.alignment = { horizontal: 'left' };
        } else {
          cell.font = {
            name: 'Arial',
            bold: true,
            underline: 'single',
            size: 10
          };
          cell.alignment = { horizontal: 'right' };
        }
      });
    } else if (rowNumber > laborTableStart && rowNumber < endLaborRow) {
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if ([1, 2].includes(colNumber)) cell.alignment = { horizontal: 'center' };
        else if (colNumber === 3)
          // labor description lookup
          cell.value = {
            formula: `IFERROR(VLOOKUP(B${rowNumber},'Cost Codes'!A$${LaborCCs[0]}:I$${LaborCCs[1]},7,FALSE),"N/A")`
          };
        else if (colNumber === 5) cell.numFmt = numberFormatAccounting$;
        else if (colNumber === 6) cell.numFmt = numberFormatRP1000s0D;
        else if (colNumber === 7) {
          cell.value = {
            formula: `IFERROR(ROUND(E${rowNumber}*F${rowNumber}*0.65,2),0)`,
            result: NaNtoNum(TemplateWorksheet.getCell(`E${rowNumber}`)) * NaNtoNum(TemplateWorksheet.getCell(`F${rowNumber}`)) * 0.65
          };
          blueMoney(cell);
        } else if (colNumber === 8) {
          cell.value = {
            formula: `IFERROR(ROUND(E${rowNumber}*F${rowNumber}*0.35,2),0)`,
            result: NaNtoNum(TemplateWorksheet.getCell(`E${rowNumber}`)) * NaNtoNum(TemplateWorksheet.getCell(`F${rowNumber}`)) * 0.35
          };
          blueMoney(cell);
        } else if (colNumber === 9) {
          cell.value = {
            formula: `IFERROR(G${rowNumber}+H${rowNumber},0)`,
            result: NaNtoNum(TemplateWorksheet.getCell(`G${rowNumber}`)) + NaNtoNum(TemplateWorksheet.getCell(`H${rowNumber}`))
          };
          blueMoney(cell);
        } else if (colNumber === 10)
          // P/NP lookup
          cell.value = {
            formula: `IFERROR(VLOOKUP(B${rowNumber},'Cost Codes'!A$${LaborCCs[0]}:I$${LaborCCs[1]},9,FALSE),"")`
          };
      });
    } else if (rowNumber >= budgetTableStart && rowNumber <= budgetTableStart + 3) {
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (colNumber < 12) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFA7A7A7' }
          };
        }
      });
    } else if (
      [endLaborRow, endMaterialRow, endSubcontractorRow, endEquipmentRow, endOtherRow].includes(rowNumber) ||
      (rowNumber > endOtherRow + 1 && rowNumber < endOtherRow + 8) ||
      isInNonLaborTable(rowNumber)
    ) {
      if (rowNumber === GLrow) {
        row.eachCell((cell, colNumber) => {
          if (colNumber === 9)
            cell.value = {
              formula: `G${budgetTableStart + 1} * ${ContractGLRate}`,
              result: NaNtoNum(TemplateWorksheet.getCell(`G${budgetTableStart + 1}`) * ContractGLRate)
            };
        });
      }
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if ([7, 8, 9].includes(colNumber)) totalRowAccounting(cell);
        else if ([1, 2].includes(colNumber)) cell.alignment = { horizontal: 'center' };
        else if (colNumber === 3 && isInNonLaborTable(rowNumber)) {
          const lookupTableRanges = getCCLookupRange(rowNumber);
          // non labor description lookup
          cell.value = {
            formula: `IFERROR(VLOOKUP(B${rowNumber},'Cost Codes'!A$${lookupTableRanges[0]}:I$${lookupTableRanges[1]},7,FALSE),"N/A")`
          };
        }
      });
    }
  });

  // end template worksheet
  workbook.xlsx.writeBuffer().then((r) => {
    saveAs(
      new Blob([r.buffer]),
      existsWithLength(record?.data.BudgetDescription)
        ? `${recordid} - ${changeOrderType} - ${
            record?.data.BudgetDescription.length > 20
              ? `${(record?.data.BudgetDescription || '').trim().slice(0, 19)}..`
              : record?.data.BudgetDescription
          }_Budget Upload.xlsx`
        : `${recordid}_${record?.data?.ChangeOrderType === 'Revised Budget' ? 'RevisedBudget' : 'BudgetUpload'}.xlsx`
    );
  });
};

export default DownloadChangeOrder;
