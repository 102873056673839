import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import MenuCard from './MenuCard';

const DraggableArea = (props) => {
  const { menuCard, idx, setPreferences, setChanged, preferences, history } = props;
  const [draggedOver, setDraggedOver] = React.useState(false);

  const onDragStart = (ev, id) => {
    ev.dataTransfer.setData('id', id);
  };
  const onDragOver = (ev) => {
    ev.preventDefault();
    setDraggedOver(true);
  };

  const reOrder = (moved, order) => {
    const updatedPreferences = JSON.parse(JSON.stringify(preferences));

    updatedPreferences.splice(moved.order, 1);
    updatedPreferences.splice(order, 0, moved);

    for (let i = 0; i < updatedPreferences.length; i++) {
      updatedPreferences[i] = Object.assign(JSON.parse(JSON.stringify(updatedPreferences[i])), { order: i });
    }
    if (preferences[order].favorite !== moved.favorite) updatedPreferences[moved.order].favorite = !moved.favorite;

    setPreferences(updatedPreferences);
    setChanged(true);
  };

  const onDrop = (ev, order) => {
    const id = ev.dataTransfer.getData('id');

    const movedPref = preferences.filter((pref) => {
      if (pref.title === id) return true;
      return false;
    })[0];

    setDraggedOver(false);
    if (order === movedPref.order) return;
    reOrder(movedPref, order);
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={4}
      xl={3}
      key={menuCard.label}
      style={{ padding: '0px', outline: draggedOver ? '1px solid blue' : 'none', borderRadius: draggedOver ? '4px' : 'none' }}
    >
      <div onDragOver={(e) => onDragOver(e)} onDragLeave={() => setDraggedOver(false)} onDrop={(e) => onDrop(e, idx)} key={menuCard.href}>
        <div
          key={menuCard.description}
          draggable
          onDragStart={(e) => {
            onDragStart(e, menuCard.title);
          }}
        >
          <MenuCard
            key={menuCard.title}
            label={menuCard.title}
            icon={menuCard.icon}
            link={menuCard.href}
            description={menuCard.description}
            preferences={preferences}
            currentLocation={idx}
            setChanged={setChanged}
            setPreferences={setPreferences}
            history={history}
          />
        </div>
      </div>
    </Grid>
  );
};
DraggableArea.propTypes = {
  menuCard: PropTypes.object.isRequired,
  preferences: PropTypes.arrayOf(PropTypes.object).isRequired,
  idx: PropTypes.number.isRequired,
  setPreferences: PropTypes.func.isRequired,
  setChanged: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default DraggableArea;
