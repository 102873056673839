import React from 'react';
import { Grid, Typography } from '@mui/material';

const Fallback = () => (
  <Grid item xs={12}>
    <Typography variant='h5' style={{ textAlign: 'center', padding: '25px', fontWeight: 'bold', fontStyle: 'italic', outline: '1px solid black' }}>
      Content Loading, please wait...
    </Typography>
  </Grid>
);

export default Fallback;
