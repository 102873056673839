import React from 'react';

const Test = () => {
  const beans = 1;
  return (
    <div>
      <div>Test {beans}</div>
    </div>
  );
};

export default Test;
