/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { collection, onSnapshot } from 'firebase/firestore';
import dayjs from 'dayjs';
import TooltipIconButton from '../../UIComponents/TooltipIconButton';
import { firestore } from '../../../firebase';

const ContractAbstractList = (props) => {
  const { jctdscid, history } = props;
  const [rows, setRows] = useState([]);
  const columns = [
    // { field: 'id', headerName: 'Contract Abstract ID', valueGetter: (params) => params.row.id, flex: 30 },
    {
      field: 'CreatedBy',
      headerName: 'Created By',
      valueGetter: (params) => params.row.CreatedBy,
      flex: 30
    },
    {
      field: 'CreatedDate',
      headerName: 'Created Date',
      valueGetter: (params) => params.row.CreatedDate,
      valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY HH:mm a'),
      flex: 30
    }
  ];

  useEffect(() => {
    let mounted = true;
    onSnapshot(collection(firestore, `ENT-Jobs/${jctdscid}/ContractAbstract`), (snap) => {
      const all = [];
      snap.forEach((d) => all.push(d.data()));
      if (mounted) setRows(all);
    });
    return () => {
      mounted = false;
    };
  }, []);

  const handleSelect = useCallback((id) => {
    if (id === 'new') history.push(`/Job/${jctdscid}/ContractAbstract/new/ContractAbstractView`);
    else {
      history.push(`/Job/${jctdscid}/ContractAbstract/${id}/ContractAbstractView`);
    }
  }, []);

  return (
    <Paper variant='outlined' className='aldg-field'>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: 'block',
            padding: '0px',
            textAlign: 'center'
          }}
        >
          <Grid container justifyContent='flex-end'>
            <TooltipIconButton color='success' onClick={() => handleSelect('new')} title='New Abstract' icon={faPlus} />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <AldgDataGrid
              pageSizeOptions={[10, 20, 30]}
              columns={columns}
              rows={rows}
              onRowClick={(params) => {
                handleSelect(params.id);
              }}
              getRowId={(row) => row.id}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

ContractAbstractList.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};
ContractAbstractList.defaultProps = {};

export default ContractAbstractList;
