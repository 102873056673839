import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputToggle from '../../UIComponents/InputToggle';
import TextDisplay from '../../UIComponents/TextDisplay';

const ProjectOwnership = (props) => {
  const { Transaction, onChange, editing, allowWrap } = props;

  return (
    <Grid container>
      <TextDisplay label='Cosential Owner' value={Transaction.CosentialOwner || ''} />
      <TextDisplay label='Project Role' value={Transaction.ProjectRole} />
      <TextDisplay label='Cosential Client' value={Transaction.CosentialClient || ''} />
      <InputToggle
        allowWrap={allowWrap}
        edit={editing}
        label='Customer Contract Number'
        value={Transaction.CustomerContractNumber}
        onChange={onChange}
        name='CustomerContractNumber'
      />
      <TextDisplay label='Ecms Division' value={Transaction.EcmsDivision || ''} />
      <TextDisplay label='ECMS Division Name' value={Transaction.EcmsDivisionName || ''} />
    </Grid>
  );
};

ProjectOwnership.propTypes = {
  Transaction: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  editing: PropTypes.bool,
  allowWrap: PropTypes.bool
};
ProjectOwnership.defaultProps = {
  onChange: () => {},
  editing: false,
  allowWrap: false
};

export default ProjectOwnership;
