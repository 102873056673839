import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { _MonthEndDate } from '../../../../../_Recoil/atoms';
import { nonMuiThemes } from '../../../../../theme';
import NaNtoZero from '../../../../../utils/convertNaNToZero';
import { formatAsCurrency } from '../../../../UIComponents/format';
import getMostRecentMonth from '../../../../../utils/getMostRecentMonth';
import { firestore } from '../../../../../firebase';
import ContigencyCard from './ContingencyCard';

const ContingencyKPI = (props) => {
  const { jctdscid } = props;
  const [contingencies, setContingencies] = useState([]);
  const [past3Months, setPast3Months] = useState([]);
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    const getData = async () => {
      const queryPromises = [];
      const allContingencies = [];
      const p3m = [
        MonthEndDate,
        await getMostRecentMonth(jctdscid, MonthEndDate),
        await getMostRecentMonth(jctdscid, await getMostRecentMonth(jctdscid, MonthEndDate))
      ];
      if (mounted.current) setPast3Months(p3m);
      p3m.forEach((month) => {
        const q = query(
          collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${month}/Forecasting`),
          where('CostCode', 'in', ['59991', '59992', '59993', '59994', '59999'])
        );
        queryPromises.push(
          getDocs(q).then((docs) => {
            docs.forEach((d) => {
              allContingencies.push(d.data());
            });
          })
        );
      });
      if (mounted.current)
        Promise.all(queryPromises).then(() => {
          if (mounted.current) setContingencies(allContingencies);
        });
    };
    getData();
  }, []);

  const anyConts = (conts, monthEnd) => existsWithLength(conts?.filter((c) => c.MonthEndDate === monthEnd));

  return (
    <>
      {past3Months
        .filter((m) => m !== '2010-12-31')
        .map((month) => (
          <React.Fragment key={month}>
            <div key={month} style={{ outline: '2px solid gray', padding: '6px', marginBottom: '16px' }}>
              <Typography variant='h4' style={{ textAlign: 'center' }}>
                {month}
              </Typography>
              <Typography variant='h6' style={{ textAlign: 'center', color: 'gray', paddingBottom: '8px' }}>
                Total:{' '}
                {formatAsCurrency(
                  contingencies?.filter((c) => c.MonthEndDate === month).reduce((totalCont, cont) => totalCont + NaNtoZero(cont.CostToComplete), 0),
                  true,
                  0
                )}
              </Typography>
              {anyConts(contingencies, month) &&
                contingencies
                  ?.filter((c) => c.MonthEndDate === month)
                  .map((contingency) => <ContigencyCard contingency={contingency} key={`${contingency.MonthEndDate}${contingency.Picc}`} />)}
            </div>
            <div style={nonMuiThemes.divider} />
          </React.Fragment>
        ))}
    </>
  );
};

ContingencyKPI.propTypes = {
  jctdscid: PropTypes.string.isRequired
};
ContingencyKPI.defaultProps = {};

export default ContingencyKPI;
