import React from 'react';

const StatusTooltip = () => (
  <ul style={{ fontSize: '12px' }}>
    <li>
      <b>Pending Tracking - </b>Change is in prelim stage, with no definitive value. Change will not appear on the Status Sheet.
    </li>
    <br />
    <li>
      <b>Pending Not Carried - </b>Change Order pricing is submitted but no cost has been incurred. Change will not appear on the Status Sheet.
    </li>
    <br />
    <li>
      <b>Pending Negative - </b>Deductive change orders are always carried on the Status Sheet, regardless of cost incurred.
    </li>
    <br />
    <li>
      <b>Pending Carried - </b>Change Order pricing is positive, submitted and cost is incurred. Change will appear on the Status Sheet as Pending.
    </li>
    <br />
    {/* <li>
      <b>Pending Approval - </b> Change Order pricing is submitted and cost is incurred. Change will appear on the Status Sheet as Pending Approval.
    </li>
    <br /> */}
    <li>
      <b>Approved - </b>We have received written approval from the customer. Change will appear on the Status Sheet as approved.
    </li>
    <br />
    <li>
      <b>Approved FE - </b>Job Cost use only, to be checked when we receive a fully executed document from the customer.
    </li>
    <br />
    <li>
      <b>Voided - </b>Budgets that have been revoked or canceled.
    </li>
    <br />
  </ul>
);

export default StatusTooltip;
