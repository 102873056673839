import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ErrorBoundary from '../../../_GlobalComponents/ErrorBoundary';
import TextDisplay from '../../UIComponents/TextDisplay';

const ProjectOverview = (props) => {
  const { Transaction, monthEndDate } = props;

  return (
    <ErrorBoundary componentName='Project Overview'>
      <Grid container className='aldg-bordered'>
        <Grid item xs={12} className='aldg-headerTitle'>
          JOB INFORMATION
        </Grid>
        <Grid item xs={12} md={5}>
          <TextDisplay label='Job #' value={Transaction.EcmsJobNumber} />
          <TextDisplay label='Project' value={Transaction.EcmsJobDescription} />
          <TextDisplay label='Project Manager' value={Transaction.ProjectManager} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextDisplay label='Sub #' value={Transaction.EcmsSubJobNumber} />
          <TextDisplay label='Division' value={`${Transaction.EcmsDivisionNumber || ''} - ${Transaction.EcmsDivisionName}`} />
          <TextDisplay label='Customer' value={Transaction.EcmsCustomerName} />
          <TextDisplay label='Owner' value={Transaction.CosentialOwner} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextDisplay label='Report Date' value={new Date().toLocaleDateString()} />
          <TextDisplay label='Cost Through' value={monthEndDate} />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

ProjectOverview.propTypes = {
  Transaction: PropTypes.object,
  monthEndDate: PropTypes.string
};
ProjectOverview.defaultProps = {
  Transaction: {},
  monthEndDate: '2010-12-31'
};

export default ProjectOverview;
