import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SpeedDialAction, SpeedDial, SpeedDialIcon, Box } from '@mui/material';
import { faNotebook, faListCheck, faPaperclip, faTableRows } from '@fortawesome/pro-regular-svg-icons';
// import { faNotebook, faListCheck, faPaperclip, faTableRows } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../providers/UserProvider';
import { _MonthEndDate, _NotesModal, _SecurityLevel } from '../../../_Recoil/atoms';
import existsWithLength from '../../../utils/existsWithLength';
// import Popout from 'react-popout';
import uploadAttachment from '../Attachments/uploadAttachment';
import QuickAddActionItems from './QuickAddActionItems';
import QuickAddNote from './QuickAddNote';
import { AdminUp, EditorUp } from '../UserAssignment/checkSecurity';
import { StatusContext } from '../../../providers/StatusProvider';

const QuickActions = (props) => {
  const { jctdscid, memorialized } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const user = useContext(UserContext);
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  const inputRef = useRef(null);
  const currentLocation = useLocation().pathname;
  const { loadAttachments } = useContext(StatusContext);

  const Role = useRecoilValue(_SecurityLevel);
  const setNotesModal = useSetRecoilState(_NotesModal);

  const handleSpeedDialOpen = (event) => {
    if (!existsWithLength(event) || event.type !== 'focus') setSpeedDialOpen(true);
  };
  const handleSpeedDialClose = (event) => {
    if (!existsWithLength(event) || !modalOpen) setSpeedDialOpen(false);
  };
  const quickAddClose = () => {
    setModalOpen(false);
    handleSpeedDialClose();
  };

  const actions = [
    {
      name: 'Add Note',
      icon: faNotebook,
      onClick: () => {
        setModalOpen('note');
      },
      canUserUse: EditorUp(Role)
    },
    {
      name: 'Add Action Item',
      icon: faListCheck,
      onClick: () => {
        setModalOpen('ai');
      },
      canUserUse: EditorUp(Role)
    },
    {
      name: 'View All Table Notes',
      icon: faTableRows,
      onClick: () => {
        setNotesModal({ open: true, force: true });
      },
      canUserUse: AdminUp(Role)
    }
  ];

  return (
    <>
      <QuickAddNote
        open={modalOpen === 'note'}
        jctdscid={jctdscid}
        handleClose={quickAddClose}
        location={currentLocation}
        memorialized={memorialized}
      />
      <QuickAddActionItems
        open={modalOpen === 'ai'}
        jctdscid={jctdscid}
        handleClose={quickAddClose}
        location={currentLocation}
        memorialized={memorialized}
      />
      {/* {modalOpen === 'tn' && (
        <Popout onClosing={quickAddClose} title='Table Notes Popout'>
          <TableNotesPopOut jctdscid={jctdscid} />
        </Popout>
      )} */}
      <Box
        sx={{
          '& .MuiFab-root': {
            maxHeight: '45px',
            maxWidth: '45px'
          },
          '& .MuiSpeedDialAction-fab': {
            backgroundColor: '#8dc9fa',
            fontSize: '25px',
            color: 'white'
          },
          zIndex: 10,
          position: 'absolute',
          bottom: '10px'
        }}
      >
        <SpeedDial
          icon={<SpeedDialIcon />}
          direction='right'
          ariaLabel='quick action wheel'
          open={speedDialOpen}
          onOpen={handleSpeedDialOpen}
          onClose={handleSpeedDialClose}
        >
          {existsWithLength(actions) &&
            actions
              .filter((action) => action.canUserUse)
              .map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={<FontAwesomeIcon icon={action.icon} />}
                  tooltipTitle={action.name}
                  onClick={action.onClick}
                />
              ))}
          {EditorUp(Role) && (
            <SpeedDialAction
              tooltipTitle='Add Attachment'
              key='file upload'
              onClick={() => inputRef.current.click()}
              icon={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <input
                    type='file'
                    ref={inputRef}
                    style={{ display: 'none' }}
                    onChange={(event) =>
                      uploadAttachment(event.target.files, jctdscid, true, loadAttachments, () => {}, user, MonthEndDate, currentLocation)
                    }
                  />
                  <FontAwesomeIcon icon={faPaperclip} />
                </div>
              }
            />
          )}
        </SpeedDial>
      </Box>
    </>
  );
};

QuickActions.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  memorialized: PropTypes.bool
};
QuickActions.defaultProps = { memorialized: false };

export default QuickActions;
