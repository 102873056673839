/* eslint-disable no-unused-vars */

import React, { useState, createContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { useRecoilValue } from 'recoil';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { _CurrentJob, _MonthEndDate } from '../_Recoil/atoms';
import { firestore } from '../firebase';
import getAttachments from '../Components/Jobs/Attachments/getAttachements';

export const StatusContext = createContext({
  budgets: [],
  forecasting: [],
  subjobs: [],
  attachments: [],
  loadAttachments: () => {}
});
const StatusProvider = (props) => {
  const [budgets, setBudgets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [forecasting, setForecasting] = useState([]);
  const [subjobs, setSubjobs] = useState([]);
  const [contractValues, setContractValues] = useState({});
  const [attachments, setAttachments] = useState([]);
  const Record = useRecoilValue(_CurrentJob);
  const MonthToPullFrom = useRecoilValue(_MonthEndDate);
  const { children } = props;

  useEffect(() => {
    if (existsWithLength(Record.id)) getAttachments({ current: true }, setLoading, setAttachments, Record.id);
  }, [Record.id]);

  const loadAttachments = useCallback((id) => getAttachments({ current: true }, setLoading, setAttachments, id || Record.id), [Record.id]);

  useEffect(() => {
    let mounted = true;
    if (Record.id !== '' && existsWithLength(MonthToPullFrom)) {
      onSnapshot(collection(firestore, `ENT-Jobs/${Record.id}/MonthEnd/${MonthToPullFrom}/ChangeOrders`), (snap) => {
        const allCOs = [];
        snap.forEach((d) => {
          allCOs.push(d.data());
        });
        if (mounted) setBudgets(allCOs);
      });
      onSnapshot(collection(firestore, `ENT-Jobs/${Record.id}/MonthEnd/${MonthToPullFrom}/Forecasting`), (snap) => {
        const allForecasts = [];
        snap.forEach((d) => {
          allForecasts.push(d.data());
        });
        if (mounted) setForecasting(allForecasts.filter((a) => !a.Subtotal));
      });
      onSnapshot(doc(firestore, `ENT-Jobs/${Record.id}/MonthEnd/${MonthToPullFrom}`), (snap) => {
        if (mounted) setContractValues(snap.data());
      });
    }
    return () => (mounted = false);
  }, [Record.id, MonthToPullFrom]);

  // useEffect(() => {
  //   let mounted = true;
  //   if (existsWithLength(Record.id)) {
  //     const baseJobNumber = Record.id.split('.').filter((part) => part.length === 6)[0];
  //     if (existsWithLength(baseJobNumber) && !firestore._terminated) {
  //       getDocs(query(collection(firestore, 'ENT-Jobs'), where('EcmsJobNumber', '==', baseJobNumber))).then((docs) => {
  //         if (!docs.empty) {
  //           const allSJs = [];
  //           docs.forEach((d) => {
  //             allSJs.push({ value: d.id, label: getLabel(d.id) });
  //           });
  //           if (mounted) setSubjobs(allSJs);
  //         }
  //       });
  //     }
  //   }
  //   return () => (mounted = false);
  // }, [Record.id]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StatusContext.Provider value={{ budgets, forecasting, subjobs, contractValues, attachments, loadAttachments }}>
      {children}
    </StatusContext.Provider>
  );
};

StatusProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.arrayOf(PropTypes.object)])
};
StatusProvider.defaultProps = {
  children: []
};
export default StatusProvider;
