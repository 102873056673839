import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const PageHeader = (props) => {
  const { title } = props;

  return (
    <>
      <Grid item xs={12} style={{ fontSize: '28px', fontWeight: 'bold', height: 'min-content', paddingTop: '24px', display: 'flex' }}>
        <div style={{ width: '34%' }} />
        <div style={{ textAlign: 'center', width: '33%' }}>{title}</div>
        <div style={{ width: '33%', display: 'flex', justifyContent: 'flex-end', paddingRight: '8px' }}>
          <Link to='/' style={{ textDecoration: 'none', color: 'white' }}>
            <Button variant='contained'>Back</Button>
          </Link>
        </div>
      </Grid>
      <Grid className='aldg-divider' style={{ width: '100%' }} />
    </>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string
};
PageHeader.defaultProps = {
  title: ''
};

export default PageHeader;
