import dayjs from 'dayjs';
import { uploadBytes, ref, getStorage } from 'firebase/storage';
import { toast } from 'react-toastify';

const uploadAttachment = (files, jctdscid, mounted, loadAttachments, setLoading, user, MonthEndDate, location = '') => {
  const storage = getStorage();

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const fileName = file?.name?.replace(/\s/g, '');
    const fileMetadata = {
      contentType: file?.type,
      customMetadata: { uploadedBy: user.email, uploadedDate: dayjs().format('YYYY-MM-DDTHH:mm.ss A'), monthEndDate: MonthEndDate, LinkTo: location }
    };

    const uploadRef = ref(storage, `${jctdscid}/${fileName}`);
    uploadBytes(uploadRef, file, fileMetadata)
      .then(() => {
        toast.success('Uploaded successfully!');
        loadAttachments(jctdscid);
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.error(err);
        toast.error('Something went wrong.');
      });
  }
};

export default uploadAttachment;
