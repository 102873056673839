import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

const CashFlowTiesOutKPI = (props) => {
  const { FirebaseTiesOut } = props;

  return (
    <div style={{ color: FirebaseTiesOut ? 'green' : '#b71c1c', textAlign: 'center' }}>
      <FontAwesomeIcon icon={FirebaseTiesOut ? faCheckCircle : faTimesCircle} size='3x' style={{ margin: '-20px 0px 16px 0px' }} />
      <Typography variant='h4'>{FirebaseTiesOut ? 'Cash Flow Ties Out.' : 'Cash Flow does NOT tie out!'}</Typography>
    </div>
  );
};

CashFlowTiesOutKPI.propTypes = {
  FirebaseTiesOut: PropTypes.bool
};
CashFlowTiesOutKPI.defaultProps = {
  FirebaseTiesOut: undefined
};

export default CashFlowTiesOutKPI;
