import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { collection, onSnapshot } from 'firebase/firestore';
import { Card, CardHeader, CardContent, Grid, Stack, Typography, FormControlLabel, Switch } from '@mui/material';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import existsWithLength from '../../../utils/existsWithLength';
import { firestore } from '../../../firebase';
import { UserContext } from '../../../providers/UserProvider';
import { _SecurityLevel } from '../../../_Recoil/atoms';
import { AdminUp, DivisionalUp, SuperAdmin, ViewerUp } from '../UserAssignment/checkSecurity';

const TableNotesPopOut = (props) => {
  const { jctdscid } = props;
  const [GridNotes, setGridNotes] = useState([]);
  const [StatusNotes, setStatusNotes] = useState([]);
  const [SortedNotes, setSortedNotes] = useState([]);
  const [Checked, setChecked] = useState(true);
  const user = useContext(UserContext);
  const Role = useRecoilValue(_SecurityLevel);

  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    if (existsWithLength(jctdscid.id))
      onSnapshot(collection(firestore, `ENT-Jobs/${jctdscid.id}/AldgDataGridNotes`), (snapshot) => {
        if (mounted) setGridNotes(snapshot.docs.map((d) => ({ ...d.data(), id: d.id })));
      });
    if (existsWithLength(jctdscid.id))
      onSnapshot(collection(firestore, `ENT-Jobs/${jctdscid.id}/Notes`), (snapshot) => {
        if (mounted) setStatusNotes(snapshot.docs.map((d) => ({ ...d.data(), id: d.id })));
      });
    return () => {
      mounted.current = false;
    };
  }, [jctdscid.id]);

  const canUserViewNote = (_, rowWriterNote) => {
    if (user.email === rowWriterNote.owner) return true;
    if (SuperAdmin(Role)) return true;
    if (AdminUp(Role)) return !SuperAdmin(rowWriterNote.Role);
    if (DivisionalUp(Role)) return !AdminUp(rowWriterNote.Role);
    if (ViewerUp(Role)) return !DivisionalUp(rowWriterNote.Role);
    return false;
  };
  useEffect(() => {
    const finalNotesSortedonDate = [];
    if (existsWithLength(GridNotes)) {
      GridNotes.forEach((note) => {
        finalNotesSortedonDate.push({
          ...note,
          MonthEndDate: note.meta?.MonthEndDate || '1900-01-01',
          type: 'grid',
          Location: note.dataGridName,
          createdTime:
            (note.CreatedDate || note.CreatedTime || note.createdTime || 'No Timestamp Available') !== 'No Timestamp Available'
              ? dayjs(note.CreatedDate || note.CreatedTime || note.createdTime).format('MM/DD/YY')
              : 'No Timestamp Available',
          Role: note.userRole,
          owner: note.userEmail
        });
      });
    }
    if (existsWithLength(StatusNotes)) {
      StatusNotes.forEach((note) => {
        finalNotesSortedonDate.push({
          ...note,
          MonthEndDate: note.MonthEnd,
          userName: note.NoteAuthor,
          content: note.Notes,
          createdTime:
            (note.CreatedDate || note.CreatedTime || note.createdTime || 'No Timestamp Available') !== 'No Timestamp Available'
              ? dayjs(note.CreatedDate || note.CreatedTime || note.createdTime).format('MM/DD/YY')
              : 'No Timestamp Available',
          Location: note.LinkTo?.split('/').at(-1),
          owner: note.NoteOwner,
          Role: note.Role,
          type: 'app'
        });
      });
    }

    const filteredNotes = finalNotesSortedonDate.filter((note) => canUserViewNote(undefined, note));
    const sorted = filteredNotes?.sort((a, b) => new Date(b.MonthEndDate) - new Date(a.MonthEndDate));
    setSortedNotes(sorted);
  }, [GridNotes, StatusNotes]);

  const cardTitle = (note) => (existsWithLength(note.meta?.Page) ? `[${note.meta.Page}] ${note.Location}` : `${note.Location}`);

  const cardSubtitle = (note) => {
    if (note.type === 'grid') {
      return (
        <Grid container>
          <Grid item xs={6}>
            <Typography>MonthEnd: {note.MonthEndDate}</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography>
              Made by {note.userName} on {note.createdTime}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='span'>
              Row: {note.row} {existsWithLength(note?.record?.PiccDescription) ? note?.record?.PiccDescription : ''}
            </Typography>{' '}
            &nbsp;
            <Typography variant='span'>Column: {note.col}</Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container>
        <Grid item xs={6}>
          <Typography>MonthEnd: {note.MonthEndDate}</Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Typography>
            Made by {note.userName} on {note.createdTime}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      {existsWithLength(jctdscid.id) ? (
        <div style={{ padding: '16px' }}>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant='h4'>
                {jctdscid.id} - {jctdscid.EcmsJobDescription}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant='span'>All Notes</Typography>
              <FormControlLabel
                sx={{ margin: 0 }}
                control={<Switch checked={Checked} onChange={() => setChecked(!Checked)} inputProps={{ 'aria-label': 'controlled' }} />}
              />
              <Typography variant='span'>My Notes</Typography>
            </Grid>
          </Grid>
          <Stack spacing={1}>
            {SortedNotes.filter((n) => (Checked ? n.owner === user.email : true)).map((note, idx, arr) => (
              <React.Fragment key={note.id}>
                {idx === 0 || note.MonthEndDate !== arr[idx - 1].MonthEndDate ? (
                  <Typography
                    variant='h6'
                    style={{
                      fontWeight: 'bold',
                      backgroundColor: '#bdc3dc',
                      padding: '3px',
                      borderRadius: '3px',
                      color: '#656565',
                      marginTop: '32px'
                    }}
                  >
                    Month End ({note.MonthEndDate}) Notes
                  </Typography>
                ) : null}
                {/* {ModifiedCardLayout(note)} */}
                <Card variant='outlined'>
                  <CardHeader
                    sx={{
                      paddingTop: '8px !important',
                      '.MuiCardHeader-title': {
                        fontSize: '1.25rem',
                        color: '#0000b4'
                      },
                      '.MuiCardHeader-subheader': {
                        fontStyle: 'italic',
                        fontSize: '.75rem',
                        color: 'rgba(0, 0, 0, 0.4)'
                      },
                      '.MuiCardHeader-subheader .MuiGrid-item': {
                        padding: '0'
                      },
                      '.MuiCardHeader-subheader .MuiTypography-body1': {
                        fontStyle: 'italic',
                        fontSize: '.75rem',
                        color: 'rgba(0, 0, 0, 0.4)'
                      }
                    }}
                    title={cardTitle(note)}
                    subheader={cardSubtitle(note)}
                  />
                  <CardContent
                    sx={{
                      paddingTop: '0px  !important',
                      paddingBottom: '8px !important'
                    }}
                  >
                    {existsWithLength(note.val) ? (
                      <div style={{ fontSize: '.9rem', color: 'rgba(0,0,180,.65)', paddingBottom: '8px' }}>Value: {note.val}</div>
                    ) : undefined}
                    {note.content}
                  </CardContent>
                </Card>
              </React.Fragment>
            ))}
          </Stack>
        </div>
      ) : (
        <div style={{ fontSize: '24px' }}>Select a job to see notes.</div>
      )}
    </>
  );
};

TableNotesPopOut.propTypes = {
  jctdscid: PropTypes.object
};
TableNotesPopOut.defaultProps = {
  jctdscid: {}
};

export default React.memo(TableNotesPopOut);
