/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Grid } from '@mui/material';
import { InputAutocomplete } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faClose, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import existsWithLength from '../../utils/existsWithLength';
import { UserContext } from '../../providers/UserProvider';
import { _CurrentJob } from '../../_Recoil/atoms';

const JobSwitcher = (props) => {
  const { title, match } = props;
  const [changeJob, setChangeJob] = useState(false);
  const user = useContext(UserContext);
  const history = useHistory();
  const CurrentJob = useRecoilValue(_CurrentJob);
  const page = match.path.split('/').pop();
  const disabledPages = ['Budgets', 'BudgetView', 'RevisedBudget'];

  return !changeJob ? (
    <Grid item xs={12} md={8} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
      {`${title} - ${match.params.id} : ${
        existsWithLength(CurrentJob.EcmsSubJobDescription) ? CurrentJob.EcmsSubJobDescription : CurrentJob.EcmsJobDescription
      }`}
      {/* {!disabledPages.includes(page) ? <ToolTipIconButton icon={faPencil} onClick={() => setChangeJob(true)} shape='circle' /> : null} */}
    </Grid>
  ) : (
    <Grid item xs={12} md={8} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
      <InputAutocomplete
        callType='static'
        options={user?.Jobs || []}
        loading={!existsWithLength(user?.Jobs)}
        value={[]}
        label='Select a Job'
        optionDisplay={(o) =>
          `${o.id} [Div: ${o.EcmsDivisionNumber}] - ${existsWithLength(o.CosentialJobName) ? o.CosentialJobName : o.EcmsJobDescription}`
        }
        onChange={(e, n, t, c) => {
          if (c?.length > 0) {
            setChangeJob(false);
            history.push(match.path.replace(':id', c[0].id));
          }
        }}
      />
      <ToolTipIconButton icon={faClose} onClick={() => setChangeJob(false)} color='error' shape='circle' />
    </Grid>
  );
};

JobSwitcher.propTypes = {
  match: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};
JobSwitcher.defaultProps = {};

export default JobSwitcher;
