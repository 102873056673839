import { isDM, isVP, isPM, isFinance } from '../Components/Jobs/UserAssignment/checkSecurity';
import existsWithLength from './existsWithLength';

const getOwner = (users) => {
  const DMarr = users.filter((u) => isDM(u.role));
  if (existsWithLength(DMarr)) return DMarr[0].nameDisplay.split('[')[0];

  const VParr = users.filter((u) => isVP(u.role));
  if (existsWithLength(VParr)) return VParr[0].nameDisplay.split('[')[0];

  return undefined;
};

const getName = (nameDisplay) => nameDisplay?.split('[')[0]?.trim() || '';

const getRole = (users, isRole) => {
  const arr = users.filter((u) => isRole(u.role) && existsWithLength(getName(u.nameDisplay)));
  return existsWithLength(arr) && existsWithLength(getName(arr[0]?.nameDisplay)) ? getName(arr[0]?.nameDisplay) : undefined;
};

const getPM = (users) => getRole(users, isPM);

const getDM = (users) => getRole(users, isDM);

const getVP = (users) => getRole(users, isVP);

const getFinance = (users) => getRole(users, isFinance);

export { getOwner, getPM, getDM, getVP, getFinance };
