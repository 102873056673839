import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import { useRecoilValue } from 'recoil';
import { formatAsCurrency } from '../../../../UIComponents/format';
import existsWithLength from '../../../../../utils/existsWithLength';
import NaNtoZero from '../../../../../utils/convertNaNToZero';
import { _MonthEndDate } from '../../../../../_Recoil/atoms';

const RetentionKPI = (props) => {
  const { jctdscid } = props;
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  const { data, loading, functionCall } = useFunctionCall('AccountingDetail', { jctdscid, monthEnd: MonthEndDate, process: 'D' });

  useEffect(() => {
    let mounted = true;
    if (jctdscid !== '' && mounted) functionCall({ jctdscid, monthEnd: MonthEndDate, process: 'D' });
    return () => {
      mounted = false;
    };
  }, [jctdscid]);

  return (
    <Typography variant='h5' style={{ width: '100%', textAlign: 'center' }}>
      {!loading && existsWithLength(data) ? formatAsCurrency(NaNtoZero((data[0].Retention, true, 0))) : 'Loading...'}
    </Typography>
  );
};

RetentionKPI.propTypes = {
  jctdscid: PropTypes.string.isRequired
};
RetentionKPI.defaultProps = {};

export default RetentionKPI;
