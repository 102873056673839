import React from 'react';
import PropTypes from 'prop-types';
import { Button, createTheme, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-regular-svg-icons';

const SaveChangesButton = (props) => {
  const { changed, handleSave } = props;

  const matches = useMediaQuery(createTheme().breakpoints.down('md'));

  return (
    changed && (
      <Button
        variant='contained'
        color='primary'
        style={{
          position: 'absolute',
          left: '60px',
          bottom: matches ? '60px' : '8px',
          height: '45px',
          borderRadius: '45px',
          zIndex: 11
        }}
        onClick={handleSave}
      >
        <FontAwesomeIcon icon={faSave} className='aldg-icon' style={{ marginRight: '10px' }} /> Save Changes
      </Button>
    )
  );
};

SaveChangesButton.propTypes = {
  changed: PropTypes.bool,
  handleSave: PropTypes.func
};
SaveChangesButton.defaultProps = {
  changed: false,
  handleSave: () => {}
};

export default SaveChangesButton;
