import React, { useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import MenuCardData from '../Components/Jobs/Dashboard/MenuCardData';
import ChangelogList from '../_GlobalComponents/Changelog/ChangelogList';
import Changelog from '../_GlobalComponents/Changelog/Changelog';
import UserAssignmentApp from './UserAssignmentApp';
import { UserContext } from '../providers/UserProvider';
import Test from './Test';
import SignIn from './SignIn';
import Unauthorized from './Unauthorized';
import NoMatch from './NoMatch';
import JobInformation from './JobInformation';
import JobsTable from './JobsTable';
import SegmentsTable from './SegmentsTable';
import SegmentsForm from './SegmentsForm';
import ErrorBoundary from '../_GlobalComponents/ErrorBoundary';
import CompanyDefaults from './CompanyDefaults';
import JobsToStatus from './JobsToStatus';
import { config } from '../firebase';
// import JobsComingToStatus from './JobsComingToStatus';

const Application = () => {
  const user = useContext(UserContext);

  const page = () => {
    if (!user) {
      return <SignIn />;
    }
    if (user && (user.admin || user.user)) {
      return (
        <>
          <Switch>
            <Route exact path='/' component={JobsTable} />
            <Route exact path='/Job' component={JobsTable} />
            <Route exact path='/JobsToStatus' component={JobsToStatus} />
            <Route exact path='/Job/:id' component={JobInformation} />
            {MenuCardData.map((mcd) => (
              <Route key={mcd.href} exact path={`/Job/:id/${mcd.href}`} component={JobInformation} />
            ))}
            <Route exact path='/Job/:id/ContractAbstract' component={JobInformation} />
            <Route exact path='/Job/:id/ContractAbstract/:aid/ContractAbstractView' component={JobInformation} />
            <Route exact path='/Job/:id/Budgets/RevisedBudget' component={JobInformation} />
            <Route exact path='/Job/:id/Budgets/:bid/BudgetView' component={JobInformation} />
            {user.admin ? <Route exact path='/Segments' component={SegmentsTable} /> : null}
            {user.admin ? <Route exact path='/Segments/:id' component={SegmentsForm} /> : null}
            {user.SuperAdmin ? <Route exact path='/Test' component={Test} /> : null}
            {user.admin ? <Route exact path='/CompanyDefaults' component={CompanyDefaults} /> : null}
            {user.admin ? <Route exact path='/UserAssignment' component={UserAssignmentApp} /> : null}
            {/* {user.admin ? <Route exact path='/Status' component={JobsComingToStatus} /> : null} */}
            {user.SuperAdmin ? <Route exact path='/Changelog' component={ChangelogList} /> : null}
            {user.SuperAdmin ? <Route exact path='/Changelog/:id' component={Changelog} /> : null}
            <Route exact>
              <NoMatch />
            </Route>
          </Switch>
        </>
      );
    }

    return <Unauthorized />;
  };

  useEffect(() => {
    if (user && (config.projectId.indexOf('-dev') > -1 || config.projectId.indexOf('-training') > -1)) {
      if (!toast.isActive('devToast'))
        toast.info(
          'You are using a development or training environment. Any data entered here will not be reported on for Status. Click here to dismiss the message.',
          { autoClose: false, toastId: 'devToast' }
        );
    }
  }, [user]);

  return (
    <ErrorBoundary componentName='The Whole Application'>
      <div className='appBody'>{page()}</div>
    </ErrorBoundary>
  );
};
export default Application;
