import { getDocs, query, collection, where } from 'firebase/firestore';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { firestore } from '../../../firebase';

const getSecurityLevel = (user, jctdscid) =>
  new Promise((resolve) => {
    if (!(user && existsWithLength(user.email))) resolve('NotSignedIn');
    else if (user.SuperAdmin) resolve('SuperAdmin');
    else if (user.admin) resolve('Admin');
    else {
      getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/UserAssignment`), where('Email', '==', user.email))).then((docs) => {
        if (docs.empty) {
          if (existsWithLength(user.globalRole) && user.globalRole !== 'none') resolve(user.globalRole);
          else resolve('Unauthorized');
        } else {
          docs.forEach((d) => {
            const fbRole = d.data().role;
            if (existsWithLength(fbRole)) resolve(fbRole);
            else if (existsWithLength(user.globalRole) && user.globalRole !== 'none') resolve(user.globalRole);
            else resolve('Unauthorized');
          });
        }
      });
    }
  });

export default getSecurityLevel;
