import dayjs from 'dayjs';

const monthEndOptions = (startDate = '2010-12-31') => {
  // 12/21 --> currentmonth/currentyear
  const epoch = startDate || '2022-12-31';
  const lastMonth = dayjs().add(1, 'M');
  const months = [];
  let month = dayjs(epoch).endOf('month');
  while (month.isBefore(lastMonth)) {
    months.push({
      value: month.format('YYYY-MM-DD'),
      label: month.format('YYYY-MM-DD')
    });
    month = dayjs(month).add(1, 'M').endOf('month');
  }
  if (months.length === 0) {
    months.push({
      value: dayjs().format('YYYY-MM-DD').endOf('month'),
      label: dayjs().format('YYYY-MM-DD').endOf('month')
    });
  }
  return months.reverse();
};

const getPrevMonth = (month) => dayjs(month).subtract(1, 'M').endOf('month').format('YYYY-MM-DD');
const getCurrentMEDate = (month) => dayjs(month).endOf('month').format('YYYY-MM-DD');

const getWorkingMonth = () => dayjs().subtract(1, 'M').endOf('month').format('YYYY-MM-DD');

export { monthEndOptions, getPrevMonth, getCurrentMEDate, getWorkingMonth };
