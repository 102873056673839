import React from 'react';
import PropTypes from 'prop-types';
import { InputDate } from '@aldridge/aldg-data-components';
import TextDisplay from './TextDisplay';
import { nonMuiThemes } from '../../theme';

const InputToggleDate = (props) => {
  const { edit, name, value, onChange, label } = props;

  return (
    <div style={edit ? nonMuiThemes.InputToggleOuterEdit : nonMuiThemes.InputToggleOuter}>
      {edit ? (
        <div style={nonMuiThemes.InputToggleTextContainerWrap}>
          <div style={nonMuiThemes.InputToggleTextWrap}>{label}:</div>
          <div style={{ width: '100%', backgroundColor: 'rgb(220, 232, 255)', borderRadius: '5px' }}>
            <InputDate name={name} value={value} onChange={onChange} style={{ width: '100%' }} />
          </div>
        </div>
      ) : (
        <TextDisplay editable label={` ${label}`} value={value} />
      )}
    </div>
  );
};

InputToggleDate.propTypes = {
  edit: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string
};
InputToggleDate.defaultProps = {
  edit: false,
  name: '',
  value: '',
  onChange: () => {},
  label: ''
};

export default InputToggleDate;
