import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Grid } from '@mui/material';
import { formatAsCurrency, formatWithCommas, formatAsPercent } from '../../UIComponents/format';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { SumLabor, SumNonLabor } from '../ProjectStatus/calculationHelpers';

const ChangeOrderSummaryTable = (props) => {
  const { record } = props;

  const extractedLaborTotals = SumLabor(record?.ChangeOrders, ['P', 'NP']);
  const extractedPLaborTotals = SumLabor(record?.ChangeOrders, ['P']);
  const extractedNPLaborTotals = SumLabor(record?.ChangeOrders, ['NP']);
  const extractedSubcontractorTotals = SumNonLabor(record?.ChangeOrders, 'Subcontractors');
  const extractedMaterialTotals = SumNonLabor(record?.ChangeOrders, 'Material');
  const extractedEquipmentTotals = SumNonLabor(record?.ChangeOrders, 'Equipment');
  const extractedOtherTotals = SumNonLabor(record?.ChangeOrders, 'Other');

  const extractedTotals =
    extractedLaborTotals + extractedSubcontractorTotals + extractedMaterialTotals + extractedEquipmentTotals + extractedOtherTotals;

  const totalHours = record?.data?.LaborChangeOrders.reduce((totalLaborHours, row) => totalLaborHours + NaNtoZero(row.LaborHours), 0);
  const productiveHours = record?.data?.LaborChangeOrders.filter((lco) => lco?.ProductiveCostCode === 'P').reduce(
    (totalLaborHours, row) => totalLaborHours + NaNtoZero(row.LaborHours),
    0
  );
  const nonProductiveHours = record?.data?.LaborChangeOrders.filter((lco) => lco?.ProductiveCostCode === 'NP').reduce(
    (totalLaborHours, row) => totalLaborHours + NaNtoZero(row.LaborHours),
    0
  );

  const getClassName = (indent, val) => {
    let cn = 'aldg-gridCell';
    if (indent) cn += 'Indent';
    if ((typeof val === 'undefined' || Math.round(val) < 0) && Number.isFinite(val)) cn += ' red';
    return cn;
  };

  return (
    <>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Grid container style={{ width: '50%', padding: '4px 8px' }}>
          <Grid item xs={8} className='aldg-gridCell'>
            Labor:
          </Grid>
          <Grid item xs={4} className={getClassName(false, extractedLaborTotals)}>
            {formatAsCurrency(extractedLaborTotals)}
          </Grid>
          <Grid item xs={8} className='aldg-gridCellIndent'>
            Productive:
          </Grid>
          <Grid item xs={4} className={getClassName(true, extractedPLaborTotals)}>
            {formatAsCurrency(extractedPLaborTotals)}
          </Grid>
          <Grid item xs={8} className='aldg-gridCellIndent'>
            Non-Productive:
          </Grid>
          <Grid item xs={4} className={getClassName(true, extractedNPLaborTotals)}>
            {formatAsCurrency(extractedNPLaborTotals)}
          </Grid>
          <Grid item xs={8} className='aldg-gridCell'>
            Material:
          </Grid>
          <Grid item xs={4} className={getClassName(false, extractedMaterialTotals)}>
            {formatAsCurrency(extractedMaterialTotals)}
          </Grid>
          <Grid item xs={8} className='aldg-gridCell'>
            Subcontracts:
          </Grid>
          <Grid item xs={4} className={getClassName(false, extractedSubcontractorTotals)}>
            {formatAsCurrency(extractedSubcontractorTotals)}
          </Grid>
          <Grid item xs={8} className='aldg-gridCell'>
            Equipment/ Fuel:
          </Grid>
          <Grid item xs={4} className={getClassName(false, extractedEquipmentTotals)}>
            {formatAsCurrency(extractedEquipmentTotals)}
          </Grid>
          <Grid item xs={8} className='aldg-gridCell'>
            Other Costs:
          </Grid>
          <Grid item xs={4} className={getClassName(false, extractedOtherTotals)}>
            {formatAsCurrency(extractedOtherTotals)}
          </Grid>
        </Grid>
        <Grid container style={{ width: '50%', padding: '4px 8px' }}>
          <Grid item xs={8} className='aldg-gridCell'>
            Hours:
          </Grid>
          <Grid item xs={4} className={getClassName(false, totalHours)}>
            {formatWithCommas(totalHours)}
          </Grid>
          <Grid item xs={8} className='aldg-gridCellIndent'>
            Productive:
          </Grid>
          <Grid item xs={4} className={getClassName(true, productiveHours)}>
            {formatWithCommas(productiveHours)}
          </Grid>
          <Grid item xs={8} className='aldg-gridCellIndent'>
            Non-Productive:
          </Grid>
          <Grid item xs={4} className={getClassName(true, nonProductiveHours)}>
            {formatWithCommas(nonProductiveHours)}
          </Grid>
        </Grid>
        <Grid container style={{ width: '50%', padding: '4px 8px' }}>
          <Grid item xs={8} className='aldg-gridCell'>
            Estimated Contract Value:
          </Grid>
          <Grid item xs={4} className={getClassName(false, record?.data?.EstimatedValue)}>
            {formatAsCurrency(record?.data?.EstimatedValue)}
          </Grid>
          <Grid item xs={8} className='aldg-gridCell'>
            Total Contract Value:
          </Grid>
          <Grid item xs={4} className={getClassName(false, record?.data?.ContractValue)}>
            {formatAsCurrency(NaNtoZero(record?.data?.ContractValue))}
          </Grid>
          <Grid item xs={8} className='aldg-gridCell'>
            Total Budget Cost:
          </Grid>
          <Grid item xs={4} className={getClassName(false, extractedTotals)}>
            {formatAsCurrency(extractedTotals)}
          </Grid>
          <Grid item xs={8} className='aldg-gridCell'>
            Total Budget Profit:
          </Grid>
          <Grid item xs={4} className={getClassName(false, NaNtoZero(record?.data?.ContractValue) - extractedTotals)}>
            {formatAsCurrency(NaNtoZero(record?.data?.ContractValue) - extractedTotals)}
          </Grid>
          <Grid item xs={8} className='aldg-gridCell'>
            Profit Margin:
          </Grid>
          <Grid
            item
            xs={4}
            className={getClassName(false, (NaNtoZero(record?.data?.ContractValue) - extractedTotals) / NaNtoZero(record?.data?.ContractValue))}
          >
            {formatAsPercent((NaNtoZero(record?.data?.ContractValue) - extractedTotals) / NaNtoZero(record?.data?.ContractValue))}
          </Grid>
          {record?.data?.ChangeOrderType === 'Construction Budget' && Math.round(extractedTotals) !== 0 && (
            <Alert severity='warning' style={{ width: '100%', marginTop: '24px', marginBottom: '-8px' }}>
              This budget is marked as a construction budget, but there is cost entered here! Construction budgets should never have costs associated
              with them.
            </Alert>
          )}
        </Grid>
      </div>
    </>
  );
};

ChangeOrderSummaryTable.propTypes = {
  record: PropTypes.object.isRequired
};

export default ChangeOrderSummaryTable;
