import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const AldgModal = (props) => {
  const { title, pieces, open, handleClose } = props;

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby={title} aria-describedby={title}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {pieces.map((piece) => {
          switch (piece.type) {
            case 'text':
              return <DialogContentText key={piece.key}>{piece.content}</DialogContentText>;
            case 'actions':
              return (
                <DialogActions key={piece.key}>
                  {piece?.buttons?.map((btn) => (
                    <Button variant='contained' onClick={btn.onClick} key={btn.text} color={btn.color}>
                      {btn.text}
                    </Button>
                  ))}
                </DialogActions>
              );
            default:
              return <React.Fragment key={piece.key}>{piece.content}</React.Fragment>;
          }
        })}
      </DialogContent>
    </Dialog>
  );
};

AldgModal.propTypes = {
  title: PropTypes.string,
  pieces: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  handleClose: PropTypes.func
};
AldgModal.defaultProps = {
  title: '',
  pieces: [],
  open: false,
  handleClose: () => {}
};

export default AldgModal;
