import React from 'react';
import { Grid, Typography, List, ListItemButton, ListItemText, ListItem, Divider, Popover } from '@mui/material';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { useRouteMatch } from 'react-router-dom';
import TooltipIconButton from '../../UIComponents/TooltipIconButton';
import { StatusContext } from '../../../providers/StatusProvider';

const DisplayAttachments = () => {
  const { attachments } = React.useContext(StatusContext);
  const match = useRouteMatch();
  const currPage = match.path.split('/')[3] || '';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const pageAttachments = [];
  const DEFAULT_OPTIONS = {
    toolbar: 'no',
    location: 'no',
    directories: 'no',
    status: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'yes',
    width: 500,
    height: 400,
    top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
    left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopOut = (file) => {
    if (file.NeedsDriveViewer)
      window.open(`https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(file.downloadURL)}`, '', DEFAULT_OPTIONS);
    else window.open(file.downloadURL, '', DEFAULT_OPTIONS);
  };

  attachments.forEach((file) => {
    if (file.LinkTo?.split('/')?.at(-1) === currPage) {
      pageAttachments.push(file);
    }
  });
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (currPage !== ':id' && currPage !== 'Attachments') {
    return (
      <>
        <TooltipIconButton aria-describedby={id} title='View Page Attachments' variant='contained' onClick={handleClick} icon={faPaperclip} />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Grid item xs={12}>
            <h2>{currPage} Attachments</h2>
            <Typography>Select an attachment to view it in a new window. </Typography>
            <Typography style={{ fontStyle: 'italic' }}> If a newly uploaded attachment is not showing up, please refresh the page. </Typography>
          </Grid>
          <Grid item xs={12}>
            <List>
              {pageAttachments.map((item) => (
                <React.Fragment key={item.name}>
                  <ListItem key={item.name}>
                    <img style={{ width: 25 }} src={item.url} srcSet={item} alt={item.title} loading='lazy' />
                    <ListItemButton
                      key={item.img}
                      onClick={() => {
                        handlePopOut(item);
                      }}
                    >
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Grid>
        </Popover>
      </>
    );
  }
  return null;
};

export default DisplayAttachments;
