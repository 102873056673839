import { deleteObject, ref, getStorage } from 'firebase/storage';
import { toast } from 'react-toastify';

const deleteFile = (file, jctdscid, loadFiles) => {
  const storage = getStorage();

  deleteObject(ref(storage, `${jctdscid}/${file.name}`))
    .then(() => {
      toast.success('Deleted Successfully!');
      loadFiles(jctdscid);
    })
    .catch((err) => {
      // eslint-disable-next-line
      console.error(err);
      toast.error('Something went wrong.');
    });
};

export default deleteFile;
