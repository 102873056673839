import React from 'react';
import PropTypes from 'prop-types';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { Card, CardHeader, Typography } from '@mui/material';
import dayjs from 'dayjs';
import markComplete from '../../../ActionItems/ActionItemsFuncs';
import existsWithLength from '../../../../../utils/existsWithLength';

const ActionItemCards = (props) => {
  const { actionItems, jctdscid, areComplete } = props;

  const pmName = (ai) => ai.PM || 'no one';
  const dueDate = (ai) => (existsWithLength(ai.DueDate) ? `Due ${dayjs(ai.DueDate).format('MMM D, YYYY')}.` : 'No due date.');

  return existsWithLength(actionItems) ? (
    actionItems.map((ai) => (
      <Card variant='outlined' key={ai.id} style={{ marginBottom: '8px' }}>
        <CardHeader
          title={ai.ActionItem}
          subheader={`Created by ${ai.Creator}, assigned to ${pmName(ai).trim()}. ${dueDate(ai)}`}
          action={
            !areComplete ? (
              <ToolTipIconButton
                shape='circle'
                tooltipText='Mark complete'
                color='success'
                icon={faCheck}
                onClick={() => markComplete(ai, jctdscid, ai.MonthEnd)}
              />
            ) : null
          }
        />
      </Card>
    ))
  ) : (
    <Typography variant='body1' style={{ color: areComplete ? 'black' : 'green' }}>
      {areComplete ? 'No completed action items.' : 'No incompletes!'}
    </Typography>
  );
};

ActionItemCards.propTypes = {
  actionItems: PropTypes.arrayOf(PropTypes.object),
  jctdscid: PropTypes.string.isRequired,
  areComplete: PropTypes.bool
};
ActionItemCards.defaultProps = {
  actionItems: [],
  areComplete: false
};

export default ActionItemCards;
