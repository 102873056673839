import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { signInWithGoogle } from '../firebase';
import GoogleButton from '../_GlobalComponents/GoogleButton';

const SignIn = () => {
  const [error] = useState(null);
  return (
    <Grid container justifyContent='center'>
      <h1 style={{ align: 'center' }}>Sign In To Use the Application</h1>
      <br />
      <br />
      <br />
      <Grid container justifyContent='center' alignItems='center' direction='row' style={{ padding: '35px' }}>
        {error !== null && <div>{error}</div>}
        <Grid container justifyContent='center' alignItems='center' direction='column'>
          <Grid item xs={12}>
            <GoogleButton onClick={signInWithGoogle} mode='dark' />
          </Grid>
          <Grid item style={{ paddingBottom: '30px' }} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SignIn;
