import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import existsWithLength from '../../../utils/existsWithLength';
import { firestore } from '../../../firebase';

const Subcontractors = (props) => {
  const { Transaction } = props;
  const [sub, setSub] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (existsWithLength(Transaction.id)) {
      onSnapshot(query(collection(firestore, `ENT-Jobs/${Transaction.id}/ENT-Subcontractors`)), (snap) => {
        const all = [];
        snap.forEach((s) => all.push(s.data()));
        if (mounted) setSub(all);
      });
    }
    return () => {
      mounted = false;
    };
  }, [Transaction.id]);

  const columns = [
    {
      field: 'ContractNumber',
      headerName: 'Contract Number',
      flex: 50
    },
    {
      field: 'SubcontractorName',
      headerName: 'Subcontractor Name',
      flex: 100
    },
    {
      field: 'AssignedCostCode',
      headerName: 'Contract Description',
      flex: 100
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography align='center' gutterBottom sx={{ fontStyle: 'italic' }}>
          These are only Subcontracts that exist in ECMS.
        </Typography>
      </Grid>
      <AldgDataGrid
        pageSizeOptions={[10, 20, 30]}
        autoHeight
        columnBuffer={12}
        columns={columns}
        disableSelectionOnClick
        getRowId={(r) => r.APTCNSID}
        hideFooter
        rows={sub}
        ToolbarProps={{
          showColumns: false,
          showDensity: false,
          showFilter: false
        }}
      />
    </Grid>
  );
};

Subcontractors.propTypes = {
  Transaction: PropTypes.objectOf(PropTypes.any).isRequired
};
Subcontractors.defaultProps = {};

export default Subcontractors;
