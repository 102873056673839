import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, BottomNavigationAction, BottomNavigation, Button, useMediaQuery, createTheme } from '@mui/material';
import { faBars, faCopyright } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SidebarData from './Menu/SideBarData';
import { UserContext } from '../providers/UserProvider';
import Sidebar from './Menu/Sidebar';
import './footer.css';
import { _AppVersion, _ShowChangelogModal } from '../_Recoil/atoms';

const ConfidentialityModal = () => {
  const [modal1, setModalOpen] = useModalState({
    open: false,
    title: 'CONFIDENTIALITY NOTICE:',
    message:
      'Unless otherwise indicated or obvious from the nature of the application, the information contained in this application is attorney privileged and confidential information intended for the use of authorized individuals or entities only. If the viewer of this content is not correctly authorized, you are hereby notified that any dissemination, distribution or copying of this content is strictly prohibited.',
    record: 'goodbyehello',
    buttons: [
      {
        text: 'Close',
        onClick: (record, { close }) => {
          close();
        }
      }
    ],
    onClose: ({ close }) => {
      close();
    }
  });
  return (
    <>
      <ModalBox modal={modal1} />
      <button type='button' className='button' onClick={() => setModalOpen(true)}>
        <i>Click here to review the confidentiality statement.</i>
      </button>
    </>
  );
};

const Footer = (props) => {
  const { copy } = props;
  const [footerOpen, setFooterOpen] = useState(false);
  const [opening, setOpening] = useState(0);
  const [closing, setClosing] = useState(0);
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const matches2 = useMediaQuery(theme.breakpoints.down('sm'));
  const SetShowChangelogModal = useSetRecoilState(_ShowChangelogModal);
  const user = useContext(UserContext);
  const [drawer, setDrawer] = React.useState(false);
  const [keyboardHide, setKeyboardHide] = React.useState(false);
  const pastHeight = window.innerHeight;
  const AppVersion = useRecoilValue(_AppVersion);

  const onClick = () => {
    setFooterOpen(!footerOpen);
    if (footerOpen && (!matches || user)) setOpening(1);
    else if (!matches || user) setClosing(1);
  };
  const findBottom = () => {
    if (!footerOpen && (!matches || user)) return '0px';
    return '55px';
  };

  const onLinkClick = () => {
    localStorage.removeItem('ChangelogShown');
    SetShowChangelogModal(true);
  };
  useEffect(() => {
    let mounted = true;
    const handleKeyboard = () => {
      if (matches2) {
        const currentHeight = window.innerHeight;
        if (pastHeight > currentHeight && mounted) {
          setKeyboardHide(true);
        } else if (mounted) {
          setKeyboardHide(false);
        }
      }
    };
    window.addEventListener('resize', handleKeyboard);
    return () => (mounted = false);
  });

  return (
    <>
      {user && !keyboardHide ? (
        <Button
          className='icon'
          disableRipple
          style={{
            position: 'fixed',
            bottom: findBottom(),
            right: matches2 ? '5px' : '25px',
            zIndex: '1',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px'
          }}
          onClick={onClick}
          onAnimationEnd={() => {
            setOpening(0);
            setClosing(0);
          }}
          opening={opening}
          closing={closing}
        >
          {footerOpen ? (
            <FontAwesomeIcon justifycontent='right' style={{ color: 'black' }} icon={faCopyright} />
          ) : (
            <FontAwesomeIcon style={{ color: 'black' }} justifycontent='right' icon={faCopyright} />
          )}
        </Button>
      ) : null}
      {matches && user && !keyboardHide && user ? (
        <BottomNavigation style={{ width: '100%', backgroundColor: 'rgb(52, 58, 64)', zIndex: 1 }} hidden={keyboardHide} showLabels>
          {SidebarData.map((item) =>
            item.dock === true ? (
              <BottomNavigationAction
                label={item.title}
                icon={<FontAwesomeIcon className='fa-fw' icon={item.icon} />}
                className='MuiBottomNavigationAction'
                component={Link}
                to={item.href}
                hidden={item.dock}
                key={item.href}
              />
            ) : null
          )}
          <BottomNavigationAction
            label='More'
            icon={<FontAwesomeIcon className='fa-fw' icon={faBars} />}
            className='MuiBottomNavigationAction'
            onClick={() => {
              setDrawer(true);
            }}
            key='More'
          />
        </BottomNavigation>
      ) : null}

      <Drawer anchor='right' onClose={() => setDrawer(false)} open={drawer} variant='temporary'>
        <Sidebar setApplicationDrawer={() => setDrawer(false)} />
      </Drawer>
      <Drawer variant='persistent' anchor='bottom' open={footerOpen && !keyboardHide} onClose={() => setFooterOpen(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            backgroundColor: '#343a40',
            padding: '3px',
            color: '#fff',
            zIndex: '2500'
          }}
        >
          <div>Copyright &copy; {copy}</div>
          <ConfidentialityModal />
          <div>
            v {AppVersion}
            <button type='button' className='button' onClick={onLinkClick}>
              <u> Update Notes</u>
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

Footer.propTypes = {
  copy: PropTypes.string
};
Footer.defaultProps = {
  copy: 'Aldridge, All Rights Reserved'
};

export default Footer;
