import React, { useEffect, useRef, useState } from 'react';
import { getDocs, query, collection } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Typography } from '@mui/material';
import { getProjectedTotal, getRBudget, getTotalApprovedContract, getTotalProjectedContract } from '../../../ProjectStatus/getValues';
import { formatAsCurrency, formatAsPercent } from '../../../../UIComponents/format';
import { SumTotalCost } from '../../../ChangeOrder/SumCosts';
import { mapDown } from '../../../ProjectStatus/calculationHelpers';
import { _MonthEndDate } from '../../../../../_Recoil/atoms';
import getMostRecentMonth from '../../../../../utils/getMostRecentMonth';
import { firestore } from '../../../../../firebase';
import CVRow from './CVRow';

const ContractValues = (props) => {
  const { pending, jctdscid, cmForecast, cmCOs, MonthEndContractNumbers } = props;

  const cm = useRecoilValue(_MonthEndDate);

  const [pmCOs, setPmCOs] = useState([]);
  const [pmForecast, setPmForecast] = useState([]);
  const [loading, setLoading] = useState(false);

  const { FinancialApprovedContract, TotalProjectedContract } = MonthEndContractNumbers;

  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const done = { one: false, two: false };
      const pm = await getMostRecentMonth(jctdscid, cm);
      const pmcoq = query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${pm}/ChangeOrders`));
      getDocs(pmcoq).then((docs) => {
        const allCOs = [];
        docs.forEach((d) => {
          allCOs.push(d.data());
        });
        if (mounted.current) setPmCOs(allCOs);
        done.one = true;
        if (done.two && mounted.current) setLoading(false);
      });
      const pmfq = query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${pm}/Forecasting`));
      getDocs(pmfq).then((docs) => {
        const allForecasts = [];
        docs.forEach((d) => {
          allForecasts.push(d.data());
        });
        if (mounted.current) setPmForecast(allForecasts);
        done.two = true;
        if (done.one && mounted.current) setLoading(false);
      });
    };
    getData();
  }, []);

  const valueCalc = (cos, forecasts, prev) => {
    const f = forecasts.filter((a) => a.Subtotal !== true);
    const rb = getRBudget(cos);
    const TotalProjectedTotal = getProjectedTotal(f);
    const TotalRevisedCosts = SumTotalCost(mapDown(rb));

    const useTotalProjectedContract = prev ? getTotalProjectedContract(cos) : TotalProjectedContract;
    const useTotalApprovedContract = prev ? getTotalApprovedContract(cos) : FinancialApprovedContract;
    // const usePendingNegative = prev ? getPendingNegative(cos) : PendingNegative;

    const costAtComplete = TotalProjectedTotal;
    const contract = pending ? useTotalProjectedContract : useTotalApprovedContract;
    const profit = contract - costAtComplete;
    const marginPercent = profit / contract;
    const variance = profit - (useTotalApprovedContract - TotalRevisedCosts);

    return {
      costAtComplete,
      profit,
      contract,
      marginPercent,
      variance
    };
  };

  // CURRENT MONTH
  const cmObj = valueCalc(cmCOs, cmForecast);

  // PRIOR MONTH
  const pmObj = valueCalc(pmCOs, pmForecast, true);

  // CHANGES
  const contractChange = cmObj.contract - pmObj.contract;
  const projChange = cmObj.costAtComplete - pmObj.costAtComplete;
  const profitChange = cmObj.profit - pmObj.profit;
  const marjChange = cmObj.marginPercent - pmObj.marginPercent;

  console.log(pmForecast);
  return (
    <div>
      {loading ? (
        <Typography variant='h6'>Calculating Values...</Typography>
      ) : (
        <>
          <CVRow label='' cVal='This Month' pVal='Last Month' change='Variance' isHeader />
          <CVRow
            label='Contract Value'
            cVal={cmObj.contract}
            pVal={pmObj.contract}
            change={contractChange}
            format={(val) => formatAsCurrency(val, true, 0)}
            skipGreen
          />
          <CVRow
            label='Total Cost At Completion'
            cVal={cmObj.costAtComplete}
            pVal={pmObj.costAtComplete}
            change={projChange}
            format={(val) => formatAsCurrency(val, true, 0)}
            skipGreen
          />
          <CVRow
            label='Margin'
            border
            cVal={cmObj.profit}
            pVal={pmObj.profit}
            change={profitChange}
            format={(val) => formatAsCurrency(val, true, 0)}
            skipGreen
          />
          <CVRow
            label='Margin %'
            cVal={cmObj.marginPercent * 100}
            pVal={pmObj.marginPercent * 100}
            change={marjChange * 100}
            format={(val) => formatAsPercent(val / 100, true, 0)}
            skipGreen
          />
        </>
      )}
    </div>
  );
};

ContractValues.propTypes = {
  pending: PropTypes.bool,
  jctdscid: PropTypes.string.isRequired,
  cmForecast: PropTypes.arrayOf(PropTypes.object),
  cmCOs: PropTypes.arrayOf(PropTypes.object),
  MonthEndContractNumbers: PropTypes.object
};
ContractValues.defaultProps = {
  pending: false,
  cmForecast: [],
  cmCOs: [],
  MonthEndContractNumbers: {}
};

export default ContractValues;
