/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputToggle from '../../UIComponents/InputToggle';
import TextDisplay from '../../UIComponents/TextDisplay';

const JobInfo = (props) => {
  const { Transaction, onChange, editing, allowWrap } = props;

  const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };
  return (
    <Grid container>
      <TextDisplay label='Cosential Job Name' value={Transaction.CosentialJobName} />
      <TextDisplay label='ECMS Job Number' value={Transaction.EcmsJobNumber || ''} />
      <TextDisplay label='Status' value={Transaction.EcmsStatus || ''} />
      <TextDisplay label='Sub Job Name' value={Transaction.EcmsSubJobDescription || ''} />
      <TextDisplay label='Sub Job Number' value={(Transaction.EcmsSubJobNumber === '   ' ? '' : Transaction.EcmsSubJobNumber) || ''} />
      <TextDisplay label='Description of Work' value={decodeHtml(Transaction.CosentialDescriptionOfWork.replace(/(<([^>]+)>)/gi, ' '))} />
      <InputToggle
        allowWrap={allowWrap}
        label='Primary Documentation Application'
        name='PrimaryDocumentation'
        value={Transaction.PrimaryDocumentation || ''}
        onChange={onChange}
        edit={editing}
      />
    </Grid>
  );
};

JobInfo.propTypes = {
  Transaction: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  allowWrap: PropTypes.bool
};
JobInfo.defaultProps = {
  editing: false,
  allowWrap: false
};

export default JobInfo;
