import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { nonMuiThemes } from '../../../theme';
import History from './History';
import MonthOverMonth from './MonthOverMonth';

const MoMandHistory = (props) => {
  const { jctdscid, wPending, ogBudget, Role } = props;

  return (
    <Grid container className='aldg-bordered' style={{ backgroundColor: wPending ? '#ededed' : 'white' }}>
      <MonthOverMonth jctdscid={jctdscid} originalBudget={ogBudget} wPending={wPending} Role={Role} />
      <div style={nonMuiThemes.spacer} />
      <div style={nonMuiThemes.divider} />
      <History jctdscid={jctdscid} originalBudget={ogBudget} wPending={wPending} Role={Role} />
    </Grid>
  );
};

MoMandHistory.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  wPending: PropTypes.bool,
  ogBudget: PropTypes.arrayOf(PropTypes.object),
  Role: PropTypes.string
};
MoMandHistory.defaultProps = {
  wPending: false,
  ogBudget: [],
  Role: undefined
};

export default MoMandHistory;
