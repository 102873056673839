/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { _InitedOffline } from '../_Recoil/atoms';

const CloudSync = (props) => {
  const { children } = props;
  const setInitedOffline = useSetRecoilState(_InitedOffline);

  return (
    <>
      <div
        style={{
          display: 'flex',
          padding: '5px',
          justifyContent: 'center',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          alignItems: 'center',
          margin: '0 1px',
          cursor: 'pointer'
        }}
        title='Download Data'
      >
        <FontAwesomeIcon onClick={() => setInitedOffline((prev) => ({ ...prev, value: false }))} icon={faSync} />
      </div>
      {children.length > 0 ? (
        <span style={{ cursor: 'pointer' }} onClick={() => setInitedOffline((prev) => ({ ...prev, value: false }))} role='button'>
          {children}
        </span>
      ) : null}
    </>
  );
};

CloudSync.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string])
};
CloudSync.defaultProps = {
  children: []
};

export default CloudSync;
