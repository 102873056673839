import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button, Card, CardContent, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { toast } from 'react-toastify';
import { collection, setDoc, onSnapshot, query, doc } from 'firebase/firestore';
import { InputText, InputRadio } from '@aldridge/aldg-data-components';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';

const SegmentsForm = (props) => {
  const { match, history } = props;
  const user = useContext(UserContext);
  const [Transaction, setTransaction] = useState({
    Company: '',
    CostType: '',
    DateUpdated: '',
    Division: '',
    ProductiveCostCode: '',
    SegmentDescription: '',
    SegmentPosition: '',
    SegmentValue: '',
    jctccsid: '',
    Status: ''
  });

  useEffect(() => {
    let mounted = true;
    const RECORDID = match.params.id;
    try {
      onSnapshot(
        query(doc(collection(firestore, 'ENT-Segments'), RECORDID)),
        (d) => {
          if (d.exists()) {
            const record = d.data();
            if (mounted) setTransaction(record);
          }
        },
        (err) => {
          toast.error(err.message);
        }
      );
    } catch (err) {
      toast.error(err.message);
    }
    return () => {
      mounted = false;
    };
  }, []);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const nameCanSplit = (n) => {
        if (n.split('.').length > 1) return [true, n.split('.')];
        return [false, n];
      };
      const [canSplit, id] = typeof name !== 'undefined' ? nameCanSplit(name) : nameCanSplit(event.target.name);
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...Transaction };
      if (canSplit) {
        changedRecord[id[0]][id[1]] = value;
      } else {
        changedRecord[id] = value;
      }

      if (typeof displayFromTypeahead !== 'undefined') {
        if (canSplit) {
          changedRecord[id[0]][`${id[1]}Display`] = displayFromTypeahead;
        } else {
          changedRecord[`${id}Display`] = displayFromTypeahead;
        }
      }

      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();

      setTransaction(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const handleSave = () => {
    try {
      const docRef = doc(collection(firestore, 'ENT-Segments'), Transaction.jctccsid);
      setDoc(docRef, Transaction, { merge: true })
        .then(() => {})
        .catch((err) => {
          toast.error(err);
        });
    } catch (err) {
      toast.error(err.toString());
    }
    toast.success('Record saved!');
  };
  const goBack = () => {
    history.push('/Segments');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Grid container>
        <Grid item xs={12}>
          <Paper variant='outlined' className='aldg-formButtons'>
            <Button variant='contained' color='primary' className='aldg-buttonIconWrapper aldg-iconContainer successContainer' onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} className='aldg-icon' />
            </Button>
            <Button onClick={goBack} variant='contained' className='aldg-iconButtonWrapper aldg-iconContainer errorContainer'>
              <FontAwesomeIcon icon={faTimes} className='aldg-icon' />
            </Button>
          </Paper>
          <Grid sx={{ padding: '3px 0' }} />
          <Card style={{ width: '100%' }} className='aldg-formContainer'>
            <CardContent>
              <Grid container className='aldg-rootFormBox'>
                <Grid item xs={4}>
                  <InputText label='Segment Value' disabled name='SegmentValue' onChange={onChange} value={Transaction.SegmentValue || ''} />
                </Grid>
                <Grid item xs={4}>
                  <InputText
                    label='Segment Description'
                    disabled
                    name='SegmentDescription'
                    onChange={onChange}
                    value={Transaction.SegmentDescription || ''}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputText label='Cost Type' disabled name='CostType' onChange={onChange} value={Transaction.CostType || ''} />
                </Grid>
                <Grid item xs={12}>
                  <InputRadio
                    radios={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' }
                    ]}
                    FormControlLabelProps={{
                      label: 'Can User Select This Cost Code?'
                    }}
                    RadioGroupProps={{
                      name: 'Status',
                      onChange,
                      value: Transaction.Status || ''
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

SegmentsForm.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
SegmentsForm.defaultProps = {};

export default SegmentsForm;
