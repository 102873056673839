import dayjs from 'dayjs';
import NaNtoZero from '../../utils/convertNaNToZero';

const formatAsCurrency = (amt, parentheses = false, digits = 0) => {
  try {
    if (amt === null) return '';
    if (amt === '-') return '-';
    const parsedAmt = NaNtoZero(amt);
    if (parentheses && parsedAmt < 0)
      return `($${parsedAmt.toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits, signDisplay: 'never' })})`;
    return `$${parsedAmt.toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits })}`;
  } catch (err) {
    console.error('formatAsCurrency error', err, amt);
    return amt;
  }
};
const roundedFormatAsCurrency = (amt, parentheses = false, digits = 0) => {
  try {
    if (amt === null) return '';
    if (amt === '-') return '-';
    const parsedAmt = NaNtoZero(amt);
    if (parentheses && parsedAmt < 0)
      return `($${parsedAmt.toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits, signDisplay: 'never' })})`;
    return `$${parsedAmt.toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits, signDisplay: 'never' })}`;
  } catch (err) {
    console.error('formatAsCurrency error', err, amt);
    return amt;
  }
};
const formatWithCommas = (num, parentheses = false, digits = 0) => {
  if (num === '-') return '-';
  const parsedNum = NaNtoZero(num);
  if (parentheses && parsedNum < 0) return `(${parsedNum.toLocaleString('en-US', { maximumFractionDigits: digits, signDisplay: 'never' })})`;
  return parsedNum.toLocaleString('en-US', { maximumFractionDigits: digits });
};

const formatAsPercent = (num, parentheses = false, digits = 2) => {
  const parsedNum = NaNtoZero(num);
  if (parentheses && num < 0) return `(${(parsedNum * 100).toLocaleString('en-US', { maximumFractionDigits: digits, signDisplay: 'never' })})%`;
  return `${(parsedNum * 100).toLocaleString('en-US', { maximumFractionDigits: digits })}%`;
};

const formatShortDate = (date) => {
  if (typeof date === 'undefined') return '';
  const fDate = date.length === 10 ? `${date}T00:00` : date;
  const dateCheck = new Date(fDate);
  if (dateCheck instanceof Date && !Number.isNaN(Date.parse(fDate))) {
    return dayjs(dateCheck).format('MM/DD/YY');
  }
  return '';
};

const getColor = (num, skipGreen = false, skipRed = false) => {
  if (Math.round(num) > 0) return skipGreen ? '' : 'green';
  if (Math.round(num) < 0) return skipRed ? '' : 'red';
  return undefined;
};

export { formatAsCurrency, formatWithCommas, roundedFormatAsCurrency, formatAsPercent, formatShortDate, getColor };
