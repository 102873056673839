import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@mui/material';

const PromptUpload = (props) => {
  const { upload, setAttachedPrompted } = props;

  return (
    <>
      <Typography>Save this upload to Attachments?</Typography>
      <Button variant='contained' color='success' style={{ marginRight: '8px' }} onClick={() => upload(true)}>
        Yes, Save
      </Button>
      <Button variant='contained' onClick={() => upload(false)} style={{ marginRight: '8px', backgroundColor: 'dimgray' }}>
        No, Just Upload
      </Button>
      <Button variant='contained' color='error' onClick={() => setAttachedPrompted(false)}>
        Cancel
      </Button>
    </>
  );
};

PromptUpload.propTypes = {
  upload: PropTypes.func,
  setAttachedPrompted: PropTypes.func
};
PromptUpload.defaultProps = {
  upload: () => {},
  setAttachedPrompted: () => {}
};

export default PromptUpload;
