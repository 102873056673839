/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { collection, getDocs } from 'firebase/firestore';
import existsWithLength from '../../../../../utils/existsWithLength';
import { formatAsCurrency, getColor } from '../../../../UIComponents/format';
import NaNtoZero from '../../../../../utils/convertNaNToZero';
import { _MonthEndDate } from '../../../../../_Recoil/atoms';
import { StatusContext } from '../../../../../providers/StatusProvider';
import { firestore } from '../../../../../firebase';

const UnderbillingKPI = (props) => {
  const { jctdscid, forecast } = props;
  const [underbilling, setUnderbilling] = useState(0);
  const [underbillingScorecard, setUnderbillingScorecard] = useState(false);
  const MonthEndDate = useRecoilValue(_MonthEndDate);

  const { contractValues } = useContext(StatusContext);
  const { FinancialApprovedContract } = contractValues || {};

  const {
    data: sqlUnderbillingInfo,
    functionCall: getUnderbillingInfoFromSQL,
    loading: underbillingLoading
  } = useFunctionCall('getUnderbillingInformation');

  useEffect(() => {
    getUnderbillingInfoFromSQL({
      JobNumber: jctdscid.substring(4),
      monthEndDate: MonthEndDate.split('-').join('')
    });
  }, []);
  useEffect(() => {
    getDocs(collection(firestore, 'ENT-Jobs', jctdscid, 'MonthEnd', MonthEndDate, 'OverUnderBilling')).then((d) => {
      if (!d.empty) {
        setUnderbillingScorecard(d.docs[0].data());
      }
    });
  }, []);

  useEffect(() => {
    let mounted = true;
    if (existsWithLength(sqlUnderbillingInfo && mounted)) {
      const data = sqlUnderbillingInfo[0];
      if (existsWithLength(data) && mounted) {
        const costToComplete = forecast.reduce(
          (ctc, f) => ctc + (f.CostType === 'L' ? NaNtoZero(f.CostHrToComplete) * NaNtoZero(f.HoursToComplete) : NaNtoZero(f.CostToComplete)),
          0
        );
        const pTotalCaC = data.Cost_JTD + costToComplete;
        const pComplete = data.Cost_JTD / pTotalCaC;
        const eRev = pComplete * NaNtoZero(FinancialApprovedContract);
        if (mounted) setUnderbilling(data.Billings_JTD - eRev);
      }
    }
    return () => {
      mounted = false;
    };
  }, [sqlUnderbillingInfo]);

  return (
    <div style={{ color: getColor(underbilling) }}>
      <Typography variant='h4' style={{ width: '100%', textAlign: 'center' }}>
        {!underbillingLoading ? formatAsCurrency(underbilling, true, 0) : 'Loading...'}
      </Typography>
      <Typography variant='caption' style={{ width: '100%', textAlign: 'center', display: 'block', color: 'black', fontStyle: 'italic' }}>{`Amount ${
        getColor(underbilling) === 'green' ? 'overbilled' : 'underbilled'
      }`}</Typography>
      {/* {getColor(underbilling) === 'red' && (underbillingScorecard?.data?.length || 0) === 0 ? (
        <Typography variant='h6' style={{ width: '100%', textAlign: 'center', display: 'block', color: 'black', fontStyle: 'italic' }}>
          An Underbilling Scorecard has not been completed.
        </Typography>
      ) : null} */}
      {!existsWithLength(sqlUnderbillingInfo) && (
        <Typography variant='caption' style={{ width: '100%', textAlign: 'center', display: 'block', color: 'black', fontStyle: 'italic' }}>
          WIP data has not been finalized yet.
        </Typography>
      )}
    </div>
  );
};

UnderbillingKPI.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  forecast: PropTypes.arrayOf(PropTypes.object)
};
UnderbillingKPI.defaultProps = {
  forecast: []
};

export default UnderbillingKPI;
