const fileUploadClick = (
  name,
  listenerCallback,
  filesCallback = () => {},
  acceptStr = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
) => {
  const fileUpBtn = document.createElement('input');
  fileUpBtn.type = 'file';
  fileUpBtn.name = name;
  fileUpBtn.id = name;
  fileUpBtn.style = 'display:none;';
  fileUpBtn.accept = acceptStr;
  fileUpBtn.addEventListener('change', (e) => {
    const { files } = e.target;
    filesCallback(files);
    e.target.files[0].arrayBuffer().then((filedata) => {
      listenerCallback(filedata);
    });
  });
  document.body.appendChild(fileUpBtn);
  fileUpBtn.click();
};

export default fileUploadClick;
