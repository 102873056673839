import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import TextDisplay from '../../UIComponents/TextDisplay';

const GeneralInformation = (props) => {
  const { Transaction, monthEndDate } = props;

  return (
    <Grid container className='aldg-bordered'>
      <Grid item xs={12} className='aldg-headerTitle'>
        General Information
      </Grid>
      <Grid item xs={12} md={6}>
        <TextDisplay label='Job #' value={Transaction.EcmsJobNumber} />
        <TextDisplay label='Project' value={Transaction.EcmsJobDescription} />
        <TextDisplay label='Project Manager' value={Transaction.ProjectManager} />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextDisplay label='Sub #' value={Transaction.EcmsSubJobNumber} />
        <div style={{ opacity: 0 }}>
          <TextDisplay label='' value='' />
        </div>
        <TextDisplay label='Customer' value={Transaction.EcmsCustomerName} />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextDisplay label='Report Date' value={dayjs().format('YYYY-MM-DD')} />
        <div style={{ opacity: 0 }}>
          <TextDisplay label='' value='' />
        </div>
        <TextDisplay label='Costs Through' value={monthEndDate} />
      </Grid>
    </Grid>
  );
};

GeneralInformation.propTypes = {
  Transaction: PropTypes.object.isRequired,
  monthEndDate: PropTypes.string
};
GeneralInformation.defaultProps = {
  monthEndDate: '2010-12-31'
};

export default GeneralInformation;
