import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

const getCostDiffValues = async () =>
  new Promise((resolve) => {
    getDoc(doc(firestore, `CompanyDefaults/JobsToStatus`)).then((d) => {
      resolve(d.data());
    });
  });

export default getCostDiffValues;
