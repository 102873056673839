import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { getColor } from '../../../../UIComponents/format';

const CVRow = (props) => {
  const { label, cVal, pVal, change, isHeader, format, border, skipGreen, skipRed } = props;

  return (
    <div
      style={{
        display: 'flex',
        borderTop: border && '2px solid black'
      }}
    >
      <div style={{ width: '25%' }}>
        <Typography variant='body2' style={{ width: '100%', padding: '4px 8px' }}>
          {label}
        </Typography>
      </div>
      <div style={{ width: '25%', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Typography variant={isHeader ? 'h6' : 'body1'} style={{ width: '100%' }}>
          {format(pVal)}
        </Typography>
      </div>
      <div style={{ width: '25%', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Typography variant={isHeader ? 'h6' : 'body1'} style={{ width: '100%' }}>
          {format(cVal)}
        </Typography>
      </div>
      <div
        style={{
          width: '25%',
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          color: getColor(change, skipGreen, skipRed)
        }}
      >
        <Typography variant={isHeader ? 'h6' : 'body1'} style={{ width: '100%' }}>
          {format(change)}
        </Typography>
      </div>
    </div>
  );
};

CVRow.propTypes = {
  label: PropTypes.string,
  cVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  change: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isHeader: PropTypes.bool,
  format: PropTypes.func,
  border: PropTypes.bool,
  skipGreen: PropTypes.bool,
  skipRed: PropTypes.bool
};
CVRow.defaultProps = {
  label: '',
  cVal: 0,
  pVal: 0,
  change: 0,
  isHeader: false,
  format: (val) => val,
  border: false,
  skipRed: false,
  skipGreen: false
};

export default CVRow;
