import React from 'react';
import PropTypes from 'prop-types';
import { InputSelect } from '@aldridge/aldg-data-components';
import { useMediaQuery } from '@mui/material';
import TextDisplay from './TextDisplay';
import { nonMuiThemes, AldgTheme } from '../../theme';

const InputToggleSelect = (props) => {
  const { edit, name, value, onChange, label, allowWrap, options } = props;
  const matches = useMediaQuery(AldgTheme.breakpoints.down('md'));

  return (
    <div style={edit ? nonMuiThemes.InputToggleOuterEdit : nonMuiThemes.InputToggleOuter}>
      {edit ? (
        <div style={allowWrap || matches ? nonMuiThemes.InputToggleTextContainerWrap : nonMuiThemes.InputToggleTextContainer}>
          <div style={allowWrap || matches ? nonMuiThemes.InputToggleTextWrap : nonMuiThemes.InputToggleText}>{label}:</div>
          {/* <div
            style={{
              whiteSpace: !(allowWrap || matches) && 'nowrap',
              paddingRight: '8px',
              fontWeight: 'bold',
              marginBottom: '-16px',
              width: '100%',
              backgroundColor: !nobg && '#dedede'
            }}
          >
            {label}:
          </div> */}
          <div style={nonMuiThemes.InputToggleValue}>
            <InputSelect name={name} value={value} onChange={onChange} options={options} />
          </div>
        </div>
      ) : (
        <TextDisplay editable label={label} value={value} />
      )}
    </div>
  );
};

InputToggleSelect.propTypes = {
  edit: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  allowWrap: PropTypes.bool,
  options: PropTypes.array
};
InputToggleSelect.defaultProps = {
  edit: false,
  name: '',
  value: '',
  onChange: () => {},
  label: '',
  allowWrap: false,
  options: []
};

export default InputToggleSelect;
