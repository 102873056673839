import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, createTheme } from '@mui/material';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { useRecoilValue } from 'recoil';
import { formatAsCurrency, formatAsPercent, formatWithCommas } from '../../UIComponents/format';
import { _CurrentJob, _MonthEndDate } from '../../../_Recoil/atoms';
import NaNtoZero from '../../../utils/convertNaNToZero';
import aggregateForecasts from './aggregateForecasts';
import { SumLabor, SumNonLabor, SumLaborHours, mapDown } from './calculationHelpers';
import { SumTotalCost } from '../ChangeOrder/SumCosts';
import { firebaseConfig } from '../../../firebase';
import { AdminUp, DivisionalUp, EditorUp, SuperAdmin, ViewerUp } from '../UserAssignment/checkSecurity';
import { UserContext } from '../../../providers/UserProvider';

const MonthOverMonth = (props) => {
  const { jctdscid, originalBudget, wPending, Role } = props;
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  const Record = useRecoilValue(_CurrentJob);
  const user = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const matches = useMediaQuery(createTheme().breakpoints.down('xl'));

  const transformOG = () => {
    if (existsWithLength(originalBudget)) {
      const total = SumTotalCost(mapDown(originalBudget));
      return {
        L: SumLabor(originalBudget),
        M: SumNonLabor(originalBudget, 'Material'),
        S: SumNonLabor(originalBudget, 'Subcontractors'),
        E: SumNonLabor(originalBudget, 'Equipment'),
        O: SumNonLabor(originalBudget, 'Other'),
        Hours: SumLaborHours(originalBudget),
        TotalCosts: total,
        Contract: originalBudget[0].data.ContractValue,
        GrossProfit: originalBudget[0].data.ContractValue - total,
        ProfitMargin: (originalBudget[0].data.ContractValue - total) / originalBudget[0].data.ContractValue,
        Memorialized: 'true'
      };
    }
    return '';
  };

  useEffect(() => {
    let mounted = true;
    const ogRow = transformOG();
    if (mounted) setLoading(true);
    if (mounted)
      aggregateForecasts(jctdscid, MonthEndDate, Record.EcmsJobSetUpDate, wPending).then((aggregation) => {
        const [currentMonth, prevMonth] = aggregation.concat(ogRow);
        const MoM = {
          id: 'Month Over Month',
          Title: 'Month Over Month',
          Contract: NaNtoZero(currentMonth?.Contract) - NaNtoZero(prevMonth?.Contract),
          Hours: NaNtoZero(currentMonth?.Hours) - NaNtoZero(prevMonth?.Hours),
          L: NaNtoZero(currentMonth?.L) - NaNtoZero(prevMonth?.L),
          M: NaNtoZero(currentMonth?.M) - NaNtoZero(prevMonth?.M),
          S: NaNtoZero(currentMonth?.S) - NaNtoZero(prevMonth?.S),
          E: NaNtoZero(currentMonth?.E) - NaNtoZero(prevMonth?.E),
          O: NaNtoZero(currentMonth?.O) - NaNtoZero(prevMonth?.O),
          TotalCosts: NaNtoZero(currentMonth?.TotalCosts) - NaNtoZero(prevMonth?.TotalCosts),
          GrossProfit: NaNtoZero(currentMonth?.GrossProfit) - NaNtoZero(prevMonth?.GrossProfit),
          ProfitMargin: NaNtoZero(currentMonth?.ProfitMargin) - NaNtoZero(prevMonth?.ProfitMargin)
        };
        if (mounted) setRows([MoM]);
        if (mounted) setLoading(false);
      });
    if (mounted) setRows([]);
    if (mounted) setLoading(false);
    return () => (mounted = false);
  }, [jctdscid, originalBudget]);

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const columns = [
    {
      field: 'Date',
      headerName: '',
      headerAlign: 'center',
      align: 'left',
      valueGetter: () => '',
      width: getWidth(100),
      flex: getFlex(100)
    },
    {
      field: 'Contract',
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => formatAsCurrency(params.value, true, 0),
      width: getWidth(150),
      flex: getFlex(150)
    },
    {
      field: 'Hours',
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => formatWithCommas(params.value, true, 0),
      width: getWidth(120),
      flex: getFlex(120)
    },
    {
      field: 'Labor',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.L,
      valueFormatter: (params) => formatAsCurrency(params.value, true, 0),
      width: getWidth(180),
      flex: getFlex(180)
    },
    {
      field: 'Material',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.M,
      valueFormatter: (params) => formatAsCurrency(params.value, true, 0),
      width: getWidth(180),
      flex: getFlex(180)
    },
    {
      field: 'Subs',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.S,
      valueFormatter: (params) => formatAsCurrency(params.value, true, 0),
      width: getWidth(180),
      flex: getFlex(180)
    },
    {
      field: 'Equipment',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.E,
      valueFormatter: (params) => formatAsCurrency(params.value, true, 0),
      width: getWidth(180),
      flex: getFlex(180)
    },
    {
      field: 'Other',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.O,
      valueFormatter: (params) => formatAsCurrency(params.value, true, 0),
      width: getWidth(180),
      flex: getFlex(180)
    },
    {
      field: 'TotalCosts',
      headerAlign: 'center',
      headerName: 'Total Costs',
      align: 'center',
      valueGetter: (params) => params.row.TotalCosts,
      valueFormatter: (params) => formatAsCurrency(params.value, true, 0),
      width: getWidth(180),
      flex: getFlex(180)
    },
    {
      field: 'GrossProfit',
      headerAlign: 'center',
      headerName: 'Gross Profit',
      align: 'center',
      valueFormatter: (params) => formatAsCurrency(params.value, true, 0),
      width: getWidth(180),
      flex: getFlex(180)
    },
    {
      field: 'ProfitMargin',
      headerAlign: 'center',
      headerName: 'Profit Margin',
      align: 'center',
      valueFormatter: (params) => formatAsPercent(params.value, true, 1),
      width: getWidth(90),
      flex: getFlex(90)
    }
  ];

  const canUserViewNote = (_, rowWriterNote) => {
    if (SuperAdmin(Role)) return true;
    if (AdminUp(Role)) return !SuperAdmin(rowWriterNote);
    if (DivisionalUp(Role)) return !AdminUp(rowWriterNote);
    if (ViewerUp(Role)) return !DivisionalUp(rowWriterNote);
    return false;
  };
  return (
    <>
      <Grid item xs={12} className='aldg-headerTitle'>
        {wPending ? 'MONTH OVER MONTH (Pending)' : 'MONTH OVER MONTH (Approved)'}
      </Grid>
      <Grid item xs={12} className='aldg-subheaderTitle'>
        {wPending ? 'Based on Projected Totals w/Pending' : 'Based on Projected Totals w/o Pending'}
      </Grid>

      <AldgDataGrid
        pageSizeOptions={[10, 20, 30]}
        columns={columns}
        extraClasses={[
          {
            '& .unmemorialized': {
              fontWeight: '600'
            }
          },
          {
            '& .variance1': {
              bgcolor: '#fbc1b6'
            }
          },
          {
            '& .variance-1': {
              bgcolor: '#bde9bd'
            }
          }
        ]}
        getRowId={(r) => r.Title}
        globalCellClassName={(params) => {
          let result = '';
          if (params.row.Memorialized !== 'true') result += ' unmemorialized';
          return result;
        }}
        FirebaseProps={{
          firebaseConfig,
          collection: `ENT-Jobs/${jctdscid}/AldgDataGridNotes`
        }}
        noteSettings={{
          allowNotes: true,
          canUserViewNote,
          getUserRole: () => Role,
          canUserAddNote: EditorUp(Role),
          noteCollection: `ENT-Jobs/${jctdscid}/AldgDataGridNotes`,
          dataGridName: `${wPending ? 'Pending' : 'Approved'} Month Over Month Delta Table`,
          meta: {
            MonthEndDate,
            Page: 'Project Status Report'
          }
        }}
        user={user}
        loading={loading}
        noToolbar
        rows={rows}
      />
    </>
  );
};

MonthOverMonth.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  originalBudget: PropTypes.arrayOf(PropTypes.object),
  wPending: PropTypes.bool,
  Role: PropTypes.string
};
MonthOverMonth.defaultProps = {
  originalBudget: [{}],
  wPending: false,
  Role: undefined
};

export default MonthOverMonth;
