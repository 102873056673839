import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { Card, CardContent, CardHeader, Switch } from '@mui/material';
import existsWithLength from '../../../utils/existsWithLength';
import getLink from './getLink';
import { theme } from '../../../theme/index';

const MenuCard = (props) => {
  const { label, description, icon, link, preferences, currentLocation, setPreferences, setChanged, history } = props;

  const reOrder = (order) => {
    const updatedPreferences = [...preferences];

    const updatedObj = Object.assign(JSON.parse(JSON.stringify(updatedPreferences[currentLocation])), {
      favorite: !updatedPreferences[currentLocation]?.favorite || false
    });
    updatedPreferences.splice(updatedObj.order, 1);
    updatedPreferences.splice(order, 0, updatedObj);

    for (let i = 0; i < updatedPreferences.length; i++) {
      updatedPreferences[i] = Object.assign(JSON.parse(JSON.stringify(updatedPreferences[i])), { order: i });
    }
    setChanged(true);
    setPreferences(updatedPreferences);
  };

  const handleChange = () => {
    if (preferences[currentLocation]?.favorite) reOrder(preferences.length - 1);
    else reOrder(0);
  };

  const isFavorite = existsWithLength(preferences[currentLocation]) ? preferences[currentLocation].favorite : true;

  return (
    <Card sx={{ outline: '1px solid black', margin: '8px' }} elevation={4}>
      <CardHeader
        style={{ fontSize: '40px' }}
        title={label}
        titleTypographyProps={{ style: { fontSize: '20px' } }}
        avatar={<FontAwesomeIcon icon={icon} />}
        action={
          <Switch
            color='default'
            inputProps={{ 'aria-label': 'controlled' }}
            checked={isFavorite}
            onChange={handleChange}
            sx={{
              '& .MuiSwitch-switchBase': {
                '& + .MuiSwitch-track': { opacity: '1' },
                '&.Mui-checked': {
                  '& + .MuiSwitch-track': { backgroundColor: theme.palette.primary.dark, opacity: '.58' }
                }
              }
            }}
          />
        }
      />
      <CardContent>{description}</CardContent>
      <div style={{ margin: '8px -8px', outline: '1px solid black' }} />
      <CardContent style={{ textAlign: 'right', paddingBottom: '16px' }}>
        <Link to={getLink(history, link)} style={{ color: 'black' }}>
          View Page
        </Link>
      </CardContent>
    </Card>
  );
};

MenuCard.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
  link: PropTypes.string,
  preferences: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  currentLocation: PropTypes.number,
  setPreferences: PropTypes.func,
  setChanged: PropTypes.func,
  history: PropTypes.object.isRequired
};
MenuCard.defaultProps = {
  label: 'Card Title',
  description: 'Card Component Description.',
  icon: faCircle,
  link: '/',
  preferences: {},
  currentLocation: 0,
  setPreferences: () => {},
  setChanged: () => {}
};

export default MenuCard;
