import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputSelect } from '@aldridge/aldg-data-components';
import { useRecoilValue } from 'recoil';
import { monthEndOptions } from '../../utils/monthEndOptions';
import { _CurrentJob } from '../../_Recoil/atoms';

const MonthEndOpts = (props) => {
  const { monthEndDate, setMonthEndDate, disabled, disableEmpty, settingStatusMonth, updateStatusMonth } = props;
  const Record = useRecoilValue(_CurrentJob);
  const monthEndOpts = monthEndOptions(Record.EcmsJobSetUpDate);

  useEffect(() => {
    if (monthEndOpts.length > 0 && !monthEndOpts.map((r) => r.value).includes(monthEndDate)) setMonthEndDate(monthEndOpts.at(-1).value);
  }, [monthEndOpts]);

  return (
    <InputSelect
      name='MonthEndDate'
      value={monthEndDate || ''}
      options={monthEndOpts}
      onChange={(event) => {
        if (settingStatusMonth) updateStatusMonth(event.target.value, 'StatusMonth', false);
        else setMonthEndDate(event.target.value);
      }}
      label='Month End Date'
      disabled={disabled}
      disableEmptyOption={disableEmpty}
    />
  );
};

MonthEndOpts.propTypes = {
  monthEndDate: PropTypes.string,
  setMonthEndDate: PropTypes.func,
  disabled: PropTypes.bool,
  disableEmpty: PropTypes.bool,
  settingStatusMonth: PropTypes.bool,
  updateStatusMonth: PropTypes.func
};
MonthEndOpts.defaultProps = {
  monthEndDate: '2010-12-31',
  setMonthEndDate: () => {},
  disabled: false,
  disableEmpty: true,
  settingStatusMonth: false,
  updateStatusMonth: () => {}
};

export default MonthEndOpts;
