import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faBars, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AldgList = (props) => {
  const { items, setItems } = props;
  const [dragging, setDragging] = useState(false);

  const getKey = (item) => `${item.key}-${item.order}`;

  const reOrder = (newItems) => {
    for (let i = 0; i < newItems.length; i++) {
      newItems[i] = Object.assign(JSON.parse(JSON.stringify(newItems[i])), { order: i });
    }
    setItems(newItems);
  };

  const handleDelete = (item) => {
    const itemsCopy = JSON.parse(JSON.stringify(items));
    const newItems = itemsCopy.filter((i) => getKey(i) !== getKey(item));
    reOrder(newItems);
  };

  const onDragStart = (ev, id) => {
    ev.dataTransfer.setData('id', id);
  };

  const onDrop = (ev, order) => {
    setDragging(false);

    const id = ev.dataTransfer.getData('id');
    const movedItem = items.find((item) => getKey(item) === id);

    if (movedItem.order !== order) {
      const newItems = items.filter((item) => getKey(item) !== id);
      newItems.splice(order, 0, movedItem);
      reOrder(newItems);
    }
  };

  return (
    <List style={{ marginRight: '7px' }}>
      {items
        ?.sort((a, b) => a.order - b.order)
        .map((item) => (
          <ListItem
            disableGutters
            key={getKey(item)}
            secondaryAction={
              <ToolTipIconButton shape='circle' color='error' icon={faTrash} tooltipText='Remove' onClick={() => handleDelete(item)} size={32} />
            }
            onDrop={(ev) => {
              onDrop(ev, item.order);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              if (getKey(item) !== dragging) setDragging(getKey(item));
            }}
            style={{ borderTop: dragging === getKey(item) ? '2px dashed gray' : undefined }}
          >
            <ListItemIcon
              style={{ minWidth: '30px', cursor: 'pointer' }}
              draggable
              onDragStart={(e) => {
                onDragStart(e, getKey(item));
              }}
              onDragEnd={() => setDragging(false)}
            >
              <FontAwesomeIcon icon={faBars} />
            </ListItemIcon>
            <ListItemText>
              {item.order + 1}. {item.text}
            </ListItemText>
          </ListItem>
        ))}
    </List>
  );
};

AldgList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, text: PropTypes.string, order: PropTypes.number })),
  setItems: PropTypes.func
};
AldgList.defaultProps = {
  items: [],
  setItems: () => {}
};

export default AldgList;
