import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { InputNumber } from '@aldridge/aldg-data-components';

const DefaultUpdater = (props) => {
  const { transaction, collection, field, label, isPercent, handleChange, updateValue } = props;
  return (
    <Grid item xs={12} style={{ padding: '12px', display: 'flex' }}>
      <InputNumber
        onChange={(value, name) => handleChange(value, name, true)}
        name={field}
        label={label}
        value={transaction}
        min={0}
        max={isPercent ? 1 : undefined}
      />
      <Button
        variant='contained'
        onClick={() => updateValue(collection, field)}
        style={{ height: '36px', alignSelf: 'flex-end', marginLeft: '12px' }}
      >
        Update
      </Button>
    </Grid>
  );
};

DefaultUpdater.propTypes = {
  transaction: PropTypes.number,
  collection: PropTypes.string,
  label: PropTypes.string,
  isPercent: PropTypes.bool,
  field: PropTypes.string,
  handleChange: PropTypes.func,
  updateValue: PropTypes.func
};
DefaultUpdater.defaultProps = {
  transaction: 0,
  collection: 'Accounting',
  field: '',
  isPercent: false,
  label: '',
  handleChange: () => {},
  updateValue: () => {}
};

export default DefaultUpdater;
