import React from 'react';
import PropTypes from 'prop-types';
import { formatAsPercent } from '../../UIComponents/format';
import LabeledValueBox from './LabeledValueBox';

const CalculationLine = (props) => {
  const { calcName, calcArr } = props;

  const numOperators = calcArr.filter((element) => typeof element === 'string').length - 1;
  const totalBoxes = calcArr.length - numOperators;

  const operatorSize = 0.05;
  const numberSize = (1 - operatorSize * numOperators) / totalBoxes;

  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'end', padding: '8px 0px' }}>
      <div
        style={{
          textAlign: 'left',
          fontWeight: 'bold',
          width: formatAsPercent(numberSize),
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          fontSize: '20px'
        }}
      >
        {calcName}:
      </div>
      {calcArr.map((element) => (
        <React.Fragment key={typeof element === 'string' ? element : element.label}>
          {typeof element === 'object' ? (
            <LabeledValueBox label={element.label} value={element.value} formatType={element.formatType} gridSize={formatAsPercent(numberSize)} />
          ) : (
            <div style={{ width: formatAsPercent(operatorSize), height: '45px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {element}
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

CalculationLine.propTypes = {
  calcName: PropTypes.string,
  calcArr: PropTypes.array
};
CalculationLine.defaultProps = {
  calcName: 'Calculation',
  calcArr: []
};

export default CalculationLine;
