import { formatter } from '../ProjectStatus/sharedColumns';

const CostType = (getWidth, getFlex, type) => ({
  field: type,
  headerAlign: 'center',
  headerName: type,
  align: 'center',
  canEdit: true,
  totalRow: true,
  aggregable: false,
  inputType: 'InputNumber',
  cellClassName: (params) => (params.row.isActual || params.id === 'TOTAL' ? '' : 'editable'),
  valueGetter: (params) => {
    if (params?.row?.[type]?.toString() === '0') return 0;
    return params?.row?.[type];
  },
  valueFormatter: (params) => formatter('currency', params.value, params.id),
  width: getWidth(150),
  flex: getFlex(150),
  type: 'number',
  onChangeDependents: ['CumulativeJobTotal', 'ProjectedDisbursements', 'CumulativeCashPosition', 'MonthCostVBilling']
});

export default CostType;
