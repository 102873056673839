/* eslint-disable no-unused-vars */
import { NaNtoNum } from '@aldridge/aldg-helpers';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { SumCosts } from './SumCosts';
import NaNtoZero from '../../../utils/convertNaNToZero';

const limitDigits = (value, digits) => {
  const digitCount = value.toString().replaceAll('.', '').length;
  const splitDec = value.toString().split('.');
  if (digitCount > digits) {
    if (splitDec[0].length < digits) {
      return parseFloat(NaNtoNum(value)?.toFixed(digits - splitDec[0].length));
    }
    // if (splitDec[0].length > digits) console.log('WARNING: Number is too large to round');
    return parseFloat(splitDec[0]);
  }
  return value;
};

const GenerateChangeOrder = (record, chOrder) => {
  const workbook = new ExcelJS.Workbook();
  // begin worksheet
  const ECMSWorksheet = workbook.addWorksheet('ECMS Upload');
  ECMSWorksheet.columns = [
    { header: 'CO NO', key: 'CONO', width: 10 }, // A
    { header: 'DIV NO', key: 'DIVNO', width: 10 }, // B
    { header: 'C/O NUMBER', key: 'CONUMBER', width: 13 }, // C
    { header: 'C/O TYPE', key: 'COTYPE', width: 10 }, // D
    { header: 'REV AMT', key: 'REVAMT', width: 10 }, // E
    { header: 'APPROVED CODE', key: 'APPROVEDCODE', width: 18 }, // F
    { header: 'CREATE CONTRACT ITEM', key: 'CREATECONTRACTITEM', width: 25 }, // G
    { header: 'DESCRIPTION', key: 'DESCRIPTION', width: 50 }, // H
    { header: 'RCVD DATE', key: 'RECVDATE', width: 12 }, // I
    { header: 'RECORD TYPE', key: 'RCRDTYPE', width: 14 }, // J
    { header: 'REVISION NUMBER', key: 'REVNUM', width: 18 }, // K
    { header: 'C/O RETURN DATE', key: 'RETURNDATE', width: 18 }, // L
    { header: 'JOB NO', key: 'JOBNO', width: 10 }, // M
    { header: 'SUB JOB NO', key: 'SJNO', width: 12 }, // N
    { header: 'J/C DIST', key: 'JCDIST', width: 10 }, // O
    { header: 'C/T', key: 'CT', width: 8 }, // P
    { header: 'UPDATE J/C', key: 'UPJC', width: 12 }, // Q
    // { header: 'DESCRIPTION 1', key: 'DESC1', width: 50 },
    // { header: 'DESCRIPTION 2', key: 'DESC2', width: 50 },
    { header: 'REV AMT', key: 'REVAMT', width: 12 }, // R
    { header: 'REV HOURS', key: 'REVHRS', width: 15 }, // S
    { header: 'STATUS', key: 'STATUS', width: 15 }, // T
    { header: 'ITEM NO', key: 'ITEMNO', width: 15 }, // U
    { header: 'CONTRACT NO', key: 'CONTRACTNO', width: 15 }, // V
    { header: 'VEND/CUST NO', key: 'VENDCUSTNO', width: 15 } // W
  ];

  ECMSWorksheet.getRow(1).font = { bold: true };
  ECMSWorksheet.getRow(1).alignment = { horizontal: 'center' };

  const isMultipleCO = Array.isArray(chOrder);
  const changeOrders = isMultipleCO ? chOrder : [chOrder];
  const rows = [];
  for (let i = 0; i < changeOrders.length; i++) {
    const changeOrder = changeOrders[i];
    const extractedLaborTotals = SumCosts(changeOrder?.data.LaborChangeOrders.map((l) => NaNtoZero(l.LaborTotal)));
    const extractedSubcontractorTotals = SumCosts(changeOrder?.data.SubcontractorsChangeOrders.map((e) => e.Total));
    const extractedMaterialTotals = SumCosts(changeOrder?.data.MaterialChangeOrders.map((m) => m.Total));
    const extractedEquipmentTotals = SumCosts(changeOrder?.data.EquipmentChangeOrders.map((e) => e.Total));
    const extractedOtherTotals = SumCosts(changeOrder?.data.OtherChangeOrders.map((o) => o.Total));

    const extractedTotals =
      extractedLaborTotals + extractedSubcontractorTotals + extractedMaterialTotals + extractedEquipmentTotals + extractedOtherTotals;

    const data = Object.entries(changeOrder?.data);
    for (let k = 0; k < data.length; k++) {
      const d = data[k];
      if (Array.isArray(d[1]) && d[1].length > 0) {
        for (let j = 0; j < d[1].length; j++) {
          const co = d[1][j];
          if (typeof co?.LaborCost === 'undefined' ? co?.Total : co?.LaborCost !== 0) {
            rows.push([
              1,
              0,
              changeOrder?.data.ECMSChangeOrder,
              1,
              extractedTotals, // E
              'Y',
              'N',
              `${changeOrder?.data.AldridgeChangeOrder.slice(0, 40)}`,
              changeOrder?.data.DateApproved.substring(0, 10).replace(/-/g, ''),
              2,
              changeOrder?.data.DateApproved.substring(0, 10).replace(/-/g, ''),
              changeOrder?.data.DateApproved.substring(0, 10).replace(/-/g, ''),
              record.id.split('.')[2],
              record.id.split('.').length === 4 ? record.id.split('.')[3] : null,
              `${co?.PayItem}${co?.SegmentValue}`,
              d[0].charAt(0),
              'Y',
              // changeOrder?.data.BudgetDescription.slice(0, 40),
              // changeOrder?.data.CustomerChangeOrder.slice(0, 40),
              typeof co?.LaborCost === 'undefined' ? co?.Total : co?.LaborCost, // R - REV AMT should be more than 0
              typeof co?.LaborHours !== 'undefined' ? limitDigits(co?.LaborHours, 5) : '',
              'A',
              '',
              '',
              ''
            ]);
          }
          if (d[0].charAt(0) === 'L' && co?.BurdenCost !== 0) {
            rows.push([
              1,
              0,
              changeOrder?.data.ECMSChangeOrder,
              1,
              extractedTotals,
              'Y',
              'N',
              `${changeOrder?.data.AldridgeChangeOrder}`,
              changeOrder?.data.DateApproved.substring(0, 10).replace(/-/g, ''),
              2,
              changeOrder?.data.DateApproved.substring(0, 10).replace(/-/g, ''),
              changeOrder?.data.DateApproved.substring(0, 10).replace(/-/g, ''),
              record.id.split('.')[2],
              record.id.split('.').length === 4 ? record.id.split('.')[3] : null,
              `${co?.PayItem}${co?.SegmentValue}`,
              'B',
              'Y',
              // changeOrder?.data.BudgetDescription.slice(0, 40),
              // changeOrder?.data.CustomerChangeOrder,
              co?.BurdenCost, // R - REV AMT should be more than 0
              '',
              'A',
              '',
              '',
              ''
            ]);
          }
        }
      }
    }

    // end worksheet
  }
  ECMSWorksheet.addRows(rows);

  ECMSWorksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      if (rowNumber > 1) {
        if (colNumber === 1)
          cell.dataValidation = {
            type: 'whole',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 2 or shorter.',
            formulae: [100]
          };
        else if (colNumber === 2)
          cell.dataValidation = {
            type: 'whole',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 3 or shorter.',
            formulae: [1000]
          };
        else if ([3, 11].includes(colNumber))
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 10 or shorter.',
            formulae: [10]
          };
        else if (colNumber === 4)
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 2 or shorter.',
            formulae: [2]
          };
        else if ([5, 18].includes(colNumber))
          cell.dataValidation = {
            type: 'decimal',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 11 or shorter.',
            formulae: [10000000000]
          };
        else if ([6, 7, 10, 15, 16].includes(colNumber))
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 1 or shorter.',
            formulae: [1]
          };
        else if ([8, 17].includes(colNumber))
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 40 or shorter.',
            formulae: [40]
          };
        else if (colNumber === 9)
          cell.dataValidation = {
            type: 'whole',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 8 or shorter.',
            formulae: [100000000]
          };
        else if (colNumber === 12)
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 6 or shorter.',
            formulae: [6]
          };
        else if (colNumber === 13)
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 3 or shorter.',
            formulae: [3]
          };
        else if (colNumber === 14)
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 15 or shorter.',
            formulae: [15]
          };
        else if (colNumber === 19) {
          cell.dataValidation = {
            type: 'whole',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be a whole number of length 6 or shorter.',
            formulae: [100000]
          };
          cell.numFmt = '###0';
        }
      }
    });
  });
  workbook.xlsx.writeBuffer().then((r) => {
    const coData = Array.isArray(chOrder) ? chOrder[0]?.data : chOrder?.data;
    const name =
      isMultipleCO && chOrder.length > 1
        ? `ECMSChangeOrderUpload-Multiple[${record.id.split('.')[2]}].xlsx`
        : `ECMSChangeOrderUpload-${record.id.split('.')[2]}-${coData.ECMSChangeOrder}-${coData.AldridgeChangeOrder}.xlsx`;
    saveAs(new Blob([r.buffer]), name);
  });
};
const GenerateJobCostMaster = (record, changeOrder) => {
  const workbook = new ExcelJS.Workbook();
  // begin worksheet
  const ECMSWorksheet = workbook.addWorksheet('ECMS Upload');
  ECMSWorksheet.columns = [
    { header: 'Record Status', key: 'RecordStatus', width: 10 }, // A
    { header: 'CO NO', key: 'CONO', width: 10 }, // B
    { header: 'DIV NO', key: 'DIVNO', width: 10 }, // C
    { header: 'PROJ/JOB NO', key: 'JOBNO', width: 10 }, // D
    { header: 'SUB PROJ/JOB NO', key: 'SJNO', width: 12 }, // E
    { header: 'J/C DIST', key: 'JCDIST', width: 10 }, // F
    { header: 'C/T', key: 'CT', width: 8 }, // G
    { header: 'DESCRIPTION', key: 'DESC1', width: 50 }, // H
    { header: 'AS BID BUDG AMOUNT', key: 'BUDG', width: 50 }, // I
    { header: 'BUDG AMOUNT', key: 'BUDGAMT', width: 50 }, // J
    { header: 'BUDG HOURS', key: 'BUDGHRS', width: 50 }, // K
    { header: 'BUDG QTY', key: 'BUDGQTY', width: 50 } // L
  ];

  ECMSWorksheet.getRow(1).font = { bold: true };
  ECMSWorksheet.getRow(1).alignment = { horizontal: 'center' };

  const rows = [];
  rows.push([
    'A',
    1,
    0,
    record.id.split('.')[2],
    record.id.split('.').length === 4 ? record.id.split('.')[3] : null,
    `   BILLED`,
    'I',
    '',
    NaNtoNum(changeOrder?.data.ContractValue),
    NaNtoNum(changeOrder?.data.ContractValue),
    0
  ]);
  Object.entries(changeOrder?.data).forEach((d) => {
    if (Array.isArray(d[1]) && d[1].length > 0) {
      d[1].forEach((co) => {
        if (co?.PayItem !== undefined && co?.SegmentValue !== undefined) {
          if (d[0].charAt(0) === 'L') {
            rows.push([
              'A',
              1,
              0,
              record.id.split('.')[2],
              record.id.split('.').length === 4 ? record.id.split('.')[3] : null,
              `${co?.PayItem}${co?.SegmentValue}`,
              d[0].charAt(0),
              '',
              NaNtoNum(co?.LaborCost),
              NaNtoNum(co?.LaborCost),
              limitDigits(co?.LaborHours, 5)
            ]);
            rows.push([
              'A',
              1,
              0,
              record.id.split('.')[2],
              record.id.split('.').length === 4 ? record.id.split('.')[3] : null,
              `${co?.PayItem}${co?.SegmentValue}`,
              'B',
              '',
              NaNtoNum(co?.BurdenCost),
              NaNtoNum(co?.BurdenCost),
              0
            ]);
          } else {
            rows.push([
              'A',
              1,
              0,
              record.id.split('.')[2],
              record.id.split('.').length === 4 ? record.id.split('.')[3] : null,
              `${co?.PayItem}${co?.SegmentValue}`,
              d[0].charAt(0),
              '',
              NaNtoNum(co?.Total),
              NaNtoNum(co?.Total),
              0
            ]);
          }
        }
      });
    }
  });
  rows.push(['A', 1, 0, record.id.split('.')[2], record.id.split('.').length === 4 ? record.id.split('.')[3] : null, `10099999`, 'O', '', 0, 0, 0]);
  ECMSWorksheet.addRows(rows);

  ECMSWorksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      if (rowNumber > 1) {
        if (colNumber === 1)
          cell.dataValidation = {
            type: 'whole',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 2 or shorter.',
            formulae: [100]
          };
        else if (colNumber === 2)
          cell.dataValidation = {
            type: 'whole',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 3 or shorter.',
            formulae: [1000]
          };
        else if (colNumber === 3)
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 10 or shorter.',
            formulae: [10]
          };
        else if (colNumber === 4)
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 2 or shorter.',
            formulae: [2]
          };
        else if ([5, 19].includes(colNumber))
          cell.dataValidation = {
            type: 'whole',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 11 or shorter.',
            formulae: [100000000000]
          };
        else if ([6, 7, 10, 15, 16].includes(colNumber))
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 1 or shorter.',
            formulae: [1]
          };
        else if ([8, 17, 18].includes(colNumber))
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 40 or shorter.',
            formulae: [40]
          };
        else if (colNumber === 9)
          cell.dataValidation = {
            type: 'whole',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 8 or shorter.',
            formulae: [100000000]
          };
        else if (colNumber === 11) {
          cell.dataValidation = {
            type: 'whole',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be a whole number of length 6 or shorter.',
            formulae: [100000]
          };
          cell.numFmt = '###0';
        } else if (colNumber === 12)
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 6 or shorter.',
            formulae: [6]
          };
        else if (colNumber === 13)
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 3 or shorter.',
            formulae: [3]
          };
        else if (colNumber === 14)
          cell.dataValidation = {
            type: 'textLength',
            operator: 'lessThanOrEqual',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 15 or shorter.',
            formulae: [15]
          };
        else if (colNumber === 20)
          cell.dataValidation = {
            type: 'whole',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            error: 'The value must be length 6 or shorter.',
            formulae: [100000]
          };
      }
    });
  });

  // end worksheet

  workbook.xlsx.writeBuffer().then((r) => {
    saveAs(new Blob([r.buffer]), 'ECMSChangeOrderUpload.xlsx');
  });
};

// eslint-disable-next-line no-unused-vars
const UpdateRecord = (record, changeOrder) => {
  // const newRecord = { ...changeOrder };
  // setDoc(firestore, `ENT-Jobs/${record.id}/ChangeOrders/${changeOrder.id}`, newRecord.data);
};
const DownloadECMS = async (record, changeOrder) => {
  const coDownloadTypes = [
    'T & M Change Order',
    'Force Account Change Order',
    'Lump Sum Change Order',
    'Change Order Escalation',
    'Units Change Order',
    'Construction Budget',
    'Authorized Extras',
    'Claims'
  ];
  if (!Array.isArray(changeOrder) && ['Original Budget', 'Pre-Start Budget'].indexOf(changeOrder?.data?.ChangeOrderType) > -1) {
    GenerateJobCostMaster(record, changeOrder);
    UpdateRecord(record, changeOrder);
  } else if (
    (Array.isArray(changeOrder) && changeOrder.map((r) => r.data.ChangeOrderType).every((r) => coDownloadTypes.indexOf(r) > -1)) ||
    (!Array.isArray(changeOrder) && coDownloadTypes.indexOf(changeOrder?.data?.ChangeOrderType) > -1)
  ) {
    GenerateChangeOrder(record, changeOrder);
    UpdateRecord(record, changeOrder);
  } else {
    toast.error('Please select a valid change order type.');
  }
};

export default DownloadECMS;
