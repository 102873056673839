import * as ExcelJS from 'exceljs';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { doc, getDoc } from 'firebase/firestore';
import NaNtoZero from '../../../utils/convertNaNToZero';
import {
  generalOverview,
  basicFont,
  headerFont,
  numberFormatRP1000s0D,
  currency$,
  percent,
  createTotalRow,
  createTable
} from '../../../_helpers/ExcelJSHelpers';
import getMostRecentMonth from '../../../utils/getMostRecentMonth';
import { firestore } from '../../../firebase';

const createLaborRow = (lr) => [
  lr.PayItem,
  lr.CostCode,
  lr.PiccDescription,
  lr.FriendlyName?.length > 0 ? lr.FriendlyName : '',
  NaNtoZero(lr.LaborRate),
  NaNtoZero(lr.LaborHours),
  NaNtoZero(lr.LaborCost),
  NaNtoZero(lr.BurdenCost),
  NaNtoZero(lr.LaborTotal),
  NaNtoZero(lr.CostHrToComplete),
  NaNtoZero(lr.HoursToComplete),
  NaNtoZero(lr.HoursToDate),
  NaNtoZero(lr.HoursAtComplete),
  NaNtoZero(lr.CostPerHrToDate),
  NaNtoZero(lr.CostToDate),
  NaNtoZero(lr.CostToComplete),
  NaNtoZero(lr.TotalCostAtCompletion),
  NaNtoZero(lr.TotalCostAtCompletionLastMonth),
  NaNtoZero(lr.VarianceAtCompletion),
  NaNtoZero(lr.VarianceLastMonth),
  lr.PNP,
  NaNtoZero(lr.VarianceAtCompletion) - NaNtoZero(lr.VarianceLastMonth),
  lr.CustomerCostCode,
  lr.Notes
];

const createMaterialOrOtherRow = (mr) => [
  mr.PayItem,
  mr.CostCode,
  mr.PiccDescription,
  mr.FriendlyName?.length > 0 ? mr.FriendlyName : '',
  '',
  '',
  '',
  '',
  NaNtoZero(mr.Total),
  NaNtoZero(mr.CostToDate),
  NaNtoZero(mr.CostToComplete),
  NaNtoZero(mr.TotalCostAtCompletion),
  NaNtoZero(mr.TotalCostAtCompletionLastMonth),
  NaNtoZero(mr.VarianceAtCompletion),
  NaNtoZero(mr.VarianceLastMonth),
  NaNtoZero(mr.VarianceAtCompletion) - NaNtoZero(mr.VarianceLastMonth),
  mr.CustomerCostCode,
  mr.Notes
];

const createSubOrEquipRow = (sr) => [
  sr.PayItem,
  sr.CostCode,
  sr.PiccDescription,
  sr.FriendlyName?.length > 0 ? sr.FriendlyName : '',
  NaNtoZero(sr.Hours),
  '',
  '',
  '',
  NaNtoZero(sr.Total),
  NaNtoZero(sr.CostToDate),
  NaNtoZero(sr.CostToComplete),
  NaNtoZero(sr.TotalCostAtCompletion),
  NaNtoZero(sr.TotalCostAtCompletionLastMonth),
  NaNtoZero(sr.VarianceAtCompletion),
  NaNtoZero(sr.VarianceLastMonth),
  NaNtoZero(sr.VarianceAtCompletion) - NaNtoZero(sr.VarianceLastMonth),
  sr.CustomerCostCode,
  sr.Notes
];

const colorBlue = (CostCodeWorksheet, blues) => {
  blues.forEach((blue) => {
    CostCodeWorksheet.addConditionalFormatting({
      ref: `${blue[0]}:${blue[1]}`,
      rules: [
        {
          type: 'expression',
          formulae: [true],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'ff89f5fb' }
            }
          }
        }
      ]
    });
  });
};

const createTotalTableRow = (CostCodeWorksheet, letters, rowNum, endRow) => {
  letters.forEach((letter) => {
    CostCodeWorksheet.getCell(`${letter}${rowNum}`).value = {
      formula: `+${letter}${endRow}`
    };
    CostCodeWorksheet.getCell(`${letter}${rowNum}`).numFmt = currency$;
  });
};

const DownloadForecast = async (rows, monthEndDate, pm, JobID, contractValues, setLoading = () => {}) => {
  setLoading(true);
  const prevMonth = await getMostRecentMonth(JobID, monthEndDate);
  const prevContracts = (await getDoc(doc(firestore, `ENT-Jobs/${JobID}/MonthEnd/${prevMonth}`))).data();

  const jobName = await generalOverview(JobID);
  const workbook = new ExcelJS.Workbook();

  const laborRows = [];
  const materialRows = [];
  const equipmentRows = [];
  const subRows = [];
  const otherRows = [];

  const valueList = [
    'Pay Item',
    'Cost Code',
    'Description',
    'Supplementary Description',
    '',
    '',
    '',
    '',
    'Total',
    'Cost To Date',
    'Cost To Complete',
    'Total Cost at Completion',
    'Total Cost at Completion Last Month',
    'Variance at Completion',
    'Variance Last Month',
    'Variance Month Over Month',
    'Customer Cost Code',
    'Notes',
    ''
  ];
  const valueListHours = [
    'Pay Item',
    'Cost Code',
    'Description',
    'Supplementary Description',
    'Hours',
    '',
    '',
    '',
    'Total',
    'Cost To Date',
    'Cost To Complete',
    'Total Cost at Completion',
    'Total Cost at Completion Last Month',
    'Variance at Completion',
    'Variance Last Month',
    'Variance Month Over Month',
    'Customer Cost Code',
    'Notes',
    ''
  ];

  rows.forEach((row) => {
    switch (row.CostType) {
      case 'L':
        laborRows.push(createLaborRow(row));
        break;
      case 'M':
        materialRows.push(createMaterialOrOtherRow(row));
        break;
      case 'E':
        equipmentRows.push(createSubOrEquipRow(row));
        break;
      case 'S':
        subRows.push(createSubOrEquipRow(row));
        break;
      default:
        otherRows.push(createMaterialOrOtherRow(row));
        break;
    }
  });

  const blueCells = [];

  // begin header
  const CostCodeWorksheet = workbook.addWorksheet(
    'Cost Codes',
    {
      views: [{ state: 'frozen', xSplit: 2 }, { showGridLines: false }]
    },
    {
      pageSetup: { paperSize: 9, orientation: 'landscape' }
    }
  );

  CostCodeWorksheet.mergeCells('A1:B1');
  CostCodeWorksheet.getCell('A1').value = 'Cost To Complete';
  CostCodeWorksheet.getCell('C1').value = 'Detail';
  CostCodeWorksheet.getCell('D2').alignment = {
    vertical: 'middle',
    horizontal: 'center'
  };
  CostCodeWorksheet.mergeCells('D2:N5');
  CostCodeWorksheet.getCell('D2').value = `Cost Thru ${rows[0]?.MonthEndDate}`;
  CostCodeWorksheet.getCell('D2').font = {
    name: 'Arial Black',
    bold: true,
    color: { argb: '00000000' },
    size: 14,
    fgColor: 'FFEFFB1D'
  };

  CostCodeWorksheet.getCell('A2').value = 'Job #';
  CostCodeWorksheet.getCell('A3').value = 'Job Name';
  CostCodeWorksheet.getCell('A4').value = 'PM';
  CostCodeWorksheet.getCell('C1').value = 'Detail';
  CostCodeWorksheet.getCell('C2').value = JobID;
  CostCodeWorksheet.getCell('C3').value = jobName;
  CostCodeWorksheet.getCell('C4').value = pm;

  // end header

  // begin labor table

  CostCodeWorksheet.columns = [
    { key: 'PayItem', width: 6, style: basicFont },
    { key: 'CostCode', width: 20, style: basicFont },
    { key: 'PiccDescription', width: 30, style: basicFont },
    { key: 'SupplementaryDescription', width: 30, style: basicFont },
    { key: 'LaborRate', width: 15, style: basicFont },
    { key: 'LaborHours', width: 15, style: basicFont },
    { key: 'LaborCost', width: 15, style: basicFont },
    { key: 'LaborBurden', width: 15, style: basicFont },
    { key: 'LaborTotal', width: 15, style: basicFont },
    { key: 'CostHrToComplete', width: 15, style: basicFont },
    { key: 'HoursToComplete', width: 15, style: basicFont },
    { key: 'HoursToDate', width: 15, style: basicFont },
    { key: 'HoursAtComplete', width: 15, style: basicFont },
    { key: 'CostPerHrToDate', width: 15, style: basicFont },
    { key: 'CostToDate', width: 15, style: basicFont },
    { key: 'CostToComplete', width: 20, style: basicFont },
    { key: 'TotalCostAtCompletion', width: 20, style: basicFont },
    { key: 'TotalCostAtCompletionLastMonth', width: 20, style: basicFont },
    { key: 'VarianceAtCompletion', width: 15, style: basicFont },
    { key: 'VarianceAtCompletionLastMonth', width: 15, style: basicFont },
    { key: 'PNP', width: 6, style: basicFont },
    { key: 'VarianceMonthOverMonth', width: 15, style: basicFont },
    { key: 'CustomerCostCode', width: 15, style: basicFont },
    { key: 'Notes', width: 30, style: basicFont }
  ];

  const laborSums = ['F', 'G', 'H', 'I', 'K', 'L', 'M', 'O', 'P', 'Q', 'R', 'S', 'T', 'V'];

  const endLaborRow = createTable(
    CostCodeWorksheet,
    7,
    'Labor',
    laborRows,
    [
      'Pay Item',
      'Cost Code',
      'Picc Description',
      'Supplementary Description',
      'Labor Rate',
      'Labor Hours',
      'Labor Cost',
      'Labor Burden',
      'Labor Total',
      'Cost / Hr To Complete',
      'Hours To Complete',
      'Hours To Date',
      'Hours At Complete',
      'Cost Per Hr To Date',
      'Cost To Date',
      'Cost To Complete',
      'Total Cost At Completion',
      'Total Cost At Completion Last Month',
      'Variance At Completion',
      'Variance Last Month',
      'PNP',
      'Variance Month Over Month',
      'Customer Cost Code',
      'Notes'
    ],
    laborSums
  );
  blueCells.push(['J8', `J${endLaborRow - 1}`], ['K8', `K${endLaborRow - 1}`]);

  CostCodeWorksheet.getCell(`D${endLaborRow + 1}`).value = 'Productive Hours';
  CostCodeWorksheet.getCell(`D${endLaborRow + 2}`).value = 'Non Productive Hours';

  CostCodeWorksheet.getCell(`D${endLaborRow + 1}`).alignment = {
    horizontal: 'right'
  };
  CostCodeWorksheet.getCell(`D${endLaborRow + 2}`).alignment = {
    horizontal: 'right'
  };

  laborSums.forEach((letter) => {
    CostCodeWorksheet.getCell(`${letter}${endLaborRow + 1}`).value = {
      formula: `SUMIF(U8:U${endLaborRow - 1}, "P", ${letter}8:${letter}${endLaborRow - 1})`
    };
    CostCodeWorksheet.getCell(`${letter}${endLaborRow + 2}`).value = {
      formula: `SUMIF(U8:U${endLaborRow - 1}, "NP", ${letter}8:${letter}${endLaborRow - 1})`
    };
  });

  CostCodeWorksheet.getCell(`N${endLaborRow}`).value = {
    formula: `O${endLaborRow} / L${endLaborRow}`
  };
  CostCodeWorksheet.getCell(`N${endLaborRow + 1}`).value = {
    formula: `O${endLaborRow + 1} / L${endLaborRow + 1}`
  };
  CostCodeWorksheet.getCell(`N${endLaborRow + 2}`).value = {
    formula: `O${endLaborRow + 2} / L${endLaborRow + 2}`
  };

  CostCodeWorksheet.addConditionalFormatting({
    ref: `E8:E${endLaborRow - 1}`,
    rules: [
      {
        type: 'expression',
        formulae: [true],
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: 'ffffffff' }
          }
        }
      }
    ]
  });

  // end labor table

  // begin material table

  const materialTableStart = endLaborRow + 4;
  const endMaterialRow = createTable(CostCodeWorksheet, materialTableStart, 'Materials', materialRows, valueList, [
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P'
  ]);
  blueCells.push([`K${materialTableStart + 1}`, `K${endMaterialRow - 1}`]);

  // end material table

  // begin subcontractor table

  const subcontractorTableStart = endMaterialRow + 2;
  const endSubcontractorRows = createTable(CostCodeWorksheet, subcontractorTableStart, 'Subcontractor', subRows, valueListHours, [
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P'
  ]);
  blueCells.push([`K${subcontractorTableStart + 1}`, `K${endSubcontractorRows - 1}`]);

  // end subcontractor table

  // begin equipment table

  const equipmentTableStart = endSubcontractorRows + 2;
  const endEquipmentRows = createTable(CostCodeWorksheet, equipmentTableStart, 'Equipment', equipmentRows, valueListHours, [
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P'
  ]);
  blueCells.push([`K${equipmentTableStart + 1}`, `K${endEquipmentRows - 1}`]);

  // end equipment table

  // begin other table

  const otherTableStart = endEquipmentRows + 2;
  const endOtherTableRows = createTable(CostCodeWorksheet, otherTableStart, 'Other Costs', otherRows, valueList, [
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P'
  ]);
  blueCells.push([`K${otherTableStart + 1}`, `K${endOtherTableRows - 1}`]);

  // end other table

  // begin summary

  const endOtherRow = CostCodeWorksheet.lastRow._number + 1;
  CostCodeWorksheet.getCell(`A${endOtherRow}`).value = 'SUMMARY';
  CostCodeWorksheet.getCell(`A${endOtherRow}`).font = headerFont;

  CostCodeWorksheet.getCell(`D${endOtherRow + 2}`).value = 'Labor';
  CostCodeWorksheet.getCell(`F${endOtherRow + 2}`).value = {
    formula: `+L${endLaborRow}`
  };

  CostCodeWorksheet.getCell(`H${endOtherRow + 2}`).value = {
    formula: `F${endLaborRow}`
  };
  CostCodeWorksheet.getCell(`I${endOtherRow + 2}`).value = {
    formula: `I${endLaborRow}`
  };
  CostCodeWorksheet.getCell(`J${endOtherRow + 2}`).value = {
    formula: `+O${endLaborRow}`
  };
  CostCodeWorksheet.getCell(`K${endOtherRow + 2}`).value = {
    formula: `+P${endLaborRow}`
  };
  CostCodeWorksheet.getCell(`L${endOtherRow + 2}`).value = {
    formula: `+Q${endLaborRow}`
  };
  CostCodeWorksheet.getCell(`M${endOtherRow + 2}`).value = {
    formula: `+R${endLaborRow}`
  };
  CostCodeWorksheet.getCell(`N${endOtherRow + 2}`).value = {
    formula: `+S${endLaborRow}`
  };

  CostCodeWorksheet.getCell(`D${endOtherRow + 3}`).value = 'Material';
  createTotalTableRow(CostCodeWorksheet, ['I', 'J', 'K', 'L', 'M', 'N'], endOtherRow + 3, endMaterialRow);

  CostCodeWorksheet.getCell(`D${endOtherRow + 4}`).value = 'Subcontracts';
  createTotalTableRow(CostCodeWorksheet, ['I', 'J', 'K', 'L', 'M', 'N'], endOtherRow + 4, endSubcontractorRows);

  CostCodeWorksheet.getCell(`D${endOtherRow + 5}`).value = 'Equipment/Fuel';
  createTotalTableRow(CostCodeWorksheet, ['I', 'J', 'K', 'L', 'M', 'N'], endOtherRow + 5, endEquipmentRows);

  CostCodeWorksheet.getCell(`D${endOtherRow + 6}`).value = 'Other costs';
  createTotalTableRow(CostCodeWorksheet, ['I', 'J', 'K', 'L', 'M', 'N'], endOtherRow + 6, endOtherTableRows);

  createTotalRow(CostCodeWorksheet, ['F', 'I', 'J', 'K', 'L', 'M', 'N'], endOtherRow + 2, endOtherRow + 7);
  ['F', 'H', 'I', 'J', 'K', 'L', 'M', 'N'].forEach((letter) => {
    CostCodeWorksheet.getCell(`${letter}${endOtherRow + 2}`).numFmt = currency$;
    CostCodeWorksheet.getCell(`${letter}${endOtherRow + 7}`).numFmt = currency$;
  });

  // end summary

  // begin PRIOR CURRENT PROFITS

  const endSummaryRow = CostCodeWorksheet.lastRow._number + 1;
  CostCodeWorksheet.getCell(`A${endSummaryRow}`).value = 'PROFIT';
  CostCodeWorksheet.getCell(`A${endSummaryRow}`).font = headerFont;

  CostCodeWorksheet.getCell(`C${endSummaryRow + 2}`).value = `Prior Month (${prevMonth})`;
  CostCodeWorksheet.getRow(endSummaryRow + 2).font = {
    name: 'Arial Black',
    color: { argb: '00000000' },
    size: 9
  };
  CostCodeWorksheet.getCell(`C${endSummaryRow + 3}`).value = 'Contract Value';
  CostCodeWorksheet.getCell(`C${endSummaryRow + 4}`).value = 'Total Cost At Completion';
  CostCodeWorksheet.getCell(`C${endSummaryRow + 5}`).value = 'Profit';
  CostCodeWorksheet.getCell(`C${endSummaryRow + 6}`).value = 'Margin %';

  CostCodeWorksheet.getCell(`D${endSummaryRow + 3}`).value = prevContracts?.FinancialApprovedContract || 0;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 4}`).value = { formula: `=+M${endOtherRow + 7}` };
  CostCodeWorksheet.getCell(`D${endSummaryRow + 5}`).value = { formula: `=+D${endSummaryRow + 3}-D${endSummaryRow + 4}` };
  CostCodeWorksheet.getCell(`D${endSummaryRow + 6}`).value = { formula: `=+D${endSummaryRow + 5}/D${endSummaryRow + 3}` };

  CostCodeWorksheet.getCell(`D${endSummaryRow + 3}`).numFmt = currency$;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 4}`).numFmt = currency$;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 5}`).numFmt = currency$;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 6}`).numFmt = percent;

  const priorMonth = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'ffbfbfbf' }
  };
  CostCodeWorksheet.getCell(`C${endSummaryRow + 2}`).fill = priorMonth;
  CostCodeWorksheet.getCell(`C${endSummaryRow + 3}`).fill = priorMonth;
  CostCodeWorksheet.getCell(`C${endSummaryRow + 4}`).fill = priorMonth;
  CostCodeWorksheet.getCell(`C${endSummaryRow + 5}`).fill = priorMonth;
  CostCodeWorksheet.getCell(`C${endSummaryRow + 6}`).fill = priorMonth;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 2}`).fill = priorMonth;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 3}`).fill = priorMonth;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 4}`).fill = priorMonth;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 5}`).fill = priorMonth;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 6}`).fill = priorMonth;

  CostCodeWorksheet.getCell(`C${endSummaryRow + 8}`).value = `Current Month (${monthEndDate})`;
  CostCodeWorksheet.getRow(endSummaryRow + 8).font = {
    name: 'Arial Black',
    color: { argb: '00000000' },
    size: 9
  };
  CostCodeWorksheet.getCell(`C${endSummaryRow + 9}`).value = 'Contract Value';
  CostCodeWorksheet.getCell(`C${endSummaryRow + 10}`).value = 'Total Cost At Completion';
  CostCodeWorksheet.getCell(`C${endSummaryRow + 11}`).value = 'Profit';
  CostCodeWorksheet.getCell(`C${endSummaryRow + 12}`).value = 'Margin %';

  CostCodeWorksheet.getCell(`D${endSummaryRow + 9}`).value = contractValues?.FinancialApprovedContract || 0;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 10}`).value = { formula: `=+L${endOtherRow + 7}` };
  CostCodeWorksheet.getCell(`D${endSummaryRow + 11}`).value = { formula: `=+D${endSummaryRow + 9}-D${endSummaryRow + 10}` };
  CostCodeWorksheet.getCell(`D${endSummaryRow + 12}`).value = { formula: `=+D${endSummaryRow + 11}/D${endSummaryRow + 9}` };

  CostCodeWorksheet.getCell(`D${endSummaryRow + 9}`).numFmt = currency$;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 10}`).numFmt = currency$;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 11}`).numFmt = currency$;
  CostCodeWorksheet.getCell(`D${endSummaryRow + 12}`).numFmt = percent;

  // format worksheet and add formulas

  CostCodeWorksheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) {
      row.eachCell((cell) => {
        cell.font = {
          name: 'Arial Black',
          color: { argb: '00000000' },
          size: 12,
          underline: 'single'
        };
      });
    } else if (rowNumber === 2 || rowNumber === 3 || rowNumber === 4 || rowNumber === 6) {
      row.eachCell((cell, colNumber) => {
        if (colNumber === 1) {
          cell.font = {
            name: 'Arial ',
            size: 12
          };
        } else if (colNumber === 3) {
          cell.font = {
            name: 'Arial ',
            size: 10,
            underline: 'single'
          };
        } else if (colNumber === 4) {
          cell.font = {
            name: 'Arial Black',
            bold: true,
            size: 14,
            fgColor: 'FFEFFB1D'
          };
        } else {
          cell.font = {
            name: 'Arial ',
            size: 10
          };
        }
      });
    } else if (rowNumber > 7 && rowNumber < endLaborRow) {
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if ([1, 2, 6, 11, 12, 13].includes(colNumber)) cell.alignment = { horizontal: 'center' };
        if ([6, 11, 12, 13].includes(colNumber)) cell.numFmt = numberFormatRP1000s0D;
        else if ((colNumber > 4 && colNumber < 21) || colNumber === 22) cell.numFmt = currency$;
        if (colNumber === 7) cell.value = { formula: `ROUND(F${rowNumber}*E${rowNumber}*0.65,2)` };
        else if (colNumber === 8) cell.value = { formula: `ROUND(F${rowNumber}*E${rowNumber}*0.35,2)` };
        else if (colNumber === 9) cell.value = { formula: `F${rowNumber}*E${rowNumber}` };
        else if (colNumber === 13) cell.value = { formula: `K${rowNumber}+L${rowNumber}` };
        else if (colNumber === 14) {
          cell.value = {
            formula: `IF(L${rowNumber}=0,0,+O${rowNumber}/L${rowNumber})`
          };
        } else if (colNumber === 16) cell.value = { formula: `J${rowNumber} * K${rowNumber}` };
        else if (colNumber === 17) cell.value = { formula: `SUM(O${rowNumber}:P${rowNumber})` };
        else if (colNumber === 19) cell.value = { formula: `+I${rowNumber}-Q${rowNumber}` };
        else if (colNumber === 22) cell.value = { formula: `S${rowNumber}-T${rowNumber}` };
      });
    } else if ([endLaborRow, endLaborRow + 1, endLaborRow + 2].includes(rowNumber)) {
      row.eachCell((cell, colNumber) => {
        if ([6, 9, 11, 12, 13].includes(colNumber)) {
          cell.alignment = { horizontal: 'center' };
          cell.numFmt = numberFormatRP1000s0D;
        } else if (colNumber > 5 && colNumber < 23) cell.numFmt = currency$;
      });
    } else if (
      (rowNumber > materialTableStart && rowNumber < endMaterialRow) ||
      (rowNumber > subcontractorTableStart && rowNumber < endSubcontractorRows) ||
      (rowNumber > equipmentTableStart && rowNumber < endEquipmentRows) ||
      (rowNumber > otherTableStart && rowNumber < endOtherTableRows)
    ) {
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (colNumber === 12) cell.value = { formula: `SUM(J${rowNumber}:K${rowNumber})` };
        else if (colNumber === 14) cell.value = { formula: `+I${rowNumber}-L${rowNumber}` };
        else if (colNumber === 16) cell.value = { formula: `N${rowNumber}-O${rowNumber}` };
        if (colNumber > 8 && colNumber < 17) cell.numFmt = currency$;
      });
    }
  });

  colorBlue(CostCodeWorksheet, blueCells);

  workbook.xlsx.writeBuffer().then((r) => {
    saveAs(new Blob([r.buffer]), `${monthEndDate}, ${jobName}-Forecasting.xlsx`);
    toast.success(`Your Forecasting data for Month End Date of ${monthEndDate} successfully downloaded.`);
    setLoading(false);
  });
};

export default DownloadForecast;
