import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { dollarFormat, percentFormat, useFunctionCall } from '@aldridge/aldg-helpers';
import { Grid } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { _MonthEndDate } from '../../../_Recoil/atoms';
import existsWithLength from '../../../utils/existsWithLength';
import TextDisplay from '../../UIComponents/TextDisplay';

const AccountingSummaryME = (props) => {
  const { jctdscid } = props;
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  const { data, loading, error, functionCall } = useFunctionCall('AccountingDetail', { jctdscid, monthEnd: MonthEndDate, process: 'D' });

  useEffect(() => {
    let mounted = true;
    if (jctdscid !== '' && mounted) functionCall({ jctdscid, monthEnd: MonthEndDate, process: 'D' });
    return () => {
      mounted = false;
    };
  }, [jctdscid, MonthEndDate]);

  useEffect(() => {
    if (typeof error !== 'undefined') toast.error(error);
  }, [error]);

  const getColorCondition = () => {
    if (existsWithLength(data) && !loading) return data[0].ToDate < 0 ? '#cc0000' : '#000000';
    return '#000000';
  };

  return (
    <Grid container className='aldg-bordered'>
      <Grid item xs={12} className='aldg-headerTitle'>
        ACCOUNTING SUMMARY
      </Grid>
      <Grid item xs={12} className='aldg-subheaderTitle' style={{ paddingBottom: '9px' }}>
        MONTH END
      </Grid>
      <TextDisplay label='Billed' value={!loading && existsWithLength(data) ? dollarFormat(data[0].Billed, 0) : 'Loading...'} />
      <TextDisplay label='Paid' value={!loading && existsWithLength(data) ? dollarFormat(data[0].Paid, 0) : 'Loading...'} />
      <TextDisplay label='Retention' value={!loading && existsWithLength(data) ? dollarFormat(data[0].Retention, 0) : 'Loading...'} />
      <TextDisplay label='Retention %' value={!loading && existsWithLength(data) ? percentFormat(data[0].RetentionPercent, 0) : 'Loading...'} />
      <TextDisplay
        label='Month End Cash'
        value={!loading && existsWithLength(data) ? dollarFormat(data[0].ToDate, 0) : 'Loading...'}
        colorCondition={getColorCondition()}
      />
      <div style={{ margin: '9px' }} />
    </Grid>
  );
};

AccountingSummaryME.propTypes = {
  jctdscid: PropTypes.string.isRequired
};
AccountingSummaryME.defaultProps = {};

export default AccountingSummaryME;
