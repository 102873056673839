import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputSelect } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useRecoilValue } from 'recoil';
import { doc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { nonMuiThemes } from '../../../theme';
import { _MonthEndDate, _Preferences } from '../../../_Recoil/atoms';
import AldgList from '../../UIComponents/AldgList';
import existsWithLength from '../../../utils/existsWithLength';
import AldgModal from '../../UIComponents/AldgModal';
import { firestore } from '../../../firebase';

const ClickstreamModal = (props) => {
  const { name, setName, allClicks, jctdscid } = props;
  const [clickList, setClickList] = useState(allClicks[name] || []);
  const [linkToAdd, setLinkToAdd] = useState('');

  const Preferences = useRecoilValue(_Preferences);
  const MonthEndDate = useRecoilValue(_MonthEndDate);

  useEffect(() => {
    setClickList(allClicks[name] || []);
  }, [allClicks, name]);

  const cantSelect = ['Status Agenda', 'Financial Workbook']; // 'Quantity Forecasting',

  const selectOpts = Preferences?.filter((pref) => !cantSelect.includes(pref.title)).map((pref) => ({
    value: pref.href,
    label: pref.title
  }));

  const handleAdd = () => {
    const newClickList = JSON.parse(JSON.stringify(clickList));
    const linkLabel = selectOpts.find((opt) => opt.value === linkToAdd).label;
    if (newClickList.find((item) => item.key === linkToAdd.replace(/\W/g, ''))) return toast.error(`"${linkLabel}" is already in the list!`);
    newClickList.push({ key: linkToAdd.replace(/\W/g, ''), text: linkLabel, order: clickList.length });
    setClickList(newClickList);
    return true;
  };

  const handleClose = () => {
    setClickList([]);
    setName(undefined);
    setLinkToAdd('');
  };

  const handleSave = () => {
    setDoc(doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${MonthEndDate}/Agenda`, 'Clickstream'), { [name]: clickList }, { merge: true });
    toast.success('Clickstream saved!');
    handleClose();
  };

  const pieces = [
    {
      type: 'text',
      key: 'explanation',
      content: `Add modules you would like to go through during the ${name} portion of your Status presentation.`
    },
    {
      key: 'select',
      content: (
        <div style={nonMuiThemes.parentRL}>
          <InputSelect value={linkToAdd} onChange={(e) => setLinkToAdd(e.value || e.target.value)} options={selectOpts} name='Page Select' />
          <div style={{ marginRight: '-10px' }}>
            <ToolTipIconButton tooltipText='Add to Clickstream' icon={faPlus} onClick={handleAdd} />
          </div>
        </div>
      )
    },
    {
      key: 'list',
      content: <AldgList items={clickList} setItems={setClickList} />
    },
    {
      type: 'actions',
      key: 'save',
      buttons: [
        {
          text: 'Close',
          onClick: () => {
            handleClose();
          }
        },
        {
          text: 'Save',
          color: 'success',
          onClick: handleSave
        }
      ]
    }
  ];

  return <AldgModal open={existsWithLength(name)} title={`Clickstream for ${name}`} pieces={pieces} />;
};

ClickstreamModal.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func,
  allClicks: PropTypes.objectOf(PropTypes.any),
  jctdscid: PropTypes.string.isRequired
};
ClickstreamModal.defaultProps = {
  name: '',
  setName: () => {},
  allClicks: []
};

export default ClickstreamModal;
