import { existsWithLength } from '@aldridge/aldg-helpers';

const isEditor = (role) => role === 'Editor';
const isViewer = (role) => role === 'Viewer';
const isPM = (role) => role === 'PM';
const isSPM = (role) => role === 'SPM';
const isAgile = (role) => role === 'Agile';
const isExecutive = (role) => role === 'Executive';
const isQuality = (role) => role === 'Quality';
const isProjectAccountant = (role) => role === 'Project Accountant';
const isVP = (role) => role === 'VP';
const isDAM = (role) => role === 'DAM';
const isDM = (role) => role === 'DM';
const isBM = (role) => role === 'BM';
const isFinance = (role) => role === 'CFO' || role === 'EVP';

const SuperAdmin = (role) => role === 'SuperAdmin';
const AdminUp = (role) => SuperAdmin(role) || role === 'Admin';
const DivisionalUp = (role) => AdminUp(role) || ['Division', 'DAM', 'BUC', 'BM', 'VP', 'DM', 'SPM'].includes(role);
const PMUp = (role) => DivisionalUp(role) || role === 'PM';
const EditorUp = (role) => PMUp(role) || role === 'Editor';
const ViewerUp = (role) => EditorUp(role) || isAgile(role) || isExecutive(role) || isQuality(role) || isProjectAccountant(role) || role === 'Viewer';

const Unauthorized = (role) => !existsWithLength(role) || ['Unauthorized', 'NotSignedIn'].includes(role);

// Viewer = job specific
// Editor = job specific
// PM = ECMS Driven
// Admin (IT + Job Cost) = global from AD in user object

// Agile = global from user app assignment --> view only (for now)
// Quality = global from user app assignment --> view only

// VP = ECMS Driven
// VP = App Level By Division
// VP = Global Viewer

// Admin = IT + JC + BUC
// BackOfficeSupport = Able to edit certain fields
// PM = Owner of a Job (ECMS Driven)
// Editor = Allows a user to modify the Financials of a Job
// Viewer = Able to view the Financials of a Job
// User = Able to view the Project Information Dashboard for all Jobs

// eslint-disable-next-line no-unused-vars
const Security = {
  JobNumber: '1.0.300904',
  role: [] || ''
};

export {
  isEditor,
  isViewer,
  isPM,
  isSPM,
  isAgile,
  isQuality,
  isProjectAccountant,
  isDAM,
  isDM,
  isBM,
  isVP,
  isFinance,
  ViewerUp,
  EditorUp,
  PMUp,
  AdminUp,
  DivisionalUp,
  SuperAdmin,
  Unauthorized
};
