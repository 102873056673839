import { collection, doc, getDocs, orderBy, query, setDoc, where, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import existsWithLength from '../../../utils/existsWithLength';
import { isApproved } from '../../../utils/isPending';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { firestore } from '../../../firebase';
import aggregateChangeOrders from '../ChangeOrder/aggregateChangeOrders';
import {
  CostPerHrToDate,
  HoursAtComplete,
  LaborTotalCostAtCompletion,
  LaborVarianceAtCompletion,
  TotalCostAtCompletion,
  VarianceAtCompletion
} from './forecastCalcs';

const valueFromDoc = (obj, property, defaultVal = 0) => {
  // ensures no value gets set to undefined
  if (obj === -1 || typeof obj[property] === 'undefined') return defaultVal;
  return obj[property];
};
// const convertCostType = (costType) => (costType === 'R' ? 'E' : costType);

const calcTotal = (usePrev, prevForecastD, formula, costType) => {
  if (usePrev) return valueFromDoc(prevForecastD, 'TotalCostAtCompletion');
  if (costType === 'L')
    return LaborTotalCostAtCompletion({
      row: {
        HoursToComplete: formula.HoursToComplete,
        HoursToDate: formula.HoursToDate,
        CostHrToComplete: formula.CostHrToComplete,
        CostToDate: formula.CostToDate
      }
    });
  return TotalCostAtCompletion({ row: { CostToDate: formula.CostToDate, CostToComplete: formula.CostToComplete } });
};
const calcVar = (usePrev, prevForecastD, formula, costType) => {
  if (usePrev) return valueFromDoc(prevForecastD, 'VarianceAtCompletion');
  if (costType === 'L')
    return LaborVarianceAtCompletion({
      row: {
        HoursToComplete: formula.HoursToComplete,
        HoursToDate: formula.HoursToDate,
        CostHrToComplete: formula.CostHrToComplete,
        LaborTotal: formula.LaborTotal,
        CostToDate: formula.CostToDate
      }
    });

  return VarianceAtCompletion({
    row: { CostToDate: formula.CostToDate, CostToComplete: formula.CostToComplete, Total: formula.Total }
  });
};

const getPICC = (source) => {
  if (existsWithLength(source.PICC) && source.PICC.trim().length === 8) return source.PICC.trim();
  if (existsWithLength(source.Picc) && source.Picc.trim().length === 8) return source.Picc.trim();
  if (existsWithLength(source.PayItem) && source.PayItem.toString().trim().length === 3) {
    if (existsWithLength(source.SegmentValue) && source.SegmentValue.toString().trim().length === 5)
      return `${source.PayItem.toString().trim()}${source.SegmentValue.toString().trim()}`;
    if (existsWithLength(source.CostCodeDisplay) && source.CostCodeDisplay.includes('-'))
      return `${source.PayItem}${source.CostCodeDisplay.split('-')?.at(0).trim()}`;
  }
  return undefined;
};
const getCostType = (d) => {
  const numberAssign = { 1: 'L', 2: 'M', 3: 'S', 4: 'E', 5: 'O' };
  if (d.CostType === 'R') return 'E';
  if (d.CostType === 'B') return 'B';
  if (parseInt(String(d.SegmentValue)[0], 10) < 6) return numberAssign[String(d.SegmentValue)[0]] || '';
  if (existsWithLength(d.CostCode) && d.CostCode?.toString().trim().length === 5) return numberAssign[d.CostCode.toString()[0]] || '';
  return '';
};

const getValueFromCorrectSource = (usePrev, generating, prev, cur, val) => {
  if (generating.current.loading) {
    if (usePrev) return valueFromDoc(prev, val);
    return 0;
  }

  if (generating.current.refresh) return valueFromDoc(cur, val);
  return 0;
};

const generateJSON = (data, rbudget, prev, usePrev, formulaVariables, monthEndDate, curr) => ({
  PayItem: data.PayItem?.toString(),
  CostCode: data.SegmentValue?.toString() || data.CostCode, // ****
  jctmstid: data.jctmstid || -99, // ****
  Picc: getPICC(data), // ****
  PiccDescription: data.CostCodeDisplay?.substring(8) || data.Description || data.PiccDescription, // ****
  LaborRate: valueFromDoc(rbudget, 'LaborRate'),
  LaborHours: valueFromDoc(rbudget, 'LaborHours'),
  LaborCost: valueFromDoc(rbudget, 'LaborCost'),
  LaborBurden: valueFromDoc(rbudget, 'BurdenCost'),
  LaborTotal: formulaVariables.LaborTotal,
  HoursToDate: formulaVariables.HoursToDate,
  Total: formulaVariables.Total,
  CreatedBy: 'Autogenerated',
  CreatedDate: new Date().toJSON(),
  CostToDate: formulaVariables.CostToDate,
  CostHrToComplete: formulaVariables.CostHrToComplete,
  HoursToComplete: formulaVariables.HoursToComplete,
  CostToComplete: formulaVariables.CostToComplete,
  TotalCostAtCompletion: calcTotal(usePrev, prev, formulaVariables, getCostType(data)),
  TotalCostAtCompletionLastMonth: calcTotal(true, prev, formulaVariables, getCostType(data)),
  VarianceAtCompletion: calcVar(usePrev, prev, formulaVariables, getCostType(data)),
  HoursAtComplete: usePrev
    ? valueFromDoc(prev, 'HoursAtComplete')
    : HoursAtComplete({ row: { HoursToDate: formulaVariables.HoursToDate, HoursToComplete: formulaVariables.HoursToComplete } }), // ****
  CostPerHrToDate: usePrev
    ? valueFromDoc(prev, 'CostPerHrToDate')
    : CostPerHrToDate({
        row: { CostToDate: formulaVariables.CostToDate, HoursToDate: formulaVariables.HoursToDate }
      }), // ****
  PNP: data.ProductiveCostCode || '',
  VarianceLastMonth: valueFromDoc(prev, 'VarianceAtCompletion'),
  CustomerCostCode: valueFromDoc(rbudget, 'CustomerCostCode'),
  FriendlyName: valueFromDoc(rbudget, 'FriendlyName', '').toString(),
  CostType: getCostType(data),
  MonthEndDate: monthEndDate,
  Notes: usePrev ? valueFromDoc(prev, 'Notes', '') : curr?.Notes || '',
  RN: data.RN || -99,
  LastSynced: new Date().toJSON(),
  id: getPICC(data) // ****
});
// calculate difference of prev month 'Hours to Date' and 'Cost to Date' vs curr month
const calcDifference = (currDoc, prevDoc, property) => {
  let value = 0;
  value = valueFromDoc(currDoc, property) - valueFromDoc(prevDoc, property);
  return value;
};
const generateForecast = async (jctdscid, budgets, generating, monthEndDate, mostRecentMonth, refreshData, reloadData, user, showToast = true) => {
  // track records written
  let recordsWritten = 0;

  // double check budgets have been entered
  if (!existsWithLength(budgets)) {
    if (showToast) toast.error('No budgets exist for this month. Make sure budgets page is up to date before beginning forecasting.');
    return;
  }

  // roll up change orders and create revised budget
  const aggCOs = await aggregateChangeOrders(structuredClone(budgets), isApproved);

  const revisedBudget = [].concat.apply(
    [],
    [
      aggCOs.data.LaborChangeOrders.map((lco) => ({ ...lco, CostType: 'L' })),
      aggCOs.data.MaterialChangeOrders.map((lco) => ({ ...lco, CostType: 'M' })),
      aggCOs.data.EquipmentChangeOrders.map((lco) => ({ ...lco, CostType: 'E' })),
      aggCOs.data.SubcontractorsChangeOrders.map((lco) => ({ ...lco, CostType: 'S' })),
      aggCOs.data.OtherChangeOrders.map((lco) => ({ ...lco, CostType: 'O' }))
    ]
  );

  // get forecasting data for the current and previous months
  const currForecastQ = await getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/Forecasting`)));
  const currForecast = currForecastQ.docs.map((pfd) => pfd.data());
  const prevForecastQ = await getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${mostRecentMonth}/Forecasting`)));
  const prevForecast = prevForecastQ.docs.map((pfd) => pfd.data());
  const usePrev = generating.current.previous;

  // get all PICCs
  const q = query(collection(firestore, 'ENT-Piccs'), where('jobid', '==', jctdscid), orderBy('RN'));
  getDocs(q).then(async (s) => {
    const allS = s.docs.map((ss) => ss.data());

    console.log(currForecast, prevForecast, allS);
    let batch = writeBatch(firestore);

    // START generating from ENT-PICCS
    for (let i = 0; i < allS.length; i++) {
      const data = allS[i];

      // write to firestore in batches of 500 (max size)
      if (i % 500 === 0 && i !== 0) {
        recordsWritten += 500;
        // eslint-disable-next-line no-await-in-loop
        await batch.commit();
        batch = writeBatch(firestore);
      }
      // if there is a PICC and it's a valid cost type, generate a forecasting entry
      if (existsWithLength(getPICC(data)) && !['I', 'B', '99999'].includes(getCostType(data)) && getCostType(data) === data.CostType) {
        const newDoc = doc(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/Forecasting`), getPICC(data));

        // get the revised budget, refreshed wip data, and current/previous forecast data for this PICC
        const revisedBudgetDoc = revisedBudget.find((co) => getPICC(co) === getPICC(data)) || -1;
        const refreshDoc = refreshData?.find((co) => getPICC(co) === getPICC(data) && co.CostType === getCostType(data)) || -1;
        const prevForecastDoc = prevForecast.find((pf) => getPICC(pf) === getPICC(data) && getCostType(pf) === getCostType(data)) || -1;
        const currForecastDoc = currForecast.find((cf) => getPICC(cf) === getPICC(data) && getCostType(cf) === getCostType(data)) || -1;

        // caluclate some useful values
        const formulaVariables = {
          HoursToDate: valueFromDoc(refreshDoc, 'HoursToDate'),
          CostToDate: valueFromDoc(refreshDoc, 'CostToDate'),
          HoursToComplete: getValueFromCorrectSource(usePrev, generating, prevForecastDoc, currForecastDoc, 'HoursToComplete'),
          HoursAtComplete: getValueFromCorrectSource(usePrev, generating, prevForecastDoc, currForecastDoc, 'HoursAtComplete'),
          CostHrToComplete: getValueFromCorrectSource(usePrev, generating, prevForecastDoc, currForecastDoc, 'CostHrToComplete'),
          CostToComplete: getValueFromCorrectSource(usePrev, generating, prevForecastDoc, currForecastDoc, 'CostToComplete'),
          LaborTotal: valueFromDoc(revisedBudgetDoc, 'LaborTotal'),
          Total: valueFromDoc(revisedBudgetDoc, 'Total')
        };
        // create the new record and add it to the batch
        const json = generateJSON(data, revisedBudgetDoc, prevForecastDoc, usePrev, formulaVariables, monthEndDate, currForecastDoc);
        // if (getPICC(data) === '10032003') {
        //   console.log('here');
        //   console.log(getCostType(data), data.CostType);
        //   console.log(revisedBudgetDoc);
        //   console.log(refreshDoc);
        //   console.log(prevForecastDoc);
        //   console.log(currForecastDoc);
        //   console.log(json);
        // }
        batch.set(newDoc, json, { merge: true });
      }
    }
    // END generating from ENT-PICCS

    // START generating from budgets
    const isSamePicc = (a, b) => getPICC(a) === getPICC(b);
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter((leftValue) => !right.some((rightValue) => compareFunction(leftValue, rightValue)));

    // if budgets exist with cost codes not in ENT-Piccs, add values from there
    const revisedBudgets = onlyInLeft(revisedBudget, allS, isSamePicc);
    revisedBudgets.forEach((rBudget) => {
      const currForecastDoc = currForecast.find((cf) => getPICC(cf) === getPICC(rBudget)) || -1;
      const prevForecastDoc = prevForecast.find((cf) => getPICC(cf) === getPICC(rBudget)) || -1;
      const formulaVariables = {
        HoursToDate: valueFromDoc(currForecastDoc, 'HoursToDate'),
        CostToDate: valueFromDoc(currForecastDoc, 'CostToDate'),
        LaborTotal: valueFromDoc(rBudget, 'LaborTotal'),
        Total: valueFromDoc(rBudget, 'Total'),
        CostHrToComplete: getValueFromCorrectSource(usePrev, generating, prevForecastDoc, currForecastDoc, 'CostHrToComplete'),
        HoursToComplete: getValueFromCorrectSource(usePrev, generating, prevForecastDoc, currForecastDoc, 'HoursToComplete'),
        HoursAtComplete: getValueFromCorrectSource(usePrev, generating, prevForecastDoc, currForecastDoc, 'HoursAtComplete'),
        CostToComplete: getValueFromCorrectSource(usePrev, generating, prevForecastDoc, currForecastDoc, 'CostToComplete')
      };
      if (getPICC(currForecastDoc) === '10032003') console.log(formulaVariables);

      if (existsWithLength(getPICC(rBudget))) {
        const newDoc = doc(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/Forecasting`), getPICC(rBudget));

        const json = generateJSON(rBudget, rBudget, prevForecastDoc, usePrev, formulaVariables, monthEndDate, currForecastDoc);
        batch.set(newDoc, json, { merge: true });
      }
    });

    // any picc currently in the forecast that was removed from a budget or in a budget no longer marked as approved
    const piccsToRemove = onlyInLeft(
      currForecast.filter(
        (cf) => !allS.map((as) => getPICC(as)).includes(getPICC(cf)) && !refreshData.map((rd) => getPICC(rd)).includes(getPICC(cf))
      ),
      revisedBudget,
      (a, b) => getPICC(a) === getPICC(b)
    );
    piccsToRemove.forEach((piccToRemove) => {
      batch.delete(doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/Forecasting`, getPICC(piccToRemove)));
    });
    recordsWritten += batch._mutations.length;
    await batch.commit();
    reloadData();
    setDoc(
      doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}`),
      { ForecastLastUpdated: new Date().toJSON(), ForecastLastUpdatedBy: user.email },
      { merge: true }
    );
    if (showToast) toast.success(`${NaNtoZero(recordsWritten)} records written to Forecasting.`);
    generating.current.loading = false;
    generating.current.refresh = false;
  });
};

const AutoCalculateForecast = async (jctdscid, monthEndDate, mostRecentMonth, reloadData, setUploadingForecast, user, showToast = true) => {
  // double check budgets have been entered
  const currForecastQ = await getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/Forecasting`)));
  const currForecast = currForecastQ.docs.map((pfd) => [pfd, pfd.data()]);
  const prevForecastQ = await getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${mostRecentMonth}/Forecasting`)));
  const prevForecast = prevForecastQ.docs.map((pfd) => pfd.data());

  let batch = writeBatch(firestore);
  let recordsWritten = 0;
  for (let i = 0; i < currForecast.length; i++) {
    if (i % 500 === 0 && i !== 0) {
      // eslint-disable-next-line no-await-in-loop
      await batch.commit();
      batch = writeBatch(firestore);
    }
    const [docRef, docData] = currForecast[i];
    const prevForecastDoc = prevForecast.find((pf) => getPICC(pf) === getPICC(docData)) || -1;

    const formulaVariables = {
      HoursToDate: valueFromDoc(docData, 'HoursToDate'),
      CostToDate: valueFromDoc(docData, 'CostToDate'),
      LaborTotal: valueFromDoc(docData, 'LaborTotal'),
      Total: valueFromDoc(docData, 'Total'),
      CostHrToComplete: valueFromDoc(docData, 'CostHrToComplete'),
      HoursToComplete: valueFromDoc(docData, 'HoursToComplete'),
      CostToComplete: valueFromDoc(docData, 'CostToComplete'),
      HoursDiff: calcDifference(docData, prevForecastDoc, 'HoursToDate'),
      CostDiff: calcDifference(docData, prevForecastDoc, 'CostToDate')
    };
    const currHoursToComplete = valueFromDoc(docData, 'HoursToComplete');
    const currCostToComplete = valueFromDoc(docData, 'CostToComplete');

    if (!existsWithLength(docData.ModifiedBy)) {
      if (mostRecentMonth === '2010-12-31') {
        if (
          getCostType(docData) === 'L' &&
          docData.HoursToComplete === 0 &&
          docData.CostHrToComplete === 0 &&
          docData.LaborHours > 0 &&
          docData.LaborRate > 0
        ) {
          docData.HoursToComplete = valueFromDoc(docData, 'LaborHours');
          docData.CostHrToComplete = valueFromDoc(docData, 'LaborRate');
          docData.ModifiedBy = `AutoCalculated-${user.email ? user.email : null}`;
          docData.ModifiedDate = new Date().toJSON();
          recordsWritten += 1;
          batch.set(docRef.ref, docData, { merge: true });
        }
        if (getCostType(docData) !== 'L' && docData.CostToComplete === 0 && docData.Total > 0) {
          docData.CostToComplete = valueFromDoc(docData, 'Total');
          docData.ModifiedBy = `AutoCalculated-${user.email ? user.email : null}`;
          docData.ModifiedDate = new Date().toJSON();
          recordsWritten += 1;
          batch.set(docRef.ref, docData, { merge: true });
        }
      } else {
        if (valueFromDoc(docData, 'CostType') === 'L' && currHoursToComplete > 0 && formulaVariables.HoursDiff > 0) {
          docData.HoursToComplete = currHoursToComplete - formulaVariables.HoursDiff;
          docData.HoursToComplete = docData.HoursToComplete < 0 ? 0 : docData.HoursToComplete;
          docData.TotalCostAtCompletion = calcTotal(false, null, formulaVariables, getCostType(docData));
          docData.VarianceAtCompletion = calcVar(false, null, formulaVariables, getCostType(docData));

          docData.ModifiedBy = `AutoCalculated-${user.email ? user.email : null}`;
          docData.ModifiedDate = new Date().toJSON();
          docData.Notes = docData.Notes || '';
          recordsWritten += 1;

          batch.set(docRef.ref, docData, { merge: true });
        }
        if (valueFromDoc(docData, 'CostType') !== 'L' && currCostToComplete > 0 && formulaVariables.CostDiff > 0) {
          docData.CostToComplete = currCostToComplete - formulaVariables.CostDiff;
          docData.CostToComplete = docData.CostToComplete < 0 ? 0 : docData.CostToComplete;
          docData.TotalCostAtCompletion = calcTotal(false, null, formulaVariables, getCostType(docData));
          docData.VarianceAtCompletion = calcVar(false, null, formulaVariables, getCostType(docData));

          docData.ModifiedBy = `AutoCalculated-${user.email ? user.email : null}`;
          docData.ModifiedDate = new Date().toJSON();
          recordsWritten += 1;

          docData.Notes = docData.Notes || '';
          batch.set(docRef.ref, docData, { merge: true });
        }
      }
    }
  }
  await batch.commit();
  setUploadingForecast(false);
  reloadData();
  if (showToast) toast.success(`${NaNtoZero(recordsWritten)} records CTC Calculated based on last month's data.`);
};

export { generateForecast, AutoCalculateForecast };
