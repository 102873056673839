import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Button, useMediaQuery, createTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen } from '@fortawesome/pro-regular-svg-icons';

const ToggleEditCard = (props) => {
  const { section, toggleEdit, handleSave, edit, color, allowWrap, allowEdit } = props;

  const beingEdited = () => edit.includes(section.title);
  const matches = useMediaQuery(createTheme().breakpoints.down('md'));

  const onClick = () => {
    if (beingEdited()) handleSave();
    toggleEdit(section.title);
  };

  return (
    <Card variant='outlined' key={section.title} style={{ marginBottom: '12px', backgroundColor: color, border: 'none' }}>
      <CardHeader
        style={{ paddingBottom: '0px', fontFamily: 'system-ui', padding: matches && '12px 8px 12px 0px' }}
        title={
          <div>
            <div style={{ letterSpacing: '-1.5px' }}>{section.title}</div>
            {color === '#dedede' && <hr />}
          </div>
        }
        action={
          allowEdit && (
            <Button
              variant='contained'
              onClick={onClick}
              color={beingEdited() ? 'success' : 'primary'}
              style={{ maxWidth: '35px', minWidth: '35px', height: '35px', borderRadius: '50%', fontSize: '1.25rem' }}
            >
              <FontAwesomeIcon icon={beingEdited() ? faCheck : faPen} />
            </Button>
          )
        }
      />
      <CardContent style={{ paddingBottom: color === '#dedede' && '0px', padding: matches && '0px 0px 8px 0px' }}>
        {React.cloneElement(section.component, { allowWrap })}
      </CardContent>
    </Card>
  );
};

ToggleEditCard.propTypes = {
  section: PropTypes.object,
  toggleEdit: PropTypes.func,
  handleSave: PropTypes.func,
  edit: PropTypes.string,
  color: PropTypes.string,
  allowWrap: PropTypes.bool,
  allowEdit: PropTypes.bool
};
ToggleEditCard.defaultProps = {
  section: { title: 'default', component: <div>default</div> },
  toggleEdit: () => {},
  handleSave: () => {},
  edit: '',
  color: 'white',
  allowWrap: false,
  allowEdit: false
};

export default ToggleEditCard;
