import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';

export const basicFont = { font: { name: 'Arial', size: 10 } };
export const headerFont = { name: 'Arial ', color: { argb: '00000000' }, size: 12, bold: true };
export const numberFormatRP1000s0D = '#,##0;[Red](#,##0);0;'; // number, red parentheses when negative, 1000s seperators, no decimals
export const numberFormatAccounting = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'; // default accounting format, no $
export const numberFormatAccounting$ = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)'; // default accounting format, with $
export const currency$ = '$#,##0.00_);[Red]($#,##0.00)'; // default $ currency format
export const percent = '#,##0.00%_);[Red](#,##0.00%)'; // default $ currency format
export const doubleBorder = {
  top: { style: 'double', color: { argb: '00000000' } },
  bottom: { style: 'double', color: { argb: '00000000' } }
};

export const parseCellVal = (cell) => {
  if (typeof cell.value === 'object') return cell.value.result;
  return cell.value;
};

export const generalOverview = async (JobID) => {
  let jobName;
  const q = await getDocs(query(collection(firestore, 'ENT-Jobs'), where('id', '==', JobID)));
  q.forEach((ji) => {
    jobName = ji.data().CosentialJobName;
  });
  return jobName;
};

export const createTotalRow = (sheetName, letters, startRow, endRow) => {
  sheetName.getCell(`D${endRow}`).value = 'TOTAL:';
  sheetName.getCell(`D${endRow}`).alignment = { horizontal: 'right' };
  sheetName.getRow(endRow).border = { top: { style: 'thick' } };
  letters.forEach((letter) => {
    sheetName.getCell(`${letter}${endRow}`).value = { formula: `SUM(${letter}${startRow}:${letter}${endRow - 1})` };
    sheetName.getCell(`${letter}${endRow}`).numFmt = currency$;
  });
};

export const formatSubTableHeader = (sheetName, startRow) => {
  sheetName.getRow(startRow).border = doubleBorder;
  sheetName.getRow(startRow).font = {
    name: 'Arial Black',
    color: { argb: '00000000' },
    size: 9
  };
  sheetName.getRow(startRow).alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
    shrinkToFit: true
  };
};

export const createTable = (sheetName, tableStart, tableName, tableRows, valueList, letters) => {
  formatSubTableHeader(sheetName, tableStart);
  sheetName.getCell(`A${tableStart - 1}`).value = tableName;
  sheetName.getCell(`A${tableStart - 1}`).font = headerFont;

  sheetName.getRow(tableStart).values = valueList;
  sheetName.addRows(tableRows);

  const endRow = sheetName.lastRow._number + 1;
  createTotalRow(sheetName, letters, tableStart + 1, endRow);
  return endRow;
};

export const createSegmentTable = (workbook, Segments, SegCount) => {
  const CostCodeWorksheet = workbook.addWorksheet('Cost Codes');

  CostCodeWorksheet.columns = [
    { header: 'Segment Value', key: 'SegmentValue', width: 10 }, // A
    { header: 'Lookup Value', key: 'LookupValue', width: 20 }, // B
    { header: 'jctccsid', key: 'jctccsid', width: 10 }, // C
    { header: 'Company', key: 'Company', width: 10 }, // D
    { header: 'Division', key: 'Division', width: 13 }, // E
    { header: 'Segment Position', key: 'SegmentPosition', width: 15 }, // F
    { header: 'Segment Description', key: 'SegmentDescription', width: 20 }, // G
    { header: 'Cost Type', key: 'CostType', width: 10 }, // H
    { header: 'PNP', key: 'ProductiveCostCode', width: 6 } // I
  ];
  CostCodeWorksheet.addRows(Segments?.sort((a, b) => (a.CostType > b.CostType ? 1 : -1)));
  CostCodeWorksheet.fillFormula(`B2:B${SegCount + 1}`, 'A2&" - "&G2&"("&H2&")"', (row) => row);

  CostCodeWorksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      if (colNumber === 1 && rowNumber !== 1) cell.value = { formula: `ROUND(VALUE(${cell.value}), 0)` };
    });
  });
};

export const createSuppDescriptionTable = (workbook, Segments, SegCount) => {
  const CostCodeWorksheet = workbook.addWorksheet('Supplementary Descriptions');

  CostCodeWorksheet.columns = [
    { header: 'PICC', key: 'PICC', width: 10 }, // A
    { header: 'Lookup Value', key: 'LookupValue', width: 20 }, // B
    { header: 'jctmstid', key: 'jctmstid', width: 10 }, // C
    { header: 'Description', key: 'Description', width: 20 }, // D
    { header: 'Supplementary Description', key: 'FriendlyName', width: 20 }, // E
    { header: 'Cost Type', key: 'CostType', width: 10 }, // F
    { header: 'PNP', key: 'ProductiveCostCode', width: 6 } // G
  ];
  CostCodeWorksheet.addRows(Segments?.sort((a, b) => a.PICC - b.PICC));
  CostCodeWorksheet.fillFormula(`B2:B${SegCount + 1}`, 'A2&" - "&D2&"("&F2&")"', (row) => row);

  CostCodeWorksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      if (colNumber === 1 && rowNumber !== 1) cell.value = { formula: `ROUND(VALUE(${cell.value}), 0)` };
    });
  });
};
