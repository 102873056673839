import React from 'react';
import PropTypes from 'prop-types';
import existsWithLength from '../../utils/existsWithLength';

const InputToggleList = (props) => {
  const { edit, value, name, label, RepeatableListComponent } = props;

  return (
    <div style={{ padding: edit && '1.1px 8px', width: '100%' }}>
      {edit ? (
        <div style={{ outline: '12px solid #dce8ff', borderRadius: '4px' }}>{RepeatableListComponent} </div>
      ) : (
        <div style={{ width: '100%', padding: '0px 4px' }}>
          <div style={{ width: '100%', color: '#a9a9a9', paddingLeft: '4px', fontStyle: 'italic', fontWeight: !existsWithLength(value) && 'bold' }}>
            {`${label}`}:
          </div>
          <ul style={{ marginBottom: '0px', lineHeight: '16px', textAlign: 'right', paddingRight: '16px' }} dir='rtl'>
            {value.map((val) => (
              <li key={val[name] || 'default'}>{val[name]}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

InputToggleList.propTypes = {
  edit: PropTypes.bool,
  value: PropTypes.array,
  name: PropTypes.string,
  label: PropTypes.string,
  RepeatableListComponent: PropTypes.object.isRequired
};
InputToggleList.defaultProps = {
  edit: false,
  value: [],
  name: '',
  label: ''
};

export default InputToggleList;
