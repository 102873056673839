import { RecoilRoot } from 'recoil';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Loader from 'react-loaders';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import ChangelogModal from './_GlobalComponents/Changelog/ChangelogModal';
import StatusProvider from './providers/StatusProvider';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import './_assets/base.scss';
import Header from './_GlobalComponents/Header';
import Footer from './_GlobalComponents/Footer';
import Application from './Pages/Application';
import UserProvider from './providers/UserProvider';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AldgTheme } from './theme';
import 'react-toastify/dist/ReactToastify.css';
import { config } from './firebase';

import NotesPopout from './Pages/NotesPopout';

const rootElement = document.getElementById('root');
const renderApp = () => {
  const configClass = `${config.projectId.indexOf('-dev') > -1 ? 'bg-dev' : ''} ${
    config.projectId.indexOf('-training') > -1 ? 'bg-training' : ''
  }`.trim();
  const root = createRoot(rootElement);
  root.render(
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={AldgTheme}>
        <UserProvider>
          <RecoilRoot>
            <StatusProvider>
              <NotesPopout />
              <BrowserRouter>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ToastContainer
                    position='bottom-left'
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='dark'
                  />
                  <Suspense
                    fallback={
                      <div className='loader-container'>
                        <div className='loader-container-inner'>
                          <div className='text-center'>
                            <Loader type='ball-grid-cy' />
                          </div>
                          <h6 className='mt-3'>Please wait while we load the Application</h6>
                        </div>
                      </div>
                    }
                  >
                    <div
                      id='appParent'
                      className={configClass}
                      style={{
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}
                    >
                      <ChangelogModal />
                      <Header appTitle='Project Information' />
                      <Application />
                      <Footer version='1.2.1' copy={`${new Date().getFullYear()} Aldridge, All Rights Reserved`} />
                      <ServiceWorkerWrapper />
                    </div>
                  </Suspense>
                </LocalizationProvider>
              </BrowserRouter>
            </StatusProvider>
          </RecoilRoot>
        </UserProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
renderApp();
// serviceWorkerRegistration.register();
