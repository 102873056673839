import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNote } from '@fortawesome/pro-regular-svg-icons';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const NotesButton = (props) => {
  const { note } = props;
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip title={note} onClose={handleTooltipClose} open={open} placement='left'>
          <Button
            variant='contained'
            color='primary'
            onClick={handleTooltipOpen}
            style={{
              maxHeight: '30px',
              minHeight: '30px',
              maxWidth: '25px',
              minWidth: '25px',
              fontSize: '14px',
              borderRadius: '50%',
              backgroundColor: note.length === 0 && 'gray'
            }}
          >
            <FontAwesomeIcon icon={faNote} />
          </Button>
        </Tooltip>
      </ClickAwayListener>
    </div>
  );
};

NotesButton.propTypes = {
  note: PropTypes.string
};
NotesButton.defaultProps = {
  note: ''
};

export default NotesButton;
