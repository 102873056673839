import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, createTheme, useMediaQuery } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { round } from 'lodash';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { useIsFirstRender } from '@aldridge/aldg-helpers';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { config } from '../../../firebase';
import { formatAsCurrency, formatWithCommas } from '../../UIComponents/format';
import NotesButton from '../../UIComponents/NotesButton';
import existsWithLength from '../../../utils/existsWithLength';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { genSortComparator } from './budgetSort';
import { _GridPagination } from '../../../_Recoil/atoms';

const BudgetDataGrid = (props) => {
  const { costCodeType, title, setRecord, record, editable, editing, setEditing } = props;
  const isFirstRender = useIsFirstRender();
  const apiRef = useGridApiRef();

  const labor = costCodeType === 'LaborChangeOrders';

  const rows = record.data[costCodeType];

  const [editedRows, setEditedRows] = useState(rows);
  // eslint-disable-next-line no-unused-vars
  const [GridPagination, setGridPagination] = useRecoilState(_GridPagination);

  const getRowID = (r) => (existsWithLength(r.PayItem) && existsWithLength(r.CostCode) ? `${r.PayItem}${r.CostCode[0]}` : r.id);

  const matches = useMediaQuery(createTheme().breakpoints.down('md'));
  const laborMatches = useMediaQuery(createTheme().breakpoints.down('lg'));

  const getFlex = (f) => {
    if ((labor && laborMatches) || (!labor && matches) || editing) return null;
    return f;
  };
  const getWidth = (w) => {
    if (editing) return w * 2;
    if ((labor && laborMatches) || (!labor && matches)) return w;
    return null;
  };

  const generateCols = () => {
    const columns = [
      {
        field: 'PayItem',
        headerAlign: 'left',
        headerName: 'Pay Item',
        valueGetter: (params) => params.row.PayItem,
        flex: getFlex(25),
        width: getWidth(60),
        canEdit: true,
        inputType: 'InputNumber'
      },
      {
        field: 'CostCode',
        headerAlign: 'center',
        headerName: 'Cost Code',
        valueGetter: (params) => params.row.CostCodeDisplay,
        canEdit: true,
        inputType: 'InputAutocomplete',
        config,
        firestoreOptions: {
          collection: 'ENT-Segments',
          where: [
            ['CostType', '==', costCodeType.substring(0, 1)],
            ['Status', '==', 'Yes']
          ],
          orderBy: 'SegmentValue',
          valueKey: 'jctccsid',
          method: 'onSnapshot'
        },
        filterDataset: (dataopts, params) => {
          const finalOpts = [];
          const allIDs = apiRef?.current?.getAllRowIds() || [];
          dataopts.forEach((opt) => {
            if (!allIDs.includes(`${params?.row?.PayItem || ''}${opt.jctccsid}`) || opt.jctccsid === params?.row?.CostCode[0] || '')
              finalOpts.push(opt);
          });
          return finalOpts;
        },
        optionDisplay: ['option_SegmentValue', '-', 'option_SegmentDescription'],
        autorecordFields: ['SegmentValue', 'ProductiveCostCode'],
        flex: getFlex(80),
        width: getWidth(250)
      }
    ];
    columns.push({
      field: 'FriendlyName',
      headerAlign: 'center',
      headerName: 'Supplementary Description',
      valueGetter: (params) => params.row.FriendlyName,
      canEdit: true,
      flex: getFlex(40),
      width: getWidth(150),
      sortable: false
    });
    columns.push({
      field: 'CustomerCostCode',
      headerAlign: 'center',
      headerName: 'Customer Cost Code',
      valueGetter: (params) => params.row.CustomerCostCode,
      canEdit: true,
      flex: getFlex(40),
      width: getWidth(100),
      sortable: false
    });
    if (labor) {
      columns.push({
        field: 'LaborRate',
        headerAlign: 'center',
        align: 'right',
        headerName: 'Rate',
        valueGetter: (params) => params.row.LaborRate,
        valueFormatter: (params) => formatAsCurrency(params.value),
        sortComparator: (param1, param2) => genSortComparator(param1, param2),
        canEdit: true,
        aggregable: false,
        type: 'number',
        onChangeDependents: ['LaborCost', 'BurdenCost', 'LaborTotal'],
        inputType: 'InputNumber',
        flex: getFlex(40),
        width: getWidth(90)
      });
      columns.push({
        field: 'LaborHours',
        headerAlign: 'center',
        align: 'right',
        headerName: 'Hours',
        valueGetter: (params) => NaNtoZero(params.row.LaborHours),
        sortComparator: (param1, param2) => genSortComparator(param1, param2),
        valueFormatter: (params) => formatWithCommas(params.value),
        canEdit: true,
        aggregable: true,
        type: 'number',
        availableAggregationFunctions: ['sum'],
        onChangeDependents: ['LaborCost', 'BurdenCost', 'LaborTotal'],
        inputType: 'InputNumber',
        flex: getFlex(40),
        width: getWidth(90)
      });
      columns.push({
        field: 'LaborCost',
        headerAlign: 'center',
        align: 'right',
        headerName: 'Cost',
        aggregable: true,
        type: 'number',
        canEdit: record.data.ChangeOrderType === 'Change Order Escalation',
        availableAggregationFunctions: ['sum'],
        valueGetter: (params) =>
          record.data.ChangeOrderType === 'Change Order Escalation'
            ? params.row.LaborCost || params.row.LaborTotal * 0.65
            : +round(NaNtoZero(params.row.LaborRate * params.row.LaborHours * 0.65), 2).toFixed(2),
        valueFormatter: (params) => formatAsCurrency(params.value),
        sortComparator: (param1, param2) => genSortComparator(param1, param2),
        flex: getFlex(60),
        width: getWidth(110)
      });
      columns.push({
        field: 'BurdenCost',
        headerAlign: 'center',
        align: 'right',
        headerName: 'Burden',
        aggregable: true,
        type: 'number',
        availableAggregationFunctions: ['sum'],
        valueGetter: (params) =>
          record.data.ChangeOrderType === 'Change Order Escalation'
            ? params.row.BurdenCost || params.row.LaborTotal * 0.35
            : +round(NaNtoZero(params.row.LaborRate * params.row.LaborHours * 0.35), 2).toFixed(2),
        valueFormatter: (params) => formatAsCurrency(params.value),
        sortComparator: (param1, param2) => genSortComparator(param1, param2),
        flex: getFlex(50),
        width: getWidth(120)
      });
      columns.push({
        field: 'LaborTotal',
        headerAlign: 'center',
        align: 'right',
        headerName: 'Labor Total',
        aggregable: true,
        type: 'number',
        availableAggregationFunctions: ['sum'],
        canEdit: record.data.ChangeOrderType === 'Change Order Escalation',
        inputType: 'InputNumber',
        valueGetter: (params) => {
          try {
            return record.data.ChangeOrderType === 'Change Order Escalation'
              ? NaNtoZero(params.row.LaborTotal) || NaNtoZero(params.row.LaborCost) + NaNtoZero(params.row.BurdenCost)
              : +round(NaNtoZero(params.row.LaborRate * params.row.LaborHours), 2).toFixed(2);
          } catch (err) {
            return 0;
          }
        },
        valueFormatter: (params) => formatAsCurrency(params.value),
        sortComparator: (param1, param2) => genSortComparator(param1, param2),
        flex: getFlex(60),
        width: getWidth(150)
      });
      columns.push({
        field: 'PNP',
        headerAlign: 'center',
        align: 'right',
        headerName: 'P/NP',
        valueGetter: (params) => params.row.ProductiveCostCode || '',
        sortComparator: (param1, param2) => genSortComparator(param1, param2),
        flex: getFlex(20),
        width: getWidth(50)
      });
    } else
      columns.push({
        field: 'Total',
        headerAlign: 'center',
        align: 'right',
        aggregable: true,
        type: 'number',
        availableAggregationFunctions: ['sum'],
        valueGetter: (params) => NaNtoZero(params.row.Total),
        sortComparator: (param1, param2) => genSortComparator(param1, param2),
        valueFormatter: (params) => formatAsCurrency(params.value),
        canEdit: true,
        inputType: 'InputNumber',
        flex: getFlex(100),
        width: getWidth(70)
      });
    columns.push({
      field: 'Notes',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.Notes,
      renderCell: (params) => !editing && <NotesButton note={params.value} />,
      canEdit: true,
      inputType: 'InputTextarea',
      flex: getFlex(25),
      width: editing ? getWidth(120) : getWidth(60),
      sortable: false
    });
    return columns;
  };

  const columns = generateCols();

  useEffect(() => {
    let mounted = true;
    if (!isFirstRender) {
      const updatedRecord = JSON.parse(JSON.stringify(record));
      const validRows = editedRows.filter((er) => existsWithLength(er.PayItem) && existsWithLength(er.CostCode));
      if (validRows.length !== editedRows.length) {
        if (mounted) setEditedRows(validRows);
        toast.warn(
          'Some rows do not contain a pay item and/or a cost code and will not be saved. Double to check that all data is correctly entered.',
          { autoclose: 5000 }
        );
      }
      updatedRecord.data[costCodeType] = validRows;
      if (mounted && JSON.stringify(validRows) !== JSON.stringify(record.data[costCodeType])) setRecord(updatedRecord);
    }
    return () => {
      mounted = false;
    };
  }, [editedRows]);

  useEffect(() => {
    let mounted = true;
    if (mounted) setEditedRows(rows);
    return () => {
      mounted = false;
    };
  }, [rows]);

  return (
    <Grid item xs={12} id={`${title}RevisedBudgetTable`}>
      <AldgDataGrid
        containerHeight={rows.length > 11 ? '750px' : undefined}
        pageSizeOptions={[10, 20, 30]}
        aggregationModel={{
          LaborHours: 'sum',
          BurdenCost: 'sum',
          LaborCost: 'sum',
          LaborTotal: 'sum',
          Total: 'sum'
        }}
        apiRef={apiRef}
        columns={columns}
        confirmDiscard
        editable={editable}
        editingOverride={editing}
        setEditingOverride={setEditing}
        getRowId={(r) => getRowID(r)}
        rows={editedRows}
        setRows={setEditedRows}
      />
    </Grid>
  );
};

BudgetDataGrid.propTypes = {
  costCodeType: PropTypes.string,
  editing: PropTypes.bool,
  title: PropTypes.string,
  record: PropTypes.object,
  setRecord: PropTypes.func,
  editable: PropTypes.bool,
  setEditing: PropTypes.func
};
BudgetDataGrid.defaultProps = {
  costCodeType: 'LaborChangeOrders',
  editing: false,
  title: '',
  record: { data: {} },
  setRecord: () => {},
  editable: false,
  setEditing: () => {}
};

export default BudgetDataGrid;
