import React from 'react';
import PropTypes from 'prop-types';
import { InputText } from '@aldridge/aldg-data-components';
import { useMediaQuery } from '@mui/material';
import TextDisplay from './TextDisplay';
import { nonMuiThemes, AldgTheme } from '../../theme';

const InputToggle = (props) => {
  const { edit, name, value, onChange, label, allowWrap } = props;
  const matches = useMediaQuery(AldgTheme.breakpoints.down('md'));

  return (
    <div style={edit ? nonMuiThemes.InputToggleOuterEdit : nonMuiThemes.InputToggleOuter}>
      {edit ? (
        <div style={allowWrap || matches ? nonMuiThemes.InputToggleTextContainerWrap : nonMuiThemes.InputToggleTextContainer}>
          <div style={allowWrap || matches ? nonMuiThemes.InputToggleTextWrap : nonMuiThemes.InputToggleText}>
            {label}
            {label !== '' ? ':' : ''}
          </div>
          <div style={nonMuiThemes.InputToggleValue}>
            <InputText name={name} value={value} onChange={onChange} />
          </div>
        </div>
      ) : (
        <TextDisplay editable label={` ${label}`} value={value} />
      )}
    </div>
  );
};

InputToggle.propTypes = {
  edit: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  allowWrap: PropTypes.bool
};
InputToggle.defaultProps = {
  edit: false,
  name: '',
  value: '',
  onChange: () => {},
  label: '',
  allowWrap: false
};

export default InputToggle;
