import NaNtoZero from '../../../utils/convertNaNToZero';
import { isPendingAdd, isApproved, isPendingNegative } from '../../../utils/isPending';
import { SumCostDetails, SumLaborCostToComplete } from './calculationHelpers';

const getOgBudget = (changeOrders) => changeOrders.filter((co) => co?.data?.ChangeOrderType === 'Original Budget');
const getOriginalContract = (Transaction) => NaNtoZero(Transaction.OriginalContractValue);
const getApprovedChanges = (changeOrders) =>
  changeOrders
    .filter((co) => co?.data?.ChangeOrderType !== 'Original Budget' && isApproved(co?.data?.Status, co?.data?.ChangeOrderType))
    .reduce((contractVal, co) => contractVal + NaNtoZero(co?.data?.ContractValue), 0);
const getAuthorizedExtras = (changeOrders) =>
  changeOrders
    .filter((co) => co?.data?.ChangeOrderType === 'Authorized Extras')
    .reduce((contractVal, co) => contractVal + NaNtoZero(co?.data?.ContractValue), 0);
const getClaims = (changeOrders) =>
  changeOrders
    .filter((co) => co?.data?.ChangeOrderType === 'Claims')
    .reduce((contractVal, co) => contractVal + NaNtoZero(co?.data?.ContractValue), 0);

const getOgBudgetValue = (changeOrders) =>
  changeOrders
    .filter((co) => co?.data?.ChangeOrderType === 'Original Budget')
    .reduce((contractVal, co) => contractVal + NaNtoZero(co?.data?.ContractValue), 0);

const getPendingAdd = (changeOrders) =>
  changeOrders
    .filter((co) => isPendingAdd(co?.data?.Status, co?.data?.ChangeOrderType))
    .reduce((contractVal, co) => contractVal + NaNtoZero(co?.data?.CarryAmount || co?.data?.ContractValue), 0);
const getPendingNegative = (changeOrders) =>
  changeOrders.filter((co) => isPendingNegative(co?.data?.Status)).reduce((contractVal, co) => contractVal + NaNtoZero(co?.data?.ContractValue), 0);

const getTotalApprovedContract = (changeOrders) =>
  getOgBudgetValue(changeOrders) + getApprovedChanges(changeOrders) + getAuthorizedExtras(changeOrders) + getPendingNegative(changeOrders);

const getTotalProjectedContract = (changeOrders) => getTotalApprovedContract(changeOrders) + getPendingAdd(changeOrders);

const getConstructionBudgets = (changeOrders) => changeOrders.filter((co) => co?.data?.ChangeOrderType === 'Construction Budget');
const getRBudget = (changeOrders) => changeOrders.filter((co) => isApproved(co?.data?.Status, co?.data?.ChangeOrderType));
const getApprovedCosts = (changeOrders) =>
  changeOrders.filter(
    (co) =>
      isApproved(co?.data?.Status, co?.data?.ChangeOrderType) &&
      co?.data?.ChangeOrderType !== 'Original Budget' &&
      co?.data?.ChangeOrderType !== 'Construction Budget'
  );

const getProjectedTotal = (fc) =>
  SumCostDetails(fc, undefined, 'CostToDate') +
  SumCostDetails(
    fc.filter((f) => f.CostType !== 'L'),
    undefined,
    'CostToComplete'
  ) +
  SumLaborCostToComplete(fc.filter((f) => f.CostType === 'L'));

export {
  getOriginalContract,
  getApprovedChanges,
  getAuthorizedExtras,
  getClaims,
  getTotalApprovedContract,
  getPendingAdd,
  getPendingNegative,
  getTotalProjectedContract,
  getOgBudgetValue,
  getOgBudget,
  getConstructionBudgets,
  getRBudget,
  getApprovedCosts,
  getProjectedTotal
};
