import React from 'react';
import PropTypes from 'prop-types';

const WillLinkToDiv = (props) => {
  const { type, location } = props;

  return (
    <div style={{ margin: '16px 0px 4px 0px', wordBreak: 'break-word' }}>
      <i>
        This {type} will link to <b>{location.toString()}</b>. <br />
        If this is incorrect, navigate to the page you want to link to and create the {type} there.
      </i>
    </div>
  );
};

WillLinkToDiv.propTypes = {
  type: PropTypes.string,
  location: PropTypes.string
};
WillLinkToDiv.defaultProps = {
  type: 'note',
  location: '/'
};

export default WillLinkToDiv;
