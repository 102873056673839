/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { NaNtoNum } from '@aldridge/aldg-helpers';
import { useHistory, useRouteMatch } from 'react-router-dom';
import existsWithLength from '../../../utils/existsWithLength';
import { _GridPagination } from '../../../_Recoil/atoms';
import { formatAsCurrency } from '../../UIComponents/format';

const ChangeOrdersByPicc = (props) => {
  const { picc, changeOrders, title } = props;
  const getRowID = (r) => (existsWithLength(r.PayItem) && existsWithLength(r.CostCode) ? `${r.PayItem}${r.CostCode[0]}` : r.id);
  const columns = [
    {
      field: 'CustomerBudget',
      headerAlign: 'center',
      headerName: 'Customer Budget Number',
      valueGetter: (params) => params.row.CustomerChangeOrder,
      flex: 100
    },
    {
      field: 'AldridgeBudget',
      headerAlign: 'center',
      headerName: 'Aldridge Budget Number',
      valueGetter: (params) => params.row.AldridgeChangeOrder,
      flex: 100
    },
    {
      field: 'ChangeOrderType',
      headerAlign: 'center',
      headerName: 'Change Order Type',
      valueGetter: (params) => params?.row.ChangeOrderType,
      flex: 100
    }
  ];
  if (title === 'Labor') {
    columns.push(
      {
        field: 'LaborHours',
        headerAlign: 'center',
        headerName: 'Labor Hours',
        valueGetter: (params) => NaNtoNum(params.row.LaborHours),
        type: 'number',
        availableAggregationFunctions: ['sum'],
        aggregable: true,
        flex: 100
      },
      {
        field: 'BurdenCost',
        headerAlign: 'center',
        headerName: 'Burden Cost',
        valueGetter: (params) => NaNtoNum(params.row.BurdenCost),
        valueFormatter: (params) => formatAsCurrency(params.value),
        type: 'number',
        availableAggregationFunctions: ['sum'],
        aggregable: true,
        flex: 100
      },
      {
        field: 'LaborCost',
        headerAlign: 'center',
        headerName: 'Labor Cost',
        valueGetter: (params) => NaNtoNum(params.row.LaborCost),
        valueFormatter: (params) => formatAsCurrency(params.value),
        type: 'number',
        availableAggregationFunctions: ['sum'],
        aggregable: true,
        flex: 100
      },
      {
        field: 'LaborTotal',
        headerAlign: 'center',
        headerName: 'Labor Total',
        valueGetter: (params) => NaNtoNum(params.row.LaborTotal),
        valueFormatter: (params) => formatAsCurrency(params.value),
        type: 'number',
        availableAggregationFunctions: ['sum'],
        aggregable: true,
        flex: 100
      }
    );
  } else {
    columns.push({
      field: 'Total',
      headerAlign: 'center',
      headerName: 'Total',
      valueFormatter: (params) => formatAsCurrency(params.value),
      valueGetter: (params) => NaNtoNum(params.row.Total),
      type: 'number',
      availableAggregationFunctions: ['sum'],
      aggregable: true,
      flex: 100
    });
  }
  // columns.push({
  //   field: 'Total',
  //   headerAlign: 'center',
  //   headerName: '',
  //   renderCell: (params) => {
  //     const pathSegs = route.url.split('/');
  //     pathSegs.pop();
  //     return <ToolTipIconButton icon={faArrowUpRightFromSquare} onClick={() => history.push(`${pathSegs.join('/')}/${params.row.id}/BudgetView`)} />;
  //   },
  //   flex: 100
  // });

  const rowsToShow = changeOrders.map((co) => {
    const row = {
      id: co?.id,
      CustomerChangeOrder: co?.data?.CustomerChangeOrder,
      AldridgeChangeOrder: co?.data?.AldridgeChangeOrder,
      ChangeOrderType: co?.data?.ChangeOrderType
    };

    switch (title) {
      case 'Labor':
        row.LaborHours = co?.data?.LaborChangeOrders.filter((lco) => picc === getRowID(lco))?.[0]?.LaborHours;
        row.BurdenCost = co?.data?.LaborChangeOrders.filter((lco) => picc === getRowID(lco))?.[0]?.BurdenCost;
        row.LaborCost = co?.data?.LaborChangeOrders.filter((lco) => picc === getRowID(lco))?.[0]?.LaborCost;
        row.LaborTotal = co?.data?.LaborChangeOrders.filter((lco) => picc === getRowID(lco))?.[0]?.LaborTotal;
        row.Found = co?.data?.LaborChangeOrders.filter((lco) => picc === getRowID(lco))?.length > 0;
        break;
      default:
        row.Total = co?.data[`${title}ChangeOrders`]?.filter((mseoco) => picc === getRowID(mseoco))?.[0]?.Total;
        row.Found = co?.data[`${title}ChangeOrders`]?.filter((lco) => picc === getRowID(lco))?.length > 0;
        break;
    }
    if (row.Found) return row;
    return undefined;
  });

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        py: 2
      }}
    >
      <Grid item xs={10} id='ChangeOrdersByPiccTable'>
        <AldgDataGrid
          aggregationModel={{
            LaborHours: 'sum',
            BurdenCost: 'sum',
            LaborCost: 'sum',
            LaborTotal: 'sum',
            Total: 'sum'
          }}
          columns={columns}
          rows={rowsToShow.filter((r) => existsWithLength(r))}
          totalRow
          noToolbar
        />
      </Grid>
    </Grid>
  );
};
ChangeOrdersByPicc.propTypes = {
  picc: PropTypes.string.isRequired,
  changeOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired
};
ChangeOrdersByPicc.defaultProps = {};

const RevisedBudgetDataGrid = (props) => {
  const { rows, columns, title, changeOrders } = props;
  const getRowID = (r) => (existsWithLength(r.PayItem) && existsWithLength(r.CostCode) ? `${r.PayItem}${r.CostCode[0]}` : r.id);
  const [uRows, setURows] = useState([]);

  useEffect(() => {
    setURows(rows.map((r) => ({ ...r, changeOrders })));
  }, [rows, changeOrders]);

  return (
    <Grid item xs={12} id={`${title}RevisedBudgetTable`}>
      <AldgDataGrid
        // eslint-disable-next-line react/no-unstable-nested-components
        DetailPanel={(row) => <ChangeOrdersByPicc picc={getRowID(row.row)} changeOrders={row.row.changeOrders || []} title={title} />}
        pageSizeOptions={[10, 20, 30]}
        aggregationModel={{
          LaborHours: 'sum',
          BurdenCost: 'sum',
          LaborCost: 'sum',
          LaborTotal: 'sum',
          Total: 'sum'
        }}
        columns={columns}
        getRowId={getRowID}
        rows={uRows}
        ToolbarProps={{
          jumpTo: true,
          title
        }}
      />
    </Grid>
  );
};

RevisedBudgetDataGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  changeOrders: PropTypes.arrayOf(PropTypes.object).isRequired
};
RevisedBudgetDataGrid.defaultProps = {
  rows: []
};

export default RevisedBudgetDataGrid;
