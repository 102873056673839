import { doc, getDoc } from 'firebase/firestore';
import { existsWithLength } from '@aldridge/aldg-helpers';
import MenuCardData from './MenuCardData';
import { firestore } from '../../../firebase';
import { ViewerUp } from '../UserAssignment/checkSecurity';

const getPreferences = async (user, securityLevel) =>
  new Promise((resolve) => {
    const mergePreferences = (userPrefs, allowedItems) => {
      const mergedPreferences = [];

      const ups = userPrefs.filter((up) => allowedItems.map((ai) => ai.title).includes(up.title));
      const ais = allowedItems.filter((ai) => !userPrefs.map((up) => up.title).includes(ai.title));

      const allowedUserPrefs = [].concat(ups).concat(ais);
      for (let i = 0; i < allowedUserPrefs.length; i++) {
        const pref = allowedItems.find((mcd) => mcd.title === allowedUserPrefs[i]?.title);
        pref.order = i;
        pref.favorite = allowedUserPrefs[i].favorite || false;

        mergedPreferences.push(pref);
      }
      return mergedPreferences;
    };

    if (existsWithLength(securityLevel) && ViewerUp(securityLevel)) {
      const allowedMenuItems = MenuCardData.filter((mcd) => mcd.securityLevel(securityLevel, user.beta)).map((mcd, idx) => {
        const correctOrder = JSON.parse(JSON.stringify(mcd));
        correctOrder.favorite = correctOrder.favorite || false;
        correctOrder.order = idx;
        return correctOrder;
      });
      getDoc(doc(firestore, 'Users', user.email)).then((d) => {
        if (typeof d.data().preferences !== 'undefined') resolve(mergePreferences(d.data().preferences, allowedMenuItems));
        else resolve(allowedMenuItems);
      });
    }
  });

export default getPreferences;
