import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import TooltipIconButton from './TooltipIconButton';

const FileUploadBtn = (props) => {
  const { onUpload } = props;
  const inputRef = useRef(null);

  const handleUpload = (event) => {
    onUpload(event.target.files);
  };

  return (
    <>
      <TooltipIconButton onClick={() => inputRef.current.click()} title='Upload' icon={faUpload} />
      <input type='file' ref={inputRef} style={{ display: 'none' }} onChange={handleUpload} />
    </>
  );
};

FileUploadBtn.propTypes = {
  onUpload: PropTypes.func
};
FileUploadBtn.defaultProps = {
  onUpload: () => {}
};

export default FileUploadBtn;
