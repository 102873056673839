import { doc, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import existsWithLength from '../../../utils/existsWithLength';
import { parseCellVal } from '../../../_helpers/ExcelJSHelpers';
import { firestore } from '../../../firebase';

let batch;

const updateFirestore = (value, picc, colName, monthEndDate, JobID, rows, user) => {
  const currentRowIdx = rows.map((r) => r.id).indexOf(picc);
  if (currentRowIdx > -1) {
    if (rows[currentRowIdx][colName] !== value) {
      batch.set(
        doc(firestore, `ENT-Jobs/${JobID}/MonthEnd/${monthEndDate}/Forecasting`, picc),
        { [`${colName}`]: value, ModifiedBy: user.email, ModifiedDate: new Date().toJSON() },
        { merge: true }
      );
      rows[currentRowIdx][colName] = value;
    }
  }
};

const readRange = async (letter, start, end, CostCodeWorksheet, monthEndDate, JobID, rows, user) => {
  const s = parseInt(start, 10);
  const e = parseInt(end, 10);
  let value;
  let picc;
  const colName = CostCodeWorksheet.getCell(`${letter}${s - 1}`).value.replace(/\W/g, '');
  for (let i = s; i <= e; i++) {
    if (i % 500 === 0 && i !== 0) {
      // eslint-disable-next-line no-await-in-loop
      await batch.commit();
      batch = writeBatch(firestore);
    }
    const cell = CostCodeWorksheet.getCell(`${letter}${i}`);
    if (cell.value === null) {
      if (colName === 'Notes') value = null;
      else value = 0;
    } else value = parseCellVal(cell);
    picc = CostCodeWorksheet.getCell(`A${i}`)
      ?.value?.toString()
      .concat(CostCodeWorksheet.getCell(`B${i}`)?.toString());
    if (existsWithLength(value)) {
      updateFirestore(value, picc, colName, monthEndDate, JobID, rows, user);
    }
  }
};
const ParseForecastUpload = async (workbook, monthEndDate, JobID, setUploadingForecast, rows, setRows, user) => {
  batch = writeBatch(firestore);
  const CostCodeWorksheet = workbook._worksheets.find((sheet) => typeof sheet !== 'undefined' && sheet.name === 'Cost Codes');
  const newRows = JSON.parse(JSON.stringify(rows));
  try {
    if (typeof CostCodeWorksheet !== 'undefined') {
      const wsRows = CostCodeWorksheet.getRows(0, CostCodeWorksheet.lastRow.number);

      let laborRow = -1;
      let materialRow = -1;
      let subcontractorRow = -1;
      let equipmentRow = -1;
      let otherRow = -1;
      let summaryRow = -1;
      for (let row = 0; row < wsRows.length; row++) {
        const wsRow = wsRows[row];
        for (let col = 0; col < wsRow.cellCount; col++) {
          const cell = wsRow.getCell(col + 1);
          if (cell.value === 'Labor' && laborRow === -1) {
            laborRow = row;
            break;
          }
          if (cell.value === 'Materials' && materialRow === -1) {
            materialRow = row;
            break;
          }
          if (cell.value === 'Subcontractor' && subcontractorRow === -1) {
            subcontractorRow = row;
            break;
          }
          if (cell.value === 'Equipment' && equipmentRow === -1) {
            equipmentRow = row;
            break;
          }
          if (cell.value === 'Other Costs' && otherRow === -1) {
            otherRow = row;
            break;
          }
          if (cell.value === 'SUMMARY' && summaryRow === -1) {
            summaryRow = row;
            break;
          }
        }
      }
      await readRange('J', laborRow + 2, materialRow - 4, CostCodeWorksheet, monthEndDate, JobID, newRows, user); // labor ctc
      await readRange('K', laborRow + 2, materialRow - 4, CostCodeWorksheet, monthEndDate, JobID, newRows, user); // labor htc
      await readRange('X', laborRow + 2, materialRow - 4, CostCodeWorksheet, monthEndDate, JobID, newRows, user); // labor notes
      await readRange('K', materialRow + 2, subcontractorRow - 2, CostCodeWorksheet, monthEndDate, JobID, newRows, user); // material total
      await readRange('R', materialRow + 2, subcontractorRow - 2, CostCodeWorksheet, monthEndDate, JobID, newRows, user); // material notes
      await readRange('K', subcontractorRow + 2, equipmentRow - 2, CostCodeWorksheet, monthEndDate, JobID, newRows, user); // sub total
      await readRange('R', subcontractorRow + 2, equipmentRow - 2, CostCodeWorksheet, monthEndDate, JobID, newRows, user); // sub notes
      await readRange('K', equipmentRow + 2, otherRow - 2, CostCodeWorksheet, monthEndDate, JobID, newRows, user); // equip total
      await readRange('R', equipmentRow + 2, otherRow - 2, CostCodeWorksheet, monthEndDate, JobID, newRows, user); // equip notes
      await readRange('K', otherRow + 2, summaryRow - 2, CostCodeWorksheet, monthEndDate, JobID, newRows, user); // other total
      await readRange('R', otherRow + 2, summaryRow - 2, CostCodeWorksheet, monthEndDate, JobID, newRows, user); // other notes

      setRows(newRows);

      // finish committing leftover batch items
      await batch.commit();

      toast.success(`Forecast upload for ${monthEndDate} successful!`);
    } else
      toast.error(
        "No worksheet named 'Cost Codes' in uploaded spreadsheet. Please make sure you uploaded the correct workbook and that the worksheet to be uploaded is named 'Cost Codes'."
      );
    setUploadingForecast(false);
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    toast.error('Forecast upload failed.');
    return false;
  }
};
export default ParseForecastUpload;
