import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const DEFAULT_OPTIONS = {
  toolbar: 'no',
  location: 'no',
  directories: 'no',
  status: 'no',
  menubar: 'no',
  scrollbars: 'yes',
  resizable: 'yes',
  width: 500,
  height: 400,
  top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
  left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
};

const copyStyles = (sourceDoc, targetDoc) => {
  Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
    if (styleSheet.cssRules) {
      // for <style> elements
      const newStyleEl = sourceDoc.createElement('style');

      Array.from(styleSheet.cssRules).forEach((cssRule) => {
        // write the text of each rule into the body of the style element
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) {
      // for <link> elements loading CSS from a URL
      const newLinkEl = sourceDoc.createElement('link');

      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
};

const createOptions = (ownerWindow, options) => {
  const mergedOptions = { ...DEFAULT_OPTIONS, ...options };

  return Object.keys(mergedOptions)
    .map(
      (key) => `${key}=${typeof mergedOptions[key] === 'function' ? mergedOptions[key].call(this, mergedOptions, ownerWindow) : mergedOptions[key]}`
    )
    .join(',');
};

const AldgPopout = (props) => {
  const { title, children, onClosing, appDoc, options } = props;

  // STEP 1: create an empty div
  const container = useRef(document.createElement('div'));
  let externalWindow = null;
  const mounted = useRef(null);

  const onUnload = () => {
    onClosing('E');
  };

  const programaticallyClose = () => {
    onClosing('P');
    externalWindow.close();
  };

  useEffect(() => {
    mounted.current = true;
    externalWindow = window.open('', '', createOptions(window, options));
    externalWindow.document.body.appendChild(container.current);
    externalWindow.document.title = title;

    copyStyles(appDoc, externalWindow.document);

    // close child window when parent closes
    externalWindow.opener.addEventListener('unload', () => {
      externalWindow.removeEventListener('unload', onUnload);
      if (externalWindow) externalWindow.close();
    });

    externalWindow.addEventListener('unload', onUnload);
    return () => {
      mounted.current = false;
      if (externalWindow) {
        externalWindow.removeEventListener('unload', onUnload);
        programaticallyClose();
      }
    };
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <CacheProvider value={createCache({ key: 'external', container: container.current })}>{children}</CacheProvider>,
        container.current
      )}
    </>
  );
};

AldgPopout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  onClosing: PropTypes.func,
  appDoc: PropTypes.object,
  options: PropTypes.object
};
AldgPopout.defaultProps = {
  title: '',
  children: null,
  onClosing: () => {},
  appDoc: undefined,
  options: {}
};

export default AldgPopout;
