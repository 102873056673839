import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';
import { faArrowTurnDown } from '@fortawesome/pro-regular-svg-icons';
import TooltipIconButton from './TooltipIconButton';

const JumpToButton = (props) => {
  const { tableNames, distinguisher, extraTxt } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const scrollToTable = (tableName) => {
    setAnchorEl(null);
    setTimeout(() => {
      document.getElementById(`${tableName}${distinguisher}`).scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };

  return (
    <>
      <TooltipIconButton
        title='Jump to...'
        color='gray'
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        icon={faArrowTurnDown}
      />
      <Menu open={menuOpen} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {tableNames.map((tableName) => (
          <MenuItem onClick={() => scrollToTable(tableName)} key={tableName}>
            {`${tableName}${extraTxt}`}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

JumpToButton.propTypes = {
  tableNames: PropTypes.arrayOf(PropTypes.string),
  distinguisher: PropTypes.string,
  extraTxt: PropTypes.string
};
JumpToButton.defaultProps = {
  tableNames: [''],
  distinguisher: '',
  extraTxt: ''
};

export default JumpToButton;
