import React, { useContext } from 'react';
import { OfflineContext } from '@aldridge/aldg-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignalAlt, faSignalAltSlash } from '@fortawesome/pro-regular-svg-icons';

const OnlineStatus = () => {
  const online = useContext(OfflineContext);
  return (
    <>
      <div
        style={{
          display: 'flex',
          padding: '5px',
          backgroundColor: 'white',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          alignItems: 'center',
          margin: '0 1px'
        }}
      >
        {online ? (
          <FontAwesomeIcon style={{ color: '#00ca00' }} icon={faSignalAlt} title='Current Connection Status: Online' />
        ) : (
          <FontAwesomeIcon style={{ color: '#d8262f' }} icon={faSignalAltSlash} title='Current Connection Status: Offline' />
        )}
      </div>
    </>
  );
};

export default OnlineStatus;
