import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputToggle from '../../UIComponents/InputToggle';

const Safety = (props) => {
  const { Transaction, onChange, editing, allowWrap } = props;

  return (
    <Grid container>
      <InputToggle
        allowWrap={allowWrap}
        label='Clinics And Hospitals'
        name='ClinicsAndHospitals'
        value={Transaction.ClinicsAndHospitals || ''}
        onChange={onChange}
        edit={editing}
      />
      <InputToggle
        allowWrap={allowWrap}
        label='Emergency Contact'
        name='EmergencyContact'
        value={Transaction.EmergencyContact || ''}
        onChange={onChange}
        edit={editing}
      />
      <InputToggle
        allowWrap={allowWrap}
        label='Job Hazard Analysis'
        name='JobHazardAnalysis'
        value={Transaction.JobHazardAnalysis || ''}
        onChange={onChange}
        edit={editing}
      />
      <InputToggle
        allowWrap={allowWrap}
        label='Project Risks'
        name='ProjectRisks'
        value={Transaction.ProjectRisks || ''}
        onChange={onChange}
        edit={editing}
      />
    </Grid>
  );
};

Safety.propTypes = {
  Transaction: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  editing: PropTypes.bool,
  allowWrap: PropTypes.bool
};
Safety.defaultProps = {
  onChange: () => {},
  editing: false,
  allowWrap: false
};

export default Safety;
