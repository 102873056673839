import React from 'react';
import { Button } from '@mui/material';
import { useRecoilState } from 'recoil';
import { _Clickstream } from '../../../_Recoil/atoms';
import { nonMuiThemes } from '../../../theme';

const ClickstreamControlBar = () => {
  const [Clickstream, setClickstream] = useRecoilState(_Clickstream);

  const end = () => {
    setClickstream({ currentStep: -1, steps: [], inAgenda: true, activeTask: Clickstream.activeTask, activeTaskName: Clickstream.activeTaskName });
  };

  const goBack = () => {
    if (Clickstream.currentStep > 0) {
      const newClickstreamAtom = {
        currentStep: Clickstream.currentStep - 1,
        steps: Clickstream.steps,
        inAgenda: true,
        activeTask: Clickstream.activeTask,
        activeTaskName: Clickstream.activeTaskName
      };
      setClickstream(newClickstreamAtom);
    } else end();
  };

  const goForward = () => {
    let newClickstreamAtom = {};
    if (Clickstream.currentStep < Clickstream.steps.length - 1) {
      newClickstreamAtom = {
        currentStep: Clickstream.currentStep + 1,
        steps: Clickstream.steps,
        inAgenda: true,
        activeTask: Clickstream.activeTask,
        activeTaskName: Clickstream.activeTaskName
      };
      setClickstream(newClickstreamAtom);
    } else end();
  };

  return (
    <div
      style={{
        ...nonMuiThemes.parentRL,
        width: 'auto',
        position: 'absolute',
        bottom: '10px',
        padding: '5px 10px 5px 10px',
        left: '10px',
        right: '10px',
        backgroundColor: '#FfFfFf',
        border: '3px double #000000',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ width: '33%', textAlign: 'left' }}>
        {Clickstream.currentStep > 0 ? (
          <Button variant='contained' onClick={goBack}>
            {Clickstream.steps[Clickstream.currentStep - 1].text}
          </Button>
        ) : (
          <Button variant='contained' style={{ visibility: 'hidden' }}>
            HIDDEN!
          </Button>
        )}
      </div>
      <div style={{ width: '33%', textAlign: 'center' }}>
        <Button variant='contained' color='error' onClick={end}>
          Back to Agenda
        </Button>
      </div>
      <div style={{ width: '33%', textAlign: 'right' }}>
        {Clickstream.currentStep < Clickstream.steps.length - 1 ? (
          <Button variant='contained' color='success' onClick={goForward}>
            {Clickstream.steps[Clickstream.currentStep + 1].text}
          </Button>
        ) : (
          <Button variant='contained' style={{ visibility: 'hidden' }}>
            HIDDEN!
          </Button>
        )}
      </div>
    </div>
  );
};

export default ClickstreamControlBar;
