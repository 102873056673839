import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { round } from 'lodash';
import { SumCosts } from './SumCosts';
import BudgetDataGrid from './BudgetDataGrid';
import NaNtoZero from '../../../utils/convertNaNToZero';

const AccordionDetailsList = (props) => {
  const { label, changeOrders, costCodeType, record, setRecord, editable, editing, setEditing, jctdscid } = props;
  const summaryTotal =
    costCodeType === 'LaborChangeOrders' && record.data.ChangeOrderType !== 'Change Order Escalation'
      ? SumCosts(changeOrders.map((l) => +round(NaNtoZero(l.LaborRate * l.LaborHours), 2)))
      : SumCosts(changeOrders.map((c) => c.Total));

  return (
    <Suspense fallback={<div>...Loading</div>}>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faAngleDown} />}>
          <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ whiteSpace: 'nowrap' }}>{`${label.split('-')[1]} Budget`}</div>
            <div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: '16px', width: '100%' }}>{`$${summaryTotal.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '2px 4px 4px' }}>
          <BudgetDataGrid
            rows={changeOrders}
            costCodeType={costCodeType}
            title={label}
            record={record}
            setRecord={setRecord}
            editable={editable}
            editing={editing}
            setEditing={setEditing}
            jctdscid={jctdscid}
          />
        </AccordionDetails>
      </Accordion>
    </Suspense>
  );
};

AccordionDetailsList.propTypes = {
  label: PropTypes.string,
  costCodeType: PropTypes.string,
  changeOrders: PropTypes.arrayOf(PropTypes.object),
  record: PropTypes.object,
  setRecord: PropTypes.func,
  editable: PropTypes.bool,
  editing: PropTypes.bool,
  setEditing: PropTypes.func,
  jctdscid: PropTypes.string.isRequired
};
AccordionDetailsList.defaultProps = {
  label: '',
  costCodeType: 'LaborChangeOrders',
  changeOrders: [{}],
  record: { data: {} },
  setRecord: () => {},
  editable: false,
  editing: false,
  setEditing: () => {}
};

export default AccordionDetailsList;
