/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Typography } from '@mui/material';
import { TextDisplay, ToolTipIconButton } from '@aldridge/aldg-ui-components';
import dayjs from 'dayjs';
import { faBackward } from '@fortawesome/pro-regular-svg-icons';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import { toast } from 'react-toastify';
import { StatusContext } from '../../../providers/StatusProvider';

const MonthRollback = (props) => {
  const { jctdscid, monthEndDate } = props;
  const { contractValues } = useContext(StatusContext);
  const { loading, functionCall, data, error } = useFunctionCall('DeleteMonthRecord', undefined, 540000000);

  const [open, setOpen] = useState(false);

  const deleteMonth = async () => {
    setOpen(false);
    functionCall({ path: `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}` });
  };

  useEffect(() => {
    if (data) {
      toast.success(`${monthEndDate} successfully deleted!`);
    }
    if (error) {
      toast.error('Encountered an error while deleting month! All Data may not have been deleted.');
    }
  }, [loading, data, error]);

  return (
    <Paper variant='outlined' className='aldg-field'>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Are you sure you would like to delete the entire month record?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' color='#3f3f3f'>
            {`By selecting 'Yes', you will be wiping clean the entire month [${monthEndDate}] record. You will be prompted to pull the month again once this process is complete.`}
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'right' }}>
          <Button onClick={() => setOpen(false)} variant='contained' color='error' style={{ marginRight: '50px', marginBottom: '20px' }} autoFocus>
            No
          </Button>
          <Button onClick={() => deleteMonth()} variant='contained' color='success' style={{ marginRight: '30px', marginBottom: '20px' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {contractValues ? (
        <Grid container>
          <Grid item xs={12}>
            <TextDisplay
              label='Month Pulled On'
              value={contractValues?.MonthStartedDate ? dayjs(contractValues?.MonthStartedDate).format('YYYY-MM-DD hh:mm a') : ''}
            />
            <TextDisplay label='Month Pulled By' value={contractValues?.MonthStartedBy || ''} />
          </Grid>
          <Grid item xs={12} sx={{ paddingBlock: 3 }}>
            <TextDisplay
              label='Forecast Updated On'
              value={contractValues?.ForecastLastUpdated ? dayjs(contractValues?.ForecastLastUpdated).format('YYYY-MM-DD hh:mm a') : ''}
            />
            <TextDisplay label='Forecast Updated By' value={contractValues?.ForecastLastUpdatedBy || ''} />
          </Grid>
          <Grid item xs={12}>
            <TextDisplay
              label='Cashflow Updated On'
              value={contractValues?.CashflowLastUpdated ? dayjs(contractValues?.CashflowLastUpdated).format('YYYY-MM-DD hh:mm a') : ''}
            />
            <TextDisplay label='Cashflow Updated By' value={contractValues?.CashflowLastUpdatedBy || ''} />
          </Grid>
          <Grid item xs={12}>
            <ToolTipIconButton loading={loading} onClick={() => setOpen(true)} icon={faBackward} label={`Delete Month End ${monthEndDate}`} />
          </Grid>
        </Grid>
      ) : (
        <Typography variant='h5'>{`Cannot delete Month. \n Month has not been started.`}</Typography>
      )}
    </Paper>
  );
};

MonthRollback.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  monthEndDate: PropTypes.string.isRequired
};
MonthRollback.defaultProps = {};

export default MonthRollback;
