import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputToggleDate from '../../UIComponents/InputToggleDate';
import TextDisplay from '../../UIComponents/TextDisplay';
import InputToggle from '../../UIComponents/InputToggle';

const ContractWork = (props) => {
  const { Transaction, onChange, editing } = props;

  return (
    <Grid container>
      <TextDisplay label='Contract Type' value={Transaction.ContractType || ''} />
      <TextDisplay label='Estimate Number' value={Transaction.EstimateNumber || ''} />
      <TextDisplay value={Transaction.DeliveryMethod} label='Delivery Method' />
      <TextDisplay label='Type Of Work' value={Transaction.TypeOfWork || ''} />
      <InputToggle
        label='Liquidated Damages'
        name='LiquidatedDamages'
        value={Transaction.LiquidatedDamages || ''}
        onChange={onChange}
        edit={editing}
      />
      <TextDisplay
        label='Original Contract Value'
        value={Transaction.OriginalContractValue.toLocaleString(undefined, { style: 'currency', currency: 'USD' }) || ''}
      />
      <TextDisplay value={Transaction.OCIPorCCIP} label='OCIP' />
      <InputToggleDate label='Notice to Proceed Date' name='NTPDate' value={Transaction.NTPDate} onChange={onChange} edit={editing} />
      <TextDisplay
        label='Current Contract Value'
        value={Transaction.CurrentContractValue.toLocaleString(undefined, { style: 'currency', currency: 'USD' }) || ''}
      />
      <TextDisplay value={Transaction.TaxableStatus} label='Taxable Status' />
    </Grid>
  );
};

ContractWork.propTypes = {
  Transaction: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  editing: PropTypes.bool
};
ContractWork.defaultProps = {
  onChange: () => {},
  editing: false
};

export default ContractWork;
