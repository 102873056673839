import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { InputTextarea, RepeatableList } from '@aldridge/aldg-data-components';
import InputToggleList from '../../UIComponents/InputToggleList';
import TextDisplay from '../../UIComponents/TextDisplay';

const ProjectLocation = (props) => {
  const { Transaction, onChange, editing, user } = props;

  return (
    <Grid container>
      <TextDisplay label='Primary Project Location' value={Transaction.PrimaryProjectLocation || ''} />
      <TextDisplay label='Primary Project County' value={Transaction.PrimaryProjectCounty || ''} />
      <TextDisplay label='Primary Project State' value={Transaction.PrimaryProjectState || ''} />
      <InputToggleList
        edit={editing}
        value={Transaction.OtherLocations || []}
        label='Other Locations'
        name='OtherLocations'
        RepeatableListComponent={
          <RepeatableList
            breakpoint='500px'
            label=''
            user={user}
            keepLabels
            listTransaction={{}}
            name='OtherLocations'
            onChange={onChange}
            required={false}
            rowObjects={[<InputTextarea label='Other Locations' name='OtherLocations' itemSize={11} />]}
            transactions={Transaction.OtherLocations || []}
          />
        }
      />
    </Grid>
  );
};

ProjectLocation.propTypes = {
  Transaction: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  editing: PropTypes.bool,
  user: PropTypes.object
};
ProjectLocation.defaultProps = {
  onChange: () => {},
  editing: false,
  user: undefined
};

export default ProjectLocation;
