import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, useMediaQuery, createTheme } from '@mui/material';
import { faHome, faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue } from 'recoil';
import { _Preferences } from '../../../_Recoil/atoms';
import ErrorBoundary from '../../../_GlobalComponents/ErrorBoundary';
import getLink from './getLink';
import { nonMuiThemes } from '../../../theme';
import './sidebar.css';

const MiniSidebar = (props) => {
  const { history } = props;
  const Preferences = useRecoilValue(_Preferences);
  const [hover, setHover] = useState(false);
  const [opening, setOpening] = useState(2);
  const [closing, setClosing] = useState(0);

  const favoritePrefs = [
    {
      href: '',
      icon: faHome,
      title: 'Home',
      description: 'Status Home page.',
      order: 0,
      favorite: true
    }
  ].concat(Preferences.filter((pref) => pref.favorite)?.sort((a, b) => (a.order > b.order ? 1 : -1)));

  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const isSelected = (href) => {
    const currentHref = history.location.pathname.split('/')[history.location.pathname.split('/').length - 1];
    return href?.trim() === currentHref?.trim();
  };

  const onClick = () => {
    if (opening === 2) {
      setOpening(0);
      return;
    }
    if (!hover) setOpening(1);
    else setClosing(1);
    setHover(!hover);
  };

  return (
    <ErrorBoundary componentName='MiniSidebar'>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{ display: 'flex', width: hover ? '170px' : '46px' }}
        className={matches ? 'sidebarDrawerMobile' : 'sidebarDrawer'}
        onAnimationEnd={() => {
          setOpening(0);
          setClosing(0);
        }}
        onBlur={() => {
          setOpening(2);
        }}
        // eslint-disable-next-line
        tabIndex={0}
        // eslint-disable-next-line
        opening={opening}
        // eslint-disable-next-line
        closing={closing}
      >
        <Grid container style={{ backgroundColor: 'white', height: '100%', alignContent: 'start', pointerEvents: 'all', cursor: 'pointer' }}>
          {matches && (
            <FontAwesomeIcon
              icon={hover ? faChevronLeft : faChevronRight}
              style={{ width: '100%', margin: '15px 15px 10px 15px' }}
              onClick={onClick}
            />
          )}
          {favoritePrefs.map((pref) => (
            <Grid
              item
              xs={12}
              key={pref.href}
              style={{
                textAlign: 'center',
                fontSize: '24px',
                width: '46px',
                padding: '8px',
                backgroundColor: isSelected(pref.href) ? '#b7b7b7' : 'white',
                borderRadius: '4px',
                display: 'block',
                alignItems: 'center'
              }}
            >
              <Link to={getLink(history, pref.href)} style={nonMuiThemes.blackLink}>
                <div style={{ width: '30px' }}>
                  <FontAwesomeIcon icon={pref.icon} title={pref.title} />
                </div>
                <div style={{ fontSize: '12px', fontFamily: 'sans-serif', paddingLeft: '40px', width: '0px', marginTop: '-20px', height: '20px' }}>
                  {pref.title}
                </div>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </ErrorBoundary>
  );
};

MiniSidebar.propTypes = {
  history: PropTypes.object.isRequired
};

export default MiniSidebar;
