import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader } from '@mui/material';
import { isFunction } from '@aldridge/aldg-helpers';
import ErrorBoundary from '../../../_GlobalComponents/ErrorBoundary';

const KPICard = (props) => {
  const { kpi } = props;

  const showKPI = !isFunction(kpi.show) || kpi.show();

  return (
    showKPI && (
      <ErrorBoundary componentName={`Agenda KPI - ${kpi.name}`}>
        <div style={{ padding: '12px 8px 12px 8px' }}>
          <Card key={kpi.name} variant='outlined'>
            <CardHeader title={kpi.name} />
            <CardContent>{kpi.jsx}</CardContent>
          </Card>
        </div>
      </ErrorBoundary>
    )
  );
};

KPICard.propTypes = {
  kpi: PropTypes.object.isRequired
};
KPICard.defaultProps = {};

export default KPICard;
