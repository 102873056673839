import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Grid } from '@mui/material';
import { faEye, faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { InputTextarea } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { doc, setDoc } from 'firebase/firestore';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { nonMuiThemes } from '../../../theme';
import existsWithLength from '../../../utils/existsWithLength';
import { _MonthEndDate } from '../../../_Recoil/atoms';
import FileUploadBtn from '../../UIComponents/FileUploadBtn';
import uploadAttachment from '../Attachments/uploadAttachment';
import deleteFile from '../Attachments/deleteAttachment';
import { firestore } from '../../../firebase';
import { StatusContext } from '../../../providers/StatusProvider';
import SHOK from './ImageDisplay/SHOKLogo.png';
import { UserContext } from '../../../providers/UserProvider';

const EditKPIsCard = (props) => {
  const { jctdscid, agendaTalkingPoints } = props;
  const [editing, setEditing] = useState(null);
  const [kpiText, setKpiText] = useState('');
  const { attachments, loadAttachments } = useContext(StatusContext);
  const [files, setFiles] = useState([]);
  const mounted = useRef(null);

  const user = useContext(UserContext);
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  useEffect(() => {
    mounted.current = true;
    loadAttachments(jctdscid);
    return () => {
      mounted.current = false;
    };
  }, [jctdscid]);

  useEffect(() => {
    switch (editing) {
      case 'Safety Talking Points':
        if (mounted.current) setKpiText(agendaTalkingPoints.Safety || '');
        break;
      case 'Operations Talking Points':
        if (mounted.current) setKpiText(agendaTalkingPoints.Operations || '');
        break;
      default:
        if (mounted.current) setKpiText('');
    }
  }, [editing]);

  const agendaDoc = doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${MonthEndDate}/Agenda`, 'TalkingPoints');

  const handleSave = () => {
    if (editing === 'Safety Talking Points') setDoc(agendaDoc, { Safety: kpiText }, { merge: true });
    else setDoc(agendaDoc, { Operations: kpiText }, { merge: true });
    if (mounted.current) setEditing(null);
  };

  const onFileUpload = (event) => {
    uploadAttachment(event, jctdscid, mounted, loadAttachments, () => {}, user, MonthEndDate, editing);
  };

  useEffect(() => {
    if (existsWithLength(attachments) && mounted.current) {
      const filteredFiles = attachments
        ?.filter((file) => file.LinkTo === editing && file.monthEndDate === MonthEndDate)
        ?.sort((a, b) => (dayjs(a.uploadedDate).isBefore(dayjs(b.uploadedDate)) ? -1 : 1));
      if (mounted.current) setFiles(filteredFiles);
    } else if (mounted.current && typeof attachments !== 'undefined') setFiles([]);
  }, [attachments, editing]);

  const previewFile = (file) => {
    const DEFAULT_OPTIONS = {
      toolbar: 'yes',
      location: 'yes',
      directories: 'yes',
      status: 'yes',
      menubar: 'yes',
      scrollbars: 'yes',
      resizable: 'yes',
      width: 500,
      height: 400,
      top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
      left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
    };
    if (file.NeedsDriveViewer)
      window.open(`https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(file.downloadURL)}`, '', DEFAULT_OPTIONS);
    else window.open(file.downloadURL, '', DEFAULT_OPTIONS);
  };

  return (
    <div style={{ padding: '8px' }}>
      {existsWithLength(editing) ? (
        <>
          <div style={nonMuiThemes.parentRL}>
            <Typography variant='h6'>{editing}</Typography>
            <div style={{ display: 'flex' }}>
              <ToolTipIconButton tooltipText='Save' color='success' icon={faSave} onClick={handleSave} />
              <ToolTipIconButton tooltipText='Cancel' color='error' icon={faTimes} onClick={() => setEditing(null)} />
              <FileUploadBtn onUpload={onFileUpload} />
            </div>
          </div>
          {editing === 'Safety Talking Points' ? (
            <div style={{ marginTop: 10 }}>
              <Typography variant='body2'>1. Currently, what SHOK exposure(s) do you need to watch out for when doing this work?</Typography>
              <Typography variant='body2'>2. What SHOK Controls are you implementing to reduce or eliminate this exposure?</Typography>
            </div>
          ) : null}
          <InputTextarea
            name='text'
            value={kpiText}
            onBlur={(e) => {
              if (mounted.current) setKpiText(e);
            }}
          />
          {editing === 'Safety Talking Points' ? <img src={SHOK} srcSet={SHOK} alt='SHOKLogo' style={{ width: '60%' }} /> : null}
          {files
            ?.sort((a, b) => dayjs(a.uploadedDate).isBefore(dayjs(b.uploadedDate)))
            .map((file) => (
              <div style={nonMuiThemes.parentRL}>
                <Grid container justifyContent='space-between'>
                  <Grid item xs={1}>
                    <img src={file.url} alt={file.name} key={file.name} style={{ width: '100%' }} />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant='body1'>{file.name}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <ToolTipIconButton
                      shape='circle'
                      color='primary'
                      icon={faEye}
                      tooltipText='View File'
                      placement='right'
                      onClick={() => previewFile(file)}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <ToolTipIconButton
                      shape='circle'
                      color='error'
                      icon={faTimes}
                      tooltipText='Delete'
                      placement='right'
                      onClick={() => deleteFile(file, jctdscid, loadAttachments)}
                    />
                  </Grid>
                </Grid>
              </div>
            ))}
        </>
      ) : (
        <>
          <Typography variant='body1'>Click the green Start button in the top right to view KPIs.</Typography>
          <div style={nonMuiThemes.divider} />
          <Typography variant='body1'>Or, click a category below to create and edit talking points.</Typography>
          <ul>
            <li>
              <Button className='text' variant='outlined' onClick={() => setEditing('Safety Talking Points')}>
                <Typography variant='body1'>Safety</Typography>
              </Button>
            </li>
            <li>
              <Button className='text' variant='outlined' onClick={() => setEditing('Operations Talking Points')}>
                <Typography variant='body1'>Operations</Typography>
              </Button>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

EditKPIsCard.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  agendaTalkingPoints: PropTypes.shape({ Safety: PropTypes.string, Operations: PropTypes.string })
};
EditKPIsCard.defaultProps = {
  agendaTalkingPoints: { Safety: '', Operations: '' }
};
export default EditKPIsCard;
