import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';

const TooltipIconButton = (props) => {
  const { title, color, onClick, icon } = props;

  let className = 'aldg-buttonIconWrapper aldg-iconContainer';
  switch (color) {
    case 'success':
      className += ' successContainer';
      break;
    case 'error':
      className += ' errorContainer';
      break;
    case 'gray':
      className += ' grayContainer';
      break;
    default:
      className += ' primaryContainer';
      break;
  }

  return (
    <Tooltip title={title}>
      <Button variant='contained' color='primary' className={className} onClick={onClick}>
        <FontAwesomeIcon icon={icon} className='aldg-icon' />
      </Button>
    </Tooltip>
  );
};

TooltipIconButton.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.object
};
TooltipIconButton.defaultProps = {
  title: '',
  color: 'primary',
  onClick: () => {},
  icon: faCircle
};

export default TooltipIconButton;
