import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, useMediaQuery } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { ToolTipIconButton, UserContext } from '@aldridge/aldg-ui-components';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { doc, setDoc } from 'firebase/firestore';
import { AldgTheme, nonMuiThemes } from '../../../theme';
import { StatusContext } from '../../../providers/StatusProvider';
import ProjectOverview from './ProjectOverview';
import ContractDetails from './ContractDetails';
import AccountingSummaryME from './AccountingSummaryME';
import AccountingSummaryTD from './AccountingSummaryTD';
import CostDetails from './CostDetails';
import LaborSummary from './LaborSummary';
import GrossProfitReports from './GrossProfitReports';
import EquipmentRates from './EquipmentRates';
import { getOgBudget, getProjectedTotal } from './getValues';
import MoMandHistory from './MoMandHist';
import { _SecurityLevel } from '../../../_Recoil/atoms';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { SumCostDetails } from './calculationHelpers';
import { firestore } from '../../../firebase';

const ProjectStatus = (props) => {
  const { Transaction, monthEndDate, memorialized, jctdscid } = props;
  const { budgets, forecasting, contractValues } = useContext(StatusContext);

  const Role = useRecoilValue(_SecurityLevel);
  const ogBudget = getOgBudget(structuredClone(budgets));
  const user = useContext(UserContext);

  const matches = useMediaQuery(AldgTheme.breakpoints.up('lg'));
  const matchesMd = useMediaQuery(AldgTheme.breakpoints.up('md'));

  const [open, setOpen] = useState(false);

  const totalPercentComplete = NaNtoZero(
    SumCostDetails(structuredClone(forecasting), undefined, 'CostToDate') / getProjectedTotal(structuredClone(forecasting))
  );
  const Memorialize = () => {
    setDoc(
      doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}`),
      {
        Memorialized: !memorialized ? 'true' : 'false',
        MemorializedBy: user.email,
        MemorializedDate: new Date().toJSON(),
        MemorializedOnPage: 'ProjectStatusReport'
      },
      { merge: true }
    );
  };

  return (
    <Grid container className='aldg-gridNoPadding'>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Would you like to complete the month?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' color='#3f3f3f'>
            {`By selecting 'Yes' you state that the status month [${monthEndDate}] is fully complete and is ready to be locked.`}
            <br />
          </DialogContentText>
          <DialogContentText id='alert-dialog-description' color='#3f3f3f'>
            (This action can only undone by an admin)
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'right' }}>
          <Button onClick={() => setOpen(false)} variant='contained' color='error' style={{ marginRight: '50px', marginBottom: '20px' }} autoFocus>
            No
          </Button>
          <Button onClick={Memorialize} variant='contained' color='success' style={{ marginRight: '30px', marginBottom: '20px' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {!memorialized && (
        <Grid item xs={12} className='aldg-gridNoPadding'>
          <ToolTipIconButton tooltipText='Memorialize Month' onClick={() => setOpen(true)} icon={faLock} color='gray' label='Complete Status Month' />
        </Grid>
      )}
      <Grid item xs={12} className={!matches ? 'aldg-gridNoPadding' : ''}>
        <ProjectOverview Transaction={Transaction} monthEndDate={monthEndDate} />
      </Grid>
      {!matches && <div style={nonMuiThemes.spacer} />}
      <Grid item xs={12} lg={7} className={!matches ? 'aldg-gridNoPadding' : ''} style={{ padding: matches && '8px 4px 0px 4px' }}>
        <ContractDetails Transaction={Transaction} MonthEndContractNumbers={contractValues} />
      </Grid>
      {!matchesMd && <div style={nonMuiThemes.spacer} />}
      <Grid item xs={12} md={6} lg={2.5} style={{ padding: matches ? '8px 4px 0px 4px' : '16px 4px 0px 1px' }}>
        <AccountingSummaryME jctdscid={Transaction.id} />
      </Grid>
      <Grid item xs={12} md={6} lg={2.5} style={{ padding: matches ? '8px 4px 0px 4px' : '16px 1px 0px 4px' }}>
        <AccountingSummaryTD
          jctdscid={Transaction.id}
          Transaction={Transaction}
          changeOrders={structuredClone(budgets)}
          MonthEndContractNumbers={contractValues}
          totalPercentComplete={totalPercentComplete}
        />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <CostDetails
          jctdscid={Transaction.id}
          changeOrders={structuredClone(budgets)}
          forecast={structuredClone(forecasting)}
          MonthEndContractNumbers={contractValues}
        />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <LaborSummary changeOrders={structuredClone(budgets)} forecast={structuredClone(forecasting)} Role={Role} />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <EquipmentRates changeOrders={structuredClone(budgets)} forecast={structuredClone(forecasting)} Role={Role} />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <GrossProfitReports
          Transaction={Transaction}
          changeOrders={structuredClone(budgets)}
          forecast={structuredClone(forecasting)}
          MonthEndContractNumbers={contractValues}
          Role={Role}
        />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <MoMandHistory jctdscid={Transaction.id} ogBudget={ogBudget} Role={Role} />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <MoMandHistory jctdscid={Transaction.id} ogBudget={ogBudget} wPending Role={Role} />
      </Grid>
    </Grid>
  );
};

ProjectStatus.propTypes = {
  Transaction: PropTypes.object,
  monthEndDate: PropTypes.string,
  memorialized: PropTypes.bool,
  jctdscid: PropTypes.string.isRequired
};
ProjectStatus.defaultProps = {
  Transaction: {},
  monthEndDate: '2010-12-31',
  memorialized: false
};

export default ProjectStatus;
