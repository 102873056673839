import React, { useState, useEffect, useContext } from 'react';
import { Modal, Backdrop, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { onSnapshot, orderBy, query, collection } from 'firebase/firestore';
import dayjs from 'dayjs';
import { firestore } from '../../firebase';
import { _ShowChangelogModal } from '../../_Recoil/atoms';
import { UserContext } from '../../providers/UserProvider';
import ErrorBoundary from '../ErrorBoundary';

const ChangelogModal = () => {
  const [logs, setLogs] = useState([]);
  const user = useContext(UserContext);
  const [ShowChangelogModal, setShowChangelogModal] = useRecoilState(_ShowChangelogModal);
  useEffect(() => {
    let mounted = true;
    if (mounted) setShowChangelogModal(localStorage.getItem('ChangelogShown') === null && typeof user !== 'undefined' && user !== null);
    return () => (mounted = false);
  }, [user]);

  useEffect(() => {
    let mounted = true;
    if (ShowChangelogModal) {
      const q = query(collection(firestore, 'Changelog'), orderBy('DeployDate', 'desc'));
      onSnapshot(q, (docs) => {
        const allLogs = [];
        docs.forEach((d) => allLogs.push(d.data()));
        if (mounted) setLogs(allLogs);
      });
    }
    return () => (mounted = false);
  }, [ShowChangelogModal]);

  const close = () => {
    localStorage.setItem('ChangelogShown', 'true');
    setShowChangelogModal(false);
  };

  return (
    <ErrorBoundary componentName='Changelog Modal' user={user}>
      <Grid container>
        <Modal open={ShowChangelogModal} onClose={close} BackdropComponent={Backdrop} disablePortal>
          <div className='aldg-modalPaper' style={{ paddingTop: '0px' }}>
            <div className='aldg-modalHeader'>
              <h1>What&apos;s New!</h1>
              <button className='aldg-closeBtn' type='button' onClick={close}>
                <b>X</b>
              </button>
            </div>

            <div className='aldg-modalBody'>
              {logs
                .filter((c) => (c.Deployed || 'Yes') === 'Yes')
                .map((log) => (
                  <div key={log.id}>
                    <div style={{ border: '1px ridge gray', marginTop: '6px' }} />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px 0px'
                      }}
                    >
                      <div>{log.Version}</div>
                      <i>{dayjs(log.DeployDate).isValid() ? dayjs(log.DeployDate).format('YYYY-MM-DD') : log.DeployDate}</i>
                    </div>
                    <ul>
                      {log.Changes.map((change, idx) => (
                        <li
                          style={{
                            textAlign: 'left',
                            marginLeft: '28px',
                            padding: '4px 0px'
                          }}
                          key={`${change.id}_${idx.toString()}`}
                        >
                          {change.Change}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </Modal>
      </Grid>
    </ErrorBoundary>
  );
};

export default ChangelogModal;
