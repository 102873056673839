import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import existsWithLength from '../../../../../utils/existsWithLength';
import { formatAsCurrency } from '../../../../UIComponents/format';
import { _MonthEndDate } from '../../../../../_Recoil/atoms';
import { nonMuiThemes } from '../../../../../theme';

const MonthEndKPI = (props) => {
  const { jctdscid } = props;
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  const { data, loading, functionCall } = useFunctionCall('AccountingDetail', { jctdscid, monthEnd: MonthEndDate, process: 'D' });

  useEffect(() => {
    let mounted = true;
    if (jctdscid !== '' && mounted) functionCall({ jctdscid, monthEnd: MonthEndDate, process: 'D' });
    return () => {
      mounted = false;
    };
  }, [jctdscid]);

  const getStyle = () => {
    const result = { width: '100%', textAlign: 'center' };
    if (existsWithLength(data) && data[0]?.ToDate < 0) Object.assign(result, nonMuiThemes.negative);
    return result;
  };

  return (
    <Typography variant='h4' style={getStyle()}>
      {!loading && existsWithLength(data) ? formatAsCurrency(data[0].ToDate, true, 0) : 'Loading...'}{' '}
    </Typography>
  );
};

MonthEndKPI.propTypes = {
  jctdscid: PropTypes.string.isRequired
};
MonthEndKPI.defaultProps = {};

export default MonthEndKPI;
