/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { isUndefined, useFunctionCall } from '@aldridge/aldg-helpers';
import { TailSpin } from 'react-loader-spinner';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import AldgModal from '../../UIComponents/AldgModal';
import { nonMuiThemes } from '../../../theme';
import existsWithLength from '../../../utils/existsWithLength';
import pullFromPrevious from '../../../utils/pullPreviousMonthData';
import { UserContext } from '../../../providers/UserProvider';
import { getPrevMonth } from '../../../utils/monthEndOptions';
import { firestore } from '../../../firebase';
import { SumCostDetails, SumLaborCostToComplete } from '../ProjectStatus/calculationHelpers';
import { StatusContext } from '../../../providers/StatusProvider';

const NewMonthModal = (props) => {
  const { jctdscid, monthEndDate, mostRecentMonth, setMostRecentMonth, match, Transaction } = props;
  const { forecasting } = useContext(StatusContext);
  const [isMemorialized, setIsMemorialized] = useState(false);

  const user = useContext(UserContext);
  const generating = useRef({ loading: false, refresh: true, previous: true });

  const { functionCall: RefreshForecast, data: RefreshData } = useFunctionCall('RefreshForecastData', undefined, 540000000);
  const { data: CashflowData, functionCall: CashflowUpdate } = useFunctionCall(
    'AccountingDetail',
    {
      jctdscid,
      monthEnd: monthEndDate,
      process: 'C'
    },
    540000
  );

  const laborPT = SumCostDetails(structuredClone(forecasting), 'L', 'CostToDate') + SumLaborCostToComplete(structuredClone(forecasting));
  const [materialPT, subPT, equipPT, otherPT] = ['M', 'S', 'E', 'O'].map(
    (letter) =>
      SumCostDetails(structuredClone(forecasting), letter, 'CostToDate') + SumCostDetails(structuredClone(forecasting), letter, 'CostToComplete')
  );
  const totalPT = laborPT + materialPT + subPT + equipPT + otherPT;

  const refreshCashflowData = () => {
    CashflowUpdate({ jctdscid, monthEnd: monthEndDate, process: 'C' });
    setDoc(
      doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}`),
      { CashflowLastUpdated: new Date().toJSON(), CashflowLastUpdatedBy: user.email },
      { merge: true }
    );
  };
  const setupPullFunc = () => {
    // setMostRecentMonth(undefined);
    generating.current.loading = true;
    if (existsWithLength(jctdscid)) {
      refreshCashflowData();
      RefreshForecast({
        jctdscid,
        monthEndDate
      });
    }
  };

  useEffect(() => {
    if (!isUndefined(RefreshData) && !isUndefined(CashflowData)) {
      pullFromPrevious(
        jctdscid,
        monthEndDate,
        mostRecentMonth,
        setMostRecentMonth,
        generating,
        RefreshData,
        user,
        CashflowData,
        refreshCashflowData,
        Transaction,
        totalPT
      );
    }
  }, [RefreshData, CashflowData]);

  useEffect(() => {
    // check if previous month has been memorialized
    const prevMonth = getPrevMonth(monthEndDate);
    if (!firestore._terminated) {
      getDoc(doc(firestore, `ENT-Jobs/${match.params.id}/MonthEnd/${prevMonth}`)).then((d) => {
        const data = d.data();
        if (existsWithLength(data) && data.Memorialized === 'true') {
          setIsMemorialized(true);
        } else {
          // TODO: force true always for the time being
          setIsMemorialized(true);
        }
      });
    }
  }, [monthEndDate]);

  const pullPieces = [
    {
      key: 'explain',
      type: 'text',
      content: (
        <>
          {isMemorialized ? (
            <Typography component='span'>
              You have switched to a new month (<b>{monthEndDate}</b>) that has no data. Would you like to pull across your{' '}
              <b> Budgets, Forecasting, Cash Flow, Over/Under Billing, and Agenda </b>
              data from the most recent month with data (<b>{mostRecentMonth}</b>)? <br /> <br />{' '}
            </Typography>
          ) : (
            <Typography component='span'>
              You have switched to a new month (<b>{monthEndDate}</b>) where the previous month has not been finalized. Please go to the previous
              month (<b>{mostRecentMonth}</b>) to finalize <b> Budgets, Forecasting, Cash Flow, Over/Under Billing, and Agenda </b>
              data. <br /> <br />
              <b>Note: You will not be able to pull data until the previous month is finalized.</b> <br />{' '}
            </Typography>
          )}
          <i style={{ fontSize: '13px' }}>{isMemorialized ? 'Note: You may need to reload the page to see the new data.' : null}</i>
        </>
      )
    },
    {
      key: 'btns',
      content: (
        <div style={{ ...nonMuiThemes.parentRL, paddingTop: '8px', justifyContent: generating.current.loading ? 'end' : 'space-between' }}>
          {!generating.current.loading && (
            <Button
              onClick={() => {
                setMostRecentMonth(undefined);
              }}
              color='error'
              variant='contained'
            >
              {isMemorialized ? 'No' : 'Close'}
            </Button>
          )}
          {generating.current.loading ? (
            <TailSpin height={45} />
          ) : isMemorialized ? (
            <Button variant='contained' onClick={setupPullFunc} color='success'>
              Yes
            </Button>
          ) : null}
        </div>
      )
    }
  ];

  return (
    <AldgModal
      open={existsWithLength(mostRecentMonth)}
      title='New Month Setup'
      handleClose={(e) => {
        if (!(e?.target?.className?.includes('MuiBackdrop-root') || e?.target?.className?.includes('MuiDialog'))) {
          setMostRecentMonth(undefined);
        }
      }}
      pieces={pullPieces}
    />
  );
};

NewMonthModal.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  monthEndDate: PropTypes.string,
  mostRecentMonth: PropTypes.string,
  setMostRecentMonth: PropTypes.func,
  match: PropTypes.object.isRequired,
  Transaction: PropTypes.object.isRequired
};
NewMonthModal.defaultProps = {
  monthEndDate: '2010-12-31',
  mostRecentMonth: undefined,
  setMostRecentMonth: () => {}
};

export default NewMonthModal;
