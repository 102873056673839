import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Alert } from '@mui/material';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { query, getDocs, collection, doc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { isUndefined, useFunctionCall } from '@aldridge/aldg-helpers';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import existsWithLength from '../../../utils/existsWithLength';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { _Clickstream, _SecurityLevel } from '../../../_Recoil/atoms';
import { StatusContext } from '../../../providers/StatusProvider';
import SaveChangesButton from '../../UIComponents/SaveChangesButton';
import { formatAsCurrency } from '../../UIComponents/format';
import { firestore } from '../../../firebase';
import { UserContext } from '../../../providers/UserProvider';
import CalculationLine from './CalculationLine';
import LabeledValueBox from './LabeledValueBox';
import { AdminUp, EditorUp } from '../UserAssignment/checkSecurity';

const UnderOverBilling = (props) => {
  const { Transaction, monthEndDate, memorialized, jctdscid, prevMemorialized, NextFromStatusMonth, isStatusMonth } = props;
  const user = useContext(UserContext);
  const { contractValues } = useContext(StatusContext);
  const SecurityLevel = useRecoilValue(_SecurityLevel);
  const Clickstream = useRecoilValue(_Clickstream);

  const inAgenda = Clickstream.inAgenda;

  const NextMonthEdit = prevMemorialized && monthEndDate === NextFromStatusMonth; // Can edit next month if status month is memorialized
  const StatusMonthEdit = isStatusMonth || NextMonthEdit || AdminUp(SecurityLevel); // Can edit if status month or above condition (or admin)
  const canEdit = !inAgenda && !memorialized && StatusMonthEdit && EditorUp(SecurityLevel);

  const [changed, setChanged] = useState(false);
  const [saved, setSaved] = useState(false);
  const [UnderOverBillingRecord, setUnderOverBillingRecord] = useState({
    id: '',
    ActualCostToDate: '',
    ProjectedTotalCostAtCompletion: '',
    TotalApprovedContract: '',
    BilledToDate: '',
    UnderOverNotes: []
  });
  const {
    data: sqlUnderbillingInfo,
    functionCall: getUnderbillingInfoFromSQL,
    loading: underbillingLoading
  } = useFunctionCall('getUnderbillingInformation');

  const generateUnderOverBillingInformation = async () => {
    if (monthEndDate === null) return;
    getUnderbillingInfoFromSQL({
      JobNumber: Transaction.id.substring(4),
      monthEndDate: monthEndDate.split('-').join('')
    });
  };
  const calculatePercentComplete = () => UnderOverBillingRecord.ActualCostToDate / UnderOverBillingRecord.ProjectedTotalCostAtCompletion;

  const calculateExpectedRevenue = () => calculatePercentComplete() * UnderOverBillingRecord.TotalApprovedContract;

  const calculateUnderOverBilling = () => UnderOverBillingRecord.BilledToDate - calculateExpectedRevenue();
  const calculateExplanations = () => {
    let explanations = 0;
    UnderOverBillingRecord.UnderOverNotes.forEach((note) => {
      explanations += note.Amount === '' ? 0 : NaNtoZero(note.Amount);
    });
    return explanations;
  };
  const calculateMarginFade = () => calculateUnderOverBilling() + calculateExplanations();
  useEffect(() => {
    const getData = async () => {
      let mounted = true;
      if (typeof sqlUnderbillingInfo !== 'undefined' && mounted) {
        const generatedUnderOverBillingRecord = { ...UnderOverBillingRecord };
        const querySnapshot = await getDocs(query(collection(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/Forecasting`)));
        getDocs(collection(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/OverUnderBilling`)).then((docs) => {
          if (docs.empty) generatedUnderOverBillingRecord.UnderOverNotes = [];
          else {
            docs.forEach((d) => {
              const OverUnderBillingNotes = d.data();
              if (typeof OverUnderBillingNotes !== 'undefined' && mounted) {
                generatedUnderOverBillingRecord.UnderOverNotes = OverUnderBillingNotes.data;
                generatedUnderOverBillingRecord.id = OverUnderBillingNotes.id;
              } else {
                generatedUnderOverBillingRecord.UnderOverNotes = [];
              }
            });
          }
          let costToComplete = 0;
          querySnapshot.forEach((d) => {
            const data = d.data();
            costToComplete +=
              data.CostType === 'L' ? NaNtoZero(data.CostHrToComplete) * NaNtoZero(data.HoursToComplete) : NaNtoZero(data.CostToComplete);
          });
          if (existsWithLength(sqlUnderbillingInfo)) {
            generatedUnderOverBillingRecord.ProjectedTotalCostAtCompletion = costToComplete + sqlUnderbillingInfo[0].Cost_JTD;
            generatedUnderOverBillingRecord.ActualCostToDate = sqlUnderbillingInfo[0].Cost_JTD;
            generatedUnderOverBillingRecord.TotalApprovedContract = NaNtoZero(contractValues?.FinancialApprovedContract || 0);
            generatedUnderOverBillingRecord.BilledToDate = sqlUnderbillingInfo[0].Billings_JTD;
          }

          if (mounted) setUnderOverBillingRecord(generatedUnderOverBillingRecord);
        });
      } else if (mounted) {
        setUnderOverBillingRecord({
          id: '',
          ActualCostToDate: '',
          ProjectedTotalCostAtCompletion: '',
          TotalApprovedContract: '',
          BilledToDate: '',
          UnderOverNotes: []
        });
      }
      return () => {
        mounted = false;
      };
    };
    getData();
    setSaved(false);
    // eslint-disable-next-line no-use-before-define
  }, [sqlUnderbillingInfo, saved]);

  useEffect(() => {
    generateUnderOverBillingInformation();
  }, [monthEndDate, jctdscid]);

  const saveNotes = () => {
    const changedUnderBilling = JSON.parse(JSON.stringify(UnderOverBillingRecord));
    if (!existsWithLength(changedUnderBilling.id)) {
      const newID = doc(collection(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/OverUnderBilling`)).id;
      changedUnderBilling.id = newID;
    }
    setDoc(
      doc(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/OverUnderBilling`, changedUnderBilling.id),
      { data: changedUnderBilling.UnderOverNotes, id: changedUnderBilling.id },
      { merge: true }
    ).then(() => {
      toast.success('Under/Over Billing Explanations Saved.');
      setChanged(false);
      setUnderOverBillingRecord(changedUnderBilling);
    });
  };
  const isUnderBilled = NaNtoZero(calculateUnderOverBilling()) <= 0;

  const Reasons = [
    { label: 'Quantity (Available Funds)', value: 'Quantity (Available Funds)' },
    { label: 'Quantity (Test-Burn In)', value: 'Quantity (Test-Burn In)' },
    { label: 'Quantity (Balancing C/O)', value: 'Quantity (Balancing C/O)' },
    { label: 'Force Account', value: 'Force Account' },
    { label: 'Change Order', value: 'Change Order' },
    { label: 'Material - Stored Material Ineligible', value: 'Material - Stored Material Ineligible' },
    { label: 'Stored Material - Unbilled', value: 'Stored Material - Unbilled' },
    { label: 'Material - DBE', value: 'Material - DBE' },
    { label: 'Billing Timing Gap', value: 'Billing Timing Gap' },
    { label: 'Mobilization', value: 'Mobilization' },
    { label: 'Pre Job Planning', value: 'Pre Job Planning' }
    // { label: 'Other', value: 'Other' }
  ];

  const saveNotesOnClose = (val) => {
    const changedUnderBilling = JSON.parse(JSON.stringify(UnderOverBillingRecord));
    const missingReasons = val.filter((note) => note.Reason === '');
    const missingDateAndReason = val.filter((date) => date.ExpectedBillingDate === '' && date.Reason === '');
    if (missingDateAndReason.length > 0) {
      toast.error('There was no date or reason selected for at least one explanation. The blank explanation has been removed.', { autoClose: 15000 });
      changedUnderBilling.UnderOverNotes = val;
      setUnderOverBillingRecord(changedUnderBilling);
      setSaved(true);
    } else if (missingReasons.length > 0 && existsWithLength(changedUnderBilling.UnderOverNotes.ExpectedBillingDates)) {
      toast.error('Please select a reason for each explanation. The missing reasons are highlighted in orange.', { autoClose: 15000 });
      changedUnderBilling.UnderOverNotes = val;
      setUnderOverBillingRecord(changedUnderBilling);
    } else {
      changedUnderBilling.UnderOverNotes = val;
      if (!existsWithLength(changedUnderBilling.id)) {
        const newID = doc(collection(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/OverUnderBilling`)).id;
        changedUnderBilling.id = newID;
      }
      setDoc(
        doc(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/OverUnderBilling`, changedUnderBilling.id),
        { data: changedUnderBilling.UnderOverNotes, id: changedUnderBilling.id },
        { merge: true }
      ).then(() => {
        toast.success('Under/Over Billing Explanations Saved.');
        setChanged(false);
        setUnderOverBillingRecord(changedUnderBilling);
      });
    }
  };
  return (
    <Paper variant='outlined' className='aldg-field' style={{ display: 'block' }}>
      {underbillingLoading && (
        <Alert severity='info'>
          <div style={{ width: '100%', fontStyle: 'italic' }}>Information may still be loading.</div>
        </Alert>
      )}
      {!underbillingLoading && !isUndefined(sqlUnderbillingInfo) && sqlUnderbillingInfo.length === 0 && (
        <Alert severity='info'>
          <div style={{ width: '100%', fontStyle: 'italic' }}>There is no WIP Data for this Job and Month End.</div>
        </Alert>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <CalculationLine
              calcName='Percent Complete'
              calcArr={[
                { label: 'Actual Cost To Date', value: UnderOverBillingRecord.ActualCostToDate, formatType: 'currency' },
                '/',
                {
                  label: 'Projected Total Cost At Completion',
                  value: UnderOverBillingRecord.ProjectedTotalCostAtCompletion,
                  formatType: 'currency'
                },
                '=',
                { label: 'Percent Complete', value: calculatePercentComplete(), formatType: 'percent' }
              ]}
            />
            <CalculationLine
              calcName='Expected Revenue to Date'
              calcArr={[
                { label: 'Total Approved Contract', value: UnderOverBillingRecord.TotalApprovedContract, formatType: 'currency' },
                'X',
                { label: 'Percent Complete', value: calculatePercentComplete(), formatType: 'percent' },
                '=',
                { label: 'Expected Revenue to Date', value: calculateExpectedRevenue(), formatType: 'currency' }
              ]}
            />
            <CalculationLine
              calcName={isUnderBilled ? 'Underbilling' : 'Overbilling'}
              calcArr={[
                { label: 'Billed to Date', value: UnderOverBillingRecord.BilledToDate, formatType: 'currency' },
                '-',
                { label: 'Expected Revenue to Date', value: calculateExpectedRevenue(), formatType: 'currency' },
                '=',
                { label: isUnderBilled ? 'Underbilling' : 'Overbilling', value: calculateUnderOverBilling(), formatType: 'currency' }
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={9} className='aldg-field'>
          <Alert severity='info'>
            <div style={{ width: '100%', fontStyle: 'italic' }}>
              <div>
                <strong>Underbilling</strong> will not save if any of the inputs are highlighted orange. Please make sure to select a Reason and enter
                an Expected Bill Date. date.
              </div>
            </div>
          </Alert>
          <AldgDataGrid
            pageSizeOptions={[10, 20, 30]}
            columns={[
              { field: 'Description', headerName: 'Description', canEdit: true, inputType: 'InputText', flex: 1 },
              {
                field: 'Reason',
                headerName: 'Reason',
                canEdit: true,
                inputType: 'InputSelect',
                options: Reasons,
                flex: 1,
                cellClassName: (params) => {
                  let result = '';
                  if (params.value === '') result = 'emptyVar';
                  else result = '';
                  return result;
                }
              },
              {
                field: 'Amount',
                headerName: 'Amount',
                canEdit: true,
                inputType: 'InputNumber',
                flex: 1,
                valueFormatter: (params) => formatAsCurrency(params.value)
              },

              {
                field: 'ExpectedBillingDate',
                headerName: 'Expected Billing Date',
                valueFormatter: (params) => (params.value ? dayjs(params.value).format('YYYY-MM-DD') : ''),
                canEdit: true,
                inputType: 'InputDate',
                flex: 1,
                cellClassName: (params) => {
                  let result = '';
                  if (params.value < monthEndDate && params.value !== '') result = 'oldVar';
                  else if (params.value === '') result = 'emptyVar';
                  else result = '';
                  return result;
                }
              },
              {
                field: 'Notes',
                headerName: 'Notes',
                canEdit: true,
                inputType: 'InputTextarea',
                flex: 1
              }
            ]}
            editable={canEdit && !underbillingLoading && EditorUp(SecurityLevel)}
            isRowSelectable={(params) => !params.row.Memorialized}
            rowCanBeEdited={(params) => !params.row.Memorialized}
            rows={JSON.parse(JSON.stringify(UnderOverBillingRecord.UnderOverNotes))?.sort((a, b) =>
              dayjs(a.CreatedDate).isBefore(dayjs(b.CreatedDate)) ? -1 : 1
            )}
            setRows={saveNotesOnClose}
            title='Explanations of Underbilling'
            ToolbarProps={{
              hideFooter: false,
              canAdd: canEdit && !underbillingLoading,
              canDelete: canEdit && !underbillingLoading,
              showColumns: false,
              showDensity: false,
              showFilter: false
            }}
            user={user}
            sx={[
              {
                '.oldVar': {
                  bgcolor: '#fbc1b6'
                },
                '.emptyVar': {
                  bgcolor: '#ffd495'
                }
              }
            ]}
            // firebaseConfig={firebaseConfig}
            // collection={`ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/OverUnderBilling`}
            getRowId={(row) => row.id}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container style={{ paddingLeft: '12px' }}>
            <LabeledValueBox label='Total Explanations' value={calculateExplanations()} formatType='currency' gridSize='100%' />
            <LabeledValueBox
              label={isUnderBilled ? 'Underbilling' : 'Overbilling'}
              value={calculateUnderOverBilling()}
              formatType='currency'
              gridSize='100%'
            />
            <LabeledValueBox
              label={isUnderBilled ? 'Possible Margin Fade' : 'Possible Margin Gain'}
              value={calculateMarginFade()}
              formatType='currency'
              gridSize='100%'
            />
          </Grid>
        </Grid>
      </Grid>
      <SaveChangesButton changed={changed} handleSave={saveNotes} />
    </Paper>
  );
};

UnderOverBilling.propTypes = {
  Transaction: PropTypes.objectOf(PropTypes.any).isRequired,
  monthEndDate: PropTypes.string,
  memorialized: PropTypes.bool,
  jctdscid: PropTypes.string.isRequired,
  prevMemorialized: PropTypes.bool,
  NextFromStatusMonth: PropTypes.string.isRequired,
  isStatusMonth: PropTypes.bool
};
UnderOverBilling.defaultProps = {
  monthEndDate: '2010-12-31',
  memorialized: true,
  prevMemorialized: false,
  isStatusMonth: false
};

export default UnderOverBilling;
