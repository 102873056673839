import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, createTheme } from '@mui/material';
import { isUndefined } from '@aldridge/aldg-helpers';
import existsWithLength from '../../../utils/existsWithLength';
import ErrorBoundary from '../../../_GlobalComponents/ErrorBoundary';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { formatAsPercent } from '../../UIComponents/format';
import convertCostTypeRToE from '../../../utils/convertCostTypeRToE';
import StatusDataGrid from './StatusDataGrid';
import {
  customerReAllocation,
  originalBudget,
  approvedChanges,
  revisedBudget,
  costToDate,
  costToComplete,
  projectedTotal,
  variance
} from './sharedColumns';
import { SumTotalCost } from '../ChangeOrder/SumCosts';
import { SumLabor, SumNonLabor, mapDown, SumCostDetails, SumLaborCostToComplete } from './calculationHelpers';
import { getApprovedCosts, getConstructionBudgets, getOgBudget, getProjectedTotal, getRBudget } from './getValues';

const CostDetails = (props) => {
  const { forecast, changeOrders } = props;

  const matches = useMediaQuery(createTheme().breakpoints.down('lg'));

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const columns = [
    {
      field: 'Title',
      headerAlign: 'center',
      headerName: 'Cost Type',
      align: 'left',
      valueGetter: (params) => params.row.Title,
      width: getWidth(140),
      flex: getFlex(140)
    },
    originalBudget(getWidth, getFlex),
    customerReAllocation(getWidth, getFlex),
    approvedChanges(getWidth, getFlex),
    revisedBudget(getWidth, getFlex),
    costToDate(getWidth, getFlex),
    costToComplete(getWidth, getFlex),
    projectedTotal(getWidth, getFlex),
    variance(getWidth, getFlex),
    {
      field: 'PercentComplete',
      headerAlign: 'center',
      headerName: '% Complete',
      align: 'center',
      valueGetter: (params) => params.row.PercentComplete,
      valueFormatter: (params) => formatAsPercent(params.value, true, 1),
      width: getWidth(80),
      flex: getFlex(80)
    }
  ];

  const ogBudget = getOgBudget(changeOrders);
  const constructionBudgets = getConstructionBudgets(changeOrders);
  const rBudget = getRBudget(changeOrders);
  const approvedCosts = getApprovedCosts(changeOrders);

  const createObj = (og, co, rb, ac, fc, ti, ct = undefined) => {
    const costType = ti.substring(0, 1);
    const isLabor = costType === 'L';
    const filteredFC = isUndefined(ct) ? fc : fc.filter((f) => (f.PNP === ct || f.ProductiveCostCode === ct) && f.CostType === costType);
    if (!existsWithLength(og) && !existsWithLength(co) && !existsWithLength(rb)) return { Title: ti };
    const CostToDate = SumCostDetails(filteredFC, convertCostTypeRToE(costType), 'CostToDate');
    const CostToComplete = isLabor ? SumLaborCostToComplete(filteredFC) : SumCostDetails(fc, convertCostTypeRToE(costType), 'CostToComplete');
    const RevisedBudget = isLabor ? SumLabor(rb, [ct]) : SumNonLabor(rb, ti);

    return {
      id: ti,
      Title: ti,
      OriginalBudget: isLabor ? SumLabor(og, [ct]) : SumNonLabor(og, ti),
      ConstructionReAllocation: isLabor ? SumLabor(co, [ct]) : SumNonLabor(co, ti),
      RevisedBudget,
      ApprovedChanges: isLabor ? SumLabor(ac, [ct]) : SumNonLabor(ac, ti),
      CostToDate,
      CostToComplete,
      ProjectedTotal: CostToDate + CostToComplete,
      Variance: RevisedBudget - (CostToDate + CostToComplete),
      PercentComplete: NaNtoZero(CostToDate / (CostToDate + CostToComplete))
    };
  };

  const createRows = (og, co, rb, ac, fc) => [
    createObj(og, co, rb, ac, fc, 'Labor - P', 'P'),
    createObj(og, co, rb, ac, fc, 'Labor - NP', 'NP'),
    createObj(og, co, rb, ac, fc, 'Material'),
    createObj(og, co, rb, ac, fc, 'Subcontractors'),
    createObj(og, co, rb, ac, fc, 'Equipment'),
    createObj(og, co, rb, ac, fc, 'Other'),
    {
      id: 'Total Costs',
      Title: 'Total Costs',
      OriginalBudget: SumTotalCost(mapDown(og)),
      ConstructionReAllocation: SumTotalCost(mapDown(co)),
      RevisedBudget: SumTotalCost(mapDown(rb)),
      ApprovedChanges: SumTotalCost(mapDown(ac)),
      CostToDate: SumCostDetails(fc, undefined, 'CostToDate'),
      CostToComplete:
        SumLaborCostToComplete(fc.filter((f) => f.CostType === 'L')) +
        SumCostDetails(
          fc.filter((f) => existsWithLength(f.CostType) && f.CostType !== 'L'),
          undefined,
          'CostToComplete'
        ),
      ProjectedTotal: getProjectedTotal(fc),
      Variance: SumTotalCost(mapDown(rb)) - getProjectedTotal(fc),
      PercentComplete: NaNtoZero(SumCostDetails(fc, undefined, 'CostToDate') / getProjectedTotal(fc))
    }
  ];

  return (
    <ErrorBoundary componentName='CostDetails'>
      <Grid container className='aldg-bordered'>
        <Grid item xs={12} className='aldg-headerTitle'>
          COST DETAILS
        </Grid>
        <StatusDataGrid rows={createRows(ogBudget, constructionBudgets, rBudget, approvedCosts, forecast)} columns={columns} title='Cost Details' />
      </Grid>
    </ErrorBoundary>
  );
};

CostDetails.propTypes = {
  changeOrders: PropTypes.arrayOf(PropTypes.object),
  forecast: PropTypes.arrayOf(PropTypes.object)
};
CostDetails.defaultProps = {
  changeOrders: [],
  forecast: []
};

export default CostDetails;
