/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { collection, query, onSnapshot, collectionGroup, where } from 'firebase/firestore';
import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { nonMuiThemes } from '../../../../../theme';
import { _MonthEndDate } from '../../../../../_Recoil/atoms';
import getMostRecentMonth from '../../../../../utils/getMostRecentMonth';
import { firestore } from '../../../../../firebase';
import IncompleteULs from './IncompleteULs';

const ActionItemsKPI = (props) => {
  const { jctdscid } = props;
  const [actionItems, setActionItems] = useState([]);
  const [prevMonth, setPrevMonth] = useState();
  const MonthEndDate = useRecoilValue(_MonthEndDate);

  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      const pm = await getMostRecentMonth(jctdscid, MonthEndDate);
      setPrevMonth(pm);
      const aiq = query(collection(firestore, `ENT-Jobs/${jctdscid}/ActionItems`));
      onSnapshot(aiq, (snap) => {
        const all = [];
        snap.forEach((d) => all.push(d.data()));
        if (mounted.current) setActionItems(all);
      });
    };
    getData();
  }, []);

  const lastMonthComplete = actionItems?.filter((ai) => ai.CompletionStatus === 'Complete' && ai.MonthEndAdded === prevMonth);
  const allIncomplete = actionItems?.filter((ai) => ai.CompletionStatus !== 'Complete' && dayjs(ai.MonthEndAdded).isBefore(dayjs(MonthEndDate)));

  return (
    <>
      <Typography variant='h6'>{`Last Month's Complete Action Items`}</Typography>
      <IncompleteULs actionItems={lastMonthComplete} jctdscid={jctdscid} areComplete />
      <div style={nonMuiThemes.divider} />
      <Typography variant='h6'>Outstanding Incomplete Action Items</Typography>
      <IncompleteULs actionItems={allIncomplete} jctdscid={jctdscid} />
    </>
  );
};

ActionItemsKPI.propTypes = {
  jctdscid: PropTypes.string.isRequired
};
ActionItemsKPI.defaultProps = {};

export default ActionItemsKPI;
