import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { dollarFormat, percentFormat, useFunctionCall } from '@aldridge/aldg-helpers';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import existsWithLength from '../../../utils/existsWithLength';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { formatAsPercent } from '../../UIComponents/format';
import TextDisplay from '../../UIComponents/TextDisplay';

const AccountingSummaryTD = (props) => {
  const { jctdscid, MonthEndContractNumbers, totalPercentComplete } = props;
  const { data, loading, error, functionCall } = useFunctionCall('AccountingDetail', { jctdscid, monthEnd: undefined, process: 'D' });

  const { TotalApprovedContract } = MonthEndContractNumbers;
  useEffect(() => {
    let mounted = true;
    if (jctdscid !== '' && mounted) functionCall({ jctdscid, monthEnd: undefined, process: 'D' });
    return () => {
      mounted = false;
    };
  }, [jctdscid]);

  useEffect(() => {
    if (typeof error !== 'undefined') toast.error(error);
  }, [error]);
  const Billed = !loading && existsWithLength(data) ? data[0].Billed : 0;
  const PercentBilled = NaNtoZero(Billed) / TotalApprovedContract;
  return (
    <Grid container className='aldg-bordered'>
      <Grid item xs={12} className='aldg-headerTitle'>
        ACCOUNTING SUMMARY
      </Grid>
      <Grid item xs={12} className='aldg-subheaderTitle' style={{ paddingBottom: '9px' }}>
        {`AS OF ${dayjs().format('YYYY-MM-DD')}`}
      </Grid>
      <TextDisplay label='Billed' value={!loading && existsWithLength(data) ? dollarFormat(data[0].Billed, 0) : 'Loading...'} />
      <TextDisplay label='Paid' value={!loading && existsWithLength(data) ? dollarFormat(data[0].Paid, 0) : 'Loading...'} />
      <TextDisplay label='Retention' value={!loading && existsWithLength(data) ? dollarFormat(data[0].Retention, 0) : 'Loading...'} />
      <TextDisplay label='Retention %' value={!loading && existsWithLength(data) ? percentFormat(data[0].RetentionPercent, 0) : 'Loading...'} />
      <TextDisplay label='To Date Cash' value={!loading && existsWithLength(data) ? dollarFormat(data[0].ToDate, 0) : 'Loading...'} />
      <TextDisplay
        label='% BILLED'
        value={formatAsPercent(PercentBilled, false, 0)}
        colorCondition={PercentBilled < totalPercentComplete ? '#cc0000' : '#000000'}
      />
    </Grid>
  );
};

AccountingSummaryTD.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  MonthEndContractNumbers: PropTypes.object,
  totalPercentComplete: PropTypes.number
};
AccountingSummaryTD.defaultProps = {
  MonthEndContractNumbers: {},
  totalPercentComplete: 0
};

export default AccountingSummaryTD;
