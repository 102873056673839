import excel from './defaultImages/DEFAULT_excel.jpg';
import word from './defaultImages/DEFAULT_word.png';
import file from './defaultImages/DEFAULT_file.png';
import pdf from './defaultImages/DEFAULT_pdf.png';

const getURL = (url, type) => {
  if (['.csv', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(type))
    return excel;
  if (
    ['.doc', '.docx', '.xml', 'text/xml', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(
      type
    )
  )
    return word;
  if (type.includes('/pdf')) return pdf;
  if (type.includes('image')) return url;
  return file;
};

export default getURL;
