import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import SaveChangesButton from '../../UIComponents/SaveChangesButton';
import { _Preferences } from '../../../_Recoil/atoms';
import { UserContext } from '../../../providers/UserProvider';
import DraggableArea from './DraggableArea';
import { firestore } from '../../../firebase';

const Dashboard = (props) => {
  const { history } = props;
  const user = useContext(UserContext);
  const [Preferences, setPreferences] = useRecoilState(_Preferences);
  const [changed, setChanged] = useState(false);

  const handleSave = () => {
    const preferenceLabels = [];
    Preferences.forEach((pref) => preferenceLabels.push({ title: pref.title, favorite: pref.favorite }));
    setDoc(doc(firestore, 'Users', user.email), { preferences: preferenceLabels }, { merge: true });
    toast.success('Preferences saved!');
    setChanged(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ padding: '0px' }}>
          <Grid container style={{ padding: '0px' }}>
            <Grid item xs={12} style={{ fontSize: '20px', padding: '0px 4px 0px 8px' }}>
              Favorites
            </Grid>
            <Grid item xs={12} className='aldg-helperText'>
              <i>
                Flip the switch and hit the save button in the bottom left to hide a module from the sidebar. You can also drag and drop a module to
                change its order.
              </i>
            </Grid>
            {Preferences.filter((pref) => pref.favorite).map((menuCard) => (
              <DraggableArea
                menuCard={menuCard}
                idx={menuCard.order}
                setPreferences={setPreferences}
                setChanged={setChanged}
                preferences={Preferences}
                history={history}
                key={`favorite${menuCard.title}`}
              />
            ))}
          </Grid>
        </Grid>
        {Preferences.filter((pref) => !pref.favorite).length > 0 && (
          <Grid item xs={12} className='aldg-gridNoPadding'>
            <Grid container>
              <Grid item xs={12} style={{ fontSize: '20px', padding: '0px 4px 0px 8px' }}>
                Modules
              </Grid>
              <Grid item xs={12} className='aldg-helperText'>
                <i>Flip the switch and hit the save button in the bottom left to view a module in the sidebar.</i>
              </Grid>
              {Preferences.filter((pref) => !pref.favorite).map((menuCard) => (
                <DraggableArea
                  menuCard={menuCard}
                  idx={menuCard.order}
                  setPreferences={setPreferences}
                  setChanged={setChanged}
                  preferences={Preferences}
                  history={history}
                  key={`disabled${menuCard.title}`}
                />
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
      <SaveChangesButton changed={changed} handleSave={handleSave} />
    </>
  );
};

Dashboard.propTypes = {
  history: PropTypes.object.isRequired
};

export default Dashboard;
