import React from 'react';
import PropTypes from 'prop-types';
import { formatAsCurrency, formatAsPercent, formatWithCommas } from '../../UIComponents/format';

const LabeledValueBox = (props) => {
  const { label, value, formatType, gridSize } = props;

  const formatValue = () => {
    switch (formatType) {
      case 'currency':
        return formatAsCurrency(value);
      case 'percent':
        return formatAsPercent(value);
      case 'commas':
        return formatWithCommas(value);
      default:
        return value;
    }
  };

  const displayValue = () => {
    if (value < 0) return `(${formatValue()})`;
    return formatValue();
  };

  return (
    <div style={{ display: 'grid', width: gridSize, textAlign: 'center', paddingTop: '16px' }} key={label}>
      <div style={{ fontSize: '18px' }}>{label}</div>
      <div style={{ outline: '1px solid black', padding: '12px 0px', color: value < 0 && 'red' }}>{displayValue()}</div>
    </div>
  );
};

LabeledValueBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formatType: PropTypes.oneOf(['currency', 'percent', 'commas', 'none']),
  gridSize: PropTypes.string
};
LabeledValueBox.defaultProps = {
  label: '',
  value: 0,
  formatType: 'none',
  gridSize: '25%'
};

export default LabeledValueBox;
