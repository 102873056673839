import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { SumLabor, SumLaborCostToComplete, SumToDate, SumLaborHours, getRate, getRateForecasting } from '../../../ProjectStatus/calculationHelpers';
import NaNtoZero from '../../../../../utils/convertNaNToZero';
import { isApproved, isPendingNegative } from '../../../../../utils/isPending';
import { formatAsCurrency } from '../../../../UIComponents/format';
import TextDisplay from '../../../../UIComponents/TextDisplay';
import { getRBudget } from '../../../ProjectStatus/getValues';
import { _Defaults } from '../../../../../_Recoil/atoms';

const PercentCompleteKPI = (props) => {
  const { forecast, changeOrders, type } = props;

  const Defaults = useRecoilValue(_Defaults);

  const rBudget = changeOrders?.filter((co) => isApproved(co?.data?.Status, co?.data?.ChangeOrderType) || isPendingNegative(co?.data?.Status));

  // productive
  const pForecasts = forecast?.filter((f) => f.CostType === 'L')?.filter((f) => f.PNP === 'P');
  const pLaborHoursR = SumLaborHours(rBudget, ['P']);
  const pHrsToComplete = SumToDate(pForecasts, 'HoursToComplete');

  // non productive
  const npForecasts = forecast?.filter((f) => f.CostType === 'L')?.filter((f) => f.PNP === 'NP');
  const npLaborHoursR = SumLaborHours(rBudget, ['NP']);
  const npHrsToComplete = SumToDate(npForecasts, 'HoursToComplete', 'NP');

  // equip
  const rb = getRBudget(changeOrders);
  const fc = forecast?.filter((f) => f.CostType === 'E' || f.CostType === 'R');

  const getVals = () => {
    switch (type) {
      case 'P':
        return {
          revisedBudget: SumLabor(rBudget, ['P']) / pLaborHoursR,
          actual: SumToDate(pForecasts, 'CostToDate') / SumToDate(pForecasts, 'HoursToDate'),
          forecast: NaNtoZero(SumLaborCostToComplete(pForecasts) / pHrsToComplete)
        };
      case 'NP':
        return {
          revisedBudget: SumLabor(rBudget, ['NP']) / npLaborHoursR,
          actual: SumToDate(npForecasts, 'CostToDate', 'NP') / SumToDate(npForecasts, 'HoursToDate', 'NP'),
          forecast: NaNtoZero(SumLaborCostToComplete(npForecasts) / npHrsToComplete)
        };
      default:
        return {
          revisedBudget: getRate(rb),
          actual: getRateForecasting('CostToDate', 'HoursToDate', forecast, fc),
          forecast: getRateForecasting('CostToComplete', 'HoursToComplete', forecast, fc)
        };
    }
  };

  const vals = getVals();

  const getColor = (val) => {
    const difference = 1 - val / vals.revisedBudget;
    if (difference > Defaults.Variance) return 'green';
    if (-1 * difference > Defaults.Variance) return 'red';
    return 'black';
  };

  return (
    <>
      <TextDisplay label='Revised Budget' value={formatAsCurrency(vals.revisedBudget, true, 0)} />
      <div style={{ color: getColor(vals.actual) }}>
        <TextDisplay label='Actual' value={formatAsCurrency(vals.actual, true, 0)} />
      </div>
      <div style={{ color: getColor(vals.forecast) }}>
        <TextDisplay label='Cost to Complete' value={formatAsCurrency(vals.forecast, true, 0)} />
      </div>
    </>
  );
};

PercentCompleteKPI.propTypes = {
  forecast: PropTypes.arrayOf(PropTypes.object),
  changeOrders: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.oneOf(['P', 'NP', 'E'])
};
PercentCompleteKPI.defaultProps = {
  forecast: [],
  changeOrders: [],
  type: 'E'
};

export default PercentCompleteKPI;
