import NaNtoZero from '../../../utils/convertNaNToZero';

export const HoursAtComplete = (params) => NaNtoZero(params.row.HoursToDate) + NaNtoZero(params.row.HoursToComplete);

export const CostPerHrToDate = (params) => NaNtoZero(NaNtoZero(params.row.CostToDate) / NaNtoZero(params.row.HoursToDate));

export const LaborCostToComplete = (params) => NaNtoZero(params.row.HoursToComplete) * NaNtoZero(params.row.CostHrToComplete);

export const TotalCostAtCompletion = (params) => NaNtoZero(params.row.CostToComplete) + NaNtoZero(params.row.CostToDate);

export const LaborTotalCostAtCompletion = (params) => LaborCostToComplete(params) + NaNtoZero(params.row.CostToDate);

export const LaborVarianceAtCompletion = (params) => NaNtoZero(params.row.LaborTotal) - LaborTotalCostAtCompletion(params);

export const VarianceAtCompletion = (params) => NaNtoZero(params.row.Total) - TotalCostAtCompletion(params);
