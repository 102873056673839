import React, { useState, useEffect } from 'react';
import { useIsFirstRender } from '@aldridge/aldg-helpers';
import { useRecoilState, useRecoilValue } from 'recoil';
import AldgPopout from '../Components/UIComponents/AldgPopout';
import TableNotesPopOut from '../Components/Jobs/QuickActions/TableNotesPopOut';
import { _CurrentJob, _NotesModal } from '../_Recoil/atoms';

const NotesPopout = () => {
  const [rehydratedNotesModal, setRehydratedNotesModal] = useState(false);
  const Record = useRecoilValue(_CurrentJob);

  const [NotesModal, setNotesModal] = useRecoilState(_NotesModal);

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (isFirstRender) {
      setRehydratedNotesModal(NotesModal.open);
    }
    if (NotesModal.force) {
      setRehydratedNotesModal(NotesModal.open);
      setNotesModal((modal) => ({ open: modal.open, force: false }));
    }
  }, [NotesModal]);

  useEffect(() => {
    if (!isFirstRender && rehydratedNotesModal !== NotesModal.open) {
      setNotesModal((modal) => ({ force: modal.force, open: rehydratedNotesModal }));
    }
  }, [rehydratedNotesModal]);

  return (
    <>
      {rehydratedNotesModal && !isFirstRender && Record.EcmsJobNumber && (
        <AldgPopout
          onClosing={(type) => {
            if (!rehydratedNotesModal) {
              setNotesModal((modal) => ({ force: modal.force, open: false }));
            }
            if (type === 'E') {
              setRehydratedNotesModal(false);
            }
          }}
          title={`Notes for ${Record.EcmsJobNumber}`}
          options={{ width: 800, height: 500 }}
          appDoc={window.document}
        >
          <TableNotesPopOut jctdscid={Record} />
        </AldgPopout>
      )}
    </>
  );
};

export default NotesPopout;
