import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, List, Typography } from '@mui/material';
import { OfflineContext } from '@aldridge/aldg-helpers';
import { useRecoilValue } from 'recoil';
import fRefreshApplication from '../../utils/forceRefresh';
import NavItem from './NavItem';
import SidebarData from './SideBarData';
import { UserContext } from '../../providers/UserProvider';
import OnlineStatus from '../OnlineStatus';
import CloudSync from '../CloudSync';
import IndexedDbWrapper from '../indexedDbWrapper';
import { config } from '../../firebase';
import { _AppVersion } from '../../_Recoil/atoms';
import SubmitHelpDeskTicket from '../../utils/SubmitHelpDeskTicket';

const Sidebar = (props) => {
  const user = useContext(UserContext) || {};
  const online = useContext(OfflineContext);
  const { setApplicationDrawer } = props;
  const AppVersion = useRecoilValue(_AppVersion);

  const data1 = JSON.parse(JSON.stringify(SidebarData));
  const data = !user.admin ? data1.filter((d) => !d.admin) : data1;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        {/* <Box
        sx={{
          alignItems: 'center',
          justifyItems: 'right',
          display: 'flex',
          p: 2
        }}
      >
        <ToolTipIconButton
          shape='circle'
          icon={faX}
          variant='text'
          onClick={() => {
            console.log('click', setOpen);
            setOpen(true);
            setOpen(false);
          }}
        />
      </Box> */}
        <Avatar src={user.picture} />
        <Typography color='textPrimary' variant='h5'>
          {user.name}
        </Typography>
        <Typography color='textSecondary' variant='body2'>
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2, pb: 1, pt: 0 }}>
        <List>
          {data.map((item) => (
            <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} onClick={setApplicationDrawer} />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          m: 1,
          p: 1
        }}
      >
        <div style={{ marginBottom: '24px', marginTop: '-12px', border: 'double' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '8px',
              fontWeight: 'bold'
            }}
          >
            Current App Version: {AppVersion}
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <OnlineStatus />
            Current Online Status: {online ? 'Online' : 'Offline'}
          </div>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <IndexedDbWrapper projectId={config.projectId}>View Pending Records</IndexedDbWrapper>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              marginBottom: '8px'
            }}
          >
            <CloudSync>Download Data</CloudSync>
          </div>
        </div>
        <Typography align='center' gutterBottom variant='h4'>
          Need Help?
        </Typography>
        <div style={{ marginTop: '-8px', paddingBottom: '4px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 1
            }}
          >
            {online ? (
              <Button color='primary' variant='contained' style={{ whiteSpace: 'break-spaces' }} onClick={fRefreshApplication}>
                Force Refresh Application
              </Button>
            ) : (
              <div style={{ color: 'red', fontWeight: 'bold' }}>You must be online to force refresh your application.</div>
            )}
          </Box>
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 1
          }}
        >
          <Typography align='center' variant='body2'>
            Please contact Customer Support
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 1
          }}
        >
          <SubmitHelpDeskTicket />
        </Box>
      </Box>
    </Box>
  );
};

Sidebar.propTypes = {
  setApplicationDrawer: PropTypes.func
};
Sidebar.defaultProps = {
  setApplicationDrawer: () => {}
};

export default Sidebar;
