import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faComputerMouse } from '@fortawesome/pro-regular-svg-icons';

const AgendaCard = (props) => {
  const { task, idx, agendaInProgress, allClickstreams, setClickstreamModalName } = props;

  const thisClickstream = allClickstreams[task.name];
  const notHighlighted = idx !== 0 && agendaInProgress;

  return (
    <Card
      style={{
        marginTop: '16px',
        backgroundColor: notHighlighted && 'lightgray',
        opacity: notHighlighted && '50%'
      }}
      variant='outlined'
    >
      <CardHeader
        title={task.name}
        subheader={`Presented by ${task.presenter} - Facilitated by ${task.facilitator}`}
        action={
          <span className='fa-layers fa-3x fa-fw'>
            <ToolTipIconButton
              shape='circle'
              tooltipText='Create / Edit Clickstream'
              icon={faComputerMouse}
              size={32}
              onClick={() => setClickstreamModalName(task.name)}
            />
            <span className='fa-layers-counter' style={{ backgroundColor: 'rgb(105 216 84)', top: '-3px', right: '6px' }}>
              {thisClickstream?.length || 0}
            </span>
          </span>
        }
      />
      <CardContent>
        {task.description}
        <Typography style={{ fontWeight: 'bold', paddingTop: '8px' }}>Modules to go through:</Typography>
        <ul style={{ margin: '8px' }}>
          {thisClickstream?.map((step) => (
            <li key={step.key}>{step.text}</li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

AgendaCard.propTypes = {
  task: PropTypes.shape({ name: PropTypes.string, description: PropTypes.string, presenter: PropTypes.string, facilitator: PropTypes.string }),
  idx: PropTypes.number,
  agendaInProgress: PropTypes.bool,
  allClickstreams: PropTypes.object,
  setClickstreamModalName: PropTypes.func
};
AgendaCard.defaultProps = {
  task: { name: '', description: '', presenter: '', facilitator: '' },
  idx: 0,
  agendaInProgress: false,
  allClickstreams: {},
  setClickstreamModalName: () => {}
};

export default AgendaCard;
