import { faCalendarCircleUser, faHome, faInputNumeric, faPercent, faUsers } from '@fortawesome/pro-regular-svg-icons';
// import { faHome, faInputNumeric, faPercent, faRectangleHistory, faUsers } from '@fortawesome/pro-regular-svg-icons';

const SidebarData = [
  {
    href: '/', // link to location
    icon: faHome, // icon, from font awesome
    title: 'Home', // label
    dock: true // true if want to appear on the bottom navigation bar on mobile
  },
  {
    href: '/Segments',
    icon: faInputNumeric,
    title: 'Segments',
    dock: false,
    admin: true
  },
  {
    href: '/CompanyDefaults',
    icon: faPercent,
    title: 'Company Defaults',
    dock: false,
    admin: true
  },
  {
    href: '/UserAssignment',
    icon: faUsers,
    title: 'User Assignment',
    dock: false,
    admin: true
  },
  {
    href: '/Jobstostatus',
    icon: faCalendarCircleUser,
    title: 'Jobs Coming To Status',
    dock: false,
    admin: true
  }
  // {
  //   href: '/Status',
  //   icon: faRectangleHistory,
  //   title: 'Jobs Coming To Status',
  //   dock: true,
  //   admin: true
  // }
];
export default SidebarData;
