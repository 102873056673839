import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import getLastIndex from '../../../utils/getLastIndex';
import TextDisplay from '../../UIComponents/TextDisplay';
import { formatAsCurrency } from '../../UIComponents/format';
import { _Defaults } from '../../../_Recoil/atoms';
import NaNtoZero from '../../../utils/convertNaNToZero';
import StatusDataGrid from './StatusDataGrid';

const ContractDetails = (props) => {
  const { Transaction, MonthEndContractNumbers, Type } = props;
  const { ApprovedChanges, AuthorizedExtras, Claims, OriginalContract, PendingAdd, PendingNegative, TotalApprovedContract, TotalProjectedContract } =
    MonthEndContractNumbers;
  const Defaults = useRecoilValue(_Defaults);
  const GL = Transaction.ContractGLRate || Defaults.ContractGLRate;
  const TotalContract = TotalApprovedContract;
  const TotalProjected = TotalProjectedContract;
  const isForecast = Type === 'Forecasting';

  const rows = [
    { id: 'GLCON001', Type: 'Original Contract', Value: OriginalContract, Cost: OriginalContract * GL },
    {
      id: 'GLCON002',
      Type: 'Approved Changes',
      Value: ApprovedChanges,
      Cost: ApprovedChanges * GL,
      Profit: ApprovedChanges * (1 - GL)
    },
    { id: 'GLCON003', Type: 'Authorized Extras', Value: AuthorizedExtras, Cost: AuthorizedExtras * GL, Profit: AuthorizedExtras * (1 - GL) },
    { id: 'GLCON004', Type: 'Total Approved Contract', Value: TotalContract, Cost: TotalContract * GL },
    { id: 'GLCON005', Type: 'Pending Changes - Add', Value: PendingAdd, Cost: PendingAdd * GL },
    { id: 'GLCON006', Type: 'Pending Changes - Negative', Value: PendingNegative, Cost: PendingNegative * GL },
    { id: 'GLCON007', Type: 'Total Projected Contract', Value: TotalProjected, Cost: TotalProjected * GL }
  ];

  const columns = [
    {
      headerAlign: 'center',
      field: 'Type',
      headerName: 'Contract Type',
      valueGetter: (params) => params.row.Type,
      align: 'left',
      width: 175,
      flex: 100
    },
    {
      headerAlign: 'center',
      field: 'Value',
      headerName: 'Contract Value',
      cellClassName: (params) => (params.value < -0.01 ? 'negative' : ''),
      valueFormatter: (params) => formatAsCurrency(params.value, true, 0),
      valueGetter: (params) => NaNtoZero(params.row.Value),
      align: 'right',
      width: 175,
      flex: 100
    },
    {
      headerAlign: 'center',
      field: 'Cost',
      headerName: 'GL Cost',
      cellClassName: (params) => (params.value < -0.01 ? 'negative' : ''),
      valueFormatter: (params) => formatAsCurrency(params.value, true, 0),
      valueGetter: (params) => NaNtoZero(params.row.Cost),
      align: 'right',
      width: 175,
      flex: 100
    }
  ];

  return (
    <Grid container className='aldg-bordered'>
      <Grid item xs={12} className='aldg-headerTitle'>
        {!isForecast ? `CONTRACT DETAILS` : `Contract GL Amounts`}
      </Grid>
      {isForecast ? (
        <StatusDataGrid columns={columns} rows={rows} title='Contract Details' />
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <TextDisplay label='Contract Type' value={Transaction.ContractType} />
            <TextDisplay label='Original Contract' value={formatAsCurrency(OriginalContract, false, 0)} />
            <TextDisplay label='Approved Changes' value={formatAsCurrency(ApprovedChanges, false, 0)} />
            <TextDisplay label='Authorized Extras' value={formatAsCurrency(AuthorizedExtras, false, 0)} />
            <TextDisplay label='Total Approved Contract' value={formatAsCurrency(TotalContract, false, 0)} />
            <TextDisplay label='Pending Changes - Add' value={formatAsCurrency(PendingAdd, false, 0)} />
            <TextDisplay label='Pending Changes - Negative' value={formatAsCurrency(PendingNegative, false, 0)} />
            <TextDisplay label='Total Projected Contract' value={formatAsCurrency(TotalProjected, false, 0)} />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* <TextDisplay label='Line Item Quantity Changes' value='???' /> */}
            <TextDisplay label='Start Date' value={Transaction.EcmsStartDate} />
            <TextDisplay label='Original Completion Date' value={Transaction.EcmsOriginalCompletionDate} />
            <TextDisplay
              label='Revised Completion Date'
              value={
                Array.isArray(Transaction.EcmsRevisedCompletionDate) && Transaction.EcmsRevisedCompletionDate.length > 0
                  ? dayjs(getLastIndex(Transaction.EcmsRevisedCompletionDate).EcmsRevisedCompletionDate).format('YYYY-MM-DD')
                  : dayjs(Transaction.CosentialRevisedCompletionDate).format('YYYY-MM-DD')
              }
            />
            <TextDisplay label='Substantially Completed' value={dayjs(Transaction.SubstantiallyComplete).format('YYYY-MM-DD')} />
            <TextDisplay label='Claims at 100%' value={formatAsCurrency(Claims, false, 0)} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

ContractDetails.propTypes = {
  Transaction: PropTypes.object,
  MonthEndContractNumbers: PropTypes.object,
  Type: PropTypes.string
};
ContractDetails.defaultProps = {
  Transaction: {},
  MonthEndContractNumbers: {},
  Type: ''
};

export default ContractDetails;
