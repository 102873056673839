import { getDocs, query, collection, getDoc, doc } from 'firebase/firestore';
import convertCostTypeRToE from '../../../utils/convertCostTypeRToE';
import { firestore } from '../../../firebase';
import { monthEndOptions } from '../../../utils/monthEndOptions';
import NaNtoZero from '../../../utils/convertNaNToZero';
import existsWithLength from '../../../utils/existsWithLength';

const aggregateMonth = (month, monthData, jctdscid, wPendings) =>
  new Promise((resolve, reject) => {
    try {
      const aggregatedMonth = { id: month, Date: month, Title: month, Contract: '???', TotalCosts: '???', GrossProfit: '???', ProfitMargin: '???' };

      const costTypes = ['L', 'M', 'E', 'S', 'O'];
      let total = 0;

      costTypes.forEach((costType) => {
        const costTypeData = monthData.filter((md) => convertCostTypeRToE(md.CostType) === costType);

        if (costType === 'L') {
          const monthHoursToDate = costTypeData.reduce((totalHoursToDate, row) => totalHoursToDate + NaNtoZero(row.HoursToDate), 0);
          const monthHoursToComplete = costTypeData.reduce((totalHoursToComplete, row) => totalHoursToComplete + NaNtoZero(row.HoursToComplete), 0);
          aggregatedMonth.Hours = monthHoursToDate + monthHoursToComplete;
        }

        const monthCostToComplete = ((ct) => {
          if (ct === 'L') {
            return costTypeData.reduce(
              (totalCostToComplete, row) => totalCostToComplete + NaNtoZero(row.CostHrToComplete) * NaNtoZero(row.HoursToComplete),
              0
            );
          }
          return costTypeData.reduce((totalCostToComplete, row) => totalCostToComplete + NaNtoZero(row.CostToComplete), 0);
        })(costType);

        const monthCostToDate = costTypeData.reduce((totalCostToDate, row) => totalCostToDate + NaNtoZero(row.CostToDate), 0);

        const costTypeAmt = NaNtoZero(monthCostToDate + monthCostToComplete);
        aggregatedMonth[costType] = costTypeAmt;
        total += costTypeAmt;
      });
      aggregatedMonth.TotalCosts = total;

      Promise.all([getDoc(doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${month}`))]).then((d) => {
        if (d[0].exists()) {
          const currMonData = d[0].data();
          if (existsWithLength(currMonData.OriginalContract)) {
            const CV = wPendings ? currMonData.TotalProjectedContract : currMonData.FinancialApprovedContract;
            aggregatedMonth.Contract = CV;
            aggregatedMonth.GrossProfit = CV - total;
            aggregatedMonth.ProfitMargin = (CV - total) / CV;
          }
          if (existsWithLength(d) && d[0].exists() && d[0].data().Memorialized === 'true') aggregatedMonth.Memorialized = 'true';
          else aggregatedMonth.Title = `${month}`;
          resolve(aggregatedMonth);
        } else {
          resolve(aggregatedMonth);
        }
      });
    } catch (err) {
      reject(err);
    }
  });

const aggregateForecasts = (jctdscid, monthEndDate, startDate, wPendings) =>
  new Promise((resolve, reject) => {
    try {
      if (!existsWithLength(jctdscid)) resolve([]);
      const allMonths = monthEndOptions(startDate)
        .map((me) => me.value)
        .filter((me) => me <= monthEndDate)
        ?.sort((a, b) => new Date(b) - new Date(a));
      const aggregatedForecasts = [];

      const allForecastingData = [];
      allMonths.forEach((month) => {
        allForecastingData.push(getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${month}/Forecasting`))));
      });

      const monthPromises = [];
      Promise.all(allForecastingData).then((allData) => {
        allData.forEach((monthData) => {
          if (!monthData.empty) {
            const dataByMonth = [];
            monthData.forEach((d) => {
              dataByMonth.push(d.data());
            });
            monthPromises.push(aggregateMonth(monthData.query._path.segments[3], dataByMonth, jctdscid, wPendings));
          }
        });
        Promise.all(monthPromises).then((monthData) => {
          monthData.forEach((month) => {
            aggregatedForecasts.push(month);
          });
          resolve(aggregatedForecasts);
        });
      });
    } catch (err) {
      reject(err);
    }
  });

export default aggregateForecasts;
