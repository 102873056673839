import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { getDoc, doc } from 'firebase/firestore';
import existsWithLength from './existsWithLength';
import { firestore } from '../firebase';

const getCashflowRows = (Transaction, monthEndDate, data, totalPT, setDoc, setRows) => {
  const startMonth = dayjs(Transaction.EcmsStartDate).endOf('month');
  const endMonth = dayjs(
    existsWithLength(Transaction.CashFlowCompletionDate) ? Transaction.CashFlowCompletionDate : Transaction.EcmsOriginalCompletionDate
  ).endOf('month');

  const promises = [];
  let currentMonth = startMonth;
  dayjs.extend(isSameOrBefore);

  const t1 = [];
  data?.forEach((d, idx) => {
    const currMonthTotal = d.LaborBillings + d.MaterialBillings + d.SubcontractorBillings + d.EquipmentBillings + d.OtherBillings;
    const rec = {
      id: dayjs(d.MonthEndDate).format('MMMM-YY'),
      Month: dayjs(d.MonthEndDate).format('MMMM-YY'),
      ProjectedBillings: d.ProjectBillings,
      ProjectedReceipts: d.ProjectReceipts,
      ProjectedDisbursements: d.ProjectDisbursements,
      Labor: d.LaborBillings,
      Material: d.MaterialBillings,
      Subcontracts: d.SubcontractorBillings,
      Equipment: d.EquipmentBillings,
      Other: d.OtherBillings,
      CurrentMonthTotal: currMonthTotal,
      CumulativeCashPosition: 0,
      MonthCostVBilling: 0,
      PercentComplete: 0,
      RetentionHeld: d.RetentionHeld,
      isActual: true
    };
    rec.CumulativeJobTotal = rec.CurrentMonthTotal + (idx > 0 ? t1[idx - 1].CumulativeJobTotal : 0);
    rec.PercentComplete = rec.CumulativeJobTotal / totalPT;
    rec.CumulativeCashPosition = rec.ProjectedReceipts - rec.ProjectedDisbursements - (idx > 0 ? t1[idx - 1].CumulativeCashPosition : 0);
    rec.MonthCostVBilling = rec.ProjectedBillings - rec.CurrentMonthTotal;
    t1.push(rec);
    setDoc(doc(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/CashFlow/${rec.id}`), rec, { merge: true });
  });

  while (dayjs(currentMonth).isSameOrBefore(dayjs(endMonth), 'month')) {
    const m = dayjs(currentMonth).endOf('month');
    promises.push(getDoc(doc(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/CashFlow`, m.format('MMMM-YY'))));
    currentMonth = m.add(1, 'month');
  }

  Promise.all(promises).then((results) => {
    const tempRows = t1;
    results.forEach((d) => {
      if (d.exists()) {
        const idx = tempRows.findIndex((t) => t.id === d.data().id);
        if (idx > -1) {
          tempRows[idx] = { ...d.data(), ...tempRows[idx] };
        } else {
          tempRows.push({ ...d.data(), id: d.id });
        }
      } else {
        const r = {
          id: d.id,
          Month: d.id,
          isActual: false,
          Labor: 0,
          Material: 0,
          Subcontracts: 0,
          Equipment: 0,
          Other: 0,
          CumulativeJobTotal: 0,
          PercentComplete: 0,
          RetentionHeld: 0,
          ProjectedReceipts: 0,
          ProjectedDisbursements: 0,
          CumulativeCashPosition: 0,
          MonthCostVBilling: 0,
          ProjectedBillings: 0
        };
        tempRows.push(r);
        setDoc(d.ref, r);
      }
    });
    setRows(tempRows);
    return tempRows;
  });
};

export default getCashflowRows;
