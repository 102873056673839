import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { NaNtoNum, useFunctionCall } from '@aldridge/aldg-helpers';
import { useRecoilValue } from 'recoil';
import existsWithLength from '../../../../../utils/existsWithLength';
import { formatAsCurrency } from '../../../../UIComponents/format';
import { _CurrentJob } from '../../../../../_Recoil/atoms';

const ARKPI = (props) => {
  const { jctdscid } = props;
  const Transaction = useRecoilValue(_CurrentJob);
  const { data, loading, functionCall } = useFunctionCall('AROver60', { jctdscid });

  useEffect(() => {
    let mounted = true;
    if (jctdscid !== '' && Transaction.id && mounted) {
      const dso = NaNtoNum(Transaction.OwnerPayCycle) + NaNtoNum(Transaction.ApprovalPayCycle) + NaNtoNum(Transaction.CustomerPayCycle);
      functionCall({ jctdscid, dso });
    }
    return () => {
      mounted = false;
    };
  }, [jctdscid, Transaction]);

  return (
    <>
      {loading && 'Loading...'}
      {existsWithLength(data) &&
        data.map((ar) => (
          <div key={`${ar.Customer}${ar.OpenAmount}`} style={{ textAlign: 'center' }}>
            <Typography variant='h4'>{formatAsCurrency(ar.OpenAmount, true, 0)}</Typography>
            <Typography variant='caption'>{`${ar.Customer}`}</Typography>
          </div>
        ))}
    </>
  );
};

ARKPI.propTypes = {
  jctdscid: PropTypes.string.isRequired
};
ARKPI.defaultProps = {};

export default ARKPI;
