import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import TextDisplay from '../../UIComponents/TextDisplay';

const WorkDetails = (props) => {
  const { Transaction } = props;

  // const Roles = [
  //   { label: 'Management', value: 'Management' },
  //   { label: 'Financials', value: 'Financials' },
  //   { label: 'Operations', value: 'Operations' },
  //   { label: 'View?', value: 'View?' }
  // ];
  return (
    <Grid container>
      <TextDisplay label='Vice President' value={Transaction.VicePresident || ''} />
      <TextDisplay label='Division Manager' value={Transaction.DivisionManager || ''} />
      <TextDisplay label='Superintendent(s)' value='' />
      <TextDisplay label='Project Managers(s)' value={Transaction.ProjectManager || ''} />
      <TextDisplay label='Accounting Manager(s)' value={Transaction.AccountingManager || ''} />
      <div style={{ paddingTop: '8px', paddingLeft: '8px', fontStyle: 'italic' }}>
        Please reach out to JobCost if this information is out of date!
      </div>
    </Grid>
  );
};

WorkDetails.propTypes = {
  Transaction: PropTypes.objectOf(PropTypes.any).isRequired
};
WorkDetails.defaultProps = {};

export default WorkDetails;
