import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, createTheme } from '@mui/material';
import {
  approvedChanges,
  costToComplete,
  costToDate,
  customerReAllocation,
  originalBudget,
  projectedTotal,
  revisedBudget,
  variance
} from './sharedColumns';
import StatusDataGrid from './StatusDataGrid';
import { getApprovedCosts, getConstructionBudgets, getOgBudget, getRBudget } from './getValues';
import { getRate, getProductiveAmt, getRateForecasting, getAmt } from './calculationHelpers';

const EquipmentRates = (props) => {
  const { changeOrders, forecast } = props;
  const matches = useMediaQuery(createTheme().breakpoints.down('lg'));

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const columns = [
    originalBudget(getWidth, getFlex, 'currency'),
    customerReAllocation(getWidth, getFlex, 'currency'),
    approvedChanges(getWidth, getFlex, 'currency'),
    revisedBudget(getWidth, getFlex, 'currency'),
    costToDate(getWidth, getFlex, 'currency'),
    costToComplete(getWidth, getFlex, 'currency'),
    projectedTotal(getWidth, getFlex, 'currency'),
    variance(getWidth, getFlex, 'currency')
  ];

  const og = getOgBudget(changeOrders);
  const cb = getConstructionBudgets(changeOrders);
  const ac = getApprovedCosts(changeOrders);
  const rb = getRBudget(changeOrders);
  const fc = forecast.filter((f) => f.CostType === 'E' || f.CostType === 'R');

  const OriginalRate = getRate(og);
  const ConstructionRate = getRate(cb);
  const ApprovedRate = getRate(ac);
  const RevisedRate = getRate(rb);

  const CostToDate = getRateForecasting('CostToDate', 'HoursToDate', forecast, fc);
  const CostToComplete = getRateForecasting('CostToComplete', 'HoursToComplete', forecast, fc);
  const ProjectedTotal =
    (getAmt('CostToDate', fc) + getAmt('CostToComplete', fc)) /
    (getProductiveAmt(forecast, 'HoursToDate') + getProductiveAmt(forecast, 'HoursToComplete'));
  const Variance = RevisedRate - ProjectedTotal;

  const rows = [
    {
      id: 'EQUIPMENT RATES',
      Title: 'EQUIPMENT RATES',
      OriginalBudget: OriginalRate, // original budget equipment/productive hours from original budget
      ConstructionReAllocation: ConstructionRate, // construction budget equip/productive hours from construction budgets
      ApprovedChanges: ApprovedRate, // approved budget equip/productive hours from approved budgets
      RevisedBudget: RevisedRate, // revised budget equip/productive hours from revised budgets
      CostToDate, // equip cost to date/productive hours to date from forecast
      CostToComplete, // equip cost to complete / productive hours to complete
      ProjectedTotal, // equip projected total / productive hours at complete
      Variance // revised rate - projected rate
    }
  ];

  return (
    <Grid container className='aldg-bordered'>
      <Grid item xs={12} className='aldg-headerTitle'>
        EQUIPMENT RATES
      </Grid>
      <StatusDataGrid rows={rows} columns={columns} title='Equipment Rates' />
    </Grid>
  );
};

EquipmentRates.propTypes = {
  changeOrders: PropTypes.arrayOf(PropTypes.object),
  forecast: PropTypes.arrayOf(PropTypes.object)
};
EquipmentRates.defaultProps = {
  changeOrders: [],
  forecast: []
};

export default EquipmentRates;
