import NaNtoZero from '../../../utils/convertNaNToZero';

const genSortComparator = (a, b) => (NaNtoZero(a) > NaNtoZero(b) ? 1 : -1);

const sortOnSegment = (a, b) => {
  if (a.PayItem > b.PayItem) return 1;
  if (a.PayItem < b.PayItem) return -1;
  if (a.SegmentValue > b.SegmentValue) return 1;
  if (a.SegmentValue < b.SegmentValue) return -1;
  return -1;
};

export { genSortComparator, sortOnSegment };
