/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography } from '@mui/material';
import { InputDate } from '@aldridge/aldg-data-components';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { TailSpin } from 'react-loader-spinner';
import { faRotate, faLock, faBookCircleArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { config } from '../../../firebase';
import TooltipIconButton from '../../UIComponents/TooltipIconButton';

const FinancialWorkbook = (props) => {
  const { jctdscid, monthEndDate } = props;
  const { data, loading, error, variables, functionCall, setVars } = useFunctionCall('UpdateFinancialWorkbook');

  const RefreshWorkbook = () => {
    if (monthEndDate === '') {
      toast.error('Please Select a Month End');
      return;
    }
    const dtObj = dayjs(monthEndDate);
    if (monthEndDate !== dtObj.endOf('month').format('YYYY-MM-DD')) {
      toast.error('You must select the last day of the month to process the workbook.');
      return;
    }
    functionCall({ jctdscid, monthEndDate });
  };
  const LockMonthEndWorkbook = () => {
    if (monthEndDate === '') {
      toast.error('Please Select a Month End');
      return;
    }
    const dtObj = dayjs(monthEndDate);
    if (monthEndDate !== dtObj.endOf('month').format('YYYY-MM-DD')) {
      toast.error('You must select the last day of the month to lock the month end.');
      return;
    }
    toast.info('The workbook will close out the month end once the workflow has been defined.');
    // functionCall({ jctdscid, monthEndDate });
  };
  const GoToTableau = () => {
    let validMonthEnd = false;
    if (monthEndDate === '') {
      validMonthEnd = false;
    }
    const dtObj = dayjs(monthEndDate);
    if (monthEndDate !== dtObj.endOf('month').format('YYYY-MM-DD')) {
      validMonthEnd = false;
    }
    if (monthEndDate === dtObj.endOf('month').format('YYYY-MM-DD')) {
      validMonthEnd = true;
    }
    toast.info(`Go to Financial Workbook for ${jctdscid}${validMonthEnd ? ` Month End: ${monthEndDate}` : ''}.`);
    window.open(
      `https://001tableau01.aldridge-electric.com/#/views/FinancialWorkbook/JobDetails?Job%20Number=${
        jctdscid.split('.').length >= 3 ? jctdscid.split('.')[2] : ''
      }${validMonthEnd ? `&Month%20End%20Date=${monthEndDate}` : ''}`,
      '_blank'
    );
  };

  return (
    <Paper variant='outlined' className='aldg-field'>
      <Grid container>
        <Typography>Coming Soon.</Typography>
        {/* <Grid item xs={12} style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
          Looking to Update Your Financial Workbook? Please Select the Month End you&apos;d like to run your workbook for.
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          {loading ? (
            <TailSpin height={56} />
          ) : (
            <div style={{ display: 'flex' }}>
              <TooltipIconButton title='Refresh Workbook' onClick={RefreshWorkbook} icon={faRotate} />
              <TooltipIconButton title='Lock Month End Workbook' color='gray' onClick={LockMonthEndWorkbook} icon={faLock} />
              <TooltipIconButton title='Go To Financial Workbook' color='success' onClick={GoToTableau} icon={faBookCircleArrowRight} />
            </div>
          )}
        </Grid> */}
      </Grid>
    </Paper>
  );
};

FinancialWorkbook.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  monthEndDate: PropTypes.string
};
FinancialWorkbook.defaultProps = {
  monthEndDate: '2010-12-31'
};

export default FinancialWorkbook;
