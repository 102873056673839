import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, createTheme } from '@mui/material';
import { formatAsCurrency, formatAsPercent } from '../../UIComponents/format';
import { approvedChanges, originalBudget, revisedBudget, title } from './sharedColumns';
import StatusDataGrid from './StatusDataGrid';
import { getApprovedCosts, getConstructionBudgets, getOgBudget, getProjectedTotal, getRBudget } from './getValues';
import { SumTotalCost } from '../ChangeOrder/SumCosts';
import { mapDown } from './calculationHelpers';

const GrossProfitReports = (props) => {
  const { changeOrders, forecast, MonthEndContractNumbers } = props;
  const matches = useMediaQuery(createTheme().breakpoints.down('md'));

  const { ApprovedChanges, OriginalContract, TotalProjectedContract, TotalApprovedContract } = MonthEndContractNumbers;
  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const columns = [
    title(getWidth, getFlex),
    originalBudget(getWidth, getFlex),
    {
      field: 'AfterConstructionBudget',
      headerAlign: 'center',
      headerName: 'After Construction Budget',
      align: 'center',
      valueGetter: (params) => params.row.AfterConstructionBudget,
      valueFormatter: (params) => (params.id === 'Gross Return $' ? formatAsCurrency(params.value, true, 0) : formatAsPercent(params.value, true, 1)),
      width: getWidth(150),
      flex: getFlex(150)
    },
    approvedChanges(getWidth, getFlex),
    revisedBudget(getWidth, getFlex),
    {
      field: 'MarginWOPendings',
      headerAlign: 'center',
      headerName: 'MARGIN w/o Pendings',
      align: 'center',
      valueGetter: (params) => params.row.MarginWOPendings,
      valueFormatter: (params) => (params.id === 'Gross Return $' ? formatAsCurrency(params.value, true, 0) : formatAsPercent(params.value, true, 1)),
      width: getWidth(150),
      flex: getFlex(150)
    },
    {
      field: 'MarginWPendings',
      headerAlign: 'center',
      headerName: 'MARGIN w/ Pendings',
      align: 'center',
      valueGetter: (params) => params.row.MarginWPendings,
      valueFormatter: (params) => (params.id === 'Gross Return $' ? formatAsCurrency(params.value, true, 0) : formatAsPercent(params.value, true, 1)),
      width: getWidth(150),
      flex: getFlex(150)
    },
    {
      field: 'MarginVarianceWO',
      headerAlign: 'center',
      headerName: 'vs. Margin w/o Pending VARIANCE',
      align: 'center',
      valueGetter: (params) => params.row.MarginVarianceWO,
      valueFormatter: (params) => (params.id === 'Gross Return $' ? formatAsCurrency(params.value, true, 0) : formatAsPercent(params.value, true, 1)),
      width: getWidth(200),
      flex: getFlex(150)
    },
    {
      field: 'MarginVariance',
      headerAlign: 'center',
      headerName: 'vs. Margin w/ Pending VARIANCE',
      align: 'center',
      valueGetter: (params) => params.row.MarginVariance,
      valueFormatter: (params) => (params.id === 'Gross Return $' ? formatAsCurrency(params.value, true, 0) : formatAsPercent(params.value, true, 1)),
      width: getWidth(200),
      flex: getFlex(150)
    }
  ];

  const og = getOgBudget(changeOrders);
  const cb = getConstructionBudgets(changeOrders);
  const ac = getApprovedCosts(changeOrders);
  const rb = getRBudget(changeOrders);

  const TotalOriginalCosts = SumTotalCost(mapDown(og));
  const TotalConstructionCosts = SumTotalCost(mapDown(cb));
  const TotalApprovedCosts = SumTotalCost(mapDown(ac));
  const TotalRevisedCosts = SumTotalCost(mapDown(rb));
  const TotalProjectedTotal = getProjectedTotal(forecast);

  const OriginalBudgetDollar = OriginalContract - TotalOriginalCosts;
  const OriginalBudgetPercent = OriginalBudgetDollar / OriginalContract;

  const ConstructionBudgetDollar = OriginalContract - (TotalOriginalCosts + TotalConstructionCosts);
  const ConstructionBudgetPercent = ConstructionBudgetDollar / OriginalContract;

  const ApprovedBudgetDollar = ApprovedChanges - TotalApprovedCosts;
  const ApprovedBudgetPercent = ApprovedBudgetDollar / ApprovedChanges;
  const RevisedBudgetDollar = TotalApprovedContract - TotalRevisedCosts;
  const RevisedBudgetPercent = RevisedBudgetDollar / TotalApprovedContract;
  const MarginWOPendingsDollar = TotalApprovedContract - TotalProjectedTotal;
  const MarginWOPendingsPercent = MarginWOPendingsDollar / TotalApprovedContract;

  const MarginWPendingsDollar = TotalProjectedContract - TotalProjectedTotal;
  const MarginWPendingsPercent = MarginWPendingsDollar / TotalProjectedContract;

  const MarginVarianceDollar = MarginWPendingsDollar - RevisedBudgetDollar;
  const MarginVariancePercent = MarginVarianceDollar / TotalProjectedContract;

  const MarginVarianceDollarWO = MarginWOPendingsDollar - RevisedBudgetDollar;
  const MarginVariancePercentWO = MarginVarianceDollarWO / TotalApprovedContract;

  const rows = [
    {
      id: 'Gross Return $',
      Title: 'Gross Return $',
      OriginalBudget: OriginalBudgetDollar, // original contract - total costs
      AfterConstructionBudget: ConstructionBudgetDollar, // original contract - (total costs + construction realloc)
      ApprovedChanges: ApprovedBudgetDollar, // approved changes - total approved costs
      RevisedBudget: RevisedBudgetDollar, // total approved contract - revised costs
      MarginWOPendings: MarginWOPendingsDollar, // total approved contract + pending changes negative - total projected total
      MarginWPendings: MarginWPendingsDollar, // total projected contract - total projected total
      MarginVariance: MarginVarianceDollar, // margin W pendings - revised budget
      MarginVarianceWO: MarginVarianceDollarWO // margin WO pendings - revised budget
    },
    {
      id: 'Gross Return %',
      Title: 'Gross Return %',
      OriginalBudget: OriginalBudgetPercent,
      AfterConstructionBudget: ConstructionBudgetPercent,
      ApprovedChanges: ApprovedBudgetPercent,
      RevisedBudget: RevisedBudgetPercent,
      MarginWOPendings: MarginWOPendingsPercent,
      MarginWPendings: MarginWPendingsPercent,
      MarginVariance: MarginVariancePercent,
      MarginVarianceWO: MarginVariancePercentWO
    }
  ];

  return (
    <Grid container className='aldg-bordered'>
      <Grid item xs={12} className='aldg-headerTitle'>
        GROSS PROFIT REPORTS
      </Grid>
      <StatusDataGrid rows={rows} columns={columns} title='Gross Profit' />
    </Grid>
  );
};

GrossProfitReports.propTypes = {
  changeOrders: PropTypes.arrayOf(PropTypes.object),
  forecast: PropTypes.arrayOf(PropTypes.object),
  MonthEndContractNumbers: PropTypes.object
};
GrossProfitReports.defaultProps = {
  changeOrders: [],
  forecast: [],
  MonthEndContractNumbers: {}
};

export default GrossProfitReports;
