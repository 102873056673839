import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, createTheme } from '@mui/material';
import existsWithLength from '../../utils/existsWithLength';

const TextDisplay = (props) => {
  const { label, value, editable, displayFunc, colorCondition } = props;
  const matches = useMediaQuery(createTheme().breakpoints.up('md'));

  const needsInput = editable && !existsWithLength(value);

  return (
    <div style={{ display: matches && 'flex', width: '100%', justifyContent: 'space-between', padding: '0px 8px' }}>
      <div
        style={{
          paddingRight: '6px',
          color: '#a9a9a9',
          whiteSpace: matches && 'nowrap',
          fontStyle: editable && 'italic',
          fontWeight: needsInput && 'bold'
        }}
      >
        {label}:
      </div>
      <div style={{ textAlign: 'right', padding: !matches && '4px 0px', color: colorCondition }}>{displayFunc(value)?.trim() || ''}</div>
    </div>
  );
};

TextDisplay.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  displayFunc: PropTypes.func,
  colorCondition: PropTypes.string
};
TextDisplay.defaultProps = {
  label: '',
  value: '',
  editable: false,
  displayFunc: (val) => val?.toString(),
  colorCondition: '#000000'
};

export default TextDisplay;
