import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import { formatAsCurrency } from '../../../../UIComponents/format';

const ContigencyCard = (props) => {
  const { contingency, key } = props;

  let name = contingency.PiccDescription;
  const contiStart = contingency?.PiccDescription?.indexOf('CONTI');
  if (contiStart > 0) name = name.substring(0, contiStart);
  name = name.toLowerCase();
  name = name[0].toUpperCase() + name.substring(1);
  return (
    <>
      {contingency.MonthEndDate !== '2010-12-31' && (
        <div key={key}>
          <Card variant='outlined' style={{ marginBottom: '8px' }}>
            <CardHeader
              title={`${name} [${contingency.Picc}]`}
              subheader={`Last updated ${dayjs(contingency.LastSynced).format('YYYY-MM-DD @ h:mm a')}`}
              style={{ padding: '16px 8px 0px 16px' }}
            />
            <CardContent style={{ padding: '8px 16px' }}>
              <div style={{ textAlign: 'center', fontSize: '30px' }}>{formatAsCurrency(contingency.CostToComplete, true, 0)}</div>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

ContigencyCard.propTypes = {
  contingency: PropTypes.object,
  key: PropTypes.string
};
ContigencyCard.defaultProps = {
  contingency: {
    MonthEndDate: '2010-12-31',
    Picc: '0',
    PiccDescription: 'Unknown',
    CostToComplete: 0
  },
  key: '0'
};

export default ContigencyCard;
