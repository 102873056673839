/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, createTheme, useMediaQuery, Typography, Paper, Button } from '@mui/material';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { useRecoilValue } from 'recoil';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import existsWithLength from '../../../utils/existsWithLength';
import { isApproved, isApprovedWithExtras } from '../../../utils/isPending';
import TooltipIconButton from '../../UIComponents/TooltipIconButton';
import { StatusContext } from '../../../providers/StatusProvider';
import aggregateChangeOrders from './aggregateChangeOrders';
import ChangeOrderSummaryTable from './ChangeOrderSummaryTable';
import RevisedBudgetDataGrid from './RevisedBudgetDataGrid';
import { formatAsCurrency, formatWithCommas } from '../../UIComponents/format';
import JumpToButton from '../../UIComponents/JumpToButton';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { sortOnSegment, genSortComparator } from './budgetSort';
import DownloadChangeOrder from './DownloadChangeOrder';
import { _Defaults } from '../../../_Recoil/atoms';
import { firestore } from '../../../firebase';
import { getRBudget } from '../ProjectStatus/getValues';

const RevisedBudget = (props) => {
  const { jctdscid, history, Transaction, monthEndDate } = props;

  const { budgets } = useContext(StatusContext);
  const Defaults = useRecoilValue(_Defaults);
  const matches = useMediaQuery(createTheme().breakpoints.down('md'));
  const [Segments, setSegments] = useState([]);
  const [aggRecord, setAggRecord] = useState({
    ChangeOrders: [],
    data: {
      ChangeOrderType: 'Revised Budget',
      ContractValue: 0,
      LaborChangeOrders: [],
      EquipmentChangeOrders: [],
      MaterialChangeOrders: [],
      SubcontractorsChangeOrders: [],
      OtherChangeOrders: []
    }
  });

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const createLaborTotalRow = (rowSet) => {
    if (!existsWithLength(rowSet)) return [];
    return rowSet?.sort((a, b) => sortOnSegment(a, b));
  };

  const createNonLaborTotalRow = (rowSet) => {
    if (!existsWithLength(rowSet)) return [];
    return rowSet?.sort((a, b) => sortOnSegment(a, b));
  };

  useEffect(() => {
    const getData = async () => {
      let mounted = true;
      const rBudget = getRBudget(structuredClone(budgets));
      const res = await aggregateChangeOrders(structuredClone(budgets), isApprovedWithExtras);
      const resSorted = JSON.parse(JSON.stringify(res));
      resSorted.data.ChangeOrderType = 'Revised Budget';
      resSorted.data.LaborChangeOrders = createLaborTotalRow(resSorted?.data?.LaborChangeOrders);
      resSorted.data.MaterialChangeOrders = createNonLaborTotalRow(resSorted?.data?.MaterialChangeOrders);
      resSorted.data.EquipmentChangeOrders = createNonLaborTotalRow(resSorted?.data?.EquipmentChangeOrders);
      resSorted.data.SubcontractorsChangeOrders = createNonLaborTotalRow(resSorted?.data?.SubcontractorsChangeOrders);
      resSorted.data.OtherChangeOrders = createNonLaborTotalRow(resSorted?.data?.OtherChangeOrders);
      resSorted.ChangeOrders = rBudget;
      if (mounted) setAggRecord(resSorted);
      return () => {
        mounted = false;
      };
    };
    getData();
  }, [budgets, jctdscid, monthEndDate]);

  useEffect(() => {
    let mounted = true;
    getDocs(query(collection(firestore, 'ENT-Segments'), where('Status', '==', 'Yes'), orderBy('SegmentValue'))).then((d) => {
      const all = [];
      d.forEach((dd) => all.push(dd.data()));
      if (mounted) setSegments(all);
    });
    return () => {
      mounted = false;
    };
  }, [jctdscid, monthEndDate]);

  const laborCols = [
    {
      field: 'PayItem',
      headerAlign: 'left',
      headerName: 'Pay Item',
      valueGetter: (params) => params.row.PayItem,
      flex: getFlex(37),
      width: getWidth(100)
    },
    {
      field: 'CostCode',
      headerAlign: 'center',
      headerName: 'Cost Code',
      valueGetter: (params) => params.row.CostCodeDisplay,
      flex: getFlex(100),
      width: getWidth(500)
    },
    {
      field: 'LaborRate',
      headerAlign: 'center',
      align: 'right',
      headerName: 'Rate',
      valueGetter: (params) => params.row.LaborRate,
      valueFormatter: (params) => (params.value === '-' ? '-' : formatAsCurrency(params.value)),
      sortComparator: (param1, param2) => genSortComparator(param1, param2),
      type: 'number',
      aggregable: false,
      flex: getFlex(40),
      width: getWidth(80)
    },
    {
      field: 'LaborHours',
      headerAlign: 'center',
      align: 'right',
      headerName: 'Hours',
      type: 'number',
      availableAggregationFunctions: ['sum'],
      aggregable: true,
      valueGetter: (params) => NaNtoZero(params.row.LaborHours),
      valueFormatter: (params) => formatWithCommas(params.value),
      sortComparator: (param1, param2) => genSortComparator(param1, param2),
      flex: getFlex(30),
      width: getWidth(80)
    },
    {
      field: 'LaborCost',
      headerAlign: 'center',
      align: 'right',
      type: 'number',
      availableAggregationFunctions: ['sum'],
      aggregable: true,
      headerName: 'Cost',
      valueGetter: (params) => params.row.LaborCost,
      valueFormatter: (params) => formatAsCurrency(params.value),
      sortComparator: (param1, param2) => genSortComparator(param1, param2),
      flex: getFlex(60),
      width: getWidth(80)
    },
    {
      field: 'BurdenCost',
      headerAlign: 'center',
      align: 'right',
      aggregable: true,
      type: 'number',
      availableAggregationFunctions: ['sum'],
      headerName: 'Burden',
      valueGetter: (params) => params.row.BurdenCost,
      valueFormatter: (params) => formatAsCurrency(params.value),
      sortComparator: (param1, param2) => genSortComparator(param1, param2),
      flex: getFlex(50),
      width: getWidth(80)
    },
    {
      field: 'LaborTotal',
      headerAlign: 'center',
      align: 'right',
      type: 'number',
      availableAggregationFunctions: ['sum'],
      aggregable: true,
      headerName: 'Total',
      valueGetter: (params) => params.row.LaborTotal,
      valueFormatter: (params) => formatAsCurrency(params.value),
      sortComparator: (param1, param2) => genSortComparator(param1, param2),
      flex: getFlex(60),
      width: getWidth(80)
    }
  ];

  const nonLaborCols = [
    {
      field: 'PayItem',
      headerAlign: 'left',
      headerName: 'Pay Item',
      valueGetter: (params) => params.row.PayItem,
      flex: getFlex(37),
      width: getWidth(100)
    },
    {
      field: 'CostCode',
      headerAlign: 'center',
      headerName: 'Cost Code',
      valueGetter: (params) => params.row.CostCodeDisplay,
      flex: getFlex(100),
      width: getWidth(500)
    },
    {
      field: 'Total',
      headerAlign: 'center',
      headerName: 'Total',
      aggregable: true,
      type: 'number',
      availableAggregationFunctions: ['sum'],
      align: 'right',
      valueGetter: (params) => NaNtoZero(params.row.Total),
      valueFormatter: (params) => formatAsCurrency(params.value),
      sortComparator: (param1, param2) => genSortComparator(param1, param2),
      flex: getFlex(100),
      width: getWidth(500)
    }
  ];

  return (
    <Paper className='aldg-field' variant='outlined'>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: 'block',
            padding: '8px 0px 0px 0px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%'
            }}
          >
            <Typography variant='h4' style={{ padding: '0px 8px 12px 8px', width: '100%' }}>
              Revised Budget
              <hr />
            </Typography>
            <TooltipIconButton title='Hide Revised Budget' icon={faEyeSlash} color='gray' onClick={() => history.push(`/Job/${jctdscid}/Budgets`)} />
          </div>
          <ChangeOrderSummaryTable record={aggRecord} />
          <Grid container>
            <Grid item xs={4} />
            <Grid item xs={6}>
              {' '}
              <Button
                variant='contained'
                onClick={() =>
                  DownloadChangeOrder(
                    jctdscid,
                    aggRecord,
                    Segments,
                    Transaction.ProjectManager,
                    Transaction.ContractGLRate || Defaults.ContractGLRate,
                    Defaults
                  )
                }
              >
                Download Revised Budget
              </Button>
            </Grid>

            <Typography variant='h5' style={{ padding: '20px 0px 12px 8px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              Line Item Breakdown
              <JumpToButton
                tableNames={['Labor', 'Material', 'Equipment', 'Subcontractors', 'Other']}
                distinguisher='RevisedBudgetTable'
                extraTxt=' Breakdown '
              />
            </Typography>
            <RevisedBudgetDataGrid
              rows={aggRecord.data?.LaborChangeOrders || []}
              changeOrders={budgets.filter((r) => isApproved(r?.data?.Status, r?.data?.ChangeOrderType))}
              columns={laborCols}
              title='Labor'
            />
            <RevisedBudgetDataGrid
              rows={aggRecord.data?.MaterialChangeOrders || []}
              changeOrders={budgets.filter((r) => isApproved(r?.data?.Status, r?.data?.ChangeOrderType))}
              columns={nonLaborCols}
              title='Material'
            />
            <RevisedBudgetDataGrid
              rows={aggRecord.data?.SubcontractorsChangeOrders || []}
              changeOrders={budgets.filter((r) => isApproved(r?.data?.Status, r?.data?.ChangeOrderType))}
              columns={nonLaborCols}
              title='Subcontractors'
            />
            <RevisedBudgetDataGrid
              rows={aggRecord.data?.EquipmentChangeOrders || []}
              changeOrders={budgets.filter((r) => isApproved(r?.data?.Status, r?.data?.ChangeOrderType))}
              columns={nonLaborCols}
              title='Equipment'
            />
            <RevisedBudgetDataGrid
              rows={aggRecord.data?.OtherChangeOrders || []}
              changeOrders={budgets.filter((r) => isApproved(r?.data?.Status, r?.data?.ChangeOrderType))}
              columns={nonLaborCols}
              title='Other'
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

RevisedBudget.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  Transaction: PropTypes.object,
  monthEndDate: PropTypes.string.isRequired
};
RevisedBudget.defaultProps = { Transaction: {} };

export default RevisedBudget;
