import { collection, doc, getDocs, setDoc, where } from 'firebase/firestore';
import { toast } from 'react-toastify';
import pullLastCashFlow from '../Components/Jobs/CashFlow/pullLastCashFlow';
import pullPreviousBudgets from '../Components/Jobs/ChangeOrder/pullPreviousBudgets';
import { generateForecast } from '../Components/Jobs/Forecasting/generateForecast';
import { firestore } from '../firebase';
import NaNtoZero from './convertNaNToZero';
import getCashflowRows from './getCashflowRows';

// eslint-disable-next-line no-unused-vars
const pullFromPrevious = async (
  jctdscid,
  monthEndDate,
  mostRecentMonth,
  setMostRecentMonth,
  generating,
  refreshData,
  user,
  CashflowData,
  refreshCashflowData,
  Transaction,
  totalPT,
  showToast = true,
  collections = []
) => {
  if (generating.current.loading) {
    try {
      let budgets = [];
      if (!collections.includes('ChangeOrders')) {
        // move budgets
        budgets = await pullPreviousBudgets(jctdscid, monthEndDate, mostRecentMonth, user, 'New Month Modal', showToast);
      } else {
        // get existing budgets
        const budgetsDocs = await getDocs(
          collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/ChangeOrders`),
          where('MonthPulledFrom', '!=', monthEndDate)
        );
        budgetsDocs.forEach((a) => budgets.push(a.data()));
      }
      // move forecasting
      await generateForecast(jctdscid, budgets, generating, monthEndDate, mostRecentMonth, refreshData, () => {}, user, showToast);

      if (!collections.includes('CashFlow')) {
        // move cash flow
        await pullLastCashFlow(jctdscid, monthEndDate, mostRecentMonth, getCashflowRows, CashflowData, Transaction, totalPT, showToast);
        // .then(() =>
        //   refreshCashflowData()
        // );
      }

      if (!collections.includes('Agenda')) {
        // move agenda
        const AgendaDocs = await getDocs(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${mostRecentMonth}/Agenda`));
        AgendaDocs.forEach((d) => {
          setDoc(doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/Agenda`, d.id), d.data(), { merge: true });
        });
        if (showToast) toast.success(`Agenda pulled ${NaNtoZero(AgendaDocs.size)} records successfully!`);
      }

      if (!collections.includes('OverUnderBilling')) {
        // move over/under billing
        const OverUnderBillingDocs = await getDocs(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${mostRecentMonth}/OverUnderBilling`));
        if (OverUnderBillingDocs.docs.length > 0) {
          const nd = OverUnderBillingDocs.docs[0].data();
          setDoc(
            doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/OverUnderBilling`, OverUnderBillingDocs.docs[0].id),
            { ...nd, Memorialized: true },
            { merge: true }
          );
        }
        setDoc(
          doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}`),
          { MonthStartedBy: user.email, MonthStartedDate: new Date().toJSON() },
          { merge: true }
        );
        if (showToast) toast.success(`Over/Underbilling pulled ${NaNtoZero(OverUnderBillingDocs.size)} records successfully!`);
        setMostRecentMonth(undefined);
      }
    } catch (err) {
      console.log(err);
      if (showToast) toast.error(`Something went wrong. ${err.message}`);
    }
  }
};

export default pullFromPrevious;
