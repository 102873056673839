/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Table, TableRow, TableCell, TableBody, Paper, Grid } from '@mui/material';
import { faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { onSnapshot, query, collection, orderBy, deleteDoc, doc } from 'firebase/firestore';
import dayjs from 'dayjs';
import TooltipIconButton from '../../Components/UIComponents/TooltipIconButton';
import { firestore } from '../../firebase';

const ChangelogList = (props) => {
  const { history } = props;
  const [reports, setReports] = useState([]);

  useEffect(() => {
    let mounted = true;
    const q = query(collection(firestore, 'Changelog'), orderBy('DeployDate', 'desc'));
    onSnapshot(q, (snap) => {
      const docs = [];
      if (!snap.empty) {
        snap.forEach((d) => {
          docs.push(d.data());
        });
      }
      if (mounted) setReports(docs);
    });
    return () => (mounted = false);
  }, []);

  const addNew = () => {
    history.push(`/changelog/new`);
  };

  const removeRecord = (r) => {
    deleteDoc(doc(firestore, 'Changelog'), r).then(() => {
      toast.success('Changelog Removed.');
    });
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper variant='outlined' className='aldg-formButtons' style={{ border: 'none' }}>
          <TooltipIconButton title='Add New Changelog' icon={faPlus} onClick={addNew} />
        </Paper>
        <Grid item xs={12} style={{ padding: '3px 0' }} />
        <Table>
          <TableBody>
            <TableRow style={{ backgroundColor: 'white' }}>
              <TableCell>Version</TableCell>
              <TableCell>Deploy Date</TableCell>
              <TableCell style={{ width: '75px' }} />
            </TableRow>
            {reports.map((r, index) => (
              <TableRow key={`${r.id}`} style={{ background: index % 2 ? 'white' : '#efefef', cursor: 'pointer' }}>
                <TableCell onClick={() => history.push(`changelog/${r.id}`)}>{r.Version}</TableCell>
                <TableCell onClick={() => history.push(`changelog/${r.id}`)}>{dayjs(r.DeployDate).format('YYYY-MM-DD')}</TableCell>
                <TableCell onClick={() => history.push(`changelog/${r.id}`)}>
                  <TooltipIconButton icon={faTrashAlt} color='error' onClick={() => removeRecord(r.id)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

ChangelogList.propTypes = {
  // match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
ChangelogList.defaultProps = {
  // match: {
  //   params: {}
  // },
  history: {
    push: () => {}
  }
};
export default ChangelogList;
