/* eslint-disable react/no-unused-state */

import React from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { collection, doc, setDoc } from 'firebase/firestore';
import { getIdToken } from 'firebase/auth';
import fRefreshApplication from '../utils/forceRefresh';
import SubmitHelpDeskTicket from '../utils/SubmitHelpDeskTicket';
import { functions, auth, firestore } from '../firebase';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, data: null, fError: null };

    this.firebaseCall = this.firebaseCall.bind(this);
    this.sendTheMail = this.sendTheMail.bind(this);
  }

  componentDidCatch(e, ei) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: e,
      errorInfo: ei
    });
    // You can also log error messages to an error reporting service here
    const { componentName, user, versionNumber } = this.props;
    this.sendTheMail(componentName, ei.componentStack, e, versionNumber);
    const analytics = getAnalytics();
    logEvent(analytics, 'page_render_error', {
      pageWithError: componentName,
      versionNumber,
      errorDetailInfo: ei.componentStack,
      errorLogged: JSON.parse(JSON.stringify(e))
    });
    const d = {
      pageWithError: componentName,
      versionNumber,
      user: user.email,
      errorDetailInfo: ei.componentStack,
      errorLogged: JSON.parse(JSON.stringify(e)),
      date: new Date().toJSON()
    };
    if (firestore._terminated) return;
    const docRef = doc(collection(firestore, 'PageRenderError'));
    d.id = docRef.id;
    setDoc(docRef, d);
  }

  firebaseCall(body) {
    const firebaseFunction = httpsCallable(functions, 'SendEmail');
    this.setState({ data: null, fError: null });
    const dataCall = (b) => {
      try {
        getIdToken(auth.currentUser)
          .then((idToken) => {
            const opts = {
              b,
              idToken,
              host: window.location.hostname
            };
            firebaseFunction(opts)
              .then((res) => {
                switch (res.data?.status) {
                  case 'Success':
                    this.setState({ data: res.data.message });
                    break;
                  case 'Error':
                    this.setState({ fError: res.data.message });
                    break;
                  default:
                    this.setState({ fError: JSON.stringify(res) });
                    break;
                }
              })
              .catch((err) => {
                this.setState({ fError: err.message });
              });
          })
          .catch((err) => {
            this.setState({ fError: err.message });
          });
      } catch (err) {
        // eslint-disable-next-line
        console.error('Failed to send email.');
      }
    };
    dataCall(body);
    return this.firebaseCall;
  }

  sendTheMail(cName, eStack, err, versionNumber) {
    const sendEmail = this.firebaseCall;
    if (window.location.hostname === 'localhost') return;
    sendEmail({
      to: ['developers@aldridgegroup.com'],
      subject: 'Page Render Error',
      html: `<h1>${cName} has crashed :/</h1><h2>${err} - ${versionNumber}</h2> <p>${eStack}</p>`
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      // Error path
      return (
        <div>
          <h1>Something went wrong.</h1>
          {/* <span className="fa-layers fa-fw" style={{ width: '5em', height: '100%', position: 'relative' }}>
            <FontAwesomeIcon icon={faCog} style={{ fontSize: '4em' }} />
            <FontAwesomeIcon icon={faCog} style={{ color: 'red', position: 'absolute', top: '-40px', left: '64px', fontSize: '2.5em' }} pulse />
          </span> */}
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <br />
            {error && error.toString()}
            <h3 style={{ marginTop: '16px' }}>Keep seeing this? </h3>
            <div style={{ padding: '8px' }}>
              <Button color='primary' variant='contained' onClick={fRefreshApplication}>
                Force Refresh Application
              </Button>
            </div>
            <h4>Or submit a help desk ticket.</h4>
            <div style={{ padding: '8px' }}>
              <SubmitHelpDeskTicket />
            </div>
          </details>
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  componentName: PropTypes.string.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  versionNumber: PropTypes.string
};

ErrorBoundary.defaultProps = {
  user: { email: 'none' },
  versionNumber: '0.0.0'
};

export default ErrorBoundary;
