import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';

const getStatusMonth = async () =>
  new Promise((resolve) => {
    onSnapshot(doc(firestore, `CompanyDefaults/StatusMonth`), (d) => {
      resolve(d.data().StatusMonth);
    });
  });

export default getStatusMonth;
