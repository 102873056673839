import convertCostTypeRToE from '../../../utils/convertCostTypeRToE';
import NaNtoZero from '../../../utils/convertNaNToZero';
import existsWithLength from '../../../utils/existsWithLength';
import { SumCosts } from '../ChangeOrder/SumCosts';

// maps array of arrays of objects down to a single array based off the given property
const flattenData = (arr, property) => [].concat(...arr.map((a) => a.data[property]));
const flatten = (arr, property) => [].concat(...arr.map((a) => a[property]));

// sums all the labor change orders in an array of arrays
const SumLabor = (arr, type = ['P', 'NP']) => {
  const costsArr = [];
  arr.forEach((a) =>
    a.data.LaborChangeOrders.forEach((co) => {
      if (type.includes(co.ProductiveCostCode)) {
        costsArr.push(a.data.ChangeOrderType === 'Change Order Escalation' ? co.LaborTotal : co.LaborHours * co.LaborRate);
      }
    })
  );
  //   SumCosts(
  //     flattenData(arr, 'LaborChangeOrders')
  //     .filter((co) => type.includes(co.ProductiveCostCode))
  //     .map((lco) => lco.LaborHours * lco.LaborRate)
  // )
  return existsWithLength(arr) ? SumCosts(costsArr) : 0;
};

const SumLaborHours = (arr, type = ['P', 'NP']) =>
  existsWithLength(arr)
    ? SumCosts(
        flattenData(arr, 'LaborChangeOrders')
          .filter((co) => type.includes(co.ProductiveCostCode))
          .map((lco) => lco.LaborHours)
      )
    : 0;

// sums all the non labor change orders in an array of arrays
const SumNonLabor = (arr, costType) => (existsWithLength(arr) ? SumCosts(flattenData(arr, `${costType}ChangeOrders`).map((co) => co.Total)) : 0);

const SumCostDetails = (arr, costType, summer) =>
  existsWithLength(arr)
    ? SumCosts(
        flatten(
          arr.filter((piccCostType) => (typeof costType === 'undefined' ? piccCostType : convertCostTypeRToE(piccCostType.CostType) === costType)),
          `${summer}`
        )
      )
    : 0;

const SumLaborCostToComplete = (arr) => {
  if (!existsWithLength(arr)) return 0;
  return arr.reduce((totalCHTC, lco) => totalCHTC + NaNtoZero(lco.CostHrToComplete) * NaNtoZero(lco.HoursToComplete), 0);
};

// sums all the labor change orders in an array of arrays
const SumToDate = (arr, toDate, type = 'P') =>
  existsWithLength(arr)
    ? SumCosts(
        arr
          .filter((co) => co.CostType === 'L')
          .filter((co) => co.PNP === type)
          .map((lco) => lco[toDate])
      )
    : 0;

// maps an array of arrays down to one array
const mapDown = (budgetArr) => {
  const resultObj = {
    data: {
      LaborChangeOrders: [].concat(...budgetArr.map((ba) => ba.data.LaborChangeOrders)),
      MaterialChangeOrders: [].concat(...budgetArr.map((ba) => ba.data.MaterialChangeOrders)),
      SubcontractorsChangeOrders: [].concat(...budgetArr.map((ba) => ba.data.SubcontractorsChangeOrders)),
      EquipmentChangeOrders: [].concat(...budgetArr.map((ba) => ba.data.EquipmentChangeOrders)),
      OtherChangeOrders: [].concat(...budgetArr.map((ba) => ba.data.OtherChangeOrders))
    }
  };
  return resultObj;
};

const getProductiveAmt = (lcos, property) =>
  NaNtoZero(
    lcos
      .filter((lco) => lco.ProductiveCostCode === 'P' || lco.PNP === 'P')
      .reduce((total, lco) => total + NaNtoZero(lco[property]), 0)
      .toFixed(2)
  );

const getRate = (budgetType) => {
  const Equip = SumNonLabor(budgetType, 'Equipment');
  const ProductiveHours = existsWithLength(budgetType) ? getProductiveAmt(flattenData(budgetType, 'LaborChangeOrders'), 'LaborHours') : 0;
  return ProductiveHours !== 0 ? Equip / ProductiveHours : 0;
};

const getAmt = (equipProperty, fc) => (existsWithLength(fc) ? fc.reduce((totalEquip, f) => totalEquip + NaNtoZero(f[equipProperty]), 0) : 0);

const getRateForecasting = (equipProperty, productiveProperty, forecast, fc) => {
  const EquipAmt = getAmt(equipProperty, fc);
  const ProductiveAmt = existsWithLength(forecast) ? getProductiveAmt(forecast, productiveProperty) : 0;
  return EquipAmt / ProductiveAmt;
};

export {
  flattenData,
  flatten,
  SumCostDetails,
  SumLaborCostToComplete,
  SumLabor,
  SumToDate,
  SumLaborHours,
  SumNonLabor,
  mapDown,
  getRate,
  getProductiveAmt,
  getAmt,
  getRateForecasting
};
