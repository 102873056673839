import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, createTheme, Typography } from '@mui/material';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { formatWithCommas, formatAsCurrency } from '../../UIComponents/format';
import { isApproved } from '../../../utils/isPending';
import { approvedChanges, customerReAllocation, originalBudget, projectedTotal, revisedBudget, title, variance } from './sharedColumns';
import StatusDataGrid from './StatusDataGrid';
import { SumLabor, SumLaborHours, SumCostDetails, SumToDate, SumLaborCostToComplete } from './calculationHelpers';

const LaborSummary = (props) => {
  const { changeOrders, forecast } = props;

  const matches = useMediaQuery(createTheme().breakpoints.down('md'));

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const ogBudget = changeOrders.filter((co) => co?.data?.ChangeOrderType === 'Original Budget');
  const constructionBudgets = changeOrders.filter((co) => co?.data?.ChangeOrderType === 'Construction Budget');
  const rBudget = changeOrders.filter((co) => isApproved(co?.data?.Status, co?.data?.ChangeOrderType));
  const approvedCosts = changeOrders.filter(
    (co) => isApproved(co?.data?.Status, co?.data?.ChangeOrderType) && !['Original Budget', 'Construction Budget'].includes(co?.data?.ChangeOrderType)
  );

  const columns = [
    title(getWidth, getFlex),
    originalBudget(getWidth, getFlex, 'currency'),
    customerReAllocation(getWidth, getFlex, 'currency'),
    approvedChanges(getWidth, getFlex, 'currency'),
    revisedBudget(getWidth, getFlex, 'currency'),
    {
      field: 'HoursToDate',
      headerAlign: 'center',
      headerName: 'To Date',
      align: 'center',
      valueGetter: (params) =>
        params.id.includes('Rate') ? formatAsCurrency(params.row.HoursToDate, true, 0) : formatWithCommas(params.row.HoursToDate, true, 0),
      width: getWidth(150),
      flex: getFlex(120)
    },
    {
      field: 'HoursToComplete',
      headerAlign: 'center',
      headerName: 'To Complete',
      align: 'center',
      valueGetter: (params) =>
        params.id.includes('Rate') ? formatAsCurrency(params.row.HoursToComplete) : formatWithCommas(params.row.HoursToComplete, true, 0),
      width: getWidth(150),
      flex: getFlex(120)
    },
    projectedTotal(getWidth, getFlex, 'currency'),
    variance(getWidth, getFlex, 'currency')
  ];

  const pLaborHoursOG = SumLaborHours(ogBudget, ['P']);
  const pLaborHoursC = SumLaborHours(constructionBudgets, ['P']);
  const pLaborHoursR = SumLaborHours(rBudget, ['P']);
  const pLaborHoursA = SumLaborHours(approvedCosts, ['P']);

  const npLaborHoursOG = SumLaborHours(ogBudget, ['NP']);
  const npLaborHoursC = SumLaborHours(constructionBudgets, ['NP']);
  const npLaborHoursR = SumLaborHours(rBudget, ['NP']);
  const npLaborHoursA = SumLaborHours(approvedCosts, ['NP']);

  const pForecasts = forecast.filter((f) => f.CostType === 'L').filter((f) => f.PNP === 'P');
  const PCostToDate = SumCostDetails(pForecasts, 'L', 'CostToDate');
  const PCostToComplete = SumLaborCostToComplete(pForecasts);
  const pHrsToDate = SumToDate(pForecasts, 'HoursToDate');
  const pHrsToComplete = SumToDate(pForecasts, 'HoursToComplete');

  const npForecasts = forecast.filter((f) => f.CostType === 'L').filter((f) => f.PNP === 'NP');
  const NPCostToDate = SumCostDetails(npForecasts, 'L', 'CostToDate');
  const NPCostToComplete = SumLaborCostToComplete(npForecasts);
  const npHrsToDate = SumToDate(npForecasts, 'HoursToDate', 'NP');
  const npHrsToComplete = SumToDate(npForecasts, 'HoursToComplete', 'NP');

  const pRows = [
    {
      Title: 'Hours (P)',
      OriginalBudget: pLaborHoursOG,
      ConstructionReAllocation: pLaborHoursC,
      RevisedBudget: pLaborHoursR,
      ApprovedChanges: pLaborHoursA,
      HoursToDate: pHrsToDate,
      HoursToComplete: pHrsToComplete,
      ProjectedTotal: NaNtoZero(Math.round(pHrsToDate + pHrsToComplete)),
      Variance: NaNtoZero(Math.round(pLaborHoursR - (pHrsToDate + pHrsToComplete)))
    },
    {
      Title: 'Rate (P)',
      OriginalBudget: SumLabor(ogBudget, ['P']) / pLaborHoursOG,
      ConstructionReAllocation: SumLabor(constructionBudgets, ['P']) / pLaborHoursC,
      ApprovedChanges: SumLabor(approvedCosts, ['P']) / pLaborHoursA,
      RevisedBudget: SumLabor(rBudget, ['P']) / pLaborHoursR,
      HoursToDate: SumToDate(pForecasts, 'CostToDate') / SumToDate(pForecasts, 'HoursToDate'),
      HoursToComplete: NaNtoZero(SumLaborCostToComplete(pForecasts) / pHrsToComplete),
      // pt productive labor from cost details / pt hours
      ProjectedTotal: (PCostToDate + PCostToComplete) / (pHrsToDate + pHrsToComplete),
      Variance: SumLabor(rBudget, ['P']) / pLaborHoursR - (PCostToDate + PCostToComplete) / (pHrsToDate + pHrsToComplete)
    }
  ];
  const npRows = [
    {
      id: 'Hours (NP)',
      Title: 'Hours (NP)',
      OriginalBudget: npLaborHoursOG,
      ConstructionReAllocation: npLaborHoursC,
      RevisedBudget: npLaborHoursR,
      ApprovedChanges: npLaborHoursA,
      HoursToDate: npHrsToDate,
      HoursToComplete: npHrsToComplete,
      ProjectedTotal: NaNtoZero(Math.round(npHrsToDate + npHrsToComplete)),
      Variance: NaNtoZero(Math.round(npLaborHoursR - (npHrsToDate + npHrsToComplete)))
    },
    {
      id: 'Rate (NP)',
      Title: 'Rate (NP)',
      OriginalBudget: SumLabor(ogBudget, ['NP']) / npLaborHoursOG,
      ConstructionReAllocation: SumLabor(constructionBudgets, ['NP']) / npLaborHoursC,
      ApprovedChanges: SumLabor(approvedCosts, ['NP']) / npLaborHoursA,
      RevisedBudget: SumLabor(rBudget, ['NP']) / npLaborHoursR,
      HoursToDate: SumToDate(npForecasts, 'CostToDate', 'NP') / SumToDate(npForecasts, 'HoursToDate', 'NP'),
      HoursToComplete: NaNtoZero(SumLaborCostToComplete(npForecasts) / npHrsToComplete),
      ProjectedTotal: (NPCostToDate + NPCostToComplete) / (npHrsToDate + npHrsToComplete),
      Variance: SumLabor(rBudget, ['NP']) / npLaborHoursR - (NPCostToDate + NPCostToComplete) / (npHrsToDate + npHrsToComplete)
    }
  ];

  return (
    <Grid container className='aldg-bordered'>
      <Grid item xs={12} className='aldg-headerTitle'>
        LABOR SUMMARY
      </Grid>
      <Typography style={{ paddingLeft: '12px' }} variant='h6'>
        Productive
      </Typography>
      <StatusDataGrid rows={pRows} columns={columns} title='Productive Labor Summary' />
      <div
        style={{
          height: '50px',
          width: '100%',
          backgroundColor: 'white',
          position: 'relative',
          top: '50px',
          zIndex: 50,
          marginTop: '-50px',
          display: 'flex',
          alignItems: 'end'
        }}
      >
        <Typography style={{ paddingLeft: '12px' }} variant='h6'>
          Non Productive
        </Typography>
      </div>
      <StatusDataGrid rows={npRows} columns={columns} title='NonProductive Labor Summary' />
    </Grid>
  );
};

LaborSummary.propTypes = {
  changeOrders: PropTypes.arrayOf(PropTypes.object),
  forecast: PropTypes.arrayOf(PropTypes.object)
};
LaborSummary.defaultProps = {
  changeOrders: [],
  forecast: []
};

export default LaborSummary;
