/* eslint-disable no-unused-vars */
import {
  faCircleInfo,
  faChartLineUp,
  faMagnifyingGlassDollar,
  faTableLayout,
  faFileInvoiceDollar,
  faFileChartColumn,
  faUsers,
  faMoneyBillWave,
  faNotebook,
  faListCheck,
  faCalendarLines,
  faPaperclip,
  faScroll,
  faBackwardFast
} from '@fortawesome/pro-regular-svg-icons';
import { AdminUp, EditorUp, PMUp, ViewerUp } from '../UserAssignment/checkSecurity';

const MenuCardData = [
  {
    href: 'JobInformation',
    icon: faCircleInfo,
    title: 'Job Information',
    description: 'Static information about this job.',
    order: 0,
    favorite: true,
    securityLevel: (role) => ViewerUp(role)
  },
  {
    href: 'Agenda',
    icon: faCalendarLines,
    title: 'Status Agenda',
    description: `Walk through this month's status.`,
    order: 1,
    favorite: true,
    securityLevel: (role) => ViewerUp(role)
  },
  {
    href: 'StatusReport',
    icon: faFileChartColumn,
    title: 'Project Status Report',
    description: 'Status overview for this job.',
    order: 2,
    favorite: true,
    securityLevel: (role) => ViewerUp(role)
  },
  {
    href: 'Forecasting',
    icon: faChartLineUp,
    title: 'Forecasting',
    description: 'Forecasting information by month.',
    order: 3,
    favorite: true,
    securityLevel: (role) => ViewerUp(role)
  },
  {
    href: 'Budgets',
    icon: faMagnifyingGlassDollar,
    title: 'Budgets',
    description: 'Budget information by month.',
    order: 4,
    favorite: true,
    securityLevel: (role) => ViewerUp(role)
  },
  {
    href: 'CashFlow',
    icon: faMoneyBillWave,
    title: 'Cash Flow',
    description: 'Projected Cash Flow Schedule',
    order: 5,
    favorite: true,
    securityLevel: (role) => ViewerUp(role)
  },
  {
    href: 'FinancialWorkbook',
    icon: faTableLayout,
    title: 'Financial Workbook',
    description: 'Workbook integration with ECMS.',
    order: 6,
    favorite: false,
    securityLevel: (role) => ViewerUp(role)
  },
  {
    href: 'Underbilling',
    icon: faFileInvoiceDollar,
    title: 'Underbilling',
    description: 'Underbilling scorecard by month.',
    order: 7,
    favorite: true,
    securityLevel: (role) => ViewerUp(role)
  },
  // {
  //   href: 'QuantityForecasting',
  //   icon: faBoxesPacking,
  //   title: 'Quantity Forecasting',
  //   description: 'Track quantity by week.',
  //   order: 8,
  //   favorite: true,
  //   securityLevel: (role) => ViewerUp(role)
  // },
  {
    href: 'Notes',
    icon: faNotebook,
    title: 'Notes',
    description: 'Take notes and write comments.',
    order: 9,
    favorite: true,
    securityLevel: (role) => EditorUp(role)
  },
  {
    href: 'ActionItems',
    icon: faListCheck,
    title: 'Action Items',
    description: `Manage this month's action items`,
    order: 10,
    favorite: true,
    securityLevel: (role) => EditorUp(role)
  },
  {
    href: 'UserAssignment',
    icon: faUsers,
    title: 'User Assignment',
    description: 'Manage who can edit or view this job.',
    order: 11,
    favorite: true,
    securityLevel: (role) => PMUp(role)
  },
  {
    href: 'Attachments',
    icon: faPaperclip,
    title: 'Attachments',
    description: 'View, upload, and download attachments.',
    order: 12,
    favorite: true,
    securityLevel: (role) => ViewerUp(role)
  },
  {
    href: 'ContractAbstract',
    icon: faScroll,
    title: 'Contract Abstract',
    description: 'Contract Abstract',
    order: 2,
    favorite: true,
    securityLevel: (role, beta) => AdminUp(role) || beta
  },
  {
    href: 'MonthRollback',
    icon: faBackwardFast,
    title: 'Rollback Month',
    description: 'Rollback Month',
    order: 2,
    favorite: true,
    securityLevel: (role) => AdminUp(role)
  }
  // {
  //   href: 'CostCodes',
  //   icon: faMoneyBill,
  //   title: 'Cost Codes',
  //   description: 'View, close. and open available cost codes.',
  //   order: 13,
  //   favorite: true,
  //   securityLevel: (role) => ViewerUp(role)
  // }
];
export default MenuCardData;
