export const env = {
  REACT_APP_APIKEY: 'AIzaSyB2TN2yvd_VnbRuT40QEs6wRlQCbpVrPzA',
  REACT_APP_AUTHDOMAIN: 'aldg-pid.firebaseapp.com',
  REACT_APP_PROJECTID: 'aldg-pid',
  REACT_APP_STORAGEBUCKET: 'aldg-pid.appspot.com',
  REACT_APP_MESSAGINGSENDERID: '799596950773',
  REACT_APP_APPID: '1:799596950773:web:29cc2861df0a98386bc1bc',
  REACT_APP_MEASUREMENTID: 'G-4XTDTVBHXK',
  DATA_GRID_LICENSE: '42f6704089ac3d20afaba38a1115b415T1JERVI6MjczODYsRVhQSVJZPTE2NTg1MTUxNjAwMDAsS0VZVkVSU0lPTj0x'
};
