import { getStorage, listAll, ref, getDownloadURL, getMetadata } from 'firebase/storage';
import dayjs from 'dayjs';
import existsWithLength from '../../../utils/existsWithLength';
import getURL from './getURL';

const getAttachments = (mounted, setLoading, setAllFiles, jctdscid) => {
  const storage = getStorage();
  if (mounted.current) {
    setLoading(true);
    setAllFiles([]);
    const allFilesRef = ref(storage, jctdscid);
    listAll(allFilesRef).then((res) => {
      if (existsWithLength(res.items)) {
        res.items.forEach((itemRef) => {
          getMetadata(itemRef).then((meta) => {
            getDownloadURL(itemRef).then((url) => {
              const fileObj = {
                monthEndDate: meta.customMetadata?.monthEndDate,
                downloadURL: url,
                url: getURL(url, meta.contentType),
                type: meta.contentType,
                name: itemRef.name,
                uploadedBy: meta.customMetadata?.uploadedBy,
                uploadedDate: meta.timeCreated,
                LinkTo: meta.customMetadata?.LinkTo,
                NeedsDriveViewer: !(['application/pdf', 'text/plain'].includes(meta.contentType) || meta.contentType.indexOf('image/') > -1)
              };
              if (mounted.current) {
                setAllFiles((prev) => {
                  const newArr = [].concat(JSON.parse(JSON.stringify(prev)), fileObj);
                  const sorted = newArr
                    .filter((obj, index) => newArr.findIndex((item) => item.name === obj.name) === index)
                    ?.sort((a, b) => (dayjs(a.uploadedDate).isBefore(dayjs(b.uploadedDate)) ? 1 : -1));
                  return sorted;
                });
                setLoading(false);
              }
            });
          });
        });
      } else setLoading(false);
    });
  }
};

export default getAttachments;
